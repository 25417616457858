import { all, put, takeLatest } from 'redux-saga/effects';

import { IFullPlaylist } from '../../types/playlist';
import { IPlaylistViewsTableUser } from '../../types/playlistTableUser';

import * as playlistCalls from './playlist.api';
import {
  fetchPlaylistOverview,
  fetchPlaylistOverviewError,
  fetchPlaylistOverviewSuccess,
  fetchPlaylistUserView,
  fetchPlaylistUserViewError,
  fetchPlaylistUserViewSuccess,
} from './playlist.slice';

export default function* playlistSaga() {
  yield all([
    takeLatest(fetchPlaylistOverview, fetchPlaylistOverviewSaga),
    takeLatest(fetchPlaylistUserView, fetchPlaylistUserViewSaga),
  ]);
}

function* fetchPlaylistOverviewSaga(action) {
  try {
    const id = action.payload;
    const { data }: { data: IFullPlaylist } = yield playlistCalls.getPlaylist(id);
    yield put(fetchPlaylistOverviewSuccess(data));
  } catch (error) {
    yield put(fetchPlaylistOverviewError(error.message));
  }
}

function* fetchPlaylistUserViewSaga(action) {
  try {
    const id = action.payload;
    const {
      data,
    }: {
      data: IPlaylistViewsTableUser[];
    } = yield playlistCalls.getPlaylistViewsByUser(id);
    yield put(fetchPlaylistUserViewSuccess(data));
  } catch (error) {
    yield put(fetchPlaylistUserViewError(error.message));
  }
}
