import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import RenderOverviewTable from '../../../components/ReactDataGrid/RenderOverviewTable';
import SelectTableDisplay from '../../../components/SelectTableDisplay';
import useIsMobile from '../../../hooks/useIsMobile';
import { selectBite, setBiteViewsByUserTableDisplay } from '../bite.slice';

import OverviewTable from './OverviewTable';
import Table from './Table';

function BiteViews() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { isFetchingBiteViews, viewsError, biteViewsByUserTableDisplay } = useSelector(selectBite);

  if (isFetchingBiteViews) {
    return <Loader />;
  }

  if (viewsError) {
    return <ErrorMsg>{t('bites.errorLoadingViews', { error: viewsError })}</ErrorMsg>;
  }

  const renderTable = () => {
    if (biteViewsByUserTableDisplay !== 'drilldown') {
      return null;
    }
    return <Table />;
  };

  return (
    <>
      <SelectTableDisplay
        currentDisplay={biteViewsByUserTableDisplay}
        setDisplay={setBiteViewsByUserTableDisplay}
        origin='bite'
      />
      <RenderOverviewTable
        Table={OverviewTable}
        style={{ maxWidth: isMobile ? '100%' : 500 }}
        tableDisplay={biteViewsByUserTableDisplay}
      />
      {renderTable()}
    </>
  );
}

export default BiteViews;
