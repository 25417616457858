import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import store from '../app/store';
import { logError } from '../features/tracking/tracking.slice';

export const LANGUAGES = [
  { code: 'en', name: 'English' },
  // { code: 'he', name: 'עברית' },
  // { code: 'es', name: 'Español' },
  // { code: 'fr', name: 'Français' },
  // { code: 'de', name: 'Deutsch' },
  { code: 'pt', name: 'Português' },
];

export const initI18 = (translations) => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: localStorage.getItem('locale') || 'en',
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export const setLanguage = async (locale) => {
  try {
    await i18n.changeLanguage(locale);
    localStorage.setItem('locale', locale);
  } catch (error) {
    store.dispatch(logError({ data: { error } }));
  }
};

export const isRtl = () => i18n?.dir(i18n.language) === 'rtl';

export default i18n;
