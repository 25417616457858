import { v4 as uuidv4 } from 'uuid';

import localStorage from '../../utils/localStorage';

let _acrossSessionsId = localStorage.getItem('acrossSessionsId');
if (!_acrossSessionsId) {
  _acrossSessionsId = uuidv4();
  localStorage.setItem('acrossSessionsId', _acrossSessionsId);
}
export const acrossSessionsId = _acrossSessionsId;

export const sessionId = uuidv4();
