import { makeStyles } from '@material-ui/core';
import React from 'react';

import { ReactComponent as MultpileChoicesIcon } from '../../../assets/icons/multiple-choices.svg';
import { ReactComponent as OpenEndedIcon } from '../../../assets/icons/open-ended.svg';
import { ReactComponent as SurveyIcon } from '../../../assets/icons/survey.svg';
import { IQuestionType } from '../../../types/question';

export const QUESTION_WIDTH_BIG = 224;
export const QUESTION_WIDTH_SMALL = 150;

interface IProps {
  text: string;
  answeredCorrectlyPercentage: number;
  type: IQuestionType;
  index: number;
}

function Question({ text, answeredCorrectlyPercentage, type, index }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.question} data-cy='quizQuestion'>
      <div className={classes.header}>
        <div className={classes.title}>Question {index + 1}</div>
        {type === 'multiple choices' && <MultpileChoicesIcon className={classes.icon} />}
        {type === 'survey' && <SurveyIcon className={classes.icon} />}
        {type === 'open ended' && <OpenEndedIcon className={classes.icon} />}
      </div>
      <div className={classes.content}>
        <div className={classes.text}>{text}</div>
        {type === 'multiple choices' && <div className={classes.correctPercentage}>{answeredCorrectlyPercentage}%</div>}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  question: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.32)',
    border: 'solid 1px #b3b3b3',
    backgroundColor: 'rgba(18, 83, 250, 0.1)',
    [theme.breakpoints.up('md')]: {
      width: QUESTION_WIDTH_BIG,
      height: 136,
      borderRadius: 12,
    },
    [theme.breakpoints.down('sm')]: {
      width: QUESTION_WIDTH_SMALL,
      height: 100,
      borderRadius: 9,
    },
  },
  header: {
    padding: '0 9px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #fff',
    [theme.breakpoints.up('md')]: {
      height: 27,
    },
    [theme.breakpoints.down('sm')]: {
      height: 20,
    },
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  icon: {
    height: 20,
    width: 11,
    fill: '#4d4d4d',
  },
  content: {
    padding: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text: {
    lineHeight: 1.2,
    color: '#4f5c6d',
    [theme.breakpoints.up('md')]: {
      height: 51,
    },
    [theme.breakpoints.down('sm')]: {
      height: 36,
      fontSize: 10,
    },
  },
  correctPercentage: {
    color: '#1253fa',
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      fontSize: 34,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
}));

export default Question;
