import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import StyledPercentage from '../../../../components/StyledPercentage';
import { percentage } from '../../../../utils/numbers';
import { selectQuiz } from '../../quiz.slice';

interface IArgs {
  dataSource: any[];
}

const useFooterRows = ({ dataSource }: IArgs) => {
  const { questions } = useSelector(selectQuiz);

  return useMemo(() => {
    const CompletedPercentageFooter = () => {
      const count = sumBy(dataSource, (item) => Number(item.completed));
      const total = sumBy(dataSource, 'recordsCount');
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    const AnsweredCorrectlyPercentageFooter = () => {
      const count = sumBy(dataSource, (data) => (data.answeredCorrectly ? Number(data.answeredCorrectly) : 0));
      const total = sumBy(dataSource, 'scorableQuestionsCount');
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    const AnsweredPercentageFooter = () => {
      const count = sumBy(dataSource, (item) => Number(item.answered));
      const total = sumBy(dataSource, 'recordsCount') * questions.length;
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    return [
      {
        render: {
          name: () => `${sumBy(dataSource, 'recordsCount')} Users`,
          completedPercentage: CompletedPercentageFooter,
          answeredCorrectlyPercentage: AnsweredCorrectlyPercentageFooter,
          answeredPercentage: AnsweredPercentageFooter,
        },
      },
    ];
  }, [dataSource, questions.length]);
};

export default useFooterRows;
