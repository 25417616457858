import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectBites } from '../features/bites/bites.slice';
import gtmTrack from '../services/googleTagManager/track';
import { formatDate } from '../utils/dates';

import EllipsisWithTooltip from './EllipsisWithTooltip';
import ReactDataGridSearchableTable from './ReactDataGrid/SearchableTable';

interface IProps {
  onBiteSelect?: () => void;
}

function BiteSelectionTable({ onBiteSelect }: IProps) {
  const { t } = useTranslation();
  const { bites } = useSelector(selectBites);

  const columns = useMemo((): TypeColumn[] => {
    const Name = ({ data, value }) => (
      <Link onClick={() => handleLinkClick(data.id)} to={`/bites/${data.id}`} data-cy='name'>
        <EllipsisWithTooltip text={value} />
      </Link>
    );
    const DateColumn = ({ value }) => formatDate(value);
    const handleLinkClick = (id) => {
      gtmTrack('open_bite', { bite_id: id });
      if (onBiteSelect) {
        onBiteSelect();
      }
    };

    return [
      {
        name: 'subject',
        header: t('table.bite'),
        defaultFlex: 1,
        render: Name,
      },
      {
        name: 'watched',
        type: 'number',
        header: t('table.views'),
        defaultFlex: 1,
      },
      {
        name: 'answered',
        type: 'number',
        header: t('table.answered'),
        defaultFlex: 1,
      },
      {
        name: 'commentsCount',
        type: 'number',
        header: t('table.comments'),
        defaultFlex: 1,
      },
      {
        name: 'createdAt',
        header: t('table.dateCreated'),
        defaultFlex: 1,
        render: DateColumn,
      },
      {
        name: 'ownerName',
        header: t('table.creator'),
        defaultFlex: 1,
      },
    ];
  }, [t, onBiteSelect]);

  const footerRows = [
    {
      render: {
        subject: () => t('table.bitesLength', { length: bites.length }),
      },
    },
  ];

  const onSortInfoChange = (selectedColumn) => {
    if (selectedColumn?.id) {
      if (selectedColumn.id === 'commentsCount') {
        gtmTrack('bites_sort_by_comments');
      } else if (selectedColumn.id === 'watched') {
        gtmTrack('bites_sort_by_views');
      } else if (selectedColumn.id === 'createdAt') {
        gtmTrack('bites_sort_by_date_created');
      } else if (selectedColumn.id === 'subject') {
        gtmTrack('bites_sort_by_bite');
      }
    }
  };

  const handleSearch = (term) => {
    trackSearch(term);
  };

  return (
    <ReactDataGridSearchableTable
      rawData={bites}
      columns={columns}
      footerRows={footerRows}
      onSearch={handleSearch}
      onSortInfoChange={onSortInfoChange}
      origin='bites_selection_table'
      intercomTarget='bites_table'
      intercomExportTarget='export_bites'
    />
  );
}

export default BiteSelectionTable;

const trackSearch = debounce((term) => gtmTrack('bites_search', { term }), 1000);
