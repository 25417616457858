import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IAction } from '../../types/types';
import { loginFromQueryTokenSuccess, loginSuccess, refreshUserSuccess } from '../auth/auth.slice';
import {
  EAuthFormType,
  IAuthDataMissingPayload,
  IFinalizeProfileAction,
  ILoginWithEmail,
  ILoginWithPhone,
  IPasswordRecoveryBody,
  ISetNewRecoverPassword,
  TSignupErrorCodes,
} from '../auth/auth.types';

import { IInitialState } from './authForm.types';

export const resetErrors = createAction('auth/resetErrors');

export const initialState: IInitialState = {
  //data
  currentForm: EAuthFormType.AUTH,
  email: '',
  password: '',
  phone: '',
  firstName: '',
  lastName: '',

  //statuses
  userSelectedCountryCode: null,
  recoveryToken: null,
  isAuthDataMissing: false,
  errorCodes: [],
  isLoading: false,
  isLoginError: false,
  isWrongVerificationCode: false,
  isProcessingQueryToken: false,
  isPhoneMissing: false,
  // user info screen
  userProfile: {
    isLoading: false,
    errors: [],
  },
  social: {
    isLoading: false,
    isError: false,
  },
  // forgot password screen
  passwordRecovery: {
    isLoading: false,
    isSuccess: false,
    error: false,
  },
  // reset password screen
  newRecoverPassword: {
    isLoading: false,
    error: null,
  },
};

const AUTH_FORM = 'AUTH_FORM';

export const loginError = createAction(`${AUTH_FORM}/loginError`);

const authFormSlice = createSlice({
  name: AUTH_FORM,
  initialState,
  reducers: {
    setCurrentForm: (state, { payload }: IAction<EAuthFormType>) => {
      state.currentForm = payload;
    },
    setEmail: (state, { payload }: IAction<string>) => {
      state.email = payload;
    },
    setPassword: (state, { payload }: IAction<string>) => {
      state.password = payload;
    },
    setPhone: (state, { payload }: IAction<string>) => {
      state.phone = payload;
    },
    setIsAuthDataMissing: (state, { payload }: IAction<boolean>) => {
      state.isAuthDataMissing = payload;
    },
    setRecoveryToken: (state, { payload }: IAction<string>) => {
      state.recoveryToken = payload;
    },
    setUserSelectedCountryCode: (state, { payload }: IAction<string>) => {
      state.userSelectedCountryCode = payload;
    },
    setFirstName: (state, { payload }: IAction<string>) => {
      state.firstName = payload;
    },
    setLastName: (state, { payload }: IAction<string>) => {
      state.lastName = payload;
    },
    setMissedAuthData: (state, { payload }: IAction<IAuthDataMissingPayload>) => {
      state.currentForm = EAuthFormType.USER_INFO;
      state.isAuthDataMissing = payload.isAuthDataMissing;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phone = payload.phone;
    },
    setIsPhoneMissing: (state: IInitialState, action: IAction<boolean>) => {
      state.isPhoneMissing = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setNewRecoverPassword: (state: IInitialState, action: IAction<ISetNewRecoverPassword>) => {
      state.newRecoverPassword = {
        isLoading: true,
        error: null,
      };
    },
    setNewRecoverPasswordLoading: (state: IInitialState, action) => {
      state.newRecoverPassword.isLoading = action.payload;
    },
    setNewRecoverPasswordError: (state: IInitialState, action) => {
      state.newRecoverPassword.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginWithEmail: (state: IInitialState, action: IAction<ILoginWithEmail>) => {
      state.isLoading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginWithPhone: (state: IInitialState, action: IAction<ILoginWithPhone>) => {
      state.isLoading = true;
    },
    refreshUser: (state: IInitialState) => {
      state.userProfile.isLoading = true;
    },
    setRefreshUserErrors: (state: IInitialState, action) => {
      state.userProfile.isLoading = false;
      state.userProfile.errors = action.payload || [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    finalizeUserProfile: (state: IInitialState, action: IAction<IFinalizeProfileAction>) => {
      state.userProfile = {
        isLoading: true,
        errors: [],
      };
    },
    socialLogin: (state: IInitialState) => {
      state.social = {
        isLoading: true,
        isError: false,
      };
    },
    setIsSocialLoginError: (state: IInitialState, action) => {
      state.social = {
        isLoading: false,
        isError: action.payload,
      };
    },
    setIsWrongVerificationCode: (state: IInitialState, action) => {
      state.isWrongVerificationCode = action.payload;
    },

    loginFromQueryToken: (state: IInitialState) => {
      state.isProcessingQueryToken = true;
      state.social.isLoading = true;
    },
    loginFromQueryTokenError: (state: IInitialState) => {
      state.isProcessingQueryToken = false;
      state.social.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    passwordRecovery: (state: IInitialState, action: IAction<IPasswordRecoveryBody>) => {
      state.passwordRecovery = {
        isLoading: true,
        isSuccess: false,
        error: false,
      };
    },
    passwordRecoverySuccess: (state: IInitialState) => {
      state.passwordRecovery = {
        isLoading: false,
        isSuccess: true,
        error: false,
      };
    },
    passwordRecoveryError: (state: IInitialState) => {
      state.passwordRecovery = {
        isLoading: false,
        isSuccess: false,
        error: true,
      };
    },
    passwordRecoveryErrorReset: (state: IInitialState) => {
      state.passwordRecovery = {
        isLoading: false,
        isSuccess: false,
        error: false,
      };
    },
    setAuthErrorCodes: (state: IInitialState, action: IAction<TSignupErrorCodes[]>) => {
      state.errorCodes = action.payload;
      state.isLoading = false;
    },
    setIsLoading: (state: IInitialState, action: IAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearUserProfileErrorsByType: (state: IInitialState, action: IAction<string[]>) => {
      const newErrors = state.userProfile.errors.filter((errorCode) => !action.payload.includes(errorCode));

      if (newErrors.length === state.userProfile.errors.length) {
        return state;
      }

      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          errors: newErrors,
        },
      };
    },
    resetAuthForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loginSuccess, () => initialState);

    builder.addCase(refreshUserSuccess, (state: IInitialState) => {
      state.userProfile = {
        isLoading: false,
        errors: [],
      };
    });

    builder.addCase(loginFromQueryTokenSuccess, (state: IInitialState) => {
      state.isProcessingQueryToken = false;
      state.social.isLoading = false;
    });

    builder.addMatcher(
      isAnyOf(loginError, setRefreshUserErrors, setNewRecoverPasswordError),
      (state: IInitialState) => {
        state.isLoginError = true;
        state.isLoading = false;
      },
    );

    builder.addMatcher(isAnyOf(setCurrentForm, resetErrors), (state: IInitialState) => {
      state.errorCodes = [];
      state.isPhoneMissing = false;
      state.isLoginError = false;
      state.isLoading = false;
      state.isWrongVerificationCode = false;
      state.passwordRecovery = {
        isLoading: false,
        isSuccess: false,
        error: false,
      };
      state.social = {
        isLoading: false,
        isError: false,
      };
      state.userProfile.errors = [];
    });
  },
});

export const {
  setIsPhoneMissing,
  socialLogin,
  loginWithEmail,
  loginWithPhone,
  setIsSocialLoginError,
  setIsWrongVerificationCode,
  loginFromQueryToken,
  loginFromQueryTokenError,
  passwordRecovery,
  passwordRecoverySuccess,
  passwordRecoveryError,
  setIsLoading,
  setAuthErrorCodes,
  setNewRecoverPasswordLoading,
  setNewRecoverPasswordError,
  setNewRecoverPassword,
  setRefreshUserErrors,
  finalizeUserProfile,
  refreshUser,
  clearUserProfileErrorsByType,
  setCurrentForm,
  setEmail,
  setPassword,
  setPhone,
  resetAuthForm,
  setRecoveryToken,
  setUserSelectedCountryCode,
  setMissedAuthData,
  setIsAuthDataMissing,
  setFirstName,
  setLastName,
} = authFormSlice.actions;

export default authFormSlice.reducer;
