import { all, put, select, takeLatest } from 'redux-saga/effects';

import { ILeanQuiz } from '../../types/quiz';
import { selectOrg } from '../org/org.slice';

import * as quizzesCalls from './quizzes.api';
import { fetchQuizzes, fetchQuizzesError, fetchQuizzesSuccess } from './quizzes.slice';

export default function* quizzesSaga() {
  yield all([takeLatest(fetchQuizzes, fetchQuizzesSaga)]);
}

function* fetchQuizzesSaga() {
  try {
    const { id: orgId } = yield select(selectOrg);
    const { data }: { data: ILeanQuiz[] } = yield quizzesCalls.getQuizzes(orgId);
    yield put(fetchQuizzesSuccess(data));
  } catch (error) {
    yield put(fetchQuizzesError(error.message));
  }
}
