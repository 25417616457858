import React from 'react';

import { ReactComponent as EmailIcon } from '../../../assets/icons/email.svg';
import { ReactComponent as SMSIcon } from '../../../assets/icons/sms.svg';
import { ReactComponent as WhatsappIcon } from '../../../assets/icons/whatsapp.svg';
import BeekeeperIcon from '../../../assets/images/beekeeper-logo.png';
import { EIntegrations, TAllowedDMShareIntegrations } from '../../../types/integration';

export const getIntegrationFormattedTitle = (integrationType: TAllowedDMShareIntegrations) => {
  switch (integrationType) {
    case EIntegrations.SMS:
      return 'common.sms';
    case EIntegrations.WhatsApp:
      return 'common.whatsApp';
    case EIntegrations.Email:
      return 'common.email';
    case EIntegrations.Beekeeper:
      return 'common.beekeeper';
    default:
      return 'common.message';
  }
};

export const getIntegrationIcon = (integrationType: TAllowedDMShareIntegrations) => {
  switch (integrationType) {
    case EIntegrations.SMS:
      return SMSIcon;
    case EIntegrations.WhatsApp:
      return WhatsappIcon;
    case EIntegrations.Email:
      return EmailIcon;
    case EIntegrations.Beekeeper:
      // eslint-disable-next-line react/display-name
      return ({ className }) => <img src={BeekeeperIcon} alt='beekeeper-small-logo' className={className} />;
    default:
      return SMSIcon;
  }
};

export type TReplaceDistributionTextPlaceholdersInput = {
  title: string;
  fullName?: string;
  biteName?: string;
  organizationName?: string;
};

export function replaceDistributionTextPlaceholders({
  title,
  fullName,
  biteName,
  organizationName,
}: TReplaceDistributionTextPlaceholdersInput): string {
  if (!title) {
    return '';
  }
  let newTitle = title;

  if (biteName && newTitle.includes('{Bite Name}')) {
    newTitle = newTitle.replace(/{Bite Name}/g, biteName);
  }

  if (organizationName && newTitle.includes('{Organization Name}')) {
    newTitle = newTitle.replace(/{Organization Name}/g, organizationName);
  }

  if (fullName) {
    newTitle = newTitle.replace(/{Full Name}/g, fullName).replace(/{שם מלא}/g, fullName);
  }

  return newTitle;
}

export const basicMarkdownHandler = (markdown: string): string => {
  if (markdown === '') {
    return '';
  }

  let newMarkdown = markdown;

  newMarkdown = newMarkdown.replace(/\*\*\*(\S[\s\S]*?\S)\*\*\*/g, '<b><i>$1</i></b>');

  newMarkdown = newMarkdown.replace(/\*\*(\S[\s\S]*?\S)\*\*/g, '<b>$1</b>');

  newMarkdown = newMarkdown.replace(/(?<!\*)\*(\S[\s\S]*?\S)\*(?!\*)/g, '<i>$1</i>');

  newMarkdown = newMarkdown.replace(/\n/g, '<br>');

  return newMarkdown;
};
