import sortBy from 'lodash/sortBy';
import React from 'react';

import AnswerDistributionItem from './AnswerDistributionItem';

function AnswersDistribution({ answers }) {
  const sortedAnswers = sortBy(answers, (x) => !x.percentage);

  return (
    <>
      {sortedAnswers.map((answer) => (
        <AnswerDistributionItem
          isCorrect={answer.isCorrect}
          key={answer.text}
          text={answer.text}
          percentage={answer.percentage.toFixed()}
        />
      ))}
    </>
  );
}

export default AnswersDistribution;
