import React from 'react';
import { JSX } from 'react-dom';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import RenderOverviewTable from '../../../components/ReactDataGrid/RenderOverviewTable';
import SelectTableDisplay from '../../../components/SelectTableDisplay';
import { selectQuiz, setQuizViewsByUserTableDisplay } from '../quiz.slice';

import OverviewTable from './OvreviewTable';
import Table from './Table';

function QuizUserView() {
  const { isFetchingUserView, userViewError, quizViewsByUserTableDisplay } = useSelector(selectQuiz);

  if (isFetchingUserView) {
    return <Loader />;
  }

  if (userViewError) {
    return <ErrorMsg>Error loading data: {userViewError}</ErrorMsg>;
  }

  const renderTable = (): JSX.Element => {
    if (quizViewsByUserTableDisplay !== 'drilldown') {
      return null;
    }
    return <Table />;
  };

  return (
    <>
      <SelectTableDisplay
        currentDisplay={quizViewsByUserTableDisplay}
        setDisplay={setQuizViewsByUserTableDisplay}
        origin='quiz'
      />
      <RenderOverviewTable Table={OverviewTable} style={{ maxWidth: 600 }} tableDisplay={quizViewsByUserTableDisplay} />
      {renderTable()}
    </>
  );
}

export default QuizUserView;
