import { all, put, select, takeLatest } from 'redux-saga/effects';

import { ILeanPlaylist } from '../../types/playlist';
import { selectOrg } from '../org/org.slice';

import * as playlistsCalls from './playlists.api';
import { fetchPlaylists, fetchPlaylistsError, fetchFullPlaylistsSuccess } from './playlists.slice';

export default function* playlistsSaga() {
  yield all([takeLatest(fetchPlaylists, fetchPlaylistsSaga)]);
}

function* fetchPlaylistsSaga() {
  try {
    const leanPlaylists: ILeanPlaylist[] = yield fetchLeanPlaylists();
    yield put(fetchFullPlaylistsSuccess(leanPlaylists));
  } catch (error) {
    yield put(fetchPlaylistsError(error.message));
  }
}

function* fetchLeanPlaylists() {
  const { id: orgId } = yield select(selectOrg);
  const { data: leanPlaylists } = yield playlistsCalls.getPlaylists(orgId);
  return leanPlaylists;
}
