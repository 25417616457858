import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

interface IProps {
  headline: React.ReactNode;
  headerEnd?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

function MainScreen({ headline, headerEnd, children, className, ...restOfProps }: IProps) {
  const classes = useStyles();
  return (
    <div {...restOfProps} className={cx(className, classes.root)}>
      <div className={classes.header}>
        <h1>{headline}</h1>
        {headerEnd}
      </div>
      <div className={classes.main}>{children}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  main: {
    backgroundColor: 'white',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 6,
      boxShadow: '0 2px 12px 0 rgba(18,83,250,0.11), 0 1px 2px 0 rgba(175,196,250,0.08)',
    },
  },
}));

export default MainScreen;
