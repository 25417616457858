import React, { CSSProperties, FC, InputHTMLAttributes, memo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as PasswordHiddenIcon } from '../../../assets/icons/password-hidden.svg';
import { ReactComponent as PasswordVisibleIcon } from '../../../assets/icons/password-visible.svg';
import { isRtl } from '../../../locale/i18n';

interface IProps extends Omit<InputHTMLAttributes<string>, 'style' | 'value' | 'onChange'> {
  value: string;
  style?: CSSProperties;
  inputStyles?: CSSProperties;
  label?: string;
  error?: string;
  password?: boolean;
  onChange: (value: string) => void;
  onEnterPress?: () => void;
}

const FormTextInput: FC<IProps> = ({
  value = '',
  onChange,
  style,
  inputStyles,
  label,
  error,
  password,
  onEnterPress,
  ...props
}) => {
  const rtl = isRtl();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(!!value?.length);
  const isShifted = isFilled || isFocused;

  const [isPasswordVisible, setIsPasswordVisible] = useState(!password);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChangeText = useCallback(
    (event) => {
      setIsFilled(!!event.target.value.length);
      onChange?.(event.target.value);
    },
    [onChange],
  );

  const handleSecurePress = useCallback(() => {
    setIsPasswordVisible((prev) => !prev);
  }, []);

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && typeof onEnterPress === 'function') {
        onEnterPress();
      }
    },
    [onEnterPress],
  );

  return (
    <S.Container style={style}>
      <S.LabelWrapper rtl={rtl} isShifted={isShifted}>
        <S.Label>{label}</S.Label>
      </S.LabelWrapper>
      <S.InputContainer rtl={rtl} withError={!!error} isShifted={isShifted}>
        {/* @ts-ignore */}
        <S.Input
          rtl={rtl}
          value={value}
          onChange={handleChangeText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={inputStyles}
          type={isPasswordVisible ? 'text' : 'password'}
          onKeyPress={handleEnterPress}
          {...props}
        />
        {!!password && (
          <S.SecureButton rtl={rtl} onClick={handleSecurePress}>
            {isPasswordVisible ? <PasswordHiddenIcon /> : <PasswordVisibleIcon />}
          </S.SecureButton>
        )}
      </S.InputContainer>
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    position: relative;
  `,
  InputContainer: styled.div<{ rtl: boolean; withError: boolean; isShifted: boolean }>`
    position: relative;
    flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
    display: flex;
    width: 100%;
    height: 49px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.lightGray34};
    border-radius: ${49 / 2}px;
    transition: all 0.3s ease;
    overflow: hidden;

    border-color: ${({ withError, isShifted, theme }) =>
      withError ? theme.colors.pinkError : isShifted ? theme.colors.primaryBlue : theme.colors.lightGray34};
  `,
  Input: styled.input<{ password?: boolean; rtl?: boolean }>`
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    text-align: ${({ rtl }) => (rtl ? 'right' : 'left')};
    padding: 16px 22px;
    ${({ password, rtl }) => (password ? (rtl ? 'padding-left: 52px;' : 'padding-right: 52px;') : '')}
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.text};
  `,
  LabelWrapper: styled.div<{ isShifted?: boolean; rtl?: boolean }>`
    position: absolute;
    pointer-events: none;
    z-index: 2;
    transition: all 300ms;
    top: 19px;
    ${({ rtl, isShifted }) =>
      rtl
        ? css`
            right: 22px;
            transform: translateX(${isShifted ? 3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `
        : css`
            left: 22px;
            transform: translateX(${isShifted ? -3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `}

    background-color: ${({ isShifted }) => (isShifted ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')};
  `,
  Label: styled.div<{ isShifted?: boolean }>`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.gray19};
    padding: 0 5px;
    transition: all 0.3s ease;
    margin-bottom: -4px;
  `,
  ErrorText: styled.span`
    display: inline-block;
    text-align: center;
    align-self: center;
    width: 297px;
    font-size: 13px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.pinkError};
  `,
  SecureButton: styled.div<{ rtl?: boolean }>`
    cursor: pointer;
    display: flex;
    position: absolute;
    height: 100%;
    top: 12px;
    ${({ rtl }) => (rtl ? 'left' : 'right')}: 20px;
  `,
};

export default memo(FormTextInput);
