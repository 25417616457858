import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { AUTH_PATHS } from '../../App';
import history from '../../app/history';
import { APP } from '../../constants/constants';
import { resetBitesApiAuthorization, setBitesApiAuthorization } from '../../services/bitesApi';
import gtmTrack, { gtmSetUserId, gtmSetUserProps } from '../../services/googleTagManager/track';
import { logError } from '../../services/log';
import * as authCalls from '../../store/api/auth/auth.api';
import { IAction } from '../../types/types';
import {
  ICurrentUser,
  IUserOrganization,
  ICurrentUserUserOrganization,
  IFinalizeProfileDataRequest,
} from '../../types/user';
import { getFullName } from '../../utils/auth';
import handleHttpErrors from '../../utils/errors/handleHttpErrors';
import {
  finalizeUserProfile,
  loginError,
  loginFromQueryToken,
  loginFromQueryTokenError,
  loginWithEmail,
  loginWithPhone,
  passwordRecovery,
  passwordRecoveryError,
  passwordRecoverySuccess,
  refreshUser,
  resetAuthForm,
  setAuthErrorCodes,
  setIsAuthDataMissing,
  setIsLoading,
  setIsPhoneMissing,
  setIsSocialLoginError,
  setIsWrongVerificationCode,
  setMissedAuthData,
  setNewRecoverPassword,
  setNewRecoverPasswordError,
  setNewRecoverPasswordLoading,
  setRefreshUserErrors,
  socialLogin,
  resetErrors,
} from '../authForm/authForm.slice';
import { initFeatureFlags } from '../featureFlag/featureFlag.slice';
import { fetchOrg, selectOrg } from '../org/org.slice';
import { log } from '../tracking/tracking.slice';
import { fetchUsers } from '../userManagement/userManagement.slice';

import AuthErrorTypes from './auth.errors';
import { currentUserSelector } from './auth.selectors';
import {
  initClean,
  loginSuccess,
  logout,
  refreshUserSuccess,
  loginFromQueryTokenSuccess,
  prefetchMainScreens,
  onUser,
} from './auth.slice';
import { IFinalizeProfileAction, ILoginWithEmail, ILoginWithPhone } from './auth.types';

export default function* authSaga() {
  yield all([
    takeLatest(initClean, initCleanSaga),
    takeLatest(loginWithEmail, loginWithEmailSaga),
    takeLatest(loginWithPhone, loginWithPhoneSaga),
    takeLatest(socialLogin, socialLoginSaga),
    takeLatest(refreshUser, refreshUserSaga),
    takeLatest(passwordRecovery, passwordRecoverySaga),
    takeLatest(setNewRecoverPassword, setNewRecoverPasswordSaga),
    takeLatest(loginFromQueryToken, loginFromQueryTokenSaga),
    takeLatest(logout, handleLogout),
    takeLatest(prefetchMainScreens, prefetchMainScreensSaga),
    takeLatest(setIsSocialLoginError, setIsSocialLoginErrorSaga),
    takeLatest(onUser, onUserSaga),
    takeLatest(finalizeUserProfile, finalizeUserProfileSaga),
  ]);
}

function* setMissedAuthDataSaga({ event = 'setMissedAuthDataSaga', processId = undefined, user }) {
  if (!user?.firstName && !user?.lastName) {
    yield put(
      log({
        event,
        processId,
        data: {
          user,
        },
      }),
    );

    yield put(
      setMissedAuthData({
        isAuthDataMissing: true,
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
        phone: user?.phone || '',
      }),
    );
    return true;
  }
  return false;
}

function* initCleanSaga() {
  yield put(resetErrors());
}

function* setNewRecoverPasswordSaga(action: any) {
  const { password, token, processId, onSuccess } = action.payload;
  try {
    yield put(
      log({
        event: 'auth.saga setNewRecoverPasswordSaga: start',
        processId,
        data: {
          token,
        },
      }),
    );
    yield authCalls.setNewRecoverPassword({ password, token });

    yield put(
      log({
        event: 'auth.saga setNewRecoverPasswordSaga: setNewRecoverPassword.success',
        processId,
        data: {
          token,
        },
      }),
    );
    yield put(setNewRecoverPasswordLoading(false));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err) {
    yield call(handleHttpErrors, {
      event: 'auth.saga setNewRecoverPasswordSaga: error',
      error: err,
      processId,
    });
    yield put(setNewRecoverPasswordLoading(false));

    const error = err.response?.data;

    const errorCodes = error?.errors?.map((e) => e.code);
    yield put(setNewRecoverPasswordError(errorCodes));
  }
}

function* loginWithEmailSaga(action: IAction<ILoginWithEmail>) {
  const { username, password, otp, onOtp, processId } = action.payload;
  const loginCredentials = {
    username,
    password,
    otp,
    app: APP,
  };

  yield put(
    log({
      event: 'auth.saga loginWithEmailSaga: start',
      processId,
      data: { loginCredentials },
    }),
  );

  try {
    const { data }: { data: authCalls.ILoginResponse } = yield authCalls.login(loginCredentials);
    const { token, user } = data;

    yield put(
      log({
        event: 'auth.saga loginWithEmailSaga: result',
        processId,
        data: {
          token,
          user,
        },
      }),
    );

    setToken(token);
    yield put(loginSuccess({ token, user }));
    yield onLoginSuccess(user);
    gtmTrack('login', {
      login_type: 'email',
      login_status: 'Success',
      'gtm.userId': user?.id,
    });

    const isMissed = yield setMissedAuthDataSaga({
      event: 'auth.saga loginWithEmailSaga: missingAuthData',
      processId,
      user,
    });

    if (isMissed) {
      return;
    }

    history.push('/overview');
  } catch (err) {
    yield call(handleHttpErrors, { event: 'auth.saga loginWithEmailSaga: error', processId, error: err });
    yield put(setIsLoading(false));

    const status = err.response?.status;
    const error = err.response?.data;

    if (status === 401 && error?.verified === false && !otp) {
      if (typeof onOtp === 'function') {
        onOtp(processId);
      }
      // return - this is not an error case
      return;
    }

    if (status === 401 && error.verified === false) {
      yield put(setIsWrongVerificationCode(true));
      return;
    }

    // fix this weird logic when we clearly define the error codes
    if (!error?.error_codes) {
      if (error?.message === 'Your username or password is incorrect, Please try again.') {
        yield put(setAuthErrorCodes([AuthErrorTypes.wrongCredentials]));
      } else {
        yield put(setAuthErrorCodes(error?.code ? [error.code] : []));
      }
    } else {
      yield put(setAuthErrorCodes(error.error_codes));
    }
    yield put(loginError());
    yield put(onUser(null));
  }
}

function* loginWithPhoneSaga(action: IAction<ILoginWithPhone>) {
  const { username, otp, onOtp, processId } = action.payload;

  const loginCredentials = {
    username,
    otp,
    app: APP,
  };

  yield put(
    log({
      event: 'auth.saga loginWithPhoneSaga: start',
      processId,
      data: loginCredentials,
    }),
  );

  try {
    const { data } = yield authCalls.loginWithPhone(loginCredentials);

    yield put(
      log({
        event: 'auth.saga loginWithPhoneSaga: result',
        processId,
        data,
      }),
    );
    const { token, user } = data;

    setToken(token);
    yield put(loginSuccess({ token, user }));
    yield onLoginSuccess(user);
    gtmTrack('login', {
      login_type: 'phone',
      login_status: 'Success',
      'gtm.userId': user?.id,
    });

    const isMissed = yield setMissedAuthDataSaga({
      event: 'auth.saga loginWithPhoneSaga: missingAuthData',
      processId,
      user,
    });

    if (isMissed) {
      return;
    }

    history.push('/overview');
  } catch (err) {
    yield call(handleHttpErrors, { event: 'auth.saga loginWithPhoneSaga: error', processId, error: err });
    yield put(setIsLoading(false));
    yield put(onUser(null));

    const status = err.response?.status;
    const error = err.response?.data;

    if (status === 401 && error?.verified === false && !loginCredentials.otp) {
      if (typeof onOtp === 'function') {
        onOtp(processId);
      }
      // return - this is not an error case
      return;
    }

    if (status === 401 && error?.verified === false) {
      gtmTrack('login_error', {
        login_type: 'phone',
        login_error_message: AuthErrorTypes.invalidAccessCode,
      });
      yield put(setIsWrongVerificationCode(true));
      return;
    }

    if (status === 400 && !loginCredentials.otp) {
      gtmTrack('login_error', {
        login_type: 'phone',
        login_error_message: AuthErrorTypes.phoneNumberNotExists,
      });
      yield put(setIsPhoneMissing(true));
      return;
    }

    yield put(setAuthErrorCodes(error?.error_codes || error?.code ? [error.code] : []));
    yield put(loginError());

    gtmTrack('login_error', {
      login_type: 'phone',
    });
  }
}

function* socialLoginSaga({ payload }) {
  const loginType = payload.backend.includes('azuread')
    ? 'microsoft'
    : payload.backend.includes('google')
    ? 'google'
    : payload.backend;

  try {
    yield put(
      log({
        event: 'auth.saga socialLoginSaga: start',
        data: { payload },
      }),
    );

    const {
      data,
    }: {
      data: authCalls.IExchangeSsoTokenResponse;
    } = yield authCalls.exchangeSsoToken(payload);

    yield put(
      log({
        event: 'auth.saga socialLoginSaga: result',
        data,
      }),
    );

    const token = data.access_token;
    setToken(token);

    const { data: user } = yield authCalls.getCurrentUser();

    yield put(
      log({
        event: 'auth.saga socialLoginSaga: getCurrentUser result',
        data: {
          user,
        },
      }),
    );

    yield put(loginSuccess({ token, user }));
    yield onLoginSuccess(user);

    //"azuread-oauth2"
    gtmTrack('login', {
      login_type: loginType,
      login_status: 'Success',
      'gtm.userId': user?.id,
    });

    const isMissed = yield setMissedAuthDataSaga({
      event: 'auth.saga socialLoginSaga: missingAuthData',
      user,
    });

    if (isMissed) {
      return;
    }
    history.push('/overview');
  } catch (err) {
    gtmTrack('login_error', {
      login_type: loginType,
    });

    yield call(handleHttpErrors, { event: 'auth.saga socialLoginSaga: error', error: err });

    const error = err.response?.data;

    yield put(setAuthErrorCodes(error?.error_codes || error?.code ? [error.code] : []));
    yield put(setIsSocialLoginError(true));
  }
}

function* loginFromQueryTokenSaga(action) {
  const token = action.payload;
  setToken(token);

  try {
    const { data: user }: { data: ICurrentUser } = yield authCalls.getCurrentUser();
    if (!doesUserHasDashboardAccess(user)) {
      yield put(setIsSocialLoginError(true));
      gtmTrack('login_error', {
        login_type: 'phone',
        error_message: AuthErrorTypes.noAccess,
      });
      yield put(onUser(null));
      history.replace('/login');
      return;
    }
    yield put(loginFromQueryTokenSuccess({ token, user }));
    yield onLoginSuccess(user);
    if (window.location.pathname === '/login') {
      history.replace('/overview');
    }
  } catch (err) {
    const error = err.response?.data;
    yield put(setAuthErrorCodes(error?.error_codes || error?.code ? [error.code] : []));
    yield put(loginFromQueryTokenError());
    yield put(onUser(null));
    toast('Error logging you in automatically, please log in manually');
    history.replace('/login');
  }
}

function* passwordRecoverySaga(action: any) {
  const { email, processId, onSuccess } = action.payload;
  try {
    yield put(
      log({
        event: 'auth.saga passwordRecoverySaga: start',
        processId,
        data: { email },
      }),
    );

    yield authCalls.passwordRecovery({ email });
    yield put(passwordRecoverySuccess());

    if (typeof onSuccess === 'function') {
      onSuccess();
    }

    yield put(
      log({
        event: 'auth.saga passwordRecoverySaga: success',
        processId,
      }),
    );
  } catch (error) {
    if (error.response?.status >= 200 && error.response?.status < 500) {
      // this case is treated as success - proceed to the next screen
      yield put(passwordRecoverySuccess());

      if (typeof onSuccess === 'function') {
        onSuccess({ processId });
      }

      yield put(
        log({
          event: 'auth.saga passwordRecoverySaga: success',
          processId,
          data: {
            error,
            errorResponse: cloneDeep(error?.response),
          },
        }),
      );

      return;
    }

    yield call(handleHttpErrors, { event: 'auth.saga passwordRecoverySaga: error', processId, error });
    yield put(passwordRecoveryError());
  }
}

function* refreshUserSaga({ processId }: { processId?: string } = {}) {
  processId = processId || uuid();

  try {
    yield put(
      log({
        event: 'auth.saga refreshUserSaga: start',
        processId,
      }),
    );

    const localhostToken = window.location.hostname.includes('localhost') ? yield localStorage.getItem('token') : null;

    const { data: user, headers } = yield authCalls.getCurrentUser(localhostToken);

    const token = headers['x-access-token'];
    setToken(token || localhostToken);

    yield put(
      log({
        event: 'auth.saga refreshUser: result',
        processId,
        data: {
          user,
        },
      }),
    );

    yield setMissedAuthDataSaga({
      event: 'refreshUserSaga: missingAuthData',
      processId,
      user,
    });

    yield put(onUser(user));
    const { id } = getRelevantOrg(user);
    yield put(fetchOrg(id));
    yield put(refreshUserSuccess({ token: token || localhostToken, user }));
    yield prefetchMainScreensSaga();

    if (window.location.pathname === '/login') {
      history.replace('/overview');
    }
  } catch (error) {
    yield call(handleHttpErrors, { event: 'auth.saga refreshUser: error', processId, error });

    if (!AUTH_PATHS.includes(window.location.pathname)) {
      history.replace('/login');
    }

    yield put(
      refreshUserSuccess({
        user: null,
      }),
    );
    yield put(onUser(null));
  }
}

function* handleLogout() {
  try {
    if (window.location.hostname.includes('localhost')) {
      localStorage.removeItem('token');
    }
    history.replace('/login');
    resetBitesApiAuthorization();
    yield put(onUser(null));
    yield authCalls.logout();
  } catch (error) {
    logError(error);
  }
}

/*
 * helper functions
 */

// for better UX, so user won't have to wait when entering one of these screens for the first time
function* prefetchMainScreensSaga() {
  yield put(fetchUsers());
}

function setToken(token: string): void {
  setBitesApiAuthorization(token);

  if (window.location.hostname.includes('localhost')) {
    localStorage.setItem('token', token);
  }
}

function* onLoginSuccess(user) {
  const { id } = getRelevantOrg(user);
  yield put(fetchOrg(id));
  yield prefetchMainScreensSaga();
  yield put(onUser(user));
  yield put(resetAuthForm());
}

function* setIsSocialLoginErrorSaga() {
  yield put(onUser(null));
}

function* onUserSaga(action: IAction<ICurrentUserOrLoginUserResponse | null>) {
  const user = action.payload;
  const activeOrg = yield select(selectOrg);
  const orgName = user?.organizations.find((org) => org.id === activeOrg.id)?.name;
  gtmSetUserId(user?.id || '');
  gtmSetUserProps(user?.id, {
    email: user?.email,
    full_name: getFullName(user),
    last_org_id: `${activeOrg?.id}`,
    last_org_name: orgName,
    user_orgs: user ? ',' + user.organizations.map(({ id }) => id).join(',') + ',' : '',
  });
  yield put(initFeatureFlags());
}

function doesUserHasDashboardAccess(user: ICurrentUserOrLoginUserResponse): boolean {
  return !!getRelevantOrg(user);
}

// we don't support multiple organizations ATM, so we just get the first one we can find, if it exists
function getRelevantOrg(user: ICurrentUserOrLoginUserResponse): IUserOrganization | ICurrentUserUserOrganization {
  const organization = user.organizations.find((org) => org.showDashboard && org.isDefault);
  return organization || user.organizations.find((org) => org.showDashboard);
}

function* finalizeUserProfileSaga({ payload }: IAction<IFinalizeProfileAction>) {
  const { email, phone, firstName, lastName, processId } = payload;

  const user = yield select(currentUserSelector);

  const requestPayload: IFinalizeProfileDataRequest = {
    email: email || undefined,
    phone: phone?.length > 5 ? phone : undefined,
    first_name: firstName || undefined,
    last_name: lastName || undefined,
  };

  try {
    yield put(
      log({
        event: 'finalizeUserProfileSaga: start',
        processId,
        data: {
          userId: user.id,
          requestPayload,
        },
      }),
    );

    yield authCalls.finalizeUserProfile(user.id, requestPayload);

    yield put(
      log({
        event: 'finalizeUserProfileSaga: updateProfile success',
        processId,
        data: {
          userId: user.id,
        },
      }),
    );

    const { data: updatedUser } = yield authCalls.getCurrentUser();

    yield put(
      log({
        event: 'finalizeUserProfileSaga: getUser success',
        processId,
        data: {
          updatedUser,
          userId: user.id,
        },
      }),
    );

    // redirect before updating is auth data missing status

    yield put(setIsAuthDataMissing(false));
    yield put(refreshUserSuccess({ user: updatedUser }));
    yield put(resetAuthForm());
    history.push('/overview');
  } catch (error) {
    yield call(handleHttpErrors, {
      event: 'finalizeUserProfileSaga: error',
      processId,
      error,
      data: {
        userId: user.id,
        requestPayload,
      },
    });

    yield put(setRefreshUserErrors(error.response?.data?.error_codes || []));
  }
}

type ICurrentUserOrLoginUserResponse = ICurrentUser | authCalls.ILoginResponseUser;
