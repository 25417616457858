import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';
import ShareButtonAndManager from '../../../components/Share/ShareButtonAndManager';
import { useShareLink } from '../../../components/Share/useShareLink';
import { useFilterOutGuestsFromViewsTableUser } from '../../../utils/useUsers';
import { ContentType } from '../../userAnalysis/components/Table/Table';
import { selectPlaylist } from '../playlist.slice';

import PlaylistSelectionDialog from './PlaylistSelectionDialog';

function PlaylistScreenTop() {
  const { t } = useTranslation();
  const { name, id, playlistViewsByUser, coverUrl } = useSelector(selectPlaylist);
  const classes = useStyles();
  const shareablePath = useMemo(() => `playlists/${id}`, [id]);

  const [isPlaylistSelectOpen, setIsPlaylistSelectOpen] = useState(false);

  // need to be in useCallback to keep memoization in PlaylistSelectionTable
  const handleClosePlaylistSelectionDialog = useCallback(() => setIsPlaylistSelectOpen(false), []);

  const { handleCopyLink, onShareSuccessfulyToast } = useShareLink({
    contentType: ContentType.PLAYLIST,
    shareablePath,
    subject: name,
  });

  const filterOutGuestsFromViewsTableUser = useFilterOutGuestsFromViewsTableUser();

  return (
    <div className={classes.top}>
      <div className={classes.textContainer}>
        <label>{t('playlist.Playlist')}</label>
        <Button
          variant='outlined'
          onClick={() => setIsPlaylistSelectOpen(true)}
          title={t('playlist.clickToSelect')}
          className={classes.button}
        >
          <EllipsisWithTooltip text={name} />
          <ArrowDropDownIcon className={classes.buttonIcon} />
        </Button>
        <PlaylistSelectionDialog isOpen={isPlaylistSelectOpen} onClose={handleClosePlaylistSelectionDialog} />
      </div>
      <div className={classes.shareButton}>
        <ShareButtonAndManager
          contentId={id}
          subject={name}
          type='playlist'
          shareablePath={shareablePath}
          handleCopyLink={handleCopyLink}
          onShareSuccessfuly={onShareSuccessfulyToast}
          usersCount={filterOutGuestsFromViewsTableUser(playlistViewsByUser).length}
          shareableCoverUrl={coverUrl}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  top: {
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      color: '#666666',
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 4,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
  },
  button: {
    height: 40,
    textAlign: 'left',
    display: 'block',
    textTransform: 'none',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  buttonIcon: {
    color: '#0000008a',
    position: 'absolute',
    right: 8,
    top: 7,
  },
  shareButton: {
    paddingTop: 18.5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default PlaylistScreenTop;
