import * as msal from '@azure/msal-browser';
import { useDispatch } from 'react-redux';

import store from '../../../app/store';
import { APP, MS_PERSONAL_TENANT_ID } from '../../../constants/constants';
import { setIsSocialLoginError, socialLogin, resetErrors } from '../../authForm/authForm.slice';

const msalConfig = {
  auth: {
    clientId: '746e6bcb-cba5-46c9-9f76-c83c366537e2',
    redirectUri: window.location.origin,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

// [AdGo] 20.10.20 DO NOT MOVE THIS
// register for CB after being redirected back from MS auth process
// this only works OUTSIDE the hook itself. that's why the store is
// imported directly here instead of using useDispatch
msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (!response) {
      return;
    }
    const token = response.tenantId === MS_PERSONAL_TENANT_ID ? response.idToken : response.accessToken;
    store.dispatch(resetErrors());
    store.dispatch(
      // @ts-ignore there's no argument in the reducer action but there's on the saga
      socialLogin({
        token,
        backend: 'azuread-oauth2',
        app: APP,
      }),
    );
  })
  .catch(() => {
    store.dispatch(setIsSocialLoginError(true));
  });

const useMicrosoftLogin = () => {
  const dispatch = useDispatch();

  return () => {
    try {
      dispatch(resetErrors());
      msalInstance.loginRedirect({ scopes: [] });
    } catch (err) {
      dispatch(setIsSocialLoginError(true));
    }
  };
};

export default useMicrosoftLogin;
