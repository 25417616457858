import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { TShareTemplate, TShareTemplates } from '../../../app/integration.api';

const ShareTemplateSelection = ({
  setTemplate,
  template,
  options,
}: {
  setTemplate: React.Dispatch<any>;
  template: TShareTemplate;
  options: TShareTemplates;
}) => {
  const { t } = useTranslation();

  const formattedOptions = useMemo(() => {
    return options.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }, [options]);

  const onChange = (selectedOption: any) => {
    setTemplate(options.find((option) => option.id === selectedOption.value));
  };

  if (!options.length) {
    return null;
  }

  return (
    <>
      <div>{t('share.selectTemplate')}</div>
      <Select
        defaultValue={{ value: template.id, label: template.name }}
        onChange={onChange}
        options={formattedOptions}
      />
    </>
  );
};

export default ShareTemplateSelection;
