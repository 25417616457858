import React, { FC, memo } from 'react';
import styled from 'styled-components';

import Preloader from '../../../../components/Share/Preloader';

interface IProps {
  className?: string;
}

const SsoAuthLoader: FC<IProps> = ({ className }) => {
  return (
    <S.Container className={className}>
      <Preloader />
    </S.Container>
  );
};

export default memo(SsoAuthLoader);

const S = {
  Container: styled.div`
    display: flex;
    flex-grow: 1;
    min-height: 1px;
    align-items: center;
    justify-content: center;
  `,
};
