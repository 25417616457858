import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataGridSearchableTable from '../../../../components/ReactDataGrid/SearchableTable';
import { selectPlaylist, setPlaylistViewsByUserSortInfo } from '../../playlist.slice';

import useColumns from './useColumns';
import useDataSource from './useDataSource';
import useFooterRows from './useFooterRows';

interface IProps {
  groupedByDataIndex: number;
}

function OverviewTable({ groupedByDataIndex }: IProps) {
  const dispatch = useDispatch();
  const { playlistViewsByUserSortInfo } = useSelector(selectPlaylist);

  const columns = useColumns({ groupedByDataIndex });
  const dataSource = useDataSource({ groupedByDataIndex });
  const footerRows = useFooterRows();

  const handleSort = useCallback(
    (value) => {
      const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
      dispatch(setPlaylistViewsByUserSortInfo(newSortInfo));
    },
    [dispatch],
  );

  return (
    <ReactDataGridSearchableTable
      onSortInfoChange={handleSort}
      sortInfo={playlistViewsByUserSortInfo}
      rawData={dataSource}
      columns={columns}
      style={{ minHeight: window.innerHeight - 200 }}
      footerRows={footerRows}
      origin='playlist_userview_table'
    />
  );
}

export default OverviewTable;
