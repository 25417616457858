import { makeStyles } from '@material-ui/core';
import React from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import QuestionAndAnswers from './QuestionAndAnswers';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function QuestionAndAnswersMobileModal({ isOpen, onClose }: IProps) {
  const classes = useStyles();
  return (
    <Modal isOpen={isOpen} style={getModalStyles()} ariaHideApp={false}>
      <div className={classes.outerContainer} onClick={onClose}>
        <div className={classes.closeContainer}>
          <CloseIcon className={classes.closeIcon} />
        </div>
        <div onClick={(evt) => evt.stopPropagation()}>
          <QuestionAndAnswers />
        </div>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles({
  outerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    right: 7,
    top: 7,
    width: 25,
    height: 25,
    border: '1px solid #fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  closeIcon: {
    width: 15,
    height: 15,
    fill: '#fff',
  },
});

export default QuestionAndAnswersMobileModal;

function getModalStyles() {
  return {
    overlay: {
      zIndex: 10000,
      inset: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    content: {
      width: '100%',
      height: '100%',
      border: 'none',
      inset: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: 0,
      background: 'rgba(0, 0, 0, .8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
}
