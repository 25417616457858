import React, { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';
import { selectDatasStrings } from '../../../org/org.slice';

interface IArgs {
  groupedByDataIndex: number;
}

const useColumns = ({ groupedByDataIndex }: IArgs): TypeColumns => {
  const datasStrings = useSelector(selectDatasStrings);
  const isMobile = useIsMobile();

  return useMemo(() => {
    const Name = ({ value, data }) => `${value} (${data.recordsCount})`;
    const Completed = ({ value }) => {
      return <StyledPercentage percentage={value} />;
    };
    const AnsweredCorrectly = ({ value }) => {
      return <StyledPercentage percentage={value} />;
    };
    const Answered = ({ value }) => {
      return <StyledPercentage percentage={value} />;
    };
    let result: TypeColumns = [
      {
        name: 'name',
        header: datasStrings[groupedByDataIndex].toUpperCase(),
        defaultFlex: 1,
        render: Name,
      },
    ];

    result = result.concat([
      {
        name: 'completedPercentage',
        header: 'Completed',
        maxWidth: 115,
        render: Completed,
        type: 'number',
      },
      {
        name: 'answeredCorrectlyPercentage',
        header: 'Success',
        maxWidth: 100,
        render: AnsweredCorrectly,
        type: 'number',
      },
    ]);

    if (!isMobile) {
      result.push({
        name: 'answeredPercentage',
        header: 'Answers',
        maxWidth: 100,
        render: Answered,
        type: 'number',
      });
    }

    return result;
  }, [groupedByDataIndex, datasStrings, isMobile]);
};

export default useColumns;
