import { datadogLogger } from '../datadog/datadog';

const log = async (data) => {
  datadogLogger.info(data);
};
export default log;

export const logError = async (error) => {
  datadogLogger.error(error);
};
