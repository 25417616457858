export enum PermissionFields {
  CREATE_BITES_PERMISSION = 'createBitesPermission',
  CONTENT_ADMIN = 'contentAdmin',
  DASHBOARD_PERMISSION = 'dashboardPermission',
  SENDING_PERMISSION = 'sendingPermission',
  ADMIN_PERMISSION = 'adminPermission',
  ORGANIZATION_ADMIN_PERMISSION = 'organizationAdminPermission',
}

export enum PermissionTypes {
  CREATOR = 'Creator',
  DASHBOARD_ACCESS = 'Dashboard Access',
  CONTENT_ADMIN = 'Content Admin',
  SENDING_PERMISSION = 'Sending Permission',
}
