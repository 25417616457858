import { makeStyles, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import LinkTab from '../../../components/LinkTab';
import gtmTrack from '../../../services/googleTagManager/track';
import { selectBite } from '../bite.slice';

interface IProps {
  selectedTabIndex: number;
  onTabChange: (evt: any, tabIndex: number) => void;
}

function BiteScreenTabs({ selectedTabIndex, onTabChange }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: biteId } = useParams<{ id: string }>();
  const { questionType } = useSelector(selectBite);

  const onOverviewClick = () => {
    gtmTrack('bites_bite_overview', { bite_id: biteId });
  };

  const onViewClick = () => {
    gtmTrack('bites_bite_view', { bite_id: biteId });
  };

  const onAnswerClick = () => {
    gtmTrack('bites_bite_answers', { bite_id: biteId });
  };

  const onDiscussionClick = () => {
    gtmTrack('bites_bite_discussion', { bite_id: biteId });
  };

  return (
    <div>
      <Tabs
        value={selectedTabIndex}
        indicatorColor='primary'
        textColor='primary'
        onChange={onTabChange}
        classes={{
          root: classes.tabs,
        }}
      >
        <LinkTab
          className={classes.tab}
          label={t('bites.overview')}
          to={`/bites/${biteId}/overview`}
          data-cy='tabOverview'
          onClick={onOverviewClick}
        />
        <LinkTab
          className={classes.tab}
          label={t('bites.view')}
          to={`/bites/${biteId}/views`}
          data-cy='tabViews'
          onClick={onViewClick}
        />
        {questionType && (
          <LinkTab
            className={classes.tab}
            label={t('bites.answers')}
            to={`/bites/${biteId}/answer-analysis`}
            data-cy='tabAnswers'
            onClick={onAnswerClick}
          />
        )}
        <LinkTab
          className={classes.tab}
          label={t('bites.discussion')}
          to={`/bites/${biteId}/discussion`}
          data-cy='tabDiscussion'
          onClick={onDiscussionClick}
        />
      </Tabs>
      <div className={classes.tabBottomBorder} />
    </div>
  );
}

const useStyles = makeStyles({
  tabs: {
    minHeight: 'auto',
    '& a': { minHeight: 'auto', paddingBottom: 0 },
  },
  tab: {
    textTransform: 'none',
  },
  tabBottomBorder: {
    background: '#EDEDF2',
    height: 2,
    marginTop: -2,
  },
});

export default BiteScreenTabs;
