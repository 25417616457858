import React, { useCallback } from 'react';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';
import { percentage } from '../../../../utils/numbers';

const useMakeFooterRows = () => {
  const isMobile = useIsMobile();

  return useCallback(
    (data) => {
      const FooterViewed = (items) => {
        const count = items.filter((u) => u.viewed).length;
        const total = items.length;
        return isMobile ? (
          <StyledPercentage percentage={percentage(count, total)} />
        ) : (
          <PercentsAndNumbers count={count} total={total} />
        );
      };

      return [
        {
          render: {
            viewed: () => FooterViewed(data),
          },
        },
      ];
    },
    [isMobile],
  );
};

export default useMakeFooterRows;
