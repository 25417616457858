import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Guest1 } from '../assets/icons/guest-user-1.svg';
import { ReactComponent as Guest10 } from '../assets/icons/guest-user-10.svg';
import { ReactComponent as Guest11 } from '../assets/icons/guest-user-11.svg';
import { ReactComponent as Guest12 } from '../assets/icons/guest-user-12.svg';
import { ReactComponent as Guest13 } from '../assets/icons/guest-user-13.svg';
import { ReactComponent as Guest14 } from '../assets/icons/guest-user-14.svg';
import { ReactComponent as Guest15 } from '../assets/icons/guest-user-15.svg';
import { ReactComponent as Guest16 } from '../assets/icons/guest-user-16.svg';
import { ReactComponent as Guest2 } from '../assets/icons/guest-user-2.svg';
import { ReactComponent as Guest3 } from '../assets/icons/guest-user-3.svg';
import { ReactComponent as Guest4 } from '../assets/icons/guest-user-4.svg';
import { ReactComponent as Guest5 } from '../assets/icons/guest-user-5.svg';
import { ReactComponent as Guest6 } from '../assets/icons/guest-user-6.svg';
import { ReactComponent as Guest7 } from '../assets/icons/guest-user-7.svg';
import { ReactComponent as Guest8 } from '../assets/icons/guest-user-8.svg';
import { ReactComponent as Guest9 } from '../assets/icons/guest-user-9.svg';

interface IProps {
  text: string;
  onClick?: () => void;
  style?: Record<string, any>;
  className?: string;
  data?: string;
}

function EllipsisWithTooltipForGuest({ text, onClick, style = {}, className = '', data, ...restOfProps }: IProps) {
  const GuestsIcons = [
    Guest1,
    Guest2,
    Guest3,
    Guest4,
    Guest5,
    Guest6,
    Guest7,
    Guest8,
    Guest9,
    Guest10,
    Guest11,
    Guest12,
    Guest13,
    Guest14,
    Guest15,
    Guest16,
  ];
  const randomElement = Math.floor(Math.random() * GuestsIcons.length);
  const GuestIcon = GuestsIcons[randomElement];

  return (
    <Tooltip title={text || ''} style={style} {...restOfProps} onClick={onClick} className={className}>
      <S.Container>
        <div className='ellipsis'>{text || ''}</div>
        <GuestIcon height='20px' width='20px' style={{ marginLeft: '5px' }} />
      </S.Container>
    </Tooltip>
  );
}

const S: any = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default EllipsisWithTooltipForGuest;
