import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AuthInfo from '../AuthInfo';

const ResetInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <AuthInfo
      title={t('authForm.forgotPasswordForm.resetPassword')}
      description={t('authForm.forgotPasswordForm.resetPasswordInfo')}
    />
  );
};

export default memo(ResetInfo);
