import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { makeStyles, TextField } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { JSX } from 'react-dom';

import { REACT_DATA_GRID_LICENSE_KEY } from '../../../constants/constants';
import useIsMobile from '../../../hooks/useIsMobile';
import { IRdgSortInfo } from '../../../types/reactDataGrid';
import { getScrollProps } from '../../../utils/reactDataGrid';
import ExportToExcel from '../ExportToExcel';
import useTableUsers from '../useTableUsers';

interface IProps {
  rawData: any[];
  columns: TypeColumn[];
  onSortInfoChange?: (nextSortInfo: IRdgSortInfo) => void;
  sortInfo?: IRdgSortInfo;
  footerRows?: any[];
  onSearch?: (string) => void;
  style?: Record<string, any>;
  origin: string;
  intercomTarget?: string;
  intercomExportTarget?: string;
  renderSortTool?: (direction: number | null, extraProps: any) => JSX.Element;
}

function ReactDataGridSearchableTable({
  rawData,
  columns,
  onSortInfoChange,
  onSearch,
  origin,
  intercomTarget,
  intercomExportTarget,
  renderSortTool,
  ...restOfProps
}: IProps) {
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [gridRef, setGridRef] = useState(null);

  const dataSource = useTableUsers({
    searchText,
    users: rawData,
    columns,
  });

  const handleSearch = (evt) => {
    setSearchText(evt.target.value);
    onSearch(evt.target.value);
  };

  const onReady = useCallback((ref) => {
    ref.current.collapseAllGroups();
    setGridRef(ref);
  }, []);

  return (
    <div data-cy='searchableTable'>
      <div className={classes.exportExcel}>
        <TextField
          value={searchText}
          onChange={handleSearch}
          placeholder='Search'
          style={{ width: 193, marginBottom: 10 }}
        />
        {!isMobile && (
          <ExportToExcel
            className={classes.icon}
            gridRef={gridRef}
            dataSource={dataSource}
            origin={origin}
            intercomTarget={intercomExportTarget}
          />
        )}
      </div>
      <div data-intercom-target={intercomTarget}>
        <ReactDataGrid
          onReady={onReady}
          dataSource={dataSource}
          columns={columns}
          scrollProps={getScrollProps()}
          licenseKey={REACT_DATA_GRID_LICENSE_KEY}
          style={{ minHeight: window.innerHeight - 200 }}
          onSortInfoChange={onSortInfoChange || null}
          renderSortTool={renderSortTool}
          {...restOfProps}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  icon: {
    width: 40,
    height: 40,
    padding: '9px 8px',
    borderRadius: 11,
    boxShadow: '0 2px 3px 0 rgba(49, 89, 165, 0.46)',
    backgroundColor: 'rgba(239, 243, 249, 0.2)',
    fill: '#282828',
    cursor: 'pointer',
  },
  isIconDisabled: {
    opacity: 0.4,
  },
  exportExcel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
  },
});

export default ReactDataGridSearchableTable;
