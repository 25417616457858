import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';

import { initialState } from '../../features/featureFlag/featureFlag.slice';
import app from '../firebase';
import { logError } from '../log';

import { IRemoteConfig } from './remoteConfig.types';
import { parseConfigValues, formatToDefaultValues, minimumFetchIntervalMillis } from './remoteConfig.utils';

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
const configDefaults = formatToDefaultValues(initialState.flags);
remoteConfig.defaultConfig = configDefaults;

export const initRemoteConfig = async () => {
  try {
    const fetchedRemotely = await fetchAndActivate(remoteConfig);
    if (!fetchedRemotely) {
      logError('Remote config: No configs were fetched from the backend, and the local configs were already activated');
    }
  } catch (e) {
    logError(e);
  }
};

export const getConfigValues = () => {
  const config = getAll(remoteConfig);
  const values = parseConfigValues(config);
  return values;
};

const methods: IRemoteConfig = {
  initRemoteConfig,
  getConfigValues,
};
export default methods;
