import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import CorrectIcon from '../../../../components/Icons/CorrectIcon';
import WrongIcon from '../../../../components/Icons/WrongIcon';
import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import { EYesNo } from '../../../../constants/constants';
import useIsMobile from '../../../../hooks/useIsMobile';
import { formatDateToRelativeTime } from '../../../../utils/dates';
const useRawColumns = (): TypeColumns => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const Viewed = ({ value, data }) => {
      if (data.__group) {
        const count = data.array.filter((item) => item.viewed === EYesNo.YES).length;
        const total = data.array.length;

        return <PercentsAndNumbers count={count} total={total} />;
      }
      return value === EYesNo.YES ? <CorrectIcon /> : <WrongIcon />;
    };
    return [
      {
        name: 'email',
        maxWidth: 100,
        header: t('bites.email'),
        render: EllipsisColumn,
      },
      {
        name: 'phone',
        maxWidth: 100,
        header: t('bites.phone'),
        render: EllipsisColumn,
      },
      {
        name: 'viewed',
        maxWidth: isMobile ? 90 : 150,
        header: t('bites.viewed'),
        render: Viewed,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: EYesNo.YES, label: t('common.yes') },
            { id: EYesNo.NO, label: t('common.no') },
          ],
        },
        groupSummaryReducer: {
          initialValue: 0,
          reducer: (acc, viewed) => acc + (viewed ? 1 : 0),
        },
      },
      {
        name: 'lastVisit',
        maxWidth: 100,
        header: t('bites.lastViewDate'),
        render: ({ value }) => {
          return value ? formatDateToRelativeTime(value) : '';
        },
      },
      {
        name: 'employeeId',
        maxWidth: 100,
        header: t('bites.employeeId'),
        render: EllipsisColumn,
      },
    ];
  }, [t, isMobile]);
};

export default useRawColumns;
