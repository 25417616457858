import { debounce } from 'lodash';
import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataGridUserTable from '../../../../components/ReactDataGrid/UserTable';
import ShareFromTableManager from '../../../../components/Share/ShareFromTableManager';
import gtmTrack from '../../../../services/googleTagManager/track';
import { getSendIconDisabledMessage } from '../../../../utils/share';
import {
  togglePlaylistColumnVisibility,
  setPlaylistFilterValue,
  setPlaylistViewsByUserGrouping,
  selectPlaylist,
} from '../../playlist.slice';

import useMakeFooterRows from './useMakeFooterRows';
import useRawColumns from './useRawColumns';

function BiteViews() {
  const dispatch = useDispatch();
  const {
    playlistViewsByUser,
    playlistViewsByUserColumnsVisibility,
    playlistViewsByUserFilterValue,
    playlistViewsByUserGrouping,
    id,
    name,
    coverUrl,
  } = useSelector(selectPlaylist);

  const rawColumns = useRawColumns();
  const makeFooterRows = useMakeFooterRows();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isShareFromTableManagerOpen, setIsShareFromTableManagerOpen] = useState(false);
  const [isShared, setIsShared] = useState(false);

  const handleOnToggleColumnVisibility = useCallback(
    (columnName) => dispatch(togglePlaylistColumnVisibility(columnName)),
    [dispatch],
  );
  const handleOnFilterValueChange = useCallback(
    (filterValue) => dispatch(setPlaylistFilterValue(filterValue)),
    [dispatch],
  );

  const handleOnGroupByChange = useCallback(
    (groupByValue) => dispatch(setPlaylistViewsByUserGrouping(groupByValue)),
    [dispatch],
  );

  const handleClickSend = useCallback(() => setIsShareFromTableManagerOpen(true), []);

  const handleToggleIsShared = useCallback(() => {
    setIsShared((value) => !value);
  }, []);

  const handleSearch = useCallback((term) => {
    trackSearch(term);
  }, []);

  const handleOnClose = useCallback(() => setIsShareFromTableManagerOpen(false), []);

  const sendIconDisabledMessage = useMemo(
    () =>
      getSendIconDisabledMessage({
        selectedRows,
      }),
    [selectedRows],
  );

  return (
    <>
      <ReactDataGridUserTable
        maxVisibleColumnsOnMobile={3}
        onToggleColumnVisibility={handleOnToggleColumnVisibility}
        columnsVisibility={playlistViewsByUserColumnsVisibility}
        rawData={playlistViewsByUser}
        rawColumns={rawColumns}
        filterValue={playlistViewsByUserFilterValue}
        onFilterValueChange={handleOnFilterValueChange}
        withCheckbox
        onSelectedRowsChange={setSelectedRows}
        makeFooterRows={makeFooterRows}
        groupBy={playlistViewsByUserGrouping}
        origin='playlist_detailed_view'
        onGroupByChange={handleOnGroupByChange}
        isShared={isShared}
        onSend={handleClickSend}
        onSearch={handleSearch}
        sendIconDisabledMessage={sendIconDisabledMessage}
      />
      <ShareFromTableManager
        isOpen={isShareFromTableManagerOpen}
        onClose={handleOnClose}
        onShare={handleToggleIsShared}
        selectedRows={selectedRows}
        contentId={id}
        entity='playlist'
        shareablePath={`playlists/${id}`}
        shareableTitle={name}
        shareableCoverUrl={coverUrl}
      />
    </>
  );
}

export default BiteViews;

const trackSearch = debounce((term) => gtmTrack('playlists_playlist_detailed_view_search', { term }), 1000);
