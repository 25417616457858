import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useIntercom } from 'react-use-intercom';
import styled, { css } from 'styled-components';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { BaseInput } from '../../../../components/Inputs/InputWithIcon';
import Loader from '../../../../components/Loader';

export const iconSize = 30;
const statusBarSize = 3;

export const modalColors = {
  headerColor: '#1253fa',
  blueButtonBG: '#ebf0ff',
  blueButtonColor: '#1253fa',
  validationNoBG: '#b37878',
  validationNoColor: '#f76868',
  validationYesBG: '#e0f5bc',
  validationYesColor: '#29af8f',
  statusBarInner: '#1253fa',
  statusBarOuter: '#ebf0ff',
  successAdded: '#8fd14f',
  successModify: '#2d9bf0',
  successDelete: '#fac710',
};

const statusBarStyle = { backgroundColor: modalColors.statusBarInner };

export const LoaderHelper = () => {
  return (
    <S.LoaderWrapper>
      <Loader />
    </S.LoaderWrapper>
  );
};

interface IInputHandler {
  label: string;
  placeholder: string;
  value: string;
  onChange: any;
  errorMsg: string;
}

export const InputHandler = (props: IInputHandler) => {
  const { label, placeholder, value, onChange, errorMsg } = props;
  return (
    <S.InputLabelWrapper>
      <S.InputLabel style={{ top: '10px' }}>{label}</S.InputLabel>
      <InputSpanWrapper>
        <S.Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={{
            border: `${errorMsg !== '' ? 'red solid 1px' : 'blue solid 1px'}`,
          }}
        />
        {errorMsg !== '' ? <Span>{errorMsg}</Span> : <></>}
      </InputSpanWrapper>
    </S.InputLabelWrapper>
  );
};

const contentStyle = { fontSize: '14px', whiteSpace: 'normal' };

export const HelpHelper = () => {
  const { t } = useTranslation();
  const { showNewMessages } = useIntercom();

  const intercomHandler = () => {
    showNewMessages('Hey Bites, I need help!');
  };

  return (
    <S.HelpWrapper>
      <S.Content style={contentStyle}>
        {t('userManagement.pages.validationHelp')}
        <S.Href onClick={intercomHandler} style={{ fontSize: '14px' }}>
          {' '}
          {t('common.help')}
        </S.Href>
      </S.Content>
    </S.HelpWrapper>
  );
};

export const StatusBarHelper = (props: { numOfActiveItems: number }) => {
  const { numOfActiveItems } = props;

  const itemsList = useMemo(() => {
    const ColoredItem = (i) => <S.StatusBarItem key={i} style={statusBarStyle}></S.StatusBarItem>;
    const EmptyItem = (i) => <S.StatusBarItem key={i}></S.StatusBarItem>;
    const arr = [];
    for (let i = 0; i < statusBarSize; i++) {
      if (i < numOfActiveItems) {
        arr.push(<ColoredItem key={i} />);
      } else {
        arr.push(<EmptyItem key={i} />);
      }
    }
    return arr;
  }, [numOfActiveItems]);

  const statusBarItems = itemsList.map((i) => {
    return i;
  });
  return <S.StatusBarContainer>{statusBarItems}</S.StatusBarContainer>;
};

export const ErrorMessageComponent: React.FC<{ message: string; details?: { text?: string } }> = ({
  details,
  message,
}) => {
  return (
    <S.ErrorMessageWrapper>
      <S.ErrorMessageText>{message}</S.ErrorMessageText>
      {details && details.text && <S.ErrorMessageText>{details.text}</S.ErrorMessageText>}
    </S.ErrorMessageWrapper>
  );
};

const InputSpanWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Span = styled.span`
  color: red;
  padding: 5px;
`;

export const S = {
  Href: styled.a`
    color: blue;
    text-decoration: underline;
    cursor: grab;
  `,
  LoaderWrapper: styled.div`
    position: relative;
  `,
  ModelButton: styled(PrimaryButton)`
    border-radius: 5px;
    font-size: 14px;
    min-width: 0px;
    padding-left: 30px;
    padding-right: 30px;
  `,
  Wrapper: styled.div`
    padding: 20px;
    background-color: white;
  `,
  HelpWrapper: styled.div`
    bottom: 50px;
    width: 80%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  `,
  Content: styled.div`
    white-space: pre;
    font-size: 20px;
    color: gray;
    text-align: center;
    font-family: 'Gilroy';
  `,
  Error: styled.p`
    white-space: pre;
    font-size: 20px;
    color: #ff0000;
    text-align: center;
    font-family: 'Gilroy';
    margin-top: 30px;
  `,
  TextHeader: styled.div`
    font-weight: bold;
    font-size: 25px;
    font-family: 'Uni Sans SemiBold';
    margin-bottom: 20px;
    text-align: center;
    color: ${modalColors.headerColor};
    font-family: 'Gilroy';
  `,
  Controllers: styled.div`
    align-items: center;
    padding-bottom: 100px;
  `,
  IconWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
    margin-left: 50px;
  `,
  StatusBarContainer: styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    background-color: ${modalColors.statusBarOuter};
    bottom: 50px;
    width: 80%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  `,
  StatusBarItem: styled.div`
    flex: 1;
    border-radius: 2px;
    margin: 2px;
    height: 10px;
  `,
  BackAndHeaderWrapper: styled.div`
    padding-top: 20px;
  `,

  ErrorMessageWrapper: styled.div``,
  ErrorMessageText: styled.p`
    text-align: center;
    color: red;
    font-weight: bold;
  `,
  Input: styled(BaseInput)<{ isIcon: boolean }>`
    height: 10px;
    border-radius: 5px;
    padding: 15px 10px;
    text-align: center;
    ${({ isIcon }) => css`
      background-color: white;
      border: 1px solid #c3c3c3;
      ${isIcon ? 'padding-left: 60px' : ''}
    `}
    text-align: left;
    &:hover {
      border: blue solid 1px;
    }
    ::placeholder {
      color: gray;
      opacity: 0.5; /* Firefox */
    }
  `,
  InputLabelWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
    margin-right: 3%;
    padding: 1px;
  `,
  InputLabel: styled.p`
    font-size: 15px;
    font-family: 'Gilroy';
    white-space: pre;
    color: gray;
    text-align: left;
    bottom: 10px;
    position: relative;
    width: 80px;
  `,
  CustomSelect: styled(Select)`
    width: 209px;
  `,
  Spacer: styled.div`
    width: 100%;
    height: 60px;
  `,
  BorderText: styled.span`
    margin: 0px;
    position: sticky;
    bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;
    font-size: 10px;
    color: rgba(112, 113, 117, 1);
  `,
  InputFile: styled.input`
    width: 100%;
    text-align: center;
    margin: auto;
  `,
  ErrorWrapper: styled.div`
    display: flex;
    margin-top: 10px;
    background-color: #fa461d;
    border-radius: 10px;
  `,
  ErrorUserText: styled.span`
    color: white;
    padding-left: 15px;
  `,
  BoxErrorWrapper: styled.div`
    overflow-y: scroll;
    height: 69%;
  `,
  ErrorItemWrapper: styled.div`
    display: flex;
  `,
  ItemsWrapper: styled.div``,
  ErrorMsgWrapper: styled.div`
    margin-top: 10px;
  `,
  EmailPhonWrapper: styled.div`
    margin: 10px;
    border: dashed rgba(112, 113, 117, 0.3) 1px;
    border-radius: 20px;
    padding-bottom: 10px;
  `,
  PhoneEmail: styled.div`
    margin-top: 15px;
  `,
  SyncButtonWrapper: styled.div`
    display: flex;
  `,
};
