import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { currentFormSelector, ssoInProgressSelector } from '../authForm/authForm.selectors';
import { setCurrentForm } from '../authForm/authForm.slice';
import { log } from '../tracking/tracking.slice';

import { logout } from './auth.slice';
import { EAuthFormType } from './auth.types';
import AuthForm from './common/AuthForm';
import BackButton from './common/components/BackButton';
import OrgLogo from './common/components/OrgLogo';
import EnterEmailCode from './common/EnterCodeForm/EnterEmailCode';
import EnterPhoneCode from './common/EnterCodeForm/EnterPhoneCode';
import EnterEmail from './common/ForgotPassword/EnterEmail';
import ResetInfo from './common/ForgotPassword/ResetInfo';
import ResetPassword from './common/ForgotPassword/ResetPassword';
import SuccessInfo from './common/ForgotPassword/SuccessInfo';
import FormContainer from './common/FormContainer';
import PhoneNumberAuth from './common/PhoneNumber/PhoneNumberAuth';
import SsoAuthLoader from './common/SsoAuthLoader';
import UserInfoForm from './common/UserInfoForm';

const Auth: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentForm = useSelector(currentFormSelector);
  const ssoInProgress = useSelector(ssoInProgressSelector);

  const navigateTo = useCallback(
    (destination: EAuthFormType) => {
      dispatch(log({ event: 'Auth.navigateTo', data: { destination, currentForm } }));
      dispatch(setCurrentForm(destination));
    },
    [dispatch, currentForm],
  );

  const handleBackButtonClick = useCallback(() => {
    dispatch(
      log({
        event: 'Auth.handleBackButtonClick',
        data: { currentForm },
      }),
    );

    switch (currentForm) {
      case EAuthFormType.ENTER_CODE_EMAIL:
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
      case EAuthFormType.PHONE_NUMBER:
      case EAuthFormType.FORGOT_PASSWORD_SUCCESS:
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
        navigateTo(EAuthFormType.FORGOT_PASSWORD_EMAIL);
        break;
      case EAuthFormType.USER_INFO:
        dispatch(logout());
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.ENTER_CODE_PHONE:
        navigateTo(EAuthFormType.PHONE_NUMBER);
        break;
      default:
        history.replace('/login');
    }
  }, [dispatch, currentForm, navigateTo, history]);

  const renderContent = () => {
    switch (currentForm) {
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
        return <EnterEmail />;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
        return <ResetInfo />;
      case EAuthFormType.FORGOT_PASSWORD_RESET:
        return <ResetPassword />;
      case EAuthFormType.FORGOT_PASSWORD_SUCCESS:
        return <SuccessInfo />;
      case EAuthFormType.PHONE_NUMBER:
        return <PhoneNumberAuth />;
      case EAuthFormType.ENTER_CODE_PHONE:
        return <EnterPhoneCode />;
      case EAuthFormType.ENTER_CODE_EMAIL:
        return <EnterEmailCode />;
      case EAuthFormType.USER_INFO:
        return <UserInfoForm />;
      default:
        return <AuthForm />;
    }
  };

  return (
    <FormContainer>
      {currentForm !== EAuthFormType.AUTH ? <S.BackButton onClick={handleBackButtonClick} /> : <S.EmptyView />}
      <S.Header>
        <OrgLogo />
      </S.Header>
      {renderContent()}
      {ssoInProgress && <S.Loader />}
    </FormContainer>
  );
};

const S = {
  Wrapper: styled.div`
    align-self: center;
    width: 398px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    z-index: 1;
  `,
  BackButton: styled(BackButton)`
    height: 40px;
    width: 40px;
    margin: 11px 0 0 14px;
  `,
  EmptyView: styled.div`
    height: 40px;
    width: 40px;
    margin: 11px 0 0 14px;
  `,
  Header: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    pointer-events: none;
  `,
  Loader: styled(SsoAuthLoader)`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.white};
  `,
};

export default memo(Auth);
