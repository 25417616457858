import React, { useMemo } from 'react';

import ReactDataGridSearchableTable from '../../../../components/ReactDataGrid/SearchableTable';
import { IBiteShare } from '../../../../types/biteShare';
import { percentage } from '../../../../utils/numbers';

import useColumns from './useColumns';
import useFooterRows from './useFooterRows';

interface IProps {
  biteShares: IBiteShare[];
}

function PlaylistAnalysisTable({ biteShares }: IProps) {
  const formattedBiteShares = useMemo(
    () =>
      biteShares.map((bs) => ({
        ...bs,
        answeredPercentage: percentage(bs.answered, bs.watched),
        correctAnswerPercentage: percentage(bs.answeredCorrectly, bs.answered),
      })),
    [biteShares],
  );

  const columns = useColumns();
  const footerRows = useFooterRows({ biteShares });

  return (
    <ReactDataGridSearchableTable
      rawData={formattedBiteShares}
      columns={columns}
      footerRows={footerRows}
      style={{ minHeight: 400 }}
      origin='playlist_analysis_table'
    />
  );
}

export default PlaylistAnalysisTable;
