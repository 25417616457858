import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { useTheme } from 'styled-components';

import { IButtonProps } from '../../types/button';
import Loader from '../Share/Loader';

function BaseButton({ className, disabled, isSmall, children, onClick, isLoading, ...restOfProps }: IButtonProps) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <button
      onClick={onClick}
      type='button'
      className={cx(className, classes.button, {
        [classes.isSmall]: isSmall,
        [classes.isDisabled]: disabled,
      })}
      disabled={disabled}
      {...restOfProps}
    >
      {children}
      {isLoading && <Loader color={theme.colors.white} style={styles.loader} />}
    </button>
  );
}

const styles = {
  loader: {
    display: 'flex',
    position: 'absolute',
    width: 32,
    height: 32,
    right: 5,
    fill: 'red',
  } as React.CSSProperties,
};

const useStyles = makeStyles({
  button: {
    borderRadius: 6,
    height: 40,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0 12px',
    position: 'relative',
    display: 'flex',
    fontSize: 18,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  isSmall: {
    height: 32,
  },
  isDisabled: {
    background: '#b2b2b2 !important',
    color: '#fff !important',
    cursor: 'not-allowed !important',
  },
});

export default BaseButton;
