import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Comments from '../../../components/Comments/Comments';
import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import { selectBite } from '../bite.slice';

import { QUESTIONS_AND_COMMENTS_COLUMN_TOP_PADDING } from './styles';

interface IProps {
  columnHeaderClassName: string;
  columnPaddingClassName: string;
}

function CommentsColumn({ columnHeaderClassName, columnPaddingClassName }: IProps) {
  const { t } = useTranslation();
  const { comments, isFetchingComments, discussionEnabled, commentsError } = useSelector(selectBite);
  const { id: biteId } = useParams<{ id: string }>();
  const classes = useStyles();

  const renderComments = () => {
    if (!discussionEnabled) {
      return <p>{t('bites.commentsAreDisabled')}</p>;
    }
    if (commentsError) {
      return <ErrorMsg>{t('bites.errorLoadingComments', { error: commentsError })}</ErrorMsg>;
    }
    return isFetchingComments ? <Loader /> : <Comments comments={comments.slice(0, 3)} />;
  };

  return (
    <>
      <div className={cx(columnHeaderClassName, columnPaddingClassName)}>
        <div className={classes.commentsTitleWrap}>
          {t('bites.latestComments')}
          {discussionEnabled && (
            <Link className={classes.seeAllComments} to={`/bites/${biteId}/discussion`}>
              {t('bites.seeAll')}
            </Link>
          )}
        </div>
      </div>
      <div className={cx(columnPaddingClassName, classes.commentsColumn)}>{renderComments()}</div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  commentsColumn: {
    paddingTop: QUESTIONS_AND_COMMENTS_COLUMN_TOP_PADDING,
  },
  commentsTitleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  seeAllComments: {
    color: '#3159a5',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: 11,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 18,
    },
  },
}));

export default CommentsColumn;
