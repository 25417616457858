import React from 'react';

import { FRONTLINER_URL } from '../../../../constants/constants';
import EllipsisWithTooltip from '../../../EllipsisWithTooltip';
import ExternalLink from '../../../ExternalLink';

type MessagePreviewLinkProps = {
  path: string;
  selectedText: string | null;
};

const MessagePreviewLink = ({ path, selectedText }: MessagePreviewLinkProps) => {
  return (
    <div>
      <ExternalLink href=''>
        {/* no space added before the link if the template ends with a placeholder handlebar */}
        {selectedText && selectedText.endsWith('}') ? '' : '\n'}
        <EllipsisWithTooltip text={`${FRONTLINER_URL}/${path}`} />
      </ExternalLink>
    </div>
  );
};

export default MessagePreviewLink;
