import { makeStyles } from '@material-ui/core';
import React from 'react';

import DeleteComment from '../../features/bite/discussion/DeleteComment';
import { IComment } from '../../types/comment';
import { formatDate } from '../../utils/dates';
import EllipsisWithTooltip from '../EllipsisWithTooltip';

interface IProps {
  comment: IComment;
  inDiscussion?: boolean;
  isAdmin?: boolean;
}

function CommentItem(props: IProps) {
  const classes = useStyles();
  const { user, comment, id, createdAt, relatedComments, media } = props.comment;
  const { inDiscussion, isAdmin } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleText}>
          <EllipsisWithTooltip text={user.fullName} />
        </div>
        <div className={classes.date}>{formatDate(createdAt)}</div>
      </div>
      <div className={classes.content}>{decodeURIComponent(comment)}</div>
      {inDiscussion && isAdmin && (
        <div className={classes.deleteBtn}>
          <DeleteComment commentId={id} />
        </div>
      )}
      {media?.imageUrl && <img alt='' className={classes.image} src={media.imageUrl} />}
      {relatedComments.map((_comment) => (
        <div key={_comment.id} style={{ padding: 8 }}>
          <CommentItem comment={_comment} inDiscussion={inDiscussion} isAdmin={isAdmin} />
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
    border: '1px solid #E8EEF4',
    borderRadius: 6,
    padding: 16,
  },
  title: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  titleText: {
    color: '#666666',
    marginRight: 6,
    fontWeight: 'bold',
    flex: 1,
    minWidth: 0,
  },
  date: {
    color: '#979797',
    width: 65,
  },
  content: {
    marginTop: 8,
  },
  image: {
    maxHeight: 100,
    maxWidth: '100%',
  },
});

export default CommentItem;
