import moment from 'moment';
import React from 'react';

import CorrectIcon from '../../../../components/Icons/CorrectIcon';
import WrongIcon from '../../../../components/Icons/WrongIcon';
import { IUserTableData } from '../../userAnalysis.types';
import { ContentType } from '../Table/Table';

export function formatDate(date) {
  return moment(new Date(date)).format('MM/DD/YYYY');
}

export function formatDataSource(data: IUserTableData[]) {
  const requestedDataStructure = [];
  data?.forEach((element) => {
    requestedDataStructure.push({
      id: element.user.userOrganizationId,
      employeeId: element.user.employeeId,
      userName: element.user.userName,
      folder: true,
      data1: element.user?.data1.name,
      data2: element.user?.data2.name,
      data3: element.user?.data3.name,
      data4: element.user?.data4.name,
      data5: element.user?.data5.name,
      data6: element.user?.data6.name,
      data7: element.user?.data7.name,
      data8: element.user?.data8.name,
      content: 'Click to expand',
      nodes: [
        {
          id: 1,
          content: ContentType.BITE,
          viewed: element.stats.bite.viewed,
          answer: element.stats.bite.answered,
          success: `${(element.stats.bite.success * 100).toFixed(2)}%`,
          nodes: null,
        },
        {
          id: 2,
          content: ContentType.PLAYLIST,
          viewed: element.stats.playlist.viewed,
          answer: element.stats.playlist.answered,
          success: `${(element.stats.playlist.success * 100).toFixed(2)}%`,
          nodes: null,
        },
        {
          id: 3,
          content: ContentType.QUIZ,
          viewed: element.stats.quiz.viewed,
          answer: element.stats.quiz.answered,
          success: `${(element.stats.quiz.success * 100).toFixed(2)}%`,
          nodes: null,
        },
      ],
    });
  });

  return requestedDataStructure;
}

export const formatBiteData = (bitesData) => {
  return bitesData.map((bite) => ({
    ...bite,
    id: bite.contentId,
    content: bite.name,
    answeredCorrectlyText: bite.answeredCorrectly,
    viewed: bite.viewed ? 'Yes' : 'No',
    answer: bite.answered ? 'Yes' : 'No',
    viewDate: new Date(bite.viewDate).toLocaleDateString(),
    success: bite.success ? <CorrectIcon /> : bite.success === null ? '--' : <WrongIcon />,
  }));
};

export const formatPlaylistData = (playlistData) => {
  return playlistData.map((playlist) => ({
    ...playlist,
    id: playlist.contentId,
    content: playlist.name,
    viewed: `${playlist.viewed}/${playlist.totalBites}`,
    viewDate: new Date(playlist.viewDate).toLocaleDateString(),
    answer: `${playlist.answered}/${playlist.totalQuestions}`,
    answeredCorrectlyText: `${Number(playlist.answeredCorrectly)}/${playlist.answered}`,
    success:
      playlist.answered === 0
        ? '0%'
        : playlist.answered > 0
        ? `${((Number(playlist.answeredCorrectly) / playlist.answered) * 100).toFixed(2)}%`
        : '-',
  }));
};
