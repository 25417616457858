import Tab from '@material-ui/core/Tab';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  label: string;
  to: string;
  onClick?: () => void;
  className?: string;
}

function LinkTab({ label, to, onClick, className = '', ...restOfProps }: IProps) {
  return <Tab className={className} label={label} component={Link} to={to} onClick={onClick} {...restOfProps} />;
}

export default LinkTab;
