import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import CreatedAndPreview from '../../../components/CreatedAndPreview';
import Loader from '../../../components/Loader';
import { FRONTLINER_URL } from '../../../constants/constants';
import { tokenSelector } from '../../auth/auth.selectors';
import { selectPlaylist } from '../playlist.slice';

import BiteSharesList from './BiteSharesList';

function PlaylistOverview() {
  const classes = useStyles();
  const { isFetchingOverview, name, createdBy, id, biteShares } = useSelector(selectPlaylist);
  const token = useSelector(tokenSelector);

  if (isFetchingOverview) {
    return <Loader />;
  }

  return (
    <>
      <h1 className={classes.title}>{name}</h1>
      <div className={classes.columns}>
        <div className={classes.column}>
          <CreatedAndPreview
            createdBy={createdBy}
            previewLink={`${FRONTLINER_URL}/playlists/${id}/?token=${token}`}
            previewLabel='playlist'
          />
        </div>
        <div className={classes.column}>
          <BiteSharesList biteShares={biteShares} />
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    height: 32,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px',
    marginBottom: 16,
  },
  columns: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  column: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 20,
    },
    [theme.breakpoints.up('sm')]: {
      width: '48%',
    },
  },
}));

export default PlaylistOverview;
