import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import Button from '../../../../components/Buttons/Button';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { isValidPasswordMessage } from '../../../../utils/auth';
import {
  isLoginErrorSelector,
  isResetPasswordErrorSelector,
  isResetPasswordInProgressSelector,
  recoveryTokenSelector,
} from '../../../authForm/authForm.selectors';
import { setCurrentForm, setNewRecoverPassword, resetErrors } from '../../../authForm/authForm.slice';
import { log } from '../../../tracking/tracking.slice';
import { EAuthFormType } from '../../auth.types';
import FormTextInput from '../FormTextInput';
import Header from '../Header';

const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useIsMounted();

  const resetPasswordErrors = useSelector(isResetPasswordErrorSelector);
  const token = useSelector(recoveryTokenSelector);
  const isLoading = useSelector(isResetPasswordInProgressSelector);
  const isLoginError = useSelector(isLoginErrorSelector);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const passwordRequestError = resetPasswordErrors?.length ? t(getPasswordRequestError(resetPasswordErrors[0])) : null;
  const displayError = passwordError || passwordRequestError || (isLoginError ? t('common.somethingWentWrong') : null);

  const handleContinue = useCallback(() => {
    dispatch(
      log({ event: 'ResetPassword.handleContinue', data: { destination: EAuthFormType.FORGOT_PASSWORD_SUCCESS } }),
    );
    dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_SUCCESS));
  }, [dispatch]);

  const handleContinueButtonPress = useCallback(() => {
    if (isLoading) {
      return;
    }
    const processId = uuid();
    const invalidPasswordMessage = isValidPasswordMessage(password);

    dispatch(
      log({
        event: 'ResetPassword.handleContinue',
        processId,
        data: {
          token,
          password,
          invalidPasswordMessage,
        },
      }),
    );

    if (invalidPasswordMessage) {
      setPasswordError(invalidPasswordMessage);
      return;
    }

    const handleSuccess = () => {
      dispatch(
        log({
          event: 'ResetPassword.setNewPassword.callback',
          processId,
          data: {
            token,
            password,
            isMounted: isMountedRef.current,
          },
        }),
      );

      if (!isMountedRef.current) {
        return;
      }

      handleContinue();
    };

    dispatch(resetErrors());
    dispatch(
      setNewRecoverPassword({
        token,
        password,
        processId,
        onSuccess: handleSuccess,
      }),
    );
  }, [isLoading, dispatch, token, password, isMountedRef, handleContinue]);

  const handleChangeText = useCallback(
    (text: string) => {
      setPassword(text);
      dispatch(resetErrors());
      setPasswordError(null);
    },
    [dispatch],
  );

  return (
    <S.Container>
      <Header
        title={t('authForm.forgotPasswordForm.resetPassword')}
        description={t('authForm.forgotPasswordForm.typeNewPassword')}
      />
      <S.Form>
        <S.TextInput
          value={password}
          onChange={handleChangeText}
          label={t('authForm.forgotPasswordForm.passwordInputLabel')}
          error={displayError}
          onEnterPress={handleContinueButtonPress}
          password
        />
        <S.ContinueButton isLoading={isLoading} onClick={handleContinueButtonPress}>
          {t('common.Continue')}
        </S.ContinueButton>
      </S.Form>
    </S.Container>
  );
};

const getPasswordRequestError = (errorCode) => {
  switch (errorCode) {
    case 'auth.reset_pass_expired':
      return 'authForm.forgotPasswordForm.resetPassExpired';
    case 'auth.reset_pass_too_common':
      return 'authForm.forgotPasswordForm.resetPassTooCommon';
    case 'auth.reset_pass_too_similar':
      return 'authForm.forgotPasswordForm.resetPassTooSimilar';
    default:
      return 'common.somethingWentWrong';
  }
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div`
    margin-top: 32px;
    align-items: center;
  `,
  TextInput: styled(FormTextInput)`
    max-width: 298px;
    margin-bottom: 18px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-bottom: 50px;
    margin-top: 18px;
  `,
};

export default memo(ResetPassword);
