import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import UndoSnackbar from '../../../../components/UndoSnackbar';
import { PermissionFields } from '../../../../types/permissions';
import { selectUserManagement, undoUpdateUserField, updateUserField } from '../../userManagement.slice';

interface IProps {
  field: string;
  fieldDisplayName: string;
  id: number;
  className: string;
  label?: string;
  disabled?: boolean;
}

function CheckboxUserField({ field, fieldDisplayName, id, className, label = '', disabled = false }: IProps) {
  const { t } = useTranslation();
  const previousValue = useRef();
  const [isUndoSnackbarOpen, setIsUndoSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const { users, isUpdatingUserField } = useSelector(selectUserManagement);
  const user = users.find((u) => u.id === id);

  if (field === PermissionFields.SENDING_PERMISSION) {
    disabled = !user[PermissionFields.DASHBOARD_PERMISSION];
  }
  if (field === PermissionFields.CONTENT_ADMIN) {
    disabled = !user[PermissionFields.CREATE_BITES_PERMISSION];
  }

  const handleChange = () => {
    if (isUpdatingUserField) {
      return;
    }
    previousValue.current = user[field]; // store for undo purposes
    const changes: { id: number; fields?: { [k: string]: any }; field?: string; value?: any } = { id };
    const value = !user[field];
    if (field === PermissionFields.DASHBOARD_PERMISSION) {
      changes.fields = {
        [field]: value,
        adminPermission: value,
        organizationAdminPermission: value,
        sendingPermission: false,
      };
    } else if (field === PermissionFields.CREATE_BITES_PERMISSION) {
      changes.fields = {
        [field]: value,
        contentAdmin: false,
      };
    } else {
      changes.field = field;
      changes.value = value;
    }
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(updateUserField(changes));
    setIsUndoSnackbarOpen(true);
  };

  const handleCloseUndoSnackbar = () => setIsUndoSnackbarOpen(false);

  const handleUndo = () => {
    setIsUndoSnackbarOpen(false);
    const changes: { id: number; fields?: { [k: string]: any }; field?: string; value?: any } = { id };
    const value = !user[field];
    if (field === PermissionFields.DASHBOARD_PERMISSION) {
      changes.fields = {
        [field]: value,
        [PermissionFields.ADMIN_PERMISSION]: value,
        [PermissionFields.ORGANIZATION_ADMIN_PERMISSION]: value,
      };
    } else {
      changes.field = field;
      changes.value = value;
    }
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(undoUpdateUserField(changes));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            color='primary'
            onChange={handleChange}
            className={className}
            checked={user[field]}
            id={`${field}-${id}`}
          />
        }
        label={label}
      />

      <UndoSnackbar
        isOpen={isUndoSnackbarOpen}
        message={t('userManagement.userWindow.updatedDisplayName', { fieldDisplayName })}
        onUndo={handleUndo}
        onClose={handleCloseUndoSnackbar}
      />
    </>
  );
}

export default CheckboxUserField;
