import React, { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  isVisible: boolean;
  children?: ReactNode;
}

const TRANSITION_MS = 300;

const AuthError: FC<IProps> = ({ isVisible, children }) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [cachedChildren, setCachedChildren] = useState(children);
  const isDisplayTimeoutRef = useRef(null);
  const isExpandedTimeoutRef = useRef(null);

  const clearTimeouts = useCallback(() => {
    clearTimeout(isDisplayTimeoutRef.current);
    clearTimeout(isExpandedTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (children) {
      clearTimeouts();
      setCachedChildren(children);
    }
  }, [children, clearTimeouts]);

  useEffect(() => {
    clearTimeouts();

    if (isVisible) {
      setIsDisplay(true);

      isExpandedTimeoutRef.current = setTimeout(() => {
        setIsExpanded(true);
      }, 100);

      return;
    }

    setIsExpanded(false);

    isDisplayTimeoutRef.current = setTimeout(() => {
      setIsDisplay(false);
    }, TRANSITION_MS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    return () => {
      clearTimeouts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isDisplay) {
    return null;
  }

  return <S.Container isExpanded={isExpanded}>{cachedChildren}</S.Container>;
};

const S = {
  Container: styled.div<{ isExpanded: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: ${({ isExpanded }) => (isExpanded ? '24px' : 0)};
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.lightRed};
    align-items: center;
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    height: ${({ isExpanded }) => (isExpanded ? 'auto' : 0)};
    text-align: center;
    transition: all ${TRANSITION_MS}ms ease;
  `,
};

export default memo(AuthError);
