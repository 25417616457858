import { StyleRules } from '@material-ui/styles/withStyles/withStyles';

import { OPTION_HEIGHT } from './consts';

const styles: StyleRules = {
  option: {
    fontSize: 14,
    color: '#656464',
    height: OPTION_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingLeft: 24,
    '&:not(:last-child)': {
      borderBottom: 'solid 1px rgba(232, 238, 244, 0.9)',
    },
  },
};

export default styles;
