import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BlueButton from '../../../../components/Buttons/BlueButton';
import { resetPassword, selectUserManagement, updateUserField } from '../../userManagement.slice';

interface IProps {
  onClose: () => void;
  userId: number;
}

function Top({ onClose, userId }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, fullName, active, email } = useSelector(selectUserManagement).users.find((u) => u.id === userId);
  const classes = useStyles();

  const handleResetPassword = () => {
    const shouldContinue = window.confirm(t('common.areYouSure'));
    if (!shouldContinue) {
      return;
    }
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(resetPassword(id));
  };

  const handleBlockToggle = () =>
    dispatch(
      // @ts-ignore there's no argument in the reducer action but there's on the saga
      updateUserField({
        id,
        field: 'active',
        value: !active,
      }),
    );

  return (
    <>
      <div className={classes.title}>
        {fullName}
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
      </div>
      <div className={classes.resetAndBlock}>
        {email && (
          <BlueButton style={{ marginRight: 8 }} isSmall onClick={handleResetPassword}>
            {t('userManagement.userWindow.resetPassword')}
          </BlueButton>
        )}
        <BlueButton isSmall onClick={handleBlockToggle}>
          {t(`userManagement.userWindow.${active ? 'block' : 'unblock'}User`)}
        </BlueButton>
      </div>
    </>
  );
}

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 26,
    lineHeight: '32px',
    marginBottom: 16,
  },
  closeIcon: {
    height: 28,
    width: 28,
    cursor: 'pointer',
  },
  resetAndBlock: {
    marginBottom: 16,
    display: 'flex',
  },
});

export default Top;
