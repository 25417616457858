import { call, put, select, takeLatest } from 'redux-saga/effects';

import { initI18 as applyTranslations } from '../../locale/i18n';
import { logError } from '../../services/log';

import { getGeolocation, loadTranslations } from './appActivity.api';
import { geolocationSelector } from './appActivity.selectors';
import { requestGeolocation, runInit, setGeolocation, setTranslationsAreInitialized } from './appActivity.slice';

function* runInitSaga() {
  try {
    const { data: translations } = yield call(loadTranslations);
    yield call(applyTranslations, translations);
    yield put(setTranslationsAreInitialized());
  } catch (error) {
    logError(error);
  }
}

function* requestGeolocationSaga() {
  try {
    const geolocation = yield select(geolocationSelector);
    if (geolocation) {
      return;
    }
    const { data } = yield getGeolocation();
    yield put(setGeolocation(data?.data));
  } catch (error) {
    logError(error);
  }
}

export default function* appActivitySaga() {
  yield takeLatest(runInit, runInitSaga);
  yield takeLatest(requestGeolocation, requestGeolocationSaga);
}
