import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { Checkbox, ClickAwayListener, FormControlLabel, makeStyles, Tooltip } from '@material-ui/core';
import sumBy from 'lodash/sumBy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ColumnsIcon } from '../../assets/icons/columns.svg';
import useIsMobile from '../../hooks/useIsMobile';
import gtmTrack from '../../services/googleTagManager/track';
import { IRdgColumnVisibility } from '../../types/reactDataGrid';

interface IProps {
  columns: TypeColumns;
  onToggle: (columnName: string) => void;
  columnsVisibility: IRdgColumnVisibility;
  maxVisibleColumnsOnMobile?: number;
  iconClassName: string;
  origin: string;
}

function ColumnChooser({
  columns,
  onToggle,
  columnsVisibility,
  maxVisibleColumnsOnMobile = Infinity,
  iconClassName,
  origin,
}: IProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);

  const handleToggle = (columnName: string, isChecked: boolean): void => {
    // limit 3 columns in mobile
    if (isMobile && !columnsVisibility[columnName] && sumBy(columns, 'visible') >= maxVisibleColumnsOnMobile) {
      alert(
        t('table.viewOnceOnMobile', {
          maxVisibleColumnsOnMobile,
        }),
      );
      return;
    }
    onToggle(columnName);
    if (origin === 'bite') {
      gtmTrack('bites_bite_choose_columns', {
        column_name: columnName,
        is_checked: !isChecked,
      });
    } else if (origin === 'playlist') {
      gtmTrack('playlists_playlist_choose_columns', {
        column_name: columnName,
        is_checked: !isChecked,
      });
    } else if (origin === 'quiz') {
      gtmTrack('quizzes_quiz_choose_columns', {
        column_name: columnName,
        is_checked: !isChecked,
      });
    } else if (origin === 'user_management') {
      gtmTrack('user_management_choose_columns', {
        column_name: columnName,
        is_checked: !isChecked,
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsShown(false)}>
      <div className={classes.root}>
        <Tooltip title={t('table.chooseColumns')}>
          <ColumnsIcon onClick={() => setIsShown(!isShown)} className={iconClassName} data-cy='columnsChooserToggle' />
        </Tooltip>
        {isShown && (
          <div className={classes.columns}>
            {columns.map((c) => (
              <FormControlLabel
                className={classes.column}
                key={c.name}
                control={
                  <Checkbox
                    color='primary'
                    onChange={() => handleToggle(c.name, columnsVisibility[c.name])}
                    checked={columnsVisibility[c.name]}
                    id={`column-chooser-${c.name}`}
                    data-cy={`column-chooser-${c.name}`}
                  />
                }
                label={c.header}
              />
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  columns: {
    position: 'absolute',
    top: 37,
    right: 0,
    zIndex: 10,
    background: '#fff',
    width: 258,
  },
  column: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 0, // override MUI
    marginRight: 0, // override MUI
    fontSize: 16,
    '&:hover': {
      background: '#6691FF',
      color: '#fff',
    },
    '&:hover .MuiButtonBase-root': {
      color: '#fff',
    },
  },
});

export default ColumnChooser;
