import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { setCurrentForm, setRecoveryToken } from '../../authForm/authForm.slice';
import { EAuthFormType } from '../auth.types';

interface CreateNewPasswordParams {
  recovery_token?: string;
}

const useAuthLocationCheck = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isFirstRenderRef = useRef(true);

  const recoveryPasswordRouteMatch = useRouteMatch<CreateNewPasswordParams>({
    path: '/auth/password-recovery/create-new-password/:recovery_token?',
  });

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      return;
    }

    isFirstRenderRef.current = false;

    if (recoveryPasswordRouteMatch) {
      dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_RESET));
      const query = new URLSearchParams(location.search);
      const recoveryToken = query.get('recovery_token');
      if (recoveryToken) {
        dispatch(setRecoveryToken(recoveryToken));
      }
    }
  }, [dispatch, location.search, recoveryPasswordRouteMatch]);
};

export default useAuthLocationCheck;
