import meanBy from 'lodash/meanBy';
import React, { useMemo } from 'react';

import StyledPercentage from '../../../../components/StyledPercentage';

interface IArgs {
  dataSource: any[];
}

const useFooterRows = ({ dataSource }: IArgs) => {
  return useMemo(() => {
    const FooterCompletedPercentage = () => <StyledPercentage percentage={meanBy(dataSource, 'completedPercentage')} />;
    const FooterAnsweredCorrectlyPercentage = () => (
      <StyledPercentage
        percentage={meanBy(
          dataSource.filter((q) => q.type === 'multiple choices'),
          'answeredCorrectlyPercentage',
        )}
      />
    );
    return [
      {
        render: {
          completedPercentage: FooterCompletedPercentage,
          answeredCorrectlyPercentage: FooterAnsweredCorrectlyPercentage,
        },
      },
    ];
  }, [dataSource]);
};

export default useFooterRows;
