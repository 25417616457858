import cn from 'classnames';
import React, { CSSProperties, FC, InputHTMLAttributes, memo, useCallback, useMemo, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { isRtl } from '../../../../locale/i18n';
import { fontFamilies } from '../../../../styles/themes/defaultTheme';
import { countryCodeSelector } from '../../../appActivity/appActivity.selectors';
import { userSelectedCountryCodeSelector } from '../../../authForm/authForm.selectors';
import { setUserSelectedCountryCode } from '../../../authForm/authForm.slice';

import countries from './countries';

interface IProps extends Omit<InputHTMLAttributes<PhoneInput>, 'onChange'> {
  style: CSSProperties;
  onChange: (text: string) => void;
  value: string;
  label?: string;
  error?: string;
  centered?: boolean;
  letterSpacing?: number;
  onEnterPress?: () => void;
  onValidationChange?: (isValid: boolean) => void;
}

const COUNTRY_ISO_CODES: string[] = countries.map((country) => country.isoCode);

const PhoneInputWithIcon: FC<IProps> = ({
  style = {},
  onChange,
  value,
  label,
  error,
  centered,
  letterSpacing,
  onEnterPress,
  onValidationChange,
  ...props
}) => {
  const dispatch = useDispatch();

  const countryCode = useSelector(countryCodeSelector);
  const userSelectedCountryCode = useSelector(userSelectedCountryCodeSelector);

  const [isFocused, setIsFocused] = useState(false);

  const selectedCountry = userSelectedCountryCode || countryCode || 'US';

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const className = useMemo(() => {
    const rtl = isRtl();
    return cn({
      'PhoneInput--rtl': rtl,
      'PhoneInput--force-ltr': rtl && !!value,
    });
  }, [value]);

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && typeof onEnterPress === 'function') {
        onEnterPress();
      }
    },
    [onEnterPress],
  );

  const phoneInputStyles = useMemo(
    () => ({
      ...style,
      display: 'flex',
      textAlign: centered ? 'center' : 'left',
      letterSpacing: letterSpacing,
      fontSize: 24,
      flexDirection: 'row',
    }),
    [centered, letterSpacing, style],
  );

  const handleChange = useCallback(
    (phone: string) => {
      onChange(phone || '');

      if (typeof onValidationChange === 'function') {
        onValidationChange(isValidPhoneNumber(phone || ''));
      }
    },
    [onChange, onValidationChange],
  );

  const handleCountryChange = useCallback(
    (iso: string) => {
      dispatch(setUserSelectedCountryCode(iso));
    },
    [dispatch],
  );

  return (
    <S.Container style={style}>
      <S.InputContainer isFocused={isFocused}>
        {!!label && <S.Label>{label}</S.Label>}
        <S.PhoneInput
          {...props}
          className={className}
          defaultCountry={selectedCountry}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={props.placeholder}
          inputComponent={S.PhoneInput2}
          iconComponent={S.IconWrapper}
          innerWidth={window.innerWidth}
          value={value}
          onKeyPress={handleEnterPress}
          style={phoneInputStyles}
          countries={COUNTRY_ISO_CODES}
          onCountryChange={handleCountryChange}
          onChange={handleChange}
          addInternationalOption={false}
          limitMaxLength
          international
        />
      </S.InputContainer>
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export const BaseInput = styled.input`
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  ${({ theme }) => css`
    color: ${theme.colors.darkPrimary};
    font-family: ${theme.fontFamilies.GilroyMedium};
  `};
`;

const S = {
  PhoneInput: styled(PhoneInput)`
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
  `,
  PhoneInput2: styled(BaseInput)<{
    isIcon: boolean;
    innerWidth: number;
  }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 22px 15px 20px 10px;
    text-align: left;
    border-radius: 0 49px 49px 0;
    ${({ theme, isIcon }) => css`
      background-color: ${theme.colors.white};
      ${isIcon && 'padding-left: 48px'}
    `};
  `,
  IconWrapper: styled.div`
    position: absolute;
    left: 20px;
    border-radius: 50%;
    transform: translate(0, -100%);
    width: 16px;
    height: 16px;
  `,
  AsteriskIconWrap: styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
    height: 100%;
    background-color: #fff;
  `,
  Container: styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;

    .PhoneInput {
      flex: 1;
      align-items: center;
      z-index: 1;
      padding: 0 10px 0 15px;
      border-radius: 30px;
      background-color: white;
    }

    .PhoneInput--rtl {
    }

    .PhoneInput--submit-btn {
      margin-right: -40px;
    }

    .PhoneInput--rtl.PhoneInput--submit-btn {
      margin-left: -40px;
      margin-right: 0;
    }

    .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    .PhoneInputCountrySelectArrow {
      border-color: #fff;
    }

    .PhoneInput input {
      background: transparent;
      color: rgb(52, 67, 86);
      font-size: 16px;
      font-family: ${fontFamilies.GilroyMedium};
      flex: 1;
      padding-left: 16px;
      height: calc(100% - 4px);
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-left: 1px solid #c3c3c3;
    }

    .PhoneInput--rtl input {
      border-left: none;
      border-right: 1px solid #c3c3c3;
      padding-right: 24px;
      padding-left: 2px;
    }

    .PhoneInputCountryIconImg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      object-fit: cover;
    }

    .PhoneInputInput {
      width: 0;
      z-index: 2;
    }

    .PhoneInputCountry {
      border-radius: 100px;
      width: 21px;
      height: 21px;
      border: none;
      align-self: center;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-right: 16px;
    }

    .PhoneInput--rtl .PhoneInputCountry {
      margin-right: 0;
      margin-left: 16px;
    }

    .PhoneInput--force-ltr input {
      direction: ltr;
    }

    .PhoneInputCountryIcon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    .PhoneInputCountryIcon--border {
      box-shadow: none;
      background: none;
    }

    .PhoneInputCountrySelectArrow {
      display: none;
    }

    .PhoneInputCountryIconImg {
      display: block;
      width: 100%;
      height: 100%;
    }
  `,
  InputContainer: styled.div<{ isFocused: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 49px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: ${49 / 2}px;
    border: 1px solid ${({ theme, isFocused }) => (isFocused ? theme.colors.primaryBlue : theme.colors.lightGray34)};
    transition: all 0.3s ease;
  `,
  Label: styled.span`
    z-index: 2;
    pointer-events: none;
    display: flex;
    position: absolute;
    color: ${({ theme }) => theme.colors.gray19};
    padding: 0 5px;
    transition: all 0.3s ease;
    top: -9px;
    left: 25px;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 1);
  `,
  ErrorText: styled.span`
    display: flex;
    font-size: 13px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.pinkError};
    align-self: center;
  `,
};

export default memo(PhoneInputWithIcon);
