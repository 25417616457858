import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { VictoryContainer, VictoryLabel, VictoryPie } from 'victory';

import EllipsisWithTooltip from '../EllipsisWithTooltip';

const COLORS = ['#00BC7B', '#A45351', '#1E2336', '#67BECA', '#FBF9F9', '#8BAFCB'];

interface IProps {
  answers: {
    votes: number;
    isCorrect: boolean;
    percentage: number;
    text: string;
  }[];
  isHorizontal?: boolean;
  hideAnswersCount?: boolean;
}

function AnswerDistributionChart({ answers, isHorizontal, hideAnswersCount }: IProps) {
  const classes = useStyles();

  const answersWithCorrectAnswerFirst = sortBy(answers, (x) => !x.isCorrect);

  const data = answersWithCorrectAnswerFirst.map((a) => {
    return {
      x: hideAnswersCount ? `${a.percentage.toFixed()}%` : `${a.votes} (${a.percentage.toFixed()}%)`,
      y: a.votes,
    };
  });

  const renderKeys = () =>
    answersWithCorrectAnswerFirst.map((a, idx) => (
      <div className={classes.key} key={a.text}>
        <div className={classes.keyColor} style={{ backgroundColor: COLORS[idx] }}></div>
        <EllipsisWithTooltip style={{ width: 150 }} text={a.text} />
      </div>
    ));

  return (
    <div className={cx({ [classes.isHorizontal]: isHorizontal })}>
      <VictoryPie
        data={data}
        colorScale={COLORS}
        labelComponent={<VictoryLabel style={{ fontSize: 20 }} />}
        containerComponent={<VictoryContainer style={{ height: 175 }} />}
        innerRadius={90}
      />
      <div>{renderKeys()}</div>
    </div>
  );
}

const useStyles = makeStyles({
  isHorizontal: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  key: {
    display: 'flex',
    color: '#979797',
    padding: 5,
    alignItems: 'center',
  },
  keyColor: {
    width: 12,
    height: 12,
    marginRight: 6,
    borderRadius: '50%',
  },
});

export default AnswerDistributionChart;
