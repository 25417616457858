import bitesApi from '../../services/bitesApi';

import {
  IFilterBy,
  IFiltersAndPageInfoRequest,
  IFiltersForRequestContent,
  IResponseDataProps,
} from './userAnalysis.types';

export const getUsersAggregation = (payload: IFiltersAndPageInfoRequest): Promise<{ data: IResponseDataProps }> =>
  bitesApi.post('/dashboard/user_organization/filter/', payload);

export const getFilters = (payload: IFilterBy) =>
  bitesApi.post('/dashboard/user_organization/calc_filters/', {
    filters: {
      start_date: payload.start_date,
      end_date: payload.end_date,
      organization: payload.organization,
      data1_ids: payload.data1_ids,
      data2_ids: payload.data2_ids,
      data3_ids: payload.data3_ids,
      data4_ids: payload.data4_ids,
      data5_ids: payload.data5_ids,
      data6_ids: payload.data6_ids,
      data7_ids: payload.data7_ids,
      data8_ids: payload.data8_ids,
      user_organization_ids: payload.user_organization_ids,
    },
  });

export const getUserBitesData = ({ user_org_id, start_date, end_date, timezone_offset }: IFiltersForRequestContent) =>
  bitesApi.post(`/dashboard/user_organization/${user_org_id}/bites/filter/`, {
    filters: { start_date, end_date, timezone_offset },
  });

export const getUserPlaylistsData = ({
  user_org_id,
  start_date,
  end_date,
  timezone_offset,
}: IFiltersForRequestContent) =>
  bitesApi.post(`/dashboard/user_organization/${user_org_id}/playlists/filter/`, {
    filters: {
      start_date,
      end_date,
      timezone_offset,
    },
  });

export const getUserQuizzesData = ({ user_org_id, start_date, end_date, timezone_offset }: IFiltersForRequestContent) =>
  bitesApi.post(`/dashboard/user_organization/${user_org_id}/quizzes/filter/`, {
    filters: {
      start_date,
      end_date,
      timezone_offset,
    },
  });
