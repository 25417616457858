import { datadogLogs } from '@datadog/browser-logs';

import { isProd } from '../utils/env';

datadogLogs.init({
  clientToken: 'pub85a1df7dc99568aad7a8b35ac9dfe069',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: isProd() ? 'prod' : 'staging',
});

export default {};
