import { useDispatch } from 'react-redux';
import useComponentWillMount from 'use-component-will-mount';

import { initClean } from '../features/auth/auth.slice';

import useInitAuth from './useInitAuth';

const useInitApp = (): boolean => {
  const dispatch = useDispatch();

  useComponentWillMount(() => {
    dispatch(initClean());
  });

  const isInitAuthDone = useInitAuth();

  return isInitAuthDone;
};

export default useInitApp;
