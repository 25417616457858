import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

const theme = createTheme({
  typography: {
    fontFamily: 'Gilroy',
  },
  palette: {
    primary: { main: '#1253FA' },
  },
});

interface IProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: IProps) => <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;

export default ThemeProvider;
