import React from 'react';

interface IProps {
  children: React.ReactNode;
  href: string;
  className?: string;
}

function ExternalLink({ children, href, className = '', ...props }: IProps) {
  return (
    <a
      href={href}
      style={{ color: '#3159a5' }}
      target='_blank'
      rel='noreferrer noopener'
      className={className}
      {...props}
    >
      {children}
    </a>
  );
}

export default ExternalLink;
