import { createSlice, createAction } from '@reduxjs/toolkit';

import { PermissionTypes } from '../../types/permissions';
import { IRdgColumnVisibility, IRdgFilters, IRdgGrouping } from '../../types/reactDataGrid';
import { IRootState } from '../../types/store';
import { IUserOrganization, IUserOrganizationForTable } from '../../types/user';
import {
  initColumnVisibility,
  initFilterValue,
  initGrouping,
  mapSelectFilter,
  mapStringFilter,
} from '../../utils/reactDataGrid';
import { formatDatas, isMobile } from '../../utils/utils';

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersError,
  updateUserField,
  updateUserFieldSuccess,
  updateUserFieldError,
  undoUpdateUserField,
  undoUpdateUserFieldSuccess,
  undoUpdateUserFieldError,
  bulkBlockSuccess,
  bulkUnblockSuccess,
  toggleUserManagementColumnVisibility,
  setUserManagementFilterValue,
  setUserManagementGrouping,
} = userManagementSlice.actions;

export const resetPassword = createAction('userManagement/resetPassword');
export const bulkResetPassword = createAction('userManagement/bulkResetPassword');
export const bulkBlock = createAction('userManagement/bulkBlock');
export const bulkUnblock = createAction('userManagement/bulkUnblock');

export default userManagementSlice.reducer;

export const selectUserManagement = (state: IRootState) => state.userManagement;

const formatUsers = (users: IUserOrganization[]): IUserOrganizationForTable[] =>
  // @ts-ignore
  users
    .map((user) => ({ ...user }))
    .map((user) => {
      const roles = [];
      if (user.createBitesPermission) {
        roles.push(PermissionTypes.CREATOR);
      }
      if (user.dashboardPermission || user.adminPermission || user.organizationAdminPermission) {
        roles.push(PermissionTypes.DASHBOARD_ACCESS);
      }
      if (user.contentAdmin) {
        roles.push(PermissionTypes.CONTENT_ADMIN);
      }
      if (user.sendingPermission) {
        roles.push(PermissionTypes.SENDING_PERMISSION);
      }
      for (let i = 1; i <= 8; i++) {
        if (user[`data${i}`]) {
          user[`data${i}`] = user[`data${i}`].name;
        }
      }

      return { ...user, roles };
    });

function getInitialState() {
  return {
    isFetching: false,
    fetchingError: '',
    isUpdatingUserField: false,
    users: [] as IUserOrganizationForTable[],
    columnsVisibility: {
      name: true,
      username: true,
      email: !isMobile(),
      phone: !isMobile(),
      personalId: !isMobile(),
      employeeId: !isMobile(),
      roles: !isMobile(),
      showStats: !isMobile(),
      active: !isMobile(),
      createdAt: !isMobile(),
      createBitesPermission: !isMobile(),
    } as IRdgColumnVisibility,
    filterValue: [
      ...['name', 'username', 'personalId', 'phone', 'email', 'employeeId', 'roles'].map(mapStringFilter),
      ...['showStats', 'createBitesPermission'].map(mapSelectFilter),
      {
        name: 'active',
        operator: 'eq',
        type: 'select',
        value: true,
      },
    ] as IRdgFilters,
    grouping: [] as IRdgGrouping,
  };
}

type IUserManagementState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
    'org/fetchOrgSuccess': (state: IUserManagementState, action) => {
      const datas = formatDatas(action.payload);
      initColumnVisibility('columnsVisibility')(state, datas);
      initFilterValue('filterValue')(state, datas);
      initGrouping('grouping')(state, datas);
    },
  };
}

function getReducers() {
  return {
    fetchUsers: (state: IUserManagementState) => {
      state.isFetching = true;
      state.fetchingError = '';
    },
    fetchUsersSuccess: (state: IUserManagementState, action) => {
      state.isFetching = false;
      state.users = formatUsers(action.payload);
    },
    fetchUsersError: (state: IUserManagementState, action) => {
      state.isFetching = false;
      state.fetchingError = action.payload;
    },
    updateUserField: (state: IUserManagementState) => {
      state.isUpdatingUserField = true;
    },
    updateUserFieldSuccess: (state: IUserManagementState, action) => {
      const { id, field, value, fields } = action.payload;
      const user = state.users.find((u) => u.id === id);
      if (fields) {
        Object.keys(fields).forEach((item) => {
          user[item] = fields[item];
        });
      } else {
        user[field] = value;
      }
      state.isUpdatingUserField = false;
    },
    updateUserFieldError: (state: IUserManagementState) => {
      state.isUpdatingUserField = false;
    },
    undoUpdateUserField: (state: IUserManagementState) => {
      state.isUpdatingUserField = true;
    },
    undoUpdateUserFieldSuccess: (state: IUserManagementState, action) => {
      const { id, field, value, fields } = action.payload;
      const user = state.users.find((u) => u.id === id);
      if (fields) {
        Object.keys(fields).forEach((item) => {
          user[item] = fields[item];
        });
      } else {
        user[field] = value;
      }
      state.isUpdatingUserField = false;
    },
    undoUpdateUserFieldError: (state: IUserManagementState) => {
      state.isUpdatingUserField = false;
    },
    bulkBlockSuccess: (state: IUserManagementState, action) => {
      const ids = action.payload;
      state.users = state.users.map((u) => {
        if (ids.includes(u.id)) {
          u.active = false;
        }
        return u;
      });
    },
    bulkUnblockSuccess: (state: IUserManagementState, action) => {
      const ids = action.payload;
      state.users = state.users.map((u) => {
        if (ids.includes(u.id)) {
          u.active = true;
        }
        return u;
      });
    },
    toggleUserManagementColumnVisibility: (state: IUserManagementState, action) => {
      state.columnsVisibility[action.payload] = !state.columnsVisibility[action.payload];
    },
    setUserManagementFilterValue: (state: IUserManagementState, action) => {
      state.filterValue = action.payload;
    },
    setUserManagementGrouping: (state: IUserManagementState, action) => {
      state.grouping = action.payload;
    },
  };
}
