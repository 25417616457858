import { AxiosResponse } from 'axios';
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EOrgUsersPrimaryKey } from '../../../../../../types/org';
import { selectOrg } from '../../../../../org/org.slice';
import { organizationData, updateSingleUser } from '../../../../userManagement.api';
import { EPagesSingleUser, IPageSingleUser } from '../../SingleUserModel';
import { InputHandler, LoaderHelper, modalColors, S } from '../../ui';

import { DropDownArrHelper, IDropDown, IDropValue } from './dropDownHelper';

const controllerStyle = { overflowY: 'scroll', height: '60%', marginTop: '20px' };
const contentStyle = { textAlign: 'left', marginLeft: '15px' };
const headerStyle = { textAlign: 'center' };
const modelButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
  flex: 1,
};

export interface IUpdateUserPayload {
  employee_number: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  extra_data: IDropDown[];
}

// interface IAddUserAPI {
//   data: {
//     success: boolean;
//     params: IUpdateUserPayload;
//     code?: number;
//   };
// }

export interface IDropDownResponse {
  success: boolean;
  params: IDropDown[];
}

interface InsertUserValidationError {
  field: string;
  message: string;
}

interface IUploadUserResponse {
  success: boolean;
  params: InsertUserValidationError;
}

const SingleUploadInputs = (props: IPageSingleUser) => {
  const { t } = useTranslation();
  const { setPage } = props;
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(undefined);
  const { id, usersPrimaryKey } = useSelector(selectOrg);

  const [employeeIdError, setEmployeeIdError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumberError, setphoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [dropData, setDropData] = useState<IDropDown[]>([]);
  const [userChoiseDrop, setUserChoiseDrop] = useState([]);
  const [valueDrop, setValueDrop] = useState<IDropValue>({});
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    async function fetchData() {
      setSpinner(true);

      try {
        const { data } = (await organizationData(id)) as AxiosResponse<IDropDownResponse>;
        // const { data }: AxiosResponse<IDropDownResponse> = await axios.get(
        //   'http://localhost:3000/api/organization/5108/dropdown_data/',
        // );

        setDropData(data.params);
        setSpinner(false);
      } catch (e) {
        setError(t('userManagement.inputs.anErrorHasOccured'));
        setSpinner(false);
        return;
      }
    }
    fetchData();
  }, [id, t]);

  const onChangeHandler = useCallback(
    (e, data, name) => {
      const obj = valueDrop;
      obj[name] = e.value;
      setValueDrop(obj);
      const current = [...userChoiseDrop];
      const find = current.findIndex((i) => i.name === name);
      if (find > -1) {
        current.splice(find, 1);
      }
      current.push({ data, selected: e.value, name });
      setUserChoiseDrop(current);
    },
    [userChoiseDrop, valueDrop],
  );

  const validationInput = (key, setKey) => {
    if (key !== '') {
      setKey('');
    }
  };

  const validateInputBeforeApiCall = useCallback(() => {
    let flag = false;
    if (!employeeNumber && usersPrimaryKey === EOrgUsersPrimaryKey.EmployeeId) {
      setEmployeeIdError(t('userManagement.inputs.fillEmployeeId'));
      flag = true;
    } else {
      setEmployeeIdError('');
    }
    if (employeeNumber.length > 50) {
      setEmployeeIdError(t('userManagement.inputs.maxNum'));
      flag = true;
    }

    if (firstName === '') {
      setFirstNameError(t('userManagement.inputs.didntFillFirstName'));
      flag = true;
    } else {
      setFirstNameError('');
    }

    if (lastName === '') {
      setLastNameError(t('userManagement.inputs.didntFillLastName'));
      flag = true;
    } else {
      setLastNameError('');
    }
    if (!phoneNumber && !email) {
      setphoneNumberError(t('userManagement.inputs.emailOrPhoneRequired'));
      setEmailError(t('userManagement.inputs.eitherEmailOrPhoneRequired'));
      flag = true;
    } else {
      setphoneNumberError('');
      setEmailError('');
    }

    if (userChoiseDrop?.length !== dropData?.length) {
      setError(t('userManagement.inputs.extraDataDropbox'));
      flag = true;
    } else {
      setError('');
    }
    return flag;
  }, [
    dropData?.length,
    email,
    employeeNumber,
    firstName,
    lastName,
    phoneNumber,
    t, // todo remove this for slightly better performance
    userChoiseDrop?.length,
    usersPrimaryKey,
  ]);

  const onClicAddUserCallback = useCallback(async () => {
    const flag = validateInputBeforeApiCall();

    const payload: IUpdateUserPayload = {
      employee_number: employeeNumber,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
      extra_data: userChoiseDrop,
    };

    if (flag) {
      return;
    }

    try {
      setSpinner(true);
      (await updateSingleUser(id, payload)) as AxiosResponse<IUploadUserResponse>;

      setPage(EPagesSingleUser.SINGLE_UPLOAD_SUCCESS);
    } catch (e) {
      const { status, data } = e.response;
      const { params } = data;
      if (status === 400) {
        params.forEach((item) => {
          if (item.field === 'phone') {
            setphoneNumberError(item.message);
          }
          if (item.field === 'email') {
            setEmailError(item.message);
          }
          if (item.field === 'email_phone') {
            setError(item.message);
          }
        });
        validationInput(employeeNumber, setEmployeeIdError);
        validationInput(firstName, setFirstNameError);
        validationInput(lastName, setLastNameError);
      }
      if (status === 409) {
        const { errorCode } = data;
        if (errorCode === 'SameUserError') {
          setError('common.emailOrPhoneAlreadyExists');
        }
      } else {
        setError(t('common.error'));
      }
      setSpinner(false);
    }
  }, [
    email,
    employeeNumber,
    firstName,
    id,
    lastName,
    phoneNumber,
    setPage,
    t,
    userChoiseDrop,
    validateInputBeforeApiCall,
  ]);

  return (
    <S.Wrapper>
      <S.BackAndHeaderWrapper>
        <S.TextHeader style={headerStyle}>{t('userManagement.inputs.uploadSingleUser')}</S.TextHeader>
      </S.BackAndHeaderWrapper>
      <S.Content style={contentStyle}>{t('userManagement.inputs.fillUserDetails')}</S.Content>
      {spinner ? (
        <LoaderHelper />
      ) : (
        <>
          <S.Controllers style={controllerStyle}>
            <InputHandler
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmployeeNumber(e.target.value)}
              placeholder='123abc'
              value={employeeNumber}
              label={t('userManagement.labels.employeeId')}
              errorMsg={employeeIdError}
            />

            <InputHandler
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              placeholder='Jane'
              value={firstName}
              label={t('userManagement.labels.firstName')}
              errorMsg={firstNameError}
            />

            <InputHandler
              onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              placeholder='Smith'
              value={lastName}
              label={t('userManagement.labels.lastName')}
              errorMsg={lastNameError}
            />
            <S.EmailPhonWrapper>
              <S.BorderText>{t('userManagement.inputs.fillEmailOrPhone')}</S.BorderText>

              <S.PhoneEmail>
                <InputHandler
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                  placeholder='+1-xxx-xxx-xxxx'
                  value={phoneNumber}
                  label={t('userManagement.labels.phoneNumber')}
                  errorMsg={phoneNumberError}
                />

                <InputHandler
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  placeholder='Jane.smith@gmail.com'
                  value={email}
                  label={t('userManagement.labels.email')}
                  errorMsg={emailError}
                />
              </S.PhoneEmail>
            </S.EmailPhonWrapper>
            {dropData ? (
              <DropDownArrHelper dropData={dropData} onChangeHandler={onChangeHandler} valueDrop={valueDrop} />
            ) : (
              <></>
            )}
            <S.Error>{error}</S.Error>
          </S.Controllers>

          <S.ModelButton style={modelButtonStyle} onClick={onClicAddUserCallback}>
            {t('userManagement.inputs.addUser')}
          </S.ModelButton>
        </>
      )}
    </S.Wrapper>
  );
};

export default memo(SingleUploadInputs);
