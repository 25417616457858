import { createAction, createSlice } from '@reduxjs/toolkit';

import { EOrgUsersPrimaryKey, IOrgData } from '../../types/org';
import { IRootState } from '../../types/store';
import { formatDatas } from '../../utils/utils';

const orgSlice = createSlice({
  name: 'org',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const { fetchOrg, fetchOrgSuccess, fetchOrgError, switchOrg, updateDefaultOrg, setUsersList } = orgSlice.actions;

export default orgSlice.reducer;

export const selectOrg = (state: IRootState): IOrgState => state.org;

export const selectDatasStrings = (state: IRootState) => state.org.datas.map((data) => data.title);

function getInitialState() {
  return {
    isFetching: false,
    logoUrl: '',
    name: '',
    id: null,
    datas: [] as IOrgData[],
    usersList: null,
    readableName: '',
    usersPrimaryKey: EOrgUsersPrimaryKey.Email,
    features: [],
  };
}

interface ITeamsPayload {
  orgId: number;
}

export const fetchTeams = createAction<ITeamsPayload>('org/fetchTeams');

type IOrgState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}

function getReducers() {
  return {
    fetchOrg: (state: IOrgState, action) => {
      state.id = action.payload;
      state.isFetching = true;
    },
    fetchOrgSuccess: (state: IOrgState, action) => {
      state.isFetching = false;
      state.logoUrl = action.payload.brandIcon;
      state.datas = formatDatas(action.payload);
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.readableName = action.payload.readableName;
      state.usersPrimaryKey = action.payload.usersPrimaryKey;
      state.features = action.payload.features;
    },
    fetchOrgError: (state: IOrgState) => {
      state.isFetching = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchOrg: (state: IOrgState, action) => {
      state.usersList = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateDefaultOrg: (state: IOrgState, action) => {
      // do nothing
    },
    setUsersList: (state: IOrgState, action) => {
      state.usersList = action.payload;
    },
  };
}
