import React from 'react';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import RenderOverviewTable from '../../../components/ReactDataGrid/RenderOverviewTable';
import SelectTableDisplay from '../../../components/SelectTableDisplay';
import { selectPlaylist, setPlaylistViewsByUserTableDisplay } from '../playlist.slice';

import OverviewTable from './OverviewTable';
import Table from './Table';

function PlaylistUserView() {
  const { isFetchingUserView, userViewError, playlistViewsByUserTableDisplay } = useSelector(selectPlaylist);

  if (isFetchingUserView) {
    return <Loader />;
  }

  if (userViewError) {
    return <ErrorMsg>Error loading data: {userViewError}</ErrorMsg>;
  }

  const renderTable = () => {
    if (playlistViewsByUserTableDisplay !== 'drilldown') {
      return null;
    }
    return <Table />;
  };

  return (
    <>
      <SelectTableDisplay
        currentDisplay={playlistViewsByUserTableDisplay}
        setDisplay={setPlaylistViewsByUserTableDisplay}
        origin='playlist'
      />
      <RenderOverviewTable
        Table={OverviewTable}
        style={{ maxWidth: 600 }}
        tableDisplay={playlistViewsByUserTableDisplay}
      />
      {renderTable()}
    </>
  );
}

export default PlaylistUserView;
