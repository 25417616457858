import { createSlice } from '@reduxjs/toolkit';

import { ILeanQuiz } from '../../types/quiz';
import { IRootState } from '../../types/store';

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const { fetchQuizzes, fetchQuizzesSuccess, fetchQuizzesError } = quizzesSlice.actions;

export default quizzesSlice.reducer;

export const selectQuizzes = (state: IRootState) => state.quizzes;

function getInitialState() {
  return {
    isFetching: false,
    error: '',
    quizzes: [] as ILeanQuiz[],
  };
}

type IQuizzesState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}

function getReducers() {
  return {
    fetchQuizzes: (state: IQuizzesState) => {
      state.isFetching = true;
      state.error = '';
    },
    fetchQuizzesSuccess: (state: IQuizzesState, action) => {
      state.isFetching = false;
      state.quizzes = action.payload;
    },
    fetchQuizzesError: (state: IQuizzesState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
  };
}
