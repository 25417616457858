import axios from 'axios';

import bitesApi, { API_BASE_URL, defaultHeaders } from '../../../services/bitesApi';
import {
  ICurrentUser,
  ICurrentUserUserOrganization,
  IFinalizeProfileDataRequest,
  IUserOrganization,
} from '../../../types/user';
import { ECAptchaAction, wrapWithCaptcha } from '../../../utils/captcha';

interface IPasswordRecoveryBody {
  email: string;
  callback: () => void;
}

export const setNewRecoverPassword = (body) =>
  bitesApi.post('/accounts/password_reset/confirm/', body, {}, { raw: true });

export const login = wrapWithCaptcha<ILoginResponse>(ECAptchaAction.LOGIN, (body, { headers } = {}) =>
  bitesApi.post<ILoginResponse>('/accounts/login_v2/', body, { headers }, { raw: true }),
);
export const loginWithPhone = wrapWithCaptcha<ILoginResponse>(
  ECAptchaAction.LOGIN,
  (credentials: ISigninPhoneCredentials, { headers } = {}) =>
    bitesApi.post('/accounts/login_v2/', credentials, { headers }, { raw: true }),
);

export const exchangeSsoToken = wrapWithCaptcha(ECAptchaAction.LOGIN, (data: any, { headers } = {}) =>
  axios.post<IExchangeSsoTokenResponse>(API_BASE_URL + '/teams/get_auth_token/', data, { ...defaultHeaders, headers }),
);

export const getCurrentUser = (token?: string) => {
  const headers = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return bitesApi.get<ICurrentUser>(
    '/accounts/user/',
    {},
    {
      headers,
    },
    { raw: true },
  );
};

export const passwordRecovery = wrapWithCaptcha(
  ECAptchaAction.PASSWORD_RESET,
  (body: IPasswordRecoveryBody, { headers } = {}) =>
    bitesApi.post('/accounts/password_reset/', body, { headers }, { raw: true }),
);

export const finalizeUserProfile = (userId: IUserOrganization['id'], payload: IFinalizeProfileDataRequest) =>
  bitesApi.post(`/accounts/finalize_registration/${userId}/`, payload, {}, { raw: true });

export const logout = () => bitesApi.post('/accounts/logout/', {}, {}, { raw: true });

export interface ILoginResponse {
  token: string;
  user: ILoginResponseUser;
}

export interface ILoginResponseUser {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  organizations: ICurrentUserUserOrganization[];
  personalId: null | number;
  phone: string;
  profileImage: string;
  username: string;
}

export interface IExchangeSsoTokenResponse {
  access_token: string;
}

export interface ISigninPhoneCredentials {
  username: string;
  otp?: string;
  app?: string;
}
