import { all, select, takeLatest } from 'redux-saga/effects';

import { IAction } from '../../types/types';
import { fetchBitesDataSaga } from '../bites/bites.saga';
import { selectOrg } from '../org/org.slice';

import { fetchAskAiPromptHistory, IFetchAskAiPromptHistoryAction } from './askAiReports.actions';
import * as calls from './askAiReports.api';

export function* fetchAskAiPromptHistorySaga({
  payload: { page, pageSize, onSuccess, onError },
}: IAction<IFetchAskAiPromptHistoryAction>) {
  try {
    const org = yield select(selectOrg);
    const { data } = yield calls.fetchAskAiPromptHistory({ orgId: org.id, page, pageSize });

    const allBitesIds = [
      ...new Set(
        data.results
          .map((item) => (item.historyItem.answer ? item.historyItem.relatedContent : []))
          .flat()
          .concat(
            data.results.map((item) => (!item.historyItem.answer ? item.historyItem.searchRelatedContent : [])).flat(),
          )
          .map((bite) => bite && bite.biteId)
          .filter((biteId) => biteId),
      ),
    ] as number[];

    yield fetchBitesDataSaga(allBitesIds);

    if (typeof onSuccess === 'function') {
      onSuccess({ data: data.results, count: data.count });
    }
  } catch (error) {
    console.error(error);
    if (typeof onError === 'function') {
      onError(error);
    }
  }
}

export default function* askAiReportsSaga() {
  yield all([takeLatest(fetchAskAiPromptHistory, fetchAskAiPromptHistorySaga)]);
}
