import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoaderSvg } from '../assets/icons/loader.svg';

interface IProps {
  size?: number | string;
}

const Loader: React.FC<IProps> = ({ size }) => (
  <S.Container>
    <S.LoaderSvg size={size} />
  </S.Container>
);

const S: any = {};

S.Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: not-allowed;
`;

S.LoaderSvg = styled(LoaderSvg)<{ size?: number | string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${({ size = '32px' }) => size};
  height: ${({ size = '32px' }) => size};
`;

export default Loader;
