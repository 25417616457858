import bitesApi from '../../services/bitesApi';

export const getOrgStats = (orgId) => bitesApi.get<IStats>(`/dashboard/organization_stats/${orgId}/`);

interface IStats {
  totalBites: number;
  totalUsers: number;
  totalPlaylists: number;
  totalBiteViews: number;
}
