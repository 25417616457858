import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { authInProgressSelector, errorCodesSelector, phoneFormSelector } from '../../../authForm/authForm.selectors';
import { loginWithPhone, setCurrentForm, setPhone, resetErrors } from '../../../authForm/authForm.slice';
import { log } from '../../../tracking/tracking.slice';
import { EAuthFormType } from '../../auth.types';

import PhoneNumberForm from './PhoneNumberForm';

const PhoneNumberAuth: FC = () => {
  const dispatch = useDispatch();

  const errorCodes = useSelector(errorCodesSelector);
  const authInProgress = useSelector(authInProgressSelector);
  const currentForm = EAuthFormType.PHONE_NUMBER;
  const phone = useSelector(phoneFormSelector);

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'PhoneNumberAuth.handleOtp',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_PHONE, currentForm },
        }),
      );

      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_PHONE));
      dispatch(resetErrors());
    },
    [currentForm, dispatch],
  );

  const handleContinue = useCallback(() => {
    const processId = uuid();
    dispatch(
      log({
        event: 'PhoneNumberAuth.handleContinue',
        processId,
        data: {
          currentForm,
          username: phone,
        },
      }),
    );

    dispatch(resetErrors());

    dispatch(
      loginWithPhone({
        username: phone,
        processId,
        onOtp: handleOtp,
      }),
    );
  }, [currentForm, dispatch, handleOtp, phone]);

  const handlePhoneNumberChange = useCallback(
    (number: string) => {
      dispatch(setPhone(number));
      dispatch(resetErrors());
    },
    [dispatch],
  );

  return (
    <PhoneNumberForm
      errorCodes={errorCodes}
      phone={phone}
      isLoading={authInProgress}
      onChange={handlePhoneNumberChange}
      onContinue={handleContinue}
    />
  );
};

export default memo(PhoneNumberAuth);
