import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectQuizzes } from '../features/quizzes/quizzes.slice';
import gtmTrack from '../services/googleTagManager/track';
import { formatDate } from '../utils/dates';

import EllipsisWithTooltip from './EllipsisWithTooltip';
import ReactDataGridSearchableTable from './ReactDataGrid/SearchableTable';

interface IProps {
  onQuizSelect?: () => void;
}

function QuizSelectionTable({ onQuizSelect }: IProps) {
  const { t } = useTranslation();
  const { quizzes } = useSelector(selectQuizzes);

  const columns = useMemo((): TypeColumn[] => {
    const DateColumn = ({ value }) => formatDate(value);
    const Name = ({ value, data }) => (
      <Link
        style={{ display: 'block' }}
        onClick={() => handleLinkClick(data.id)}
        to={`/quizzes/${data.id}`}
        data-cy='name'
      >
        <EllipsisWithTooltip text={value} />
      </Link>
    );
    const handleLinkClick = (id) => {
      gtmTrack('open_quiz', { quiz_id: id });
      if (onQuizSelect) {
        onQuizSelect();
      }
    };
    return [
      { name: 'subject', header: t('table.quiz'), defaultFlex: 1, render: Name },
      {
        name: 'createdAt',
        header: t('table.dateCreated'),
        defaultFlex: 1,
        render: DateColumn,
      },
      {
        name: 'creatorName',
        header: t('table.creator'),
        defaultFlex: 1,
      },
    ];
  }, [t, onQuizSelect]);

  const footerRows = [
    {
      render: {
        subject: () => t('table.quizzesLength', { length: quizzes.length }),
      },
    },
  ];

  const onSortInfoChange = (selectedColumn) => {
    if (selectedColumn?.id) {
      if (selectedColumn.id === 'createdAt') {
        gtmTrack('quizzes_sort_by_date_created');
      } else if (selectedColumn.id === 'subject') {
        gtmTrack('quizzes_sort_by_quiz');
      }
    }
  };

  const handleSearch = (term) => {
    trackSearch(term);
  };

  return (
    <ReactDataGridSearchableTable
      rawData={quizzes}
      columns={columns}
      footerRows={footerRows}
      onSearch={handleSearch}
      onSortInfoChange={onSortInfoChange}
      origin='quiz_selection_table'
    />
  );
}
export default QuizSelectionTable;

const trackSearch = debounce((term) => gtmTrack('quizzes_search', { term }), 1000);
