import { Hidden, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as NextArrowIcon } from '../../../assets/icons/lightbox-next.svg';
import { ReactComponent as PrevArrowIcon } from '../../../assets/icons/lightbox-prev.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import gtmTrack from '../../../services/googleTagManager/track';
import { selectQuiz, setQuizAnalysisSelectedQuestionIndex } from '../quiz.slice';

import QuestionAndAnswers from './QuestionAndAnswers';
import QuestionAndAnswersMobileModal from './QuestionAndAnswersMobileModal';
import Table from './Table';

function QuizAnalysis() {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const [isQuestionAndAnswersMobileModalOpen, setIsQuestionAndAnswersMobileModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { id, questions, quizAnalysisSelectedQuestionIndex } = useSelector(selectQuiz);

  const handlePrev = () => {
    gtmTrack('quizzes_quiz_quiz_analysis_left_movement', {
      quiz_id: id,
      question_id: quizAnalysisSelectedQuestionIndex,
    });
    if (quizAnalysisSelectedQuestionIndex === 0) {
      dispatch(setQuizAnalysisSelectedQuestionIndex(questions.length - 1));
    } else {
      dispatch(setQuizAnalysisSelectedQuestionIndex(quizAnalysisSelectedQuestionIndex - 1));
    }
  };

  const handleNext = () => {
    gtmTrack('quizzes_quiz_quiz_analysis_right_movement', {
      quiz_id: id,
      question_id: quizAnalysisSelectedQuestionIndex,
    });
    if (quizAnalysisSelectedQuestionIndex === questions.length - 1) {
      dispatch(setQuizAnalysisSelectedQuestionIndex(0));
    } else {
      dispatch(setQuizAnalysisSelectedQuestionIndex(quizAnalysisSelectedQuestionIndex + 1));
    }
  };

  const handleSelectQuestion = (index) => {
    dispatch(setQuizAnalysisSelectedQuestionIndex(index));
    if (isMobile) {
      setIsQuestionAndAnswersMobileModalOpen(true);
    }
  };

  if (questions.length === 0) {
    return <span>This quiz has no questions</span>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <Table onSelectQuestion={handleSelectQuestion} />
      </div>
      <Hidden smDown>
        <div className={classes.questionAndAnswersWrapper}>
          <PrevArrowIcon data-cy='prev' onClick={handlePrev} className={classes.arrowIcon} />
          <QuestionAndAnswers />
          <NextArrowIcon data-cy='next' onClick={handleNext} className={classes.arrowIcon} />
        </div>
      </Hidden>
      <QuestionAndAnswersMobileModal
        isOpen={isQuestionAndAnswersMobileModalOpen}
        onClose={() => setIsQuestionAndAnswersMobileModalOpen(false)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    width: 384,
    marginInlineEnd: '10px',
  },
  questionAndAnswersWrapper: {
    borderRadius: 22,
    background: 'rgba(179, 179, 179, 0.2)',
    paddingTop: 45,
    paddingBottom: 45,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  arrowIcon: {
    fill: '#666',
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      width: 38,
    },
    [theme.breakpoints.down('lg')]: {
      width: 18,
    },
  },
}));

export default QuizAnalysis;
