import React, { ChangeEvent } from 'react';
import { StylesConfig } from 'react-select';

import { S } from '../../ui';

export interface IDropDown {
  data: string;
  arr: string[];
  name: string;
}

interface IDropDownSingleHelper {
  item: IDropDown;
  onChangeHandler: any;
  valueDrop: any;
}

const customStylesSelect: StylesConfig<{ label: string; value: string }, false> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'blue',
    margin: '2px',
  }),
  menu: (provided) => ({ ...provided }),
  control: (styles) => ({
    ...styles,
    width: '209px',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '125px',
  }),
};

const labelStyle = { top: '10px' };

interface IDropDownArrHelper {
  dropData: IDropDown[];
  valueDrop: IDropValue;
  onChangeHandler: any;
}

export interface IDropValue {
  name_1?: string;
  name_2?: string;
  name_3?: string;
  name_4?: string;
  name_5?: string;
  name_6?: string;
  name_7?: string;
  name_8?: string;
}

export const DropDownSingleHelper = (props: IDropDownSingleHelper) => {
  const { item } = props;
  const optionsItem = [];
  item.arr.forEach((element) => optionsItem.push({ value: element, label: element }));
  const LabelItem = () => <S.InputLabel style={labelStyle}>{item.data}</S.InputLabel>;
  const SelectItem = () => (
    <S.CustomSelect
      styles={customStylesSelect}
      options={optionsItem}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        props.onChangeHandler(e, item.data, item.name);
      }}
      value={optionsItem.filter(function (option) {
        return option.value === props.valueDrop[item.name];
      })}
    />
  );
  return (
    <S.InputLabelWrapper>
      <LabelItem />
      <SelectItem />
    </S.InputLabelWrapper>
  );
};

export const DropDownArrHelper = (props: IDropDownArrHelper) => {
  const { dropData, onChangeHandler, valueDrop } = props;
  if (dropData) {
    const arr = dropData.map((element: IDropDown, index: number) => {
      return (
        <DropDownSingleHelper item={element} key={index} onChangeHandler={onChangeHandler} valueDrop={valueDrop} />
      );
    });

    return <>{arr}</>;
  }
  return <></>;
};
