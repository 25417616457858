import { IConfig, IFormatToDefaultValues } from './remoteConfig.types';

export const minimumFetchIntervalMillis = 1 * 60 * 1000;

export const formatToDefaultValues: IFormatToDefaultValues = (values) => {
  const formatted = Object.entries(values).reduce((map, [key, data]) => {
    map[key] = data.string;
    return map;
  }, {});
  return formatted;
};

export const parseConfigValues = (config) => {
  const values = Object.entries(config).reduce((map: IConfig, [key, Value]: [string, { asString: () => string }]) => {
    const stringValue = Value.asString();
    map[key] = {
      string: stringValue,
      parsed: undefined,
      number: Number(stringValue),
      boolean: stringValue === 'true' ? true : stringValue === 'false' ? false : undefined,
    };
    try {
      map[key].parsed = JSON.parse(stringValue);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }
    return map;
  }, {});
  return values;
};
