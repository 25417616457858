import { AxiosResponse } from 'axios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as BiteHands } from '../../../../../../assets/icons/bite-hands.svg';
import { currentUserSelector } from '../../../../../auth/auth.selectors';
import { selectOrg } from '../../../../../org/org.slice';
import { updateCsv } from '../../../../userManagement.api';
import { EPagesSyncUpload } from '../../SyncUploadModal';
import { HelpHelper, iconSize, LoaderHelper, modalColors, S } from '../../ui';

import { ButtonValidHelper, TextValidationHelper } from './previewValidationHelper';
import { IValidation } from './uploadCSV';

interface IUpdateCsvResponse {
  success: boolean;
}

const modelButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
  width: '160px',
};
const iconStyle = { width: iconSize + 30, height: iconSize + 30 };
const buttonWrapper = { display: 'block', marginTop: '50px' };

const PreviewValidation = (props: IValidation) => {
  const { t } = useTranslation();
  const { setPage, uploadDetails } = props;

  const [users, setUsers] = useState(undefined);
  const [hasChanges, setHasChanges] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const { id } = useSelector(selectOrg);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    if (uploadDetails.added === 0 && uploadDetails.modified === 0 && uploadDetails.deleted === 0) {
      setHasChanges(false);
    }
    setUsers(uploadDetails);
  }, [uploadDetails]);

  const handleValidationYesClick = useCallback(async () => {
    const { url } = uploadDetails;
    setIsLoading(true);
    try {
      const { data } = (await updateCsv(id, {
        link: url,
        user_id: currentUser.id,
      })) as AxiosResponse<IUpdateCsvResponse>;
      // const { data } = await axios.post(
      //   `http://localhost:3000/api/organization/${id}/update_csv/`,
      //   { link: url, user_id: currentUser.id },
      //   {
      //     headers: { 'Content-Type': 'application/json' },
      //   },
      // );
      if (data.success) {
        setPage(EPagesSyncUpload.SUCCESS);
      } else {
        setError(t('common.forDebugOnly', { data }));
      }
    } catch (err) {
      setError(t('common.error'));
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  }, [uploadDetails, id, currentUser.id, setPage, t]);

  const handleValidationNoClick = useCallback(() => {
    setPage(EPagesSyncUpload.UPLOAD_CSV);
  }, [setPage]);

  return (
    <S.Wrapper style={{ height: `${window.innerHeight - 200}px`, overflow: 'auto' }}>
      <S.IconWrapper>
        <BiteHands style={iconStyle} />
      </S.IconWrapper>
      {error ? <S.Content>{t('userManagement.pages.previewValidation.errorHasOccured', { error })}</S.Content> : <></>}
      <TextValidationHelper extraData={uploadDetails} hasChanges={hasChanges} users={users} />
      <S.Controllers>
        <S.ButtonWrapper style={buttonWrapper}>
          {isLoading ? (
            <LoaderHelper />
          ) : (
            <>
              <ButtonValidHelper handleValidationYesClick={handleValidationYesClick} hasChanges={hasChanges} />

              <S.ModelButton style={modelButtonStyle} onClick={handleValidationNoClick}>
                {t('userManagement.pages.previewValidation.goBack')}
              </S.ModelButton>
            </>
          )}
        </S.ButtonWrapper>
      </S.Controllers>
      <HelpHelper />
    </S.Wrapper>
  );
};

export default memo(PreviewValidation);
