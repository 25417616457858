import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';
import { percentage } from '../../../../utils/numbers';
import { selectDatasStrings } from '../../../org/org.slice';
import { selectBite } from '../../bite.slice';

interface IArgs {
  groupedByDataIndex: number;
}

const useColumns = ({ groupedByDataIndex }: IArgs): TypeColumn[] => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const datasStrings = useSelector(selectDatasStrings);
  const { questionType } = useSelector(selectBite);

  return useMemo(() => {
    const Name = ({ value, data }) => `${value} (${data.recordsCount})`;

    const AnsweredPercentage = ({ data }) =>
      isMobile ? (
        <StyledPercentage percentage={percentage(data.answered, data.recordsCount)} />
      ) : (
        <PercentsAndNumbers count={data.answered} total={data.recordsCount} />
      );

    const result = [
      {
        name: 'name',
        header: datasStrings[groupedByDataIndex].toUpperCase(),
        defaultFlex: 1,
        render: Name,
      },
      {
        name: 'answeredPercentage',
        header: t('bites.answered'),
        maxWidth: isMobile ? 95 : 150,
        type: 'number',
        render: AnsweredPercentage,
      },
    ];

    if (questionType === 'multiple choices') {
      const AnsweredCorrectlyPercentage = ({ data }) =>
        isMobile ? (
          <StyledPercentage percentage={percentage(data.answeredCorrectly, data.answered)} />
        ) : (
          <PercentsAndNumbers count={data.answeredCorrectly} total={data.answered} />
        );
      result.push({
        name: 'answeredCorrectlyPercentage',
        header: t('bites.success'),
        maxWidth: isMobile ? 95 : 150,
        type: 'number',
        render: AnsweredCorrectlyPercentage,
      });
    }
    return result;
  }, [datasStrings, groupedByDataIndex, t, isMobile, questionType]);
};

export default useColumns;
