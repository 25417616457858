import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';
import { percentage } from '../../../../utils/numbers';

const useFooterRows = ({ dataSource }) => {
  const isMobile = useIsMobile();

  return useMemo(() => {
    const AnsweredPercentage = () => {
      const count = sumBy(dataSource, 'answered');
      const total = sumBy(dataSource, 'recordsCount');
      return isMobile ? (
        <StyledPercentage percentage={percentage(count, total)} />
      ) : (
        <PercentsAndNumbers count={count} total={total} />
      );
    };

    const AnsweredCorrectlyPercentage = () => {
      const count = sumBy(dataSource, 'answeredCorrectly');
      const total = sumBy(dataSource, 'answered');
      return isMobile ? (
        <StyledPercentage percentage={percentage(count, total)} />
      ) : (
        <PercentsAndNumbers count={count} total={total} />
      );
    };

    return [
      {
        render: {
          name: () => `${sumBy(dataSource, 'recordsCount')} Users`,
          answeredPercentage: AnsweredPercentage,
          answeredCorrectlyPercentage: AnsweredCorrectlyPercentage,
        },
      },
    ];
  }, [dataSource, isMobile]);
};

export default useFooterRows;
