import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  errorCodesSelector,
  isLoginErrorSelector,
  isPhoneMissingSelector,
  isSsoErrorSelector,
  isWrongVerificationCodeSelector,
} from '../../authForm/authForm.selectors';
import AuthErrorTypes from '../auth.errors';

const useAuthError = () => {
  const { t } = useTranslation();

  const isSsoError = useSelector(isSsoErrorSelector);
  const isLoginError = useSelector(isLoginErrorSelector);
  const errorCodes = useSelector(errorCodesSelector);
  const isPhoneMissing = useSelector(isPhoneMissingSelector);
  const isWrongVerificationCode = useSelector(isWrongVerificationCodeSelector);

  if (isPhoneMissing) {
    return t('authForm.phoneNumberNotExists');
  }

  if (isWrongVerificationCode) {
    return t('authForm.enterCodeForm.codeIsIncorrect');
  }

  if (errorCodes) {
    if (errorCodes.includes('1')) {
      return t('authForm.errorUserTaken');
    }

    if (errorCodes.includes('2')) {
      return t('authForm.errorInvalidNumber');
    }

    if (errorCodes.includes('3')) {
      return t('authForm.emailAlreadyTaken');
    }

    if (errorCodes.includes('4')) {
      return t('authForm.forgotPasswordForm.resetPassTooCommon');
    }

    if (errorCodes.includes(AuthErrorTypes.wrongCredentials) || errorCodes.includes(AuthErrorTypes.noUser)) {
      return t('authForm.wrongEmailOrPassword');
    }

    if (errorCodes.includes(AuthErrorTypes.noAccess)) {
      return t('authForm.noAccess');
    }
  }

  // else case
  if (isLoginError || isSsoError) {
    return t('common.somethingWentWrong');
  }

  return null;
};
export default useAuthError;
