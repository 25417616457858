import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OTPContainer from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Loader from '../../../../components/Loader';
import { authInProgressSelector } from '../../../authForm/authForm.selectors';
import { setIsWrongVerificationCode } from '../../../authForm/authForm.slice';
import useAuthError from '../../hooks/useAuthError';
import Header from '../Header';

interface IProps {
  onContinue: (code: string) => void;
  onResend: () => void;
  destination: string;
}

const RESENT_TIME_IN_SEC = 60;

const codeInputFieldStyle = {
  width: 45,
  height: 45,
};

const EnterCodeForm: FC<IProps> = ({ onContinue, onResend, destination }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authInProgress = useSelector(authInProgressSelector);
  const error = useAuthError();

  const [otp, setOtp] = useState('');

  const [isResendTimer, setIsResendTimer] = useState(true);
  const [timer, setTimer] = useState(RESENT_TIME_IN_SEC);

  const codeInputContainerStyle: CSSProperties = useMemo(
    () => ({
      justifyContent: 'space-between',
      display: 'flex',
      height: 49,
      margin: '45px 16px 0',

      opacity: authInProgress ? 0.5 : 1,
      pointerEvents: authInProgress ? 'none' : null,
    }),
    [authInProgress],
  );

  const handleResendCode = useCallback(() => {
    setOtp('');
    setIsResendTimer(true);
    onResend();
  }, [onResend]);

  const handleContinue = useCallback(
    (code: string) => {
      onContinue(code);
    },
    [onContinue],
  );

  useEffect(() => {
    let interval = null;

    if (isResendTimer && timer > 0) {
      interval = setInterval(() => {
        setTimer((val) => val - 1);
      }, 1000);
    } else if (timer < 1) {
      clearInterval(interval);
      setTimer(RESENT_TIME_IN_SEC);
      setIsResendTimer(false);
    }

    return () => clearInterval(interval);
  }, [isResendTimer, timer]);

  const handleChange = useCallback(
    (text: string) => {
      if (authInProgress) {
        return;
      }

      dispatch(setIsWrongVerificationCode(false));

      setOtp(text);

      if (text.length === 6) {
        handleContinue(text);
      }
    },
    [authInProgress, dispatch, handleContinue],
  );

  return (
    <S.Container>
      <Header
        title={t('authForm.enterCodeForm.title')}
        description={t('authForm.enterCodeForm.description')}
        destination={destination}
      />
      <OTPContainer
        shouldAutoFocus
        value={otp}
        onChange={handleChange}
        numInputs={6}
        containerStyle={codeInputContainerStyle}
        inputStyle={codeInputFieldStyle}
        renderInput={OtpInput}
      />
      {error && <S.ErrorText isError={!!error}>{error}</S.ErrorText>}
      {authInProgress ? (
        <S.LoaderContainer>
          <S.Loader style={S.LoaderStyles} size={'44px'} />
          <S.LoaderText>{t('authForm.enterCodeForm.checking')}</S.LoaderText>
        </S.LoaderContainer>
      ) : isResendTimer ? (
        <S.ResendTimer>
          {t('authForm.enterCodeForm.resendIn', {
            seconds: timer,
          })}
        </S.ResendTimer>
      ) : (
        <S.GetANewCodeButton onClick={handleResendCode}>{t('authForm.enterCodeForm.getNewCode')}</S.GetANewCodeButton>
      )}
    </S.Container>
  );
};
const OtpInput = ({ onChange, ...props }) => {
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value.match(/^[0-9]*$/)) {
        return;
      }
      onChange(e);
    },
    [onChange],
  );

  return <S.OtpInput {...props} onChange={handleInputChange} type='tel' />;
};

const S = {
  Container: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  `,
  OtpInputWrapper: styled.div``,
  OtpInput: styled.input`
    width: 45px;
    height: 45px;
    border-radius: ${45 / 2}px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray34};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    margin: 0 4px;
    &:focus {
      border-color: ${({ theme }) => theme.colors.primaryBlue};
    }
  `,
  LoaderContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
  `,
  Loader: styled(Loader)`
    width: 44px;
    height: 44px;
    margin-bottom: 14px;
  `,
  LoaderText: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray19};
    margin-top: 14px;
  `,
  GetANewCodeButton: styled.div`
    cursor: pointer;
    align-self: center;
    margin-bottom: 27px;
    margin-top: 44px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  ErrorText: styled.span<{ isError: boolean }>`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.pinkError};
    margin-top: 7px;
    align-self: center;
    height: ${({ isError }) => (isError ? 16 : 0)};
  `,
  ResendTimer: styled.div`
    align-self: center;
    margin-bottom: 27px;
    margin-top: 44px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray19};
  `,
  LoaderStyles: {
    width: 44,
    height: 44,
    marginBottom: 7,
  },
};

export default memo(EnterCodeForm);
