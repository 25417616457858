import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg';
import DeleteIcon from '../../../assets/icons/delete_icon.svg';
import { fetchDeleteComment } from '../bite.slice';

interface Props {
  isOpen: boolean;
  modalIcon?: any;
  onCancel: (any) => void;
  messageText: any;
  confirmText: string;
  cancelText: string;
  commentId: number;
}

const DeleteCommentModal: React.FC<Props> = (props) => {
  const { isOpen, modalIcon, onCancel, messageText, confirmText, cancelText, commentId } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => {
    // TO DO: extract state update into a separate reducer
    // @ts-ignore
    dispatch(fetchDeleteComment({ commentId }));
  }, [dispatch, commentId]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.colors.lightGray14,
          padding: 0,
          paddingTop: '33px',
          paddingBottom: '5px',
          borderRadius: 20,
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '280px',
          width: '320px',
          boxShadow: '0 12px 19px 0 rgba(60, 128, 209, 0.09)',
        },
      }}
    >
      <S.CloseModalIcon onClick={onCancel} />
      {modalIcon && <S.QuestionIcon src={DeleteIcon} style={{ width: 80, height: 80 }} />}
      <S.MessageText>{messageText}</S.MessageText>
      <S.Actions>
        <S.ActionContainer onClick={onCancel} isMain={false}>
          <S.CancelText>{cancelText}</S.CancelText>
        </S.ActionContainer>
        <S.ActionContainer onClick={handleConfirm} isMain={true} data-cy='confirmationModalConfirm'>
          <S.ConfirmText>{confirmText}</S.ConfirmText>
        </S.ActionContainer>
      </S.Actions>
    </Modal>
  );
};

const S = {
  CloseContainer: styled.div(
    ({ theme }) => css`
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      z-index: 1000;
      background: ${theme.colors.darkGray7};
      padding: 5px 8px;
    `,
  ),
  CloseIcon: styled(CloseIcon)`
    width: 10px;
    height: 10px;
    fill: white;
  `,
  QuestionIcon: styled.img`
    fill: white;
  `,
  MessageText: styled.div`
    color: ${({ theme }) => theme.colors.darkGray4};
    font-size: 18px;
    text-align: center;
  `,
  ConfirmText: styled.p`
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
  `,
  CancelText: styled.p`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 15px;
    font-weight: 900;
  `,
  Actions: styled.div`
    z-index: 10;
    display: flex;
    padding-bottom: 10px;
  `,
  ActionContainer: styled.div<{ isMain: boolean }>`
    background: ${({ theme, isMain }) => (isMain ? theme.colors.primaryBlue : 'transparent')};
    margin-inline-end: 15px;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 30px;
  `,
  CloseModalIcon: styled(CloseIcon)`
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  `,
};

export default DeleteCommentModal;
