import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import CorrectIcon from '../../../../components/Icons/CorrectIcon';
import WrongIcon from '../../../../components/Icons/WrongIcon';
import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import useIsMobile from '../../../../hooks/useIsMobile';
import { formatDate } from '../../../../utils/dates';
import { selectBite } from '../../bite.slice';

interface IProps {
  onImageClick: (rowIndex: number) => void;
}

const useRawColumns = ({ onImageClick }: IProps): TypeColumns => {
  const { t } = useTranslation();
  const { answerAnalysisByUser, questionType } = useSelector(selectBite);
  const isMobile = useIsMobile();

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const Answered = ({ value, data }) => {
      if (data.__group) {
        const count = data.array.filter((u) => u.answer).length;
        const total = data.array.length;
        return <PercentsAndNumbers count={count} total={total} />;
      }
      return value ? <CorrectIcon /> : <WrongIcon />;
    };
    const AnsweredCorrectly = ({ value, data }) => {
      if (data.__group) {
        const count = data.array.filter((u) => u.answeredCorrectly).length;
        const total = data.array.filter((u) => u.answer).length;
        return <PercentsAndNumbers count={count} total={total} />;
      }
      if (value === null) {
        return '';
      }
      return value ? <CorrectIcon /> : <WrongIcon />;
    };
    const ImageColumn = ({ value, data }) => {
      if (data.__group || !value) {
        return null;
      }
      return (
        <img
          onClick={() => {
            const rowIndex = answerAnalysisByUser.findIndex((u) => u.id === data.id);
            onImageClick(rowIndex);
          }}
          style={{
            cursor: 'pointer',
            maxWidth: 30,
            maxHeight: 30,
            position: 'relative',
            top: 2.5,
          }}
          src={value}
          alt='img'
        />
      );
    };

    const result: TypeColumns = [
      {
        name: 'employeeId',
        maxWidth: 100,
        header: t('bites.employeeId'),
        render: EllipsisColumn,
      },
      {
        name: 'email',
        maxWidth: 100,
        header: t('bites.email'),
        render: EllipsisColumn,
      },
      {
        name: 'phone',
        maxWidth: 100,
        header: t('bites.phone'),
        render: EllipsisColumn,
      },
      {
        name: 'answered',
        maxWidth: isMobile ? 95 : 150,
        header: t('bites.answered'),
        render: Answered,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: true, label: 'Yes' },
            { id: false, label: 'No' },
          ],
        },
      },
    ];

    if (questionType === 'multiple choices') {
      result.push({
        name: 'answeredCorrectly',
        maxWidth: isMobile ? 95 : 150,
        header: t('bites.success'),
        render: AnsweredCorrectly,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: true, label: t('bites.correct') },
            { id: false, label: t('bites.wrong') },
          ],
        },
      });
    }

    result.push({
      name: 'answer',
      defaultFlex: 1,
      header: t('bites.text'),
      render: EllipsisColumn,
    });

    if (questionType === 'open ended') {
      result.push({
        name: 'imageUrl',
        maxWidth: 150,
        header: t('bites.image'),
        render: ImageColumn,
      });
    }

    result.push({
      name: 'createdAt',
      defaultFlex: 1,
      header: t('bites.date'),
      render: ({ value }) => formatDate(value),
    });

    return result;
  }, [t, isMobile, questionType, answerAnalysisByUser, onImageClick]);
};

export default useRawColumns;
