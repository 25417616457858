import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import store from '../../app/store';
import { logError } from '../../features/tracking/tracking.slice';

interface IProps {
  event?: string;
  processId?: string;
  error: any;
  data?: any;
}

const handleHttpErrors = ({ event = 'handleHttpErrors', processId, error, data = {} }: IProps) => {
  store.dispatch(
    logError({
      event,
      processId,
      data: {
        ...data,
        error,
        errorResponse: cloneDeep(error?.response),
      },
    }),
  );

  if (error?.response?.status === 400 && error.response.headers['x-captcha-validation'] === 'bad_token') {
    toast('Something went wrong. Please try again in a while');
  }
};
export default handleHttpErrors;
