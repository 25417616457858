import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types/store';

export const currentUserSelector = (state: IRootState) => state.auth.currentUser;
export const tokenSelector = (state: IRootState) => state.auth.token;

export const selectActiveUserOrganization = createSelector(
  (state: IRootState) => state.auth.currentUser?.organizations,
  (state: IRootState) => state.org.id,
  (organizations, activeOrgId) => organizations.find((o) => o.id === activeOrgId),
);

export const selectCanSendMessages = createSelector(
  selectActiveUserOrganization,
  (activeUserOrg) => activeUserOrg.canSendMessages,
);
