enum AuthErrorTypes {
  wrongEmailOrPassword = 'auth.wrong_email_or_pass',
  phoneNumberNotExists = 'auth.phone_not_exist',
  errorPhoneTaken = 'auth.phone_taken',
  emailAlreadyTaken = 'auth.email_taken',
  invalidAccessCode = 'auth.invalid_access_code',
  noAccess = 'auth.no_access',
  noUser = 'auth.no_user',
  //to implement on BE
  wrongCredentials = 'auth.wrong_credentials',
}

export default AuthErrorTypes;
