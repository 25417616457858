import { all, put, select, takeLatest } from 'redux-saga/effects';

import { selectOrg } from '../org/org.slice';

import * as overviewCalls from './overview.api';
import { fetchOverview, fetchOverviewError, fetchOverviewSuccess } from './overview.slice';

export default function* overviewSaga() {
  yield all([takeLatest(fetchOverview, fetchOverviewRequest)]);
}

function* fetchOverviewRequest() {
  try {
    const { id: orgId } = yield select(selectOrg);
    const { data } = yield overviewCalls.getOrgStats(orgId);
    yield put(fetchOverviewSuccess(data));
  } catch (error) {
    yield put(fetchOverviewError(error.message));
  }
}
