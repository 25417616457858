import { IRootState } from '../../types/store';

export const currentFormSelector = (state: IRootState) => state.authForm.currentForm;
export const emailFormSelector = (state: IRootState) => state.authForm.email;
export const phoneFormSelector = (state: IRootState) => state.authForm.phone;
export const passwordFormSelector = (state: IRootState) => state.authForm.password;
export const recoveryTokenSelector = (state: IRootState) => state.authForm.recoveryToken;
export const firstNameSelector = (state: IRootState) => state.authForm.firstName;
export const lastNameSelector = (state: IRootState) => state.authForm.lastName;
export const userSelectedCountryCodeSelector = (state: IRootState) => state.authForm.userSelectedCountryCode;
export const ssoInProgressSelector = (state: IRootState) => state.authForm.social.isLoading;
export const isLoginErrorSelector = (state: IRootState) => state.authForm.isLoginError;
export const errorCodesSelector = (state: IRootState) => state.authForm.errorCodes;
export const authInProgressSelector = (state: IRootState) => state.authForm.isLoading;
export const isWrongVerificationCodeSelector = (state: IRootState) => state.authForm.isWrongVerificationCode;
export const isForgotEmailInProgressSelector = (state: IRootState) => state.authForm.passwordRecovery.isLoading;
export const isResetPasswordInProgressSelector = (state: IRootState) => state.authForm.newRecoverPassword.isLoading;
export const isResetPasswordErrorSelector = (state: IRootState) => state.authForm.newRecoverPassword.error;
export const isPhoneMissingSelector = (state: IRootState) => state.authForm.isPhoneMissing;
export const isSsoErrorSelector = (state: IRootState) => state.authForm.social.isError;
export const isForgotEmailErrorSelector = (state: IRootState) => state.authForm.passwordRecovery.error;
export const isProfileLoadingSelector = (state: IRootState) => state.authForm.userProfile.isLoading;
export const profileErrorsSelector = (state: IRootState) => state.authForm.userProfile.errors;
export const isProcessingQueryTokenSelector = (state: IRootState) => state.authForm.isProcessingQueryToken;
