import { TypeColumn, TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';

const useColumns = (): TypeColumns => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const StyledPercentageColumn = ({ value }) => <StyledPercentage percentage={value} />;
    const result: TypeColumns = [{ name: 'name', header: t('playlist.bite'), render: EllipsisColumn }];
    const watchedColumn: TypeColumn = {
      name: 'watched',
      header: t('playlist.views'),
    };
    const answeredPercentageColumn: TypeColumn = {
      name: 'answeredPercentage',
      header: t('playlist.completed'),
      render: StyledPercentageColumn,
    };
    const correctAnswerPercentageColumn: TypeColumn = {
      name: 'correctAnswerPercentage',
      header: t('playlist.success'),
      render: StyledPercentageColumn,
    };
    if (isMobile) {
      // @ts-ignore
      watchedColumn.maxWidth = 80;
    } else {
      // @ts-ignore
      watchedColumn.defaultFlex = 1;
      // @ts-ignore
      answeredPercentageColumn.defaultFlex = 1;
      // @ts-ignore
      correctAnswerPercentageColumn.defaultFlex = 1;
    }
    return result.concat([watchedColumn, answeredPercentageColumn, correctAnswerPercentageColumn]);
  }, [isMobile, t]);
};

export default useColumns;
