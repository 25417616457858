import { all, put, select, takeLatest } from 'redux-saga/effects';

import { ILeanBite } from '../../types/bite';
import * as biteCalls from '../bite/bite.api';
import { selectOrg } from '../org/org.slice';

import * as bitesCalls from './bites.api';
import {
  bitesMapSelector,
  fetchBites,
  fetchBitesData,
  fetchBitesError,
  fetchFullBitesSuccess,
  fetchLeanBitesSuccess,
  setBitesMap,
} from './bites.slice';

export default function* bitesSaga() {
  yield all([takeLatest(fetchBites, fetchBitesSaga), takeLatest(fetchBitesData, fetchBitesDataSaga)]);
}

function* fetchBitesSaga() {
  try {
    const leanBites: ILeanBite[] = yield fetchLeanBites();
    yield put(fetchLeanBitesSuccess(leanBites));
    yield put(fetchFullBitesSuccess(leanBites));
  } catch (error) {
    yield put(fetchBitesError(error.message));
  }
}

function* fetchLeanBites() {
  const { id: orgId } = yield select(selectOrg);
  const { data: leanBites } = yield bitesCalls.getBites(orgId);
  return leanBites;
}

export function* fetchBitesDataSaga(bitesIds) {
  const savedBitesMap = yield select(bitesMapSelector);

  const newBitesIds = bitesIds.filter((biteId) => !savedBitesMap[biteId]);

  const allBitesResponse = yield Promise.allSettled(
    newBitesIds.map(async (biteId: number) => await biteCalls.getBite(biteId)),
  );

  const allBites = allBitesResponse
    .map((bite) => (bite.status !== 'rejected' ? bite.value.data : null))
    .filter(Boolean);

  yield put(setBitesMap(allBites));
}
