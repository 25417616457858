import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { memo, useCallback, useMemo } from 'react';
import { areEqual } from 'react-window';

import chooseGroupsModalStyles from './chooseGroupsModalStyles';

interface RowProps {
  data: {
    items: Array<{ id: number; name: string }>;
    selectedGroupsIds: number[];
    onToggleOption: (id: number) => void;
  };
  index: number;
  style: React.CSSProperties;
}

const ChooseGroupsRowItem: React.FC<RowProps> = ({ data, index, style }) => {
  const item = data.items[index];
  const classNames = useStyles();

  const isChecked = useMemo(() => data.selectedGroupsIds.includes(item.id), [data.selectedGroupsIds, item.id]);
  const handleToggleCallBack = useCallback(() => data.onToggleOption(item.id), [data, item.id]);

  return (
    <div style={style}>
      <div className={classNames.option}>
        <FormControlLabel
          control={
            <Checkbox color='primary' onChange={handleToggleCallBack} checked={isChecked} id={`option-${item.id}`} />
          }
          label={item.name}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(chooseGroupsModalStyles);

export default memo(ChooseGroupsRowItem, areEqual);
