import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { emailFormSelector, passwordFormSelector } from '../../../authForm/authForm.selectors';
import { loginWithEmail } from '../../../authForm/authForm.slice';
import { log } from '../../../tracking/tracking.slice';
import { EAuthFormType } from '../../auth.types';

import EnterCodeForm from './EnterCodeForm';

const EnterEmailCode = () => {
  const dispatch = useDispatch();

  const email = useSelector(emailFormSelector);
  const password = useSelector(passwordFormSelector);
  const currentForm = EAuthFormType.ENTER_CODE_EMAIL;

  const handleContinue = useCallback(
    (otp: string) => {
      const processId = uuid();

      dispatch(
        log({
          event: 'EnterEmailCode.handleContinue',
          processId,
          data: { username: email.trim(), otp, currentForm },
        }),
      );

      dispatch(
        loginWithEmail({
          username: email.trim(),
          password,
          otp,
          processId,
        }),
      );
    },
    [currentForm, dispatch, email, password],
  );

  const handleResend = useCallback(() => {
    const processId = uuid();

    dispatch(
      log({
        event: 'EnterEmailCode.handleResend',
        processId,
        data: {
          currentForm,
          username: email.trim(),
          password,
        },
      }),
    );

    dispatch(
      loginWithEmail({
        username: email.trim(),
        password,
        processId,
      }),
    );
  }, [currentForm, dispatch, email, password]);

  return <EnterCodeForm onContinue={handleContinue} onResend={handleResend} destination={email} />;
};

export default memo(EnterEmailCode);
