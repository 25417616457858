import React, { CSSProperties, FC, memo, ReactNode, useCallback, useMemo } from 'react';
import AppleLogin from 'react-apple-login';

import {
  APP,
  URL_ACCESS_TOKEN_PLACEHOLDER,
  URL_ACTION_SOCIAL_AUTH,
  URL_ERROR_CODE_PLACEHOLDER,
} from '../../../../constants/constants';
import { API_BASE_URL } from '../../../../services/bitesApi';
import SocialButton from '../SocialButton';

interface IProps {
  title: string;
  icon: ReactNode;
  style?: CSSProperties;
}

const AppleAuthButton: FC<IProps> = ({ title, icon, style }) => {
  const state = useMemo(
    () =>
      encodeURIComponent(
        JSON.stringify({
          app: APP,
          redirect: `${window.location.protocol}//${window.location.hostname}${
            window.location.hostname === 'localhost' ? ':' + window.location.port : ''
          }${window.location.pathname}${
            window.location.search ? `${window.location.search}&` : '?'
          }action=${URL_ACTION_SOCIAL_AUTH}&errorCode=${URL_ERROR_CODE_PLACEHOLDER}&token=${URL_ACCESS_TOKEN_PLACEHOLDER}`,
        }),
      ),
    [],
  );

  const render = useCallback(
    ({ onClick }) => <SocialButton style={style} onClick={onClick} icon={icon} title={title} />,
    [style, icon, title],
  );

  return (
    <AppleLogin
      clientId='app.bites.creatorapp'
      redirectURI={`${API_BASE_URL}/teams/get_auth_token/`}
      scope='name email'
      responseType='code id_token'
      responseMode='form_post'
      state={state}
      render={render}
    />
  );
};

export default memo(AppleAuthButton);
