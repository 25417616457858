import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectQuiz } from '../../quiz.slice';

const useRawData = () => {
  const { quizViewsByUser, questions } = useSelector(selectQuiz);
  return useMemo(() => {
    return quizViewsByUser.map((user) => ({
      ...user,
      completed: Number(user.answered) >= questions.length ? 'Yes' : 'No',
      scorableQuestionsCount: questions.slice(0, user.answered).filter((q) => q.type === 'multiple choices').length,
    }));
  }, [quizViewsByUser, questions]);
};

export default useRawData;
