import { makeStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrg } from '../../../org/org.slice';

import DetailsTab from './DetailsTab';
import Top from './Top';
import UserSelectData from './UserSelectData';

function UserWindow({ onClose, userId }) {
  const { t } = useTranslation();
  const { datas: orgDatas } = useSelector(selectOrg);
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (evt, value) => {
    setSelectedTabIndex(value);
  };

  if (!userId) {
    return null;
  }

  return (
    <div className={classes.root} data-cy='userWindow'>
      <Top onClose={onClose} userId={userId} />
      <Tabs
        className={classes.tabs}
        value={selectedTabIndex}
        indicatorColor='primary'
        textColor='primary'
        onChange={handleTabChange}
      >
        <Tab className={classes.tab} label={t('userManagement.userWindow.details')} />
        <Tab className={classes.tab} label={t('userManagement.userWindow.groups')} data-cy='userWindowGroupsTab' />
      </Tabs>

      <TabPanel value={selectedTabIndex} index={0}>
        <DetailsTab userId={userId} inputRowClassName={classes.inputRow} labelClassName={classes.label} />
      </TabPanel>

      <TabPanel value={selectedTabIndex} index={1}>
        {orgDatas.map(
          (orgData) =>
            orgData?.options?.length > 0 && (
              <div className={classes.inputRow} key={orgData.title}>
                <label className={classes.label}>{orgData.title}</label>
                <UserSelectData orgData={orgData} userId={userId} />
              </div>
            ),
        )}
      </TabPanel>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    border: '1px solid black',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 24,
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: 440,
    },
  },
  tabs: {
    marginBottom: 24,
  },
  tab: {
    textTransform: 'none',
  },
  inputRow: {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: 180,
    color: '#979797',
    lineHeight: '24px',
  },
}));

export default UserWindow;

const TabPanel = ({ value, index, children }) => {
  if (value !== index) {
    return null;
  }
  return children;
};
