import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';

function CorrectIcon() {
  const classes = useStyles();
  return <CheckIcon className={classes.root} />;
}

const useStyles = makeStyles({
  root: {
    color: '#00BC7B',
    borderRadius: '50%',
    border: '1px solid',
    height: 19.5,
    width: 19.5,
  },
});

export default CorrectIcon;
