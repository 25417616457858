let innerLocalStorage;
try {
  window.localStorage.getItem('test');
  innerLocalStorage = window.localStorage;
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('localStorage is not available');
  innerLocalStorage = {
    getItem: () => null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clear: () => {},
  };
}
export default innerLocalStorage;
