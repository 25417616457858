import { makeStyles } from '@material-ui/core';
import React from 'react';

import ErrorMsg from './ErrorMsg';
import Loader from './Loader';

interface IProps {
  title: string;
  Icon: any;
  iconClassName: string;
  number: number | string;
  error?: any;
  isLoading?: boolean;
  na?: boolean;
}

function Kpi({ title, Icon, iconClassName, number, error, isLoading, na }: IProps) {
  const classes = useStyles();

  const renderNumber = (props) => {
    if (props.error) {
      return <ErrorMsg>Error</ErrorMsg>;
    }
    if (props.na) {
      return '-';
    }
    if (props.isLoading) {
      return <Loader />;
    }
    return props.number;
  };
  return (
    <div className={classes.kpi} key={title} data-cy={`kpi-${title}`}>
      <div className={classes.header}>
        <div className={classes.iconContainer}>
          <Icon className={iconClassName} />
        </div>
        <span className={classes.title}>{title}</span>
      </div>
      <div className={classes.number}>{renderNumber({ number, error, isLoading, na })}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  kpi: {
    border: 'solid 1px #b3b3b3',
    background: '#e9edf5',
    marginBottom: 15,
    [theme.breakpoints.down('lg')]: {
      height: 76,
      width: 76,
      paddingTop: 4,
      borderRadius: 6,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 8,
      height: 165.5,
      width: 165.5,
      borderRadius: 11.3,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 20,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 17,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: 33,
    },
  },
  iconContainer: {
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#3159a5',
    [theme.breakpoints.down('lg')]: {
      marginInlineEnd: '2px',
      marginInlineStart: '4px',
      height: 17,
      width: 17,
    },
    [theme.breakpoints.up('xl')]: {
      marginInlineEnd: '4px',
      marginInlineStart: '8px',
      height: 34,
      width: 34,
    },
  },
  title: {
    fontWeight: 'bold',
    color: '#3159a5',
    [theme.breakpoints.down('lg')]: {
      fontSize: 9,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 18,
    },
  },
  number: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#3159a5',
    [theme.breakpoints.down('lg')]: {
      fontSize: 23,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 40,
    },
  },
}));

export default Kpi;
