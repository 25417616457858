import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PermissionFields } from '../../../../types/permissions';
import { selectUserManagement } from '../../userManagement.slice';

import CheckboxUserField from './CheckboxUserField';
import EditableUserField from './EditableUserField';

interface IProps {
  userId: number;
  inputRowClassName: string;
  labelClassName: string;
}

function DetailsTab({ userId, inputRowClassName, labelClassName }: IProps) {
  const { t } = useTranslation();
  const { id, username } = useSelector(selectUserManagement).users.find((u) => u.id === userId);
  const classes = useStyles();

  return (
    <>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.username')}</label>
        <input className={classes.input} style={{ opacity: 0.4 }} value={username} readOnly />
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.name')}</label>
        <EditableUserField
          className={classes.input}
          field='fullName'
          fieldDisplayName={t('userManagement.userWindow.fullName')}
          id={id}
          dataCy='userWindowName'
        />
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.phoneNumber')}</label>
        <EditableUserField
          className={classes.input}
          field='phone'
          fieldDisplayName={t('userManagement.userWindow.phone')}
          id={id}
        />
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.id')}</label>
        <EditableUserField
          className={classes.input}
          field='nationalId'
          fieldDisplayName={t('userManagement.userWindow.nationalId')}
          id={id}
        />
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.EmployeeId')}</label>
        <EditableUserField
          className={classes.input}
          field='employeeId'
          fieldDisplayName={t('userManagement.userWindow.employeeId')}
          id={id}
        />
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.roles')}</label>
        <div>
          <div className={classes.checkboxRow}>
            <CheckboxUserField
              field={PermissionFields.CREATE_BITES_PERMISSION}
              fieldDisplayName={t('userManagement.userWindow.createBitesPermission')}
              className={classes.checkbox}
              label={t('userManagement.userWindow.creator')}
              id={id}
            />
          </div>
          <div className={classes.checkboxRow}>
            <CheckboxUserField
              className={classes.checkbox}
              field={PermissionFields.CONTENT_ADMIN}
              // fieldDisplayName={t('userManagement.userWindow.contentAdmin')}
              fieldDisplayName={t('userManagement.userWindow.contentAdmin')}
              // label={t('userManagement.userWindow.contentAdmin')}
              label={t('userManagement.userWindow.contentAdmin')}
              id={id}
            />
          </div>
          <div className={classes.checkboxRow}>
            <CheckboxUserField
              className={classes.checkbox}
              field={PermissionFields.DASHBOARD_PERMISSION}
              fieldDisplayName={t('userManagement.userWindow.dashboardPermission')}
              label={t('userManagement.userWindow.dashboardAccess')}
              id={id}
            />
          </div>
          <div className={classes.checkboxRow}>
            <CheckboxUserField
              className={classes.checkbox}
              field={PermissionFields.SENDING_PERMISSION}
              // fieldDisplayName={t('userManagement.userWindow.sendingPermission')}
              fieldDisplayName={t('userManagement.userWindow.sendingPermission')}
              // label={t('userManagement.userWindow.sendingPermission')}
              label={t('userManagement.userWindow.sendingPermission')}
              id={id}
            />
          </div>
        </div>
      </div>
      <div className={inputRowClassName}>
        <label className={labelClassName}>{t('userManagement.userWindow.includeInStats')}</label>
        <CheckboxUserField
          className={classes.checkbox}
          field='showStats'
          fieldDisplayName={t('userManagement.userWindow.showStats')}
          id={id}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles({
  input: {
    border: 'none',
    fontSize: 14,
    outline: 'none',
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
});

export default DetailsTab;
