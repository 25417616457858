import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrg } from '../../../features/org/org.slice';

interface IArgs {
  rawData: any[];
}

const useFormattedUsers = ({ rawData }: IArgs) => {
  const { t } = useTranslation();
  const datas = useSelector(selectOrg).datas;

  return useMemo(
    () =>
      rawData.map((user) => {
        const _user = { ...user };
        datas.forEach((d) => {
          _user[d.dataFieldName] = _user.datas[d.dataIdx];
        });
        if (_user.id === 1) {
          _user.name = t('table.guest');
        }
        return _user;
      }),
    [rawData, datas, t],
  );
};

export default useFormattedUsers;
