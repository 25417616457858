import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

interface IProps {
  text: string;
  onClick?: () => void;
  style?: Record<string, any>;
  className?: string;
}

function EllipsisWithTooltip({ text, onClick, style = {}, className = '', ...restOfProps }: IProps) {
  return (
    <Tooltip title={text || ''} style={style} {...restOfProps} onClick={onClick} className={className}>
      <span className='ellipsis'>{text || ''}</span>
    </Tooltip>
  );
}

export default EllipsisWithTooltip;
