import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TERMS_CONDITIONS_URL } from '../../../constants/constants';
import { log } from '../../tracking/tracking.slice';
import useAuthError from '../hooks/useAuthError';

import AuthError from './AuthError';
import EmailForm from './EmailForm';
import Header from './Header';
import SocialButtons from './SocialButtons';

const AuthForm: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formError = useAuthError();

  const handleTermsConditionsPress = useCallback(() => {
    dispatch(
      log({
        event: 'AuthForm.handleTermsConditionsPress',
      }),
    );
  }, [dispatch]);

  return (
    <S.Container>
      <Header title={t('authForm.signInForm.title')} description={t('authForm.signInForm.subtitle')} />
      <AuthError isVisible={!!formError}>{formError}</AuthError>
      <S.Form>
        <EmailForm />
        <S.Separator>
          <S.SeparatorLine />
          <S.SeparatorText>{t('authForm.or')}</S.SeparatorText>
          <S.SeparatorLine />
        </S.Separator>
        <SocialButtons />
        <S.TermsAndConditions>
          {t('authForm.agreeToOur')}
          <S.TermsAndConditionsLink href={TERMS_CONDITIONS_URL} target='_blank' onClick={handleTermsConditionsPress}>
            {t('authForm.terms')}
          </S.TermsAndConditionsLink>
        </S.TermsAndConditions>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div`
    margin-top: 24px;
    align-items: center;
  `,
  Header: styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.span`
    color: ${({ theme }) => theme.colors.text};
    font-size: 20px;
    line-height: 28px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  `,
  HeaderDescription: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
  `,
  ForgotPassword: styled.div`
    cursor: pointer;
  `,
  ForgotPasswordText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  Separator: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
  `,
  SeparatorLine: styled.div`
    flex: 1;
    height: 1px;
    background: ${({ theme }) => theme.colors.lightGray19};
  `,
  SeparatorText: styled.span`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 16px;
    margin: 0 10px;
  `,
  TermsAndConditions: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 295px;
    margin-bottom: 30px;
    margin-top: 4px;
    align-items: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  `,
  TermsAndConditionsLink: styled.a`
    cursor: pointer;
    margin-left: 4px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};

    &:link {
      color: ${({ theme }) => theme.colors.gray19};
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.gray19};
    }

    &:visited {
      color: ${({ theme }) => theme.colors.gray19};
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.gray19};
    }
  `,
};

export default memo(AuthForm);
