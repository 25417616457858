import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MainScreen from '../../components/MainScreen';

import Table from './components/Table/Table';
import { cleanState } from './userAnalysis.slice';

function UserAnalysisScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useMemo(() => {
    dispatch(cleanState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainScreen headline={t('userAnalysis.headline')}>
      <Table />
    </MainScreen>
  );
}

export default UserAnalysisScreen;
