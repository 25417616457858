import { makeStyles } from '@material-ui/core';
import React from 'react';

import { ReactComponent as LoaderSvg } from '../assets/icons/feed-loader.svg';

const Loader: React.FC<{ isSmall?: boolean }> = ({ isSmall }) => {
  const classes = useStyles();

  const diameter = isSmall ? 40 : 70;

  return (
    <div className={classes.loaderContainer}>
      <LoaderSvg width={diameter} height={diameter} />
    </div>
  );
};

const useStyles = makeStyles({
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.8,
    cursor: 'not-allowed',
  },
});

export default Loader;
