import React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
  style?: Record<string, any>;
}

function ErrorMsg({ children, style = {}, className = '', ...props }: IProps) {
  return (
    <div style={{ color: 'red', marginTop: 10, ...style }} className={className} data-cy='errorMsg' {...props}>
      {children}
    </div>
  );
}

export default ErrorMsg;
