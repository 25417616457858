import { makeStyles } from '@material-ui/core';
import React from 'react';

interface IProps {
  children: React.ReactNode;
}

function MiniTitle({ children }: IProps) {
  const classes = useStyles();
  return <h3 className={classes.root}>{children}</h3>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    lineHeight: '24px',
    fontWeight: 'bold',
    marginBottom: 4,
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
    },
  },
}));

export default MiniTitle;
