import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../../../../../assets/icons/icons-back.svg';
import { ReactComponent as TwoPeople } from '../../../../../../assets/icons/icons-invite-default.svg';
import { IPage, EPagesSyncUpload } from '../../SyncUploadModal';
import { HelpHelper, iconSize, modalColors, S, StatusBarHelper } from '../../ui';

const iconWrapperStyle = { top: '-25px', position: 'relative' };
const contentDownload = {
  fontWeight: 'bold',
  margin: 0,
  marginTop: '10px',
  fontSize: '30px',
  textAlign: 'center',
  textWarp: 'warp',
};
const contentExplanation = { fontSize: '14px', textAlign: 'left', margin: '0px' };
const modelButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
};
const spanStyle = { color: '#9be91c' };
const headerStyle = { width: '100%' };
const iconStyle = { width: iconSize, height: iconSize };

const Instructions = (props: IPage) => {
  const { t } = useTranslation();
  const { setPage } = props;

  const handleContinueClick = useCallback(() => {
    setPage(EPagesSyncUpload.UPLOAD_CSV);
  }, [setPage]);

  const handleBackClick = useCallback(() => {
    setPage(EPagesSyncUpload.DOWNLOAD_CSV);
  }, [setPage]);

  return (
    <S.Wrapper style={{ height: `${window.innerHeight - 200}px`, overflow: 'auto' }}>
      <S.BackAndHeaderWrapper>
        <S.IconWrapper style={iconWrapperStyle} onClick={handleBackClick}>
          <BackIcon style={iconStyle} />
        </S.IconWrapper>
        <S.TextHeader style={headerStyle}>{t('userManagement.pages.headerText')}</S.TextHeader>
      </S.BackAndHeaderWrapper>
      <S.IconWrapper>
        <TwoPeople style={iconStyle} />
      </S.IconWrapper>
      <S.Content style={contentDownload}>
        {t('userManagement.pages.downloadFile')} <br />
        <span style={spanStyle}>{t('userManagement.pages.allYourActive')}</span> {t('userManagement.pages.userDetails')}
      </S.Content>
      <S.Content style={contentExplanation}> {t('userManagement.pages.mandatoryColumns')}</S.Content>
      <S.Controllers>
        <S.ButtonWrapper>
          <S.ModelButton style={modelButtonStyle} onClick={handleContinueClick}>
            {t('userManagement.pages.allDone')}
          </S.ModelButton>
        </S.ButtonWrapper>
      </S.Controllers>
      <StatusBarHelper numOfActiveItems={2} />
      <HelpHelper />
    </S.Wrapper>
  );
};

export default memo(Instructions);
