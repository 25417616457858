import { AxiosPromise } from 'axios';

import { RECAPTCHA_SITE_KEY } from '../services/recaptcha';

export enum ECAptchaAction {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  GENERATE_OTP = 'GENERATE_OTP',
  PASSWORD_RESET = 'PASSWORD_RESET',
}

let withCaptcha = false;

const getCaptchaToken = async (action: ECAptchaAction) => {
  return new Promise((resolve) => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action }).then(resolve);
    });
  });
};

export const wrapWithCaptcha = <T>(action: ECAptchaAction, fn: (...args: any[]) => AxiosPromise<T>) => {
  const _fn = async (...props) => {
    const captchaToken = withCaptcha ? await getCaptchaToken(action) : undefined;

    const headers = captchaToken
      ? {
          'x-recaptcha-token': captchaToken,
        }
      : {};

    try {
      return await fn(...props, { headers });
    } catch (e) {
      if (
        e.response?.status === 405 &&
        (e.response?.headers?.['x-amzn-waf-action'] === 'captcha' ||
          e.response?.headers?.['X-Amzn-Waf-Action'] === 'captcha') &&
        !captchaToken
      ) {
        withCaptcha = true;
        return _fn(...props);
      }

      throw e;
    }
  };

  return _fn;
};
