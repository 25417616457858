import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ReactComponent as AsteriskSvg } from '../../assets/icons/asterisk.svg';
import LoginLoader from '../LoginLoader';

export const BaseInput = styled.input`
  text-align: left;
  font-size: 18px;
  line-height: 21px;
  color: #344356;
  outline-width: 0;
  &[value=''] {
  }
`;

const InputWithIcon = ({ style = {}, ...props }) => {
  const { t } = useTranslation();
  const { icon, placeholder, letterSpacing, centered, isConfirmEnabled, isMandatory, handleSubmit, isLoading } = props;
  const [type, setType] = useState<string>(props.type || 'text');

  const handleSwitchType = () => {
    setType(type === 'text' ? 'password' : 'text');
  };
  return (
    <S.InputContainer>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      {isMandatory && <S.AsteriskIcon />}
      <S.Input
        className={props.type === 'password' ? 'pass' : ''}
        {...props}
        type={type}
        placeholder={placeholder}
        isIcon={!!icon}
        style={Object.assign({
          textAlign: centered ? 'center' : 'left',
          letterSpacing: letterSpacing,
          ...style,
        })}
      />
      {props.type === 'password' && (
        <S.InputControl onClick={handleSwitchType}>
          {type === 'text' ? t('common.hide') : t('common.show')}
        </S.InputControl>
      )}
      {props.type === 'verify' ? (
        isLoading ? (
          <S.Loader>
            <LoginLoader />
          </S.Loader>
        ) : (
          <S.GoControl onClick={handleSubmit} isConfirmEnabled={isConfirmEnabled}>
            <span>{t('common.Go')}</span>
          </S.GoControl>
        )
      ) : null}
    </S.InputContainer>
  );
};

const S = {
  Input: styled(BaseInput)<{ isIcon: boolean }>`
    width: 300px;
    height: 44px;
    margin-bottom: 12px;
    border-radius: 25px;
    padding: 20px 25px;
    text-align: center;
    ${({ isIcon }) => css`
      background-color: white;
      border: 1px solid #c3c3c3;
      ${isIcon ? 'padding-left: 60px' : ''}
    `};
    }
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  AsteriskIcon: styled(AsteriskSvg)`
    position: absolute;
    left: 44px;
    top: 26px;
    transform: translate(0, -100%);
    width: 8px;
    height: 8px;
  `,
  InputControl: styled.span`
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    height: 34px;
    line-height: 34px;
    top: 5px;
    width: 60px;
    cursor: pointer;
    align-self: center;
    text-transform: uppercase;
    text-align: center;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.88px;
    right: 6px;
    ${({ theme }) => css`
      background-color: ${theme.colors.lightGray3};
      font-family: ${theme.fontFamilies.GilroyMedium};
      border: 0.5px solid ${theme.colors.lightGray1};
      color: ${theme.colors.lightText};
    `};
  `,
  GoControl: styled.div<{ isConfirmEnabled: boolean; isLoading: boolean }>`
    top: 20px;
    right: 6px;
    position: absolute;
    width: 36px;
    line-height: 33px;
    text-transform: uppercase;
    padding-left: 5px;
    padding-top: 3px;
    border-radius: 18px;
    font-size: 16px;
    letter-spacing: 0.88px;
    ${({ isConfirmEnabled }) => css`
      background-color: ${isConfirmEnabled ? '#1253fa' : '#C3C3C3'};
      pointer-events: ${isConfirmEnabled ? 'pointer' : 'none'};
      color: #ffffff;
    `};
  `,
  Loader: styled.div`
    top: 37px;
    right: 20px;
    position: absolute;
    border-radius: 50px;
  `,
  IconWrapper: styled.div`
    position: absolute;
    left: 20px;
    top: 29px;
    transform: translate(0, -100%);
    width: 16px;
    height: 16px;
  `,
};

export default InputWithIcon;
