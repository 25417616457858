import { makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as AnswersIcon } from '../../../assets/icons/answers.svg';
import { ReactComponent as CommentsIcon } from '../../../assets/icons/comments.svg';
import { ReactComponent as GlassesIcon } from '../../../assets/icons/glasses.svg';
import Kpi from '../../../components/Kpi';
import { percentage } from '../../../utils/numbers';
import { selectBite } from '../bite.slice';

function Kpis() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    answerAnalysisByUser,
    questionType,
    commentsCount,
    discussionEnabled,
    isFetchingComments,
    commentsError,
    viewsError,
    isFetchingBiteViews,
    watched,
  } = useSelector(selectBite);

  const getAnswersNum = useCallback(() => {
    return answerAnalysisByUser.filter((user) => user.answer).length;
  }, [answerAnalysisByUser]);

  const isMultipleChoiceQuestion = questionType === 'multiple choices';

  const getAnsweredCorrectlyNum = useCallback(() => {
    const answeredCorrectlyCount = answerAnalysisByUser.filter((user) => user.answeredCorrectly).length;
    return percentage(answeredCorrectlyCount, getAnswersNum());
  }, [answerAnalysisByUser, getAnswersNum]);

  const kpis = useMemo(
    () => [
      {
        title: t('bites.view'),
        number: watched,
        Icon: GlassesIcon,
        iconClassName: classes.glassesIcon,
        error: viewsError,
        isLoading: isFetchingBiteViews,
      },
      {
        title: isMultipleChoiceQuestion ? t('bites.success') : t('bites.answers'),
        number: isMultipleChoiceQuestion ? `${getAnsweredCorrectlyNum()}%` : getAnswersNum(),
        Icon: AnswersIcon,
        iconClassName: classes.answersIcon,
        na: !questionType,
      },
      {
        title: t('bites.comments'),
        number: commentsCount,
        Icon: CommentsIcon,
        iconClassName: classes.commentsIcon,
        error: commentsError,
        isLoading: isFetchingComments,
        na: !discussionEnabled,
      },
    ],
    [
      t,
      watched,
      classes.glassesIcon,
      classes.answersIcon,
      classes.commentsIcon,
      viewsError,
      isFetchingBiteViews,
      isMultipleChoiceQuestion,
      getAnsweredCorrectlyNum,
      getAnswersNum,
      questionType,
      commentsCount,
      commentsError,
      isFetchingComments,
      discussionEnabled,
    ],
  );

  return (
    <>
      {kpis.map(({ title, number, Icon, error, isLoading, na, iconClassName }) => (
        <Kpi
          key={title}
          title={title}
          number={number}
          Icon={Icon}
          error={error}
          isLoading={isLoading}
          na={na}
          iconClassName={iconClassName}
        />
      ))}
    </>
  );
}

const useStyles = makeStyles({
  userIcon: { width: '100%' },
  glassesIcon: { width: '70%' },
  answersIcon: { width: '50%' },
  commentsIcon: { width: '40%' },
});

export default Kpis;
