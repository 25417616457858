import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

interface IProps {
  percentage: number | typeof NaN;
}

function StyledPercentage({ percentage }: IProps) {
  const classes = useStyles();
  if (Number.isNaN(percentage)) {
    return null;
  }
  return (
    <div
      className={cx(classes.root, {
        [classes.green]: percentage >= 75,
        [classes.orange]: 75 > percentage && percentage >= 50,
        [classes.red]: percentage < 50,
      })}
    >
      {percentage.toFixed()}%
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    lineHeight: '24px',
    height: 24,
    width: 42,
    border: '1px solid',
    borderRadius: 4,
    textAlign: 'center',
    display: 'inline-block',
  },
  green: {
    borderColor: '#00BC7B',
  },
  orange: {
    borderColor: '#f9dda6',
  },
  red: {
    borderColor: '#FF284E',
  },
});

export default StyledPercentage;
