import { useEffect, useState } from 'react';

import { getVideoLinks } from '../app/generic.api';

interface IReturn {
  isFetchingLink: boolean;
  isVideoStillDecoding: boolean;
  fetchingLinkError: string;
  vimeoVideoUri: string;
  size: number;
}

const useVimeoLink = (videoLinkRequired: boolean, vimeoVideoId: string): IReturn => {
  const [isFetchingLink, setIsFetchingLink] = useState(false);
  const [isVideoStillDecoding, setVideoStillDecoding] = useState(false);
  const [fetchingLinkError, setFetchingLinkError] = useState('');
  const [vimeoVideoUri, setVimeoVideoUri] = useState(null);
  const [size, setSize] = useState(null);

  useEffect(() => {
    const fetchVideoLinks = async () => {
      try {
        setIsFetchingLink(true);
        const { data } = await getVideoLinks(vimeoVideoId);

        if ('error' in data) {
          setFetchingLinkError(data.error);
        } else if (!data.length) {
          setVideoStillDecoding(true);
        } else {
          const highestQualityVideo = data.reduce((prev, current) => (current.width > prev.width ? current : prev));
          setVimeoVideoUri(highestQualityVideo.link);
          setSize({
            width: highestQualityVideo.width,
            height: highestQualityVideo.height,
          });
        }
      } catch (error) {
        setFetchingLinkError('Error Loading the required video');
      } finally {
        setIsFetchingLink(false);
      }
    };
    if (videoLinkRequired && vimeoVideoId) {
      fetchVideoLinks();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isFetchingLink,
    isVideoStillDecoding,
    fetchingLinkError,
    vimeoVideoUri,
    size,
  };
};

export default useVimeoLink;
