import { createAction, createSlice } from '@reduxjs/toolkit';

import { IRdgColumnVisibility } from '../../types/reactDataGrid';
import { IRootState } from '../../types/store';
import getMonthAgo from '../../utils/getMonthAgo';
import { isMobile } from '../../utils/utils';

import { IFilterAvailabilities, IFilterBy, IFiltersSelected, IInitialState } from './userAnalysis.types';

const USER_ANALYSIS = 'USER_ANALYSIS';
const userAnalysisSlice = createSlice({
  name: USER_ANALYSIS,
  initialState: getInitialState(),
  reducers: getReducers(),
});

export const {
  fetchFiltersSuccess,
  fetchFiltersError,
  setFetchFiltersIsLoading,
  fetchUsersTableError,
  toggleUserAnalysisColumnVisibility,
  setFilterSelected,
  cleanState,
} = userAnalysisSlice.actions;

export default userAnalysisSlice.reducer;

export const selectUserAnalysis = (state: IRootState) => state.userAnalysis;

export const fetchFilters = createAction<IFilterBy>(`${USER_ANALYSIS}/fetchFilters`);

function filtersSelectedInitialValue(): IFiltersSelected {
  return {
    dateRange: {
      start: getMonthAgo().toISOString(),
      end: new Date().toISOString(),
    },
    userOrganizations: [],
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
    data6: [],
    data7: [],
    data8: [],
  };
}

function getInitialState(): IInitialState {
  return {
    isFetchingFilters: false,
    isFetchingUsersTable: false,
    fetchingFiltersError: false,
    fetchingUsersTableError: false,
    fetchingBitesError: false,
    fetchingPlaylistsError: false,
    fetchingQuizzesError: false,
    filterAvailabilities: {} as IFilterAvailabilities,
    filtersSelected: filtersSelectedInitialValue(),
    count: 0,
    columnsVisibility: {
      userName: true,
      employeeId: false,
      content: true,
      view: !isMobile(),
      answer: !isMobile(),
      selectedAnswer: !isMobile(),
      success: !isMobile(),
      viewDate: !isMobile(),
      data1: true,
      data2: false,
      data3: false,
      data4: true,
      data5: false,
      data6: false,
      data7: false,
      data8: false,
    } as IRdgColumnVisibility,
  };
}

type IUserAnalysisState = ReturnType<typeof getInitialState>;

function getReducers() {
  return {
    setFetchFiltersIsLoading: (state: IUserAnalysisState) => {
      state.isFetchingFilters = true;
    },
    fetchFiltersSuccess: (state: IUserAnalysisState, action) => {
      state.isFetchingFilters = false;
      state.filterAvailabilities = action.payload;
    },
    fetchFiltersError: (state: IUserAnalysisState) => {
      state.isFetchingFilters = false;
      state.fetchingFiltersError = true;
    },
    fetchUsersTableError: (state: IUserAnalysisState, action) => {
      const { user_id } = action.payload;
      state.isFetchingUsersTable[user_id].isFetching = false;
      state.fetchingUsersTableError = true;
    },
    toggleUserAnalysisColumnVisibility: (state: IUserAnalysisState, action) => {
      state.columnsVisibility[action.payload] = !state.columnsVisibility[action.payload];
    },
    setFilterSelected: (state: IUserAnalysisState, action) => {
      state.filtersSelected = {
        dateRange: action.payload?.['dateRange'] || state.filtersSelected.dateRange,
        userOrganizations: action.payload?.['userOrganizations'] || state.filtersSelected.userOrganizations,
        data1: action.payload?.['data1'] || state.filtersSelected.data1,
        data2: action.payload?.['data2'] || state.filtersSelected.data2,
        data3: action.payload?.['data3'] || state.filtersSelected.data3,
        data4: action.payload?.['data4'] || state.filtersSelected.data4,
        data5: action.payload?.['data5'] || state.filtersSelected.data5,
        data6: action.payload?.['data6'] || state.filtersSelected.data6,
        data7: action.payload?.['data7'] || state.filtersSelected.data7,
        data8: action.payload?.['data8'] || state.filtersSelected.data8,
      };
    },
    cleanState: () => {
      return getInitialState();
    },
  };
}
