import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { FRONTLINER_URL } from '../../constants/constants';
import { ContentType } from '../../features/userAnalysis/components/Table/Table';

interface IProps {
  contentType: ContentType;
  shareablePath: string;
  subject: string;
}

export const useShareLink = ({ contentType, shareablePath, subject }: IProps) => {
  const { t } = useTranslation();
  const handleCopyLink = useCallback(() => {
    toast(
      t('share.noStatsModal.linkCopied', {
        contentType:
          contentType === ContentType.BITE ? 'Bite' : contentType === ContentType.PLAYLIST ? 'Playlist' : 'Quiz',
      }),
    );
    const copyTitle = t('share.noStatsModal.hasBeenShared', {
      contentType:
        contentType === ContentType.BITE ? 'bite' : contentType === ContentType.PLAYLIST ? 'playlist' : 'quiz',
    });
    const copyLink = `${FRONTLINER_URL}/${shareablePath}`;
    copy(`${copyTitle}\n${subject}\n\n${copyLink}`);
  }, [t, contentType, shareablePath, subject]);

  const onShareSuccessfulyToast = useCallback(() => {
    toast(
      t('share.noStatsModal.successfully', {
        contentType:
          contentType === ContentType.BITE ? 'Bite' : contentType === ContentType.PLAYLIST ? 'Playlist' : 'Quiz',
      }),
    );
  }, [t, contentType]);
  return { handleCopyLink, onShareSuccessfulyToast };
};
