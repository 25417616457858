import React from 'react';
import ReactPlayer from 'react-player';

import useVimeoLink from '../../hooks/useVimeoLinks';

interface IProps {
  mediaUrl: string;
  fileType: 'youtube' | 'video';
  videoStartsAt?: string;
  videoEndsAt?: string;
}

const VideoPlayer = ({ mediaUrl, fileType, videoStartsAt, videoEndsAt }: IProps) => {
  const isVimeoLinkRequired = fileType === 'video';
  const { fetchingLinkError, isFetchingLink, vimeoVideoUri, isVideoStillDecoding } = useVimeoLink(
    isVimeoLinkRequired,
    mediaUrl,
  );

  const youtubeLink = fileType === 'youtube' ? mediaUrl + '&start=' + videoStartsAt + '&end=' + videoEndsAt : null;

  const videoUrl = isVimeoLinkRequired ? vimeoVideoUri : fileType === 'youtube' ? youtubeLink : mediaUrl;

  if (isVimeoLinkRequired && isFetchingLink) {
    return null; // vimeo links are fetched
  }
  if (isVimeoLinkRequired && isVideoStillDecoding) {
    return <div>Video is still decoding</div>;
  }
  if (isVimeoLinkRequired && fetchingLinkError) {
    return <div>Error occurred while playing the video </div>;
  }

  return (
    <ReactPlayer
      url={videoUrl}
      className='react-player'
      width='100%'
      height='90%'
      controls
      playsinline
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: window.innerHeight - 100,
      }}
    />
  );
};

export default VideoPlayer;
