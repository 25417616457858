import { makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  path: string;
  Icon: any;
  text: string;
  onClick: () => void;
  intercomTarget?: string;
}

function SidebarLink({ path, Icon, text, onClick, intercomTarget }: IProps) {
  const classes = useStyles();

  return (
    <NavLink to={path} className={classes.link} activeClassName={classes.activeLink} onClick={onClick} data-cy={path}>
      <div data-intercom-target={intercomTarget}>
        <Icon />
        {text}
      </div>
    </NavLink>
  );
}

const useStyles = makeStyles({
  link: {
    color: '#666666',
    '& div': {
      height: 48,
      width: 216,
      borderRadius: 16,
      backgroundColor: 'white',
      margin: 16,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: 19.5,
        color: '#666666',
        marginRight: 12,
      },
    },
  },
  activeLink: {
    color: '#1253FA',
    '& div': {
      background: '#EBF0FF',
      '& svg': {
        color: '#1253FA',
      },
    },
  },
});

export default SidebarLink;
