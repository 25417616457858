import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import Button from '../../../../components/Buttons/Button';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { getIsEmailValid } from '../../../../utils/auth';
import {
  emailFormSelector,
  isForgotEmailErrorSelector,
  isForgotEmailInProgressSelector,
} from '../../../authForm/authForm.selectors';
import { passwordRecovery, setCurrentForm, setEmail, resetErrors } from '../../../authForm/authForm.slice';
import { log } from '../../../tracking/tracking.slice';
import { EAuthFormType } from '../../auth.types';
import FormTextInput from '../FormTextInput';
import Header from '../Header';

const EnterEmail: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useIsMounted();

  const email = useSelector(emailFormSelector);
  const isLoading = useSelector(isForgotEmailInProgressSelector);
  const isForgotEmailError = useSelector(isForgotEmailErrorSelector);

  const [emailError, setEmailError] = useState(null);
  const emailDisplayError = isForgotEmailError ? t('common.somethingWentWrong') : emailError;

  const handleChangeEmail = useCallback(
    (text: string) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetErrors());
    },
    [dispatch],
  );

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    setEmailError(null);
    dispatch(resetErrors());

    const isEmailValid = getIsEmailValid(email);
    if (!isEmailValid) {
      setEmailError(t('EnterEmail.emailIsNotValid'));
      return;
    }

    const processId = uuid();

    const handleSuccess = () => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'EnterEmail.handleSuccess',
          processId,
          data: { destination: EAuthFormType.FORGOT_PASSWORD_INFO },
        }),
      );
      dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_INFO));
    };

    dispatch(passwordRecovery({ email, processId, onSuccess: handleSuccess }));
  }, [dispatch, email, isLoading, isMountedRef, t]);

  return (
    <S.Container>
      <Header title={t('authForm.forgotPassword')} description={t('authForm.forgotPasswordForm.typeEmail')} />
      <S.Form>
        <S.TextInput
          value={email}
          onChange={handleChangeEmail}
          label={t('authForm.forgotPasswordForm.emailInputLabel')}
          onEnterPress={handleContinue}
          error={emailDisplayError}
        />
        <S.ContinueButton isLoading={isLoading} onClick={handleContinue}>
          {t('common.Continue')}
        </S.ContinueButton>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div`
    margin-top: 32px;
    align-items: center;
    padding: 0 20px;
  `,
  TextInput: styled(FormTextInput)`
    height: 50px;
    margin-bottom: 18px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
    margin-bottom: 50px;
    margin-top: 18px;
  `,
};

export default memo(EnterEmail);
