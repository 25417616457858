import bitesApi from '../../services/bitesApi';
import { IOrg } from '../../types/org';

export const getOrgInfo = (id: number) => bitesApi.get<IOrg>(`/organizations/${id}/`);

export const updateUserDefaultOrganization = (user_organization_id: number, body: any) =>
  bitesApi.patch(`user_organization/${user_organization_id}/`, body);

export const fetchOrganiztionTeamUsers = (organizationId: number) =>
  bitesApi.get(`/organizations/${organizationId}/teams_users/`);
