import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import AnswerDistribution from '../../../components/AnswerDistribution/AnswerDistribution';
import AnswerDistributionChart from '../../../components/AnswerDistribution/AnswerDistributionChart';
import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import SelectTableDisplay from '../../../components/SelectTableDisplay';
import { selectDatasStrings } from '../../org/org.slice';
import { selectBite, setAnswerAnalysisTableDisplay } from '../bite.slice';

import OverviewTable from './OverviewTable';
import Table from './Table';

function AnswerAnalysis() {
  const datasStrings = useSelector(selectDatasStrings);
  const classes = useStyles();
  const { answers, question, questionType, answerAnalysisTableDisplay, isFetchingAnswerAnalysis, answerAnalysisError } =
    useSelector(selectBite);

  const renderOverviewTable = () => {
    if (datasStrings.length === 0) {
      return null;
    }
    if (answerAnalysisTableDisplay === 'drilldown') {
      return null;
    }
    if (isFetchingAnswerAnalysis) {
      return <Loader />;
    }
    if (answerAnalysisError) {
      return <ErrorMsg>Error loading table: {answerAnalysisError}</ErrorMsg>;
    }

    return <OverviewTable groupedByDataIndex={answerAnalysisTableDisplay === 'data1' ? 0 : 1} />;
  };

  const renderTable = () => {
    if (answerAnalysisTableDisplay !== 'drilldown') {
      return null;
    }
    if (isFetchingAnswerAnalysis) {
      return <Loader />;
    }
    if (answerAnalysisError) {
      return <ErrorMsg>Error loading table: {answerAnalysisError}</ErrorMsg>;
    }
    return <Table />;
  };

  return (
    <>
      {questionType !== 'open ended' && (
        <div className={classes.top}>
          <div className={classes.answerContainer}>
            <div className={classes.answerDistributionTitle}>Answer Distribution</div>
            <p style={{ fontSize: 16 }}>{question}</p>
            <AnswerDistribution answers={answers} />
          </div>
          <div className={classes.answerContainer} style={{ marginTop: 25 }}>
            {/* @ts-ignore we know votes will exist on answers if the question is NOT open ended */}
            <AnswerDistributionChart isHorizontal answers={answers} />
          </div>
        </div>
      )}
      <SelectTableDisplay
        currentDisplay={answerAnalysisTableDisplay}
        setDisplay={setAnswerAnalysisTableDisplay}
        origin='bite'
      />
      {renderOverviewTable()}
      {renderTable()}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  top: {
    height: '100%',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    marginBottom: 20,
  },
  answerContainer: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      width: '48%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  answerDistributionTitle: {
    fontWeight: 'bold',
  },
}));

export default AnswerAnalysis;
