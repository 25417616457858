export const percentage = (n: number, total: number, decimals = 0): number => {
  if (total === 0) {
    return 0;
  }
  if (n === null || total === null) {
    return NaN;
  }

  return Number(((n / total) * 100).toFixed(decimals));
};
