// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCDEKHJnf_ctI2zRctNAgPCxx0hivrKJ6M',
  authDomain: 'webapps-51d67.firebaseapp.com',
  projectId: 'webapps-51d67',
  storageBucket: 'webapps-51d67.appspot.com',
  messagingSenderId: '452126002913',
  appId: '1:452126002913:web:299573404db2b0475171a0',
  measurementId: 'G-PKHYXJCCY1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
