import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';
import { percentage } from '../../../../utils/numbers';
import { selectBite } from '../../bite.slice';

const useMakeFooterRows = () => {
  const { questionType } = useSelector(selectBite);
  const isMobile = useIsMobile();

  return useCallback(
    (data) => {
      const FooterName = (items) => `${items.length} users`;
      const FooterAnswered = (items) => {
        const count = items.filter((u) => u.answered).length;
        const total = items.length;
        return isMobile ? (
          <StyledPercentage percentage={percentage(count, total)} />
        ) : (
          <PercentsAndNumbers count={count} total={total} />
        );
      };
      const FooterAnsweredCorrectly = (items) => {
        const count = items.filter((u) => u.answeredCorrectly).length;
        const total = items.filter((u) => u.answer).length;
        return isMobile ? (
          <StyledPercentage percentage={percentage(count, total)} />
        ) : (
          <PercentsAndNumbers count={count} total={total} />
        );
      };

      const FooterImageUrl = (items) => {
        const count = items.filter((u) => u.imageUrl).length;
        const total = items.filter((u) => u.answered).length;
        return <PercentsAndNumbers count={count} total={total} />;
      };

      const render = {
        name: () => FooterName(data),
        answered: () => FooterAnswered(data),
      };

      if (questionType === 'multiple choices') {
        // @ts-ignore
        render.answeredCorrectly = () => FooterAnsweredCorrectly(data);
      }

      if (questionType === 'open ended') {
        // @ts-ignore
        render.imageUrl = () => FooterImageUrl(data);
      }

      return [{ render }];
    },
    [questionType, isMobile],
  );
};

export default useMakeFooterRows;
