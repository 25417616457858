import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appActivityReducer from '../features/appActivity/appActivity.slice';
import authReducer from '../features/auth/auth.slice';
import authFormReducer from '../features/authForm/authForm.slice';
import biteReducer from '../features/bite/bite.slice';
import bitesReducer from '../features/bites/bites.slice';
import featureFlagReducer from '../features/featureFlag/featureFlag.slice';
import orgReducer from '../features/org/org.slice';
import overviewReducer from '../features/overview/overview.slice';
import playlistReducer from '../features/playlist/playlist.slice';
import playlistsReducer from '../features/playlists/playlists.slice';
import quizReducer from '../features/quiz/quiz.slice';
import quizzesReducer from '../features/quizzes/quizzes.slice';
import userAnalysisReducer from '../features/userAnalysis/userAnalysis.slice';
import userManagementReducer from '../features/userManagement/userManagement.slice';

const appActivityPersistConfig = {
  key: 'appActivity',
  storage,
  whitelist: ['isNoContentOverlayDismissed'],
};

export default combineReducers({
  org: orgReducer,
  overview: overviewReducer,
  playlists: playlistsReducer,
  playlist: playlistReducer,
  bites: bitesReducer,
  quizzes: quizzesReducer,
  quiz: quizReducer,
  auth: authReducer,
  authForm: authFormReducer,
  bite: biteReducer,
  userManagement: userManagementReducer,
  userAnalysis: userAnalysisReducer,
  featureFlag: featureFlagReducer,
  appActivity: persistReducer(appActivityPersistConfig, appActivityReducer),
});
