import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import NoStatsModal from '../../components/Share/NoStatsModal';
import { isNoContentOverlayDismissedSelector } from '../appActivity/appActivity.selectors';
import { ContentType } from '../userAnalysis/components/Table/Table';

import { selectBite } from './bite.slice';
import BiteScreenContent from './components/BiteScreenContent';
import BiteScreenTabs from './components/BiteScreenTabs';
import BiteScreenTop from './components/BiteScreenTop';
import useFetchData from './hooks/useFetchData';
import useSetTabByUrl from './hooks/useSetTabByUrl';

function Bite() {
  const { isFetchingOverview, overviewError } = useSelector(selectBite);
  const isNoContentOverlayDismissed = useSelector(isNoContentOverlayDismissedSelector);
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { watched } = useSelector(selectBite);

  const handleTabChange = (evt, value) => {
    setSelectedTabIndex(value);
  };

  useFetchData();
  useSetTabByUrl({ selectedTabIndex, setSelectedTabIndex });

  if (isFetchingOverview) {
    return <Loader />;
  }

  if (overviewError) {
    return <ErrorMsg>Error loading bite: {overviewError}</ErrorMsg>;
  }

  return (
    <div className={classes.root}>
      {!watched && !isNoContentOverlayDismissed && (
        <NoStatsModal withoutContent={false} contentType={ContentType.BITE} />
      )}
      <BiteScreenTop />
      <BiteScreenTabs selectedTabIndex={selectedTabIndex} onTabChange={handleTabChange} />
      <BiteScreenContent />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default Bite;
