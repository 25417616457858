import bitesApi from '../../services/bitesApi';

export const fetchAskAiPromptHistory = async ({
  orgId,
  pageSize = 25,
  page = 0,
}: {
  orgId: number;
  pageSize: number;
  page: number;
}) => {
  return bitesApi.post(
    '/common_services/knowledge/bites/prompt/history',
    {
      filters: { orgId },
      additionalFields: ['user'],
      pageSize,
      page,
    },
    {},
    { originalData: true, originalBody: true },
  );
};
