import { makeStyles } from '@material-ui/core';
import React, { memo, useState, useCallback, useEffect } from 'react';

import Modal from '../../../../components/Modal';

import SingleUploadInputs from './pages/singleUpload/singleUploadInputs';
import SingleUploadSuccess from './pages/singleUpload/singleUploadSuccess';

interface IProps {
  isVisible: boolean;
  onClose: (evt: any) => void;
}

export interface IPageSingleUser {
  setPage: (evt: EPagesSingleUser) => void;
}

export interface ISuccessSingleUser {
  setOpenModal: (evt: any) => void;
  setPage: (evt: EPagesSingleUser) => void;
}

export enum EPagesSingleUser {
  SINGLE_UPLOAD = 'SINGLE_UPLOAD',
  SINGLE_UPLOAD_SUCCESS = 'SINGLE_UPLOAD_SUCCESS',
}

const AddUserModal = (props: IProps) => {
  const { isVisible, onClose } = props;
  const [pageModal, setPageModal] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setPageModal(EPagesSingleUser.SINGLE_UPLOAD);
    }
  }, [isVisible]);

  const PageModalSingleUser = useCallback(() => {
    switch (pageModal) {
      case EPagesSingleUser.SINGLE_UPLOAD_SUCCESS:
        return <SingleUploadSuccess setPage={setPageModal} onClose={onClose} />;
      case EPagesSingleUser.SINGLE_UPLOAD:
        return <SingleUploadInputs setPage={setPageModal} />;
    }
  }, [pageModal, onClose]);

  const classes = useStyles();

  return (
    <Modal
      title=''
      HeaderIcon=''
      className={classes.modal}
      isOpen={isVisible}
      onClose={onClose}
      content={<PageModalSingleUser />}
    />
  );
};

export default memo(AddUserModal);

const useStyles = makeStyles({
  modal: {
    width: 650,
    position: 'absolute',
  },
});
