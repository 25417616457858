import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { browserName } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import smartlook from 'smartlook-client';

import { APP } from '../../constants/constants';
import firebaseApp from '../firebase';
import log, { logError } from '../log';

import { IGtmSetUserId, IGtmTrack, IGtmSetUserProps } from './track.types';

const analytics = getAnalytics(firebaseApp);

const URL = 'https://ip.nf/me.json';
let info = {
  ip: null as {
    ip: string;
    latitude: string;
    longitude: string;
  },
};

export const getGenericProps = () => {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const timezoneOffset = new Date().getTimezoneOffset();
  const orientation = isLandscape() ? 'landscape' : 'portrait';

  const genericProps = {
    browser_name: browserName,
    device_orientation: orientation,
    page_url: window.location.href,
    ip: info?.ip.ip,
    ip_latitude: info?.ip.latitude,
    ip_longitude: info?.ip.longitude,
    referring_url: document.referrer,
    timezone_offset: timezoneOffset / 60,
    origin: APP,
    time_stamp: Date.now(),
  };

  return genericProps;
};

fetch(URL, { method: 'get' })
  .then((response) => response.json())
  .then((data) => {
    info = data;
  });

const intercomEventsWhitelist = ['login_error', 'FinalScreen', 'view-inspiration-bite', 'share_ms_teams', 'login'];

const gtmTrack: IGtmTrack = (event, data = {}) => {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const timezoneOffset = new Date().getTimezoneOffset();
  const orientation = isLandscape() ? 'landscape' : 'portrait';

  const genericProps = {
    browser_name: browserName,
    device_orientation: orientation,
    page_url: window.location.href,
    ip: info.ip?.ip,
    ip_latitude: info.ip?.latitude,
    ip_longitude: info.ip?.longitude,
    referring_url: document.referrer,
    timezone_offset: timezoneOffset / 60,
  };

  const props = { ...genericProps, ...data };

  TagManager.dataLayer({
    dataLayer: {
      event,
      ...props,
    },
  });

  try {
    if (intercomEventsWhitelist.includes(event)) {
      window.Intercom?.('trackEvent', event, props);
    }
  } catch (error) {
    logError(error);
  }

  try {
    window.heap?.track(event, props);
  } catch (error) {
    logError(error);
  }

  // try {
  //   smartlook.track(event, props)
  // } catch (error) {
  //   logError(error)
  // }

  log({
    event,
    ...props,
  });
};
export default gtmTrack;

export const gtmSetUserId: IGtmSetUserId = (userId) => {
  try {
    setUserId(analytics, `${userId}`);
    // gtmSetUserProps({ 'bites_user_id': `${userId}` }) // to use - need to set in GA as user props instead of event props
  } catch (error) {
    logError(error);
  }

  try {
    window.heap?.identify(`${userId}`);
  } catch (error) {
    logError(error);
  }

  try {
    smartlook.identify(`${userId}`, {});
  } catch (error) {
    logError(error);
  }
};

export const gtmSetUserProps: IGtmSetUserProps = async (userId, props) => {
  try {
    setUserProperties(analytics, props);
  } catch (error) {
    logError(error);
  }

  try {
    window.heap?.addUserProperties(props);
  } catch (error) {
    logError(error);
  }

  if (userId) {
    try {
      smartlook.identify(`${userId}`, props);
    } catch (error) {
      logError(error);
    }
  }
};
