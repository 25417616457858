import { Hidden, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import history from '../app/history';
import { ReactComponent as ArrowIcon } from '../assets/icons/down-arrow-white.svg';
import logo from '../assets/images/Bites_Logo_white_text_small.png';
import avatar from '../assets/images/img_avatar.png';
import { INTERCOM_ARTICLES } from '../constants/constants';
import { currentUserSelector } from '../features/auth/auth.selectors';
import { logout } from '../features/auth/auth.slice';
import { selectOrg, switchOrg, updateDefaultOrg } from '../features/org/org.slice';
import useIsMobile from '../hooks/useIsMobile';
import i18n, { LANGUAGES, setLanguage } from '../locale/i18n';
import gtmTrack from '../services/googleTagManager/track';
import { getFullName } from '../utils/auth';

interface IProps {
  onOpenSidebar: () => void;
}

function Topbar({ onOpenSidebar }: IProps) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const org = useSelector(selectOrg);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [menuOrgsAnchorEl, setMenuOrgsAnchorEl] = React.useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);

  const fullName = getFullName(currentUser);

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleOrgsClick = (event) => {
    setMenuOrgsAnchorEl(event.currentTarget);
  };

  const handleSetLanguage = (code) => {
    setLanguage(code);
    handleLanguageClose();
  };

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const handleOrgsClose = () => {
    setMenuOrgsAnchorEl(null);
  };

  const handleLogout = () => {
    setMenuAnchorEl(null);
    dispatch(logout());
    gtmTrack('logout', { user_id: currentUser?.id });
  };

  const handleHowToShareContent = useCallback(() => {
    window.Intercom('showArticle', INTERCOM_ARTICLES.howToShareContent);
  }, []);

  return (
    <AppBar position='sticky' className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftContainer}>
          <Hidden mdUp>
            <MenuIcon onClick={onOpenSidebar} className={classes.menuIcon} />
          </Hidden>
          <img src={logo} alt='bites' className={classes.logo} />
          {!isMobile && (
            <div className={classes.slogan}>
              <strong>Big Ideas</strong> Small Bites
            </div>
          )}
        </div>
        <div className={classes.rightContainer}>
          {!isMobile && (
            <div className={classes.howToShareButton} onClick={handleHowToShareContent}>
              <span className={classes.howToShareButtonText}>{t('mainScreen.header.howToShareContent')}</span>
            </div>
          )}
          <div
            className={classes.menuItem}
            aria-controls='language-menu'
            aria-haspopup='true'
            onClick={handleLanguageClick}
          >
            <span className={classes.username}>{LANGUAGES?.find(({ code }) => code === i18n.language)?.name}</span>
            <S.ArrowIcon />
          </div>
          <Menu
            id='language-menu'
            anchorEl={languageAnchorEl}
            keepMounted
            open={Boolean(languageAnchorEl)}
            onClose={handleLanguageClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.menu,
            }}
          >
            {LANGUAGES.map(({ code, name }) => (
              <MenuItem selected={i18n.language === code} onClick={() => handleSetLanguage(code)} key={code}>
                {name}
              </MenuItem>
            ))}
          </Menu>
          <div className={classes.menuItem} aria-controls='orgs-menu' aria-haspopup='true' onClick={handleOrgsClick}>
            <span className={classes.username}>
              {currentUser.organizations?.find(({ id }) => id === org?.id)?.name}
            </span>
            <S.ArrowIcon />
          </div>
          <Menu
            id='orgs-menu'
            anchorEl={menuOrgsAnchorEl}
            keepMounted
            open={Boolean(menuOrgsAnchorEl)}
            onClose={handleOrgsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.menu,
            }}
          >
            {currentUser.organizations
              ?.filter(({ showDashboard }) => showDashboard)
              .map(({ id, name, userOrganizationId }) => (
                <MenuItem
                  onClick={() => {
                    history.push('/overview');
                    dispatch(switchOrg(id));
                    dispatch(updateDefaultOrg(userOrganizationId));
                    handleOrgsClose();
                  }}
                  key={id}
                >
                  {name}
                </MenuItem>
              ))}
          </Menu>
          <div
            className={classes.menuItem}
            aria-controls='logout-menu'
            aria-haspopup='true'
            onClick={handleClick}
            data-cy='topbarRightButton'
          >
            <span className={classes.org}>{fullName}</span>
            <img className={classes.avatar} alt='username' src={currentUser?.profileImage || avatar} />
          </div>
          <Menu
            id='logout-menu'
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.menu,
            }}
          >
            <MenuItem onClick={handleLogout} data-cy='topbarLogoutButton'>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles({
  root: {
    backgroundColor: '#000925',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 48,
    minHeight: 48,
    paddingLeft: 12,
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  slogan: {
    fontSize: 18,
    marginTop: 8,
  },
  logo: {
    width: 68,
    marginInlineEnd: '11px',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '12px',
    cursor: 'pointer',
  },
  org: {
    marginInlineEnd: '8px',
  },
  username: {
    marginInlineEnd: '8px',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
  },
  menu: {
    top: '48px !important',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  menuIcon: {
    cursor: 'pointer',
    marginRight: 12,
  },
  howToShareButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid white',
    borderRadius: 32,
    padding: '6px 12px',
    cursor: 'pointer',
  },
  howToShareButtonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 400,
  },
});

const S = {
  ArrowIcon: styled(ArrowIcon)`
    width: 7px;
  `,
};

export default Topbar;
