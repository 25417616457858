import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { IButtonProps } from '../../types/button';

import BaseButton from './BaseButton';

function BlueButton({ className = '', onClick, children, ...restOfProps }: IButtonProps) {
  const classes = useStyles();
  return (
    <BaseButton className={cx(classes.root, className)} onClick={onClick} {...restOfProps}>
      {children}
    </BaseButton>
  );
}

const useStyles = makeStyles({
  root: {
    background: '#EBF0FF',
    color: '#1253FA',
  },
});

export default BlueButton;
