import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

function WrongIcon() {
  const classes = useStyles();
  return <CloseIcon className={classes.root} />;
}

const useStyles = makeStyles({
  root: {
    color: '#FF284E',
    borderRadius: '50%',
    border: '1px solid',
    height: 19.5,
    width: 19.5,
  },
});

export default WrongIcon;
