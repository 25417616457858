import { Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExportIcon } from '../../assets/icons/export.svg';
import gtmTrack from '../../services/googleTagManager/track';
import { formatDate } from '../../utils/dates';

interface IProps {
  gridRef: any;
  dataSource: any[] | ((prop: any) => any);
  className: string;
  origin: string;
  intercomTarget?: string;
}

function ExportToExcel({ gridRef, dataSource, className, origin, intercomTarget }: IProps) {
  const { t } = useTranslation();
  const handleClick = () => {
    let columns = gridRef.current.visibleColumns;
    columns = columns.filter((i) => i.name !== null);
    const header = columns.map((c) => c.header).join(',');

    const rows = (dataSource as any[]).map((data) =>
      columns
        .map((c) => {
          if (c.name === 'createdAt' || c.name === 'lastVisit') {
            return formatDate(data[c.name]);
          }
          // wrap in quotes for escaping (ie. commas)
          let cell = `"${String(data[c.name]).replace(/"/g, '""')}"`;
          if (cell === '"null"') {
            cell = '';
          }
          return cell;
        })
        .join(','),
    );
    const contents = '\uFEFF' + [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const date = format(new Date(), 'dd-MM-yyyy');
    const fileName = `Bites-data-${date}.csv`;
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    gtmTrack('export_to_excel', { page_title: origin });
  };

  return (
    <Tooltip title={t('table.exportToExcel')}>
      <div data-intercom-target={intercomTarget}>
        <ExportIcon className={className} onClick={handleClick} />
      </div>
    </Tooltip>
  );
}

export default ExportToExcel;
