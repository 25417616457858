import bitesApi from '../../services/bitesApi';
import { IUserOrganization } from '../../types/user';

import { IUpdateUserPayload } from './components/UploadUsersHelpers/pages/singleUpload/singleUploadInputs';

export const getUsers = ({ orgId, datasQuery = '' }: IGetUsersArgs) =>
  bitesApi.get<IUserOrganization[]>(`/user_organization/?organization=${orgId}${datasQuery}`);

export const updateUser = ({ id, fields }: IUpdateUserArgs) => bitesApi.patch(`/user_organization/${id}/`, fields);

export const resetPassword = (id: number) => bitesApi.post(`/user_organization/${id}/reset_password/`);

export const bulkResetPassword = (ids: number[]) => bitesApi.post('/user_organization/bulk_reset_password/', { ids });

export const bulkBlock = (ids: number[]) => bitesApi.post('/user_organization/bulk_block/', { ids });

export const bulkUnblock = (ids: number[]) => bitesApi.post('/user_organization/bulk_unblock/', { ids });

export const organizationData = (id: number) => bitesApi.get(`/organization/${id}/dropdown_data/`);

export const updateSingleUser = (id: number, payload: IUpdateUserPayload) =>
  bitesApi.post(`/organization/${id}/update_single_user/`, payload, {}, { raw: true });

export const generateCsvTemplate = (id: number) => bitesApi.get(`/organization/${id}/generate_csv/`);

export const csvUploadUrl = (id: number) => bitesApi.get(`/organization/${id}/csv_upload_url/`);

export const validateCsv = (id: number, payload: IPayload) =>
  bitesApi.post(`/organization/${id}/validate_csv/`, payload, {}, { raw: true });

export const updateCsv = (id: number, payload: IPayload) => bitesApi.post(`/organization/${id}/update_csv/`, payload);

interface IGetUsersArgs {
  orgId: number;
  datasQuery?: string;
}

interface IPayload {
  link: string;
  user_id: number;
}

interface IUpdateUserArgs {
  id: number;
  fields: { [key: string]: any };
}
