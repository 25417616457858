import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrg } from '../../../features/org/org.slice';
import { IRdgColumnVisibility } from '../../../types/reactDataGrid';
import EllipsisWithTooltip from '../../EllipsisWithTooltip';
import EllipsisWithTooltipForGuest from '../../EllipsisWithTooltipForGuest';

interface IArgs {
  rawColumns: TypeColumns;
  columnsVisibility: IRdgColumnVisibility;
}

const useColumns = ({ rawColumns, columnsVisibility }: IArgs) => {
  const { t } = useTranslation();
  const { datas } = useSelector(selectOrg);

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const NameEllipsisColumn = ({ data, value }) =>
      data?.id === 1 ? <EllipsisWithTooltipForGuest text={value} /> : <EllipsisWithTooltip text={value} />;
    const result: TypeColumns = [
      {
        name: 'name',
        header: t('common.name'),
        defaultFlex: 1,
        render: NameEllipsisColumn,
        defaultLocked: true,
      },
    ];
    datas.forEach((d) => {
      result.push({
        name: d.dataFieldName,
        defaultFlex: 1,
        render: EllipsisColumn,
        header: d.title.toUpperCase(),
        filterEditor: SelectFilter,
        filterEditorProps: {
          multiple: true,
          wrapMultiple: false,
          dataSource: d.options.map((option) => option.name).map((x) => ({ id: x, label: x })),
        },
      });
    });
    return result.concat(rawColumns).map((c) => ({ ...c, visible: columnsVisibility[c.name] }));
  }, [t, datas, rawColumns, columnsVisibility]);
};

export default useColumns;
