import React from 'react';
import { useSelector } from 'react-redux';

import { selectDatasStrings } from '../../features/org/org.slice';
import { ITableDisplay } from '../../types/reactDataGrid';

interface IProps {
  tableDisplay: ITableDisplay;
  Table: any;
  style?: Record<string, any>;
}

function RenderOverviewTable({ tableDisplay, Table, style = {} }: IProps) {
  const datasStrings = useSelector(selectDatasStrings);

  if (datasStrings.length === 0) {
    return null;
  }
  if (tableDisplay === 'drilldown') {
    return null;
  }

  return (
    <div style={style} data-cy='overviewTable'>
      <Table groupedByDataIndex={tableDisplay === 'data1' ? 0 : 1} />
    </div>
  );
}

export default RenderOverviewTable;
