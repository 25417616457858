import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useComponentWillMount from 'use-component-will-mount';

import { URL_ACCESS_TOKEN_PLACEHOLDER, URL_ACTION_SOCIAL_AUTH } from '../constants/constants';
import AuthErrorTypes from '../features/auth/auth.errors';
import { logout } from '../features/auth/auth.slice';
import { isProcessingQueryTokenSelector } from '../features/authForm/authForm.selectors';
import {
  loginFromQueryToken,
  refreshUser,
  resetErrors,
  setAuthErrorCodes,
  setIsSocialLoginError,
} from '../features/authForm/authForm.slice';
import { log } from '../features/tracking/tracking.slice';

const useInitAuth = (): boolean => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryToken = query.get('token');
  const errorCode = query.get('errorCode') as AuthErrorTypes;
  const action = query.get('action');
  const isPreview = query.get('isPreview');
  const isProcessingQueryToken = useSelector(isProcessingQueryTokenSelector);
  const [isInitAuthDone, setIsInitAuthDone] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const initAuth = () => {
    if (query.get('action') === 'logout') {
      dispatch(
        log({
          event: 'initAuth: action logout',
        }),
      );

      dispatch(logout());
      return;
    }

    if (
      action === URL_ACTION_SOCIAL_AUTH &&
      (errorCode === AuthErrorTypes.noUser || errorCode === AuthErrorTypes.noAccess)
    ) {
      dispatch(setAuthErrorCodes([errorCode]));
      dispatch(setIsSocialLoginError(true));
      history.replace(location.pathname);
      return;
    }

    if (queryToken && queryToken !== URL_ACCESS_TOKEN_PLACEHOLDER) {
      if (isPreview) {
        history.replace(`${location.pathname}?isPreview=${isPreview}`);
      } else {
        history.replace(location.pathname);
      }
      dispatch(resetErrors());
      // @ts-ignore there's no argument in the reducer action but there's on the saga
      dispatch(loginFromQueryToken(queryToken));
      return;
    }

    dispatch(refreshUser());
  };

  useComponentWillMount(() => {
    initAuth();
    setDidMount(true);
  });

  useEffect(() => {
    setIsInitAuthDone(!isProcessingQueryToken && didMount);
  }, [isProcessingQueryToken, didMount]);

  return isInitAuthDone;
};

export default useInitAuth;
