import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BiteStickHat } from '../../../../../../assets/icons/bite-stick-hat.svg';
import { iconSize, modalColors, S } from '../../ui';

export interface ISuccess {
  onClose: (evt: any) => void;
}

const headerStyle = { color: '#9be91c', textAlign: 'center', marginTop: '30px', fontSize: '24px' };
const modelButtonStyle = {
  margin: 'auto',
  marginBottom: '50px',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
};
const iconStyle = { width: iconSize + 25, height: iconSize + 25 };
const buttonWrapper = { marginTop: '100px' };

const SuccessPage = (props: ISuccess) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const handleDoneClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <BiteStickHat style={iconStyle} />
      </S.IconWrapper>
      <S.TextHeader style={headerStyle}>
        {t('userManagement.pages.previewValidation.uploadEndedSuccessfully')}
      </S.TextHeader>
      <S.ButtonWrapper style={buttonWrapper}>
        <S.ModelButton style={modelButtonStyle} onClick={handleDoneClick}>
          {t('userManagement.pages.previewValidation.done')}
        </S.ModelButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default memo(SuccessPage);
