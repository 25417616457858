import bitesApi from '../../services/bitesApi';
import { IFullPlaylist } from '../../types/playlist';
import { IQuizSettings, TQuizAnswer } from '../../types/quiz';
import { IQuizViewsTableUser } from '../../types/quizTableUser';

export const getQuiz = (id: number) => bitesApi.get<IFullPlaylist>(`/dashboard/playlists/${id}/`);

export const getQuizSettings = (id: number) => bitesApi.get<IQuizSettings>(`/playlists/${id}/settings/`);

export const getQuizViewsByUser = (id: number) =>
  bitesApi.get<IQuizViewsTableUser[]>(`/dashboard/playlists/${id}/views/`);

export const getQuizAnswers: TQuizAnswer = (usersOrgIds: number[], quizId: number) =>
  bitesApi.post(`/dashboard/quiz_answers/?quiz=${quizId}`, { users_ids: usersOrgIds });
