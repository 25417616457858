import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { shareWithIntegration } from '../../app/integration.api';
import { EIntegrations } from '../../types/integration';

interface IProps {
  itemId: number;
}

interface IShareMailMerge {
  users?: (string | number)[];
  biteShare?: number;
  playlist?: number;
  groupsIdsByDatas?: Record<string, any>;
  onLoadingChange: (isLoading: boolean) => void;
  onSuccess: () => void;
}

const useMailMerge = ({ itemId }: IProps) => {
  const { t } = useTranslation();

  const downloadMailMergeFile = useCallback(
    async (payload) => {
      const { data } = await shareWithIntegration(
        payload,
        { responseType: 'blob' },
        {
          originalData: true,
        },
      );

      const date = format(new Date(), 'dd-MM-yyyy');
      const fileName = `mail_merge_${itemId}_${date}.xlsx`;

      fileDownload(data as Blob, fileName);
    },
    [itemId],
  );

  const handleShareMailMerge = useCallback(
    async ({ users = [], groupsIdsByDatas = {}, biteShare, playlist, onLoadingChange, onSuccess }: IShareMailMerge) => {
      try {
        const payload = {
          integration: EIntegrations.Mail_merge,
          users: users.filter((user) => user !== 1),
          biteShare,
          playlist,
          ...groupsIdsByDatas,
        };

        onLoadingChange(true);
        await downloadMailMergeFile(payload);
        onLoadingChange(false);

        onSuccess();

        toast(t('share.mailMergeSuccess'), { type: 'success' });
      } catch (error) {
        onLoadingChange(false);
        toast(t('share.mailMergeFailed'), { type: 'error' });
      }
    },
    [downloadMailMergeFile, t],
  );

  return {
    handleShareMailMerge,
  };
};

export default useMailMerge;
