import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import UndoSnackbar from '../../../../components/UndoSnackbar';
import { selectUserManagement, undoUpdateUserField, updateUserField } from '../../userManagement.slice';

interface IProps {
  field: string;
  fieldDisplayName: string;
  id: number;
  className: string;
  dataCy?: string;
}

function EditableUserField({ field, fieldDisplayName, id, className, dataCy }: IProps) {
  const { t } = useTranslation();
  const previousValue = useRef();
  const [isUndoSnackbarOpen, setIsUndoSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const { users, isUpdatingUserField } = useSelector(selectUserManagement);
  const user = users.find((u) => u.id === id);
  const [value, setValue] = useState(user[field]);

  useEffect(() => {
    setValue(user[field]);
  }, [user, field]);

  const handleChange = (evt) => {
    if (isUpdatingUserField) {
      return;
    }
    setValue(evt.target.value);
  };

  const handleBlur = () => {
    // if no changes have been made, don't do anything
    if (value === user[field]) {
      return;
    }
    previousValue.current = user[field]; // store for undo purposes
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(updateUserField({ id, field, value }));
    setIsUndoSnackbarOpen(true);
  };

  const handleCloseUndoSnackbar = () => setIsUndoSnackbarOpen(false);

  const handleUndo = () => {
    setIsUndoSnackbarOpen(false);
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(undoUpdateUserField({ id, field, value: previousValue.current }));
  };

  return (
    <>
      <input
        className={className}
        value={value || ''} // convert null to empty string to supress react warnings
        onChange={handleChange}
        onBlur={handleBlur}
        data-cy={dataCy}
      />
      <UndoSnackbar
        isOpen={isUndoSnackbarOpen}
        message={t('userManagement.userWindow.updatedDisplayName', { fieldDisplayName })}
        onUndo={handleUndo}
        onClose={handleCloseUndoSnackbar}
      />
    </>
  );
}

export default EditableUserField;
