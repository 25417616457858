import sumBy from 'lodash/sumBy';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import StyledPercentage from '../../../../components/StyledPercentage';
import { IQuizViewsTableUser } from '../../../../types/quizTableUser';
import { percentage } from '../../../../utils/numbers';
import { selectQuiz } from '../../quiz.slice';

const useMakeFooter = () => {
  const { questions } = useSelector(selectQuiz);

  return useCallback(
    (data: IQuizViewsTableUser[]) => {
      const FooterName = () => `${data.length} users`;

      const FooterCompleted = () => {
        const count = sumBy(data, (item) => Number(item.completed));
        const total = data.length;
        return <StyledPercentage percentage={percentage(count, total)} />;
      };

      const FooterAnsweredCorrectly = () => {
        const count = sumBy(data, (item) => (item.answeredCorrectly ? Number(item.answeredCorrectly) : 0));
        const total = sumBy(data, 'scorableQuestionsCount');
        return <StyledPercentage percentage={percentage(count, total)} />;
      };

      const FooterAnswered = () => {
        const count = sumBy(data, (item) => Number(item.answered));
        const total = data.length * questions.length;
        return <StyledPercentage percentage={percentage(count, total)} />;
      };
      return [
        {
          render: {
            name: FooterName,
            completed: FooterCompleted,
            answeredCorrectly: FooterAnsweredCorrectly,
            answered: FooterAnswered,
          },
        },
      ];
    },
    [questions.length],
  );
};

export default useMakeFooter;
