import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/named
import { Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: string;
  left: string;
  right: string;
  onSwitch: () => void;
}

function SwitchBetweenTwoChoices({ value, left, right, onSwitch }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles({ value, left });

  return (
    <div className={classes.switchRoot}>
      <div
        className={cx(classes.text, { [classes.isSelected]: value === left })}
        onClick={() => value !== left && onSwitch()}
      >
        {t(`share.${left}`)}
      </div>
      <div className={classes.switchContainer} onClick={onSwitch}>
        <div className={classes.switchHandle} />
      </div>
      <div
        className={cx(classes.text, { [classes.isSelected]: value === right })}
        onClick={() => value !== right && onSwitch()}
      >
        {t(`share.${right}`)}
      </div>
    </div>
  );
}

const SWITCH_HANDLE_DIMENSIONS = 20;
const SWITCH_CONTAINER_WIDTH = SWITCH_HANDLE_DIMENSIONS * 2 + 5;

const useStyles = makeStyles<Theme, { value: string; left: string }>({
  switchRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    color: '#808080',
    cursor: 'pointer',
  },
  isSelected: {
    color: '#1253f7',
  },
  switchContainer: {
    margin: '0 14px',
    width: SWITCH_CONTAINER_WIDTH,
    height: SWITCH_HANDLE_DIMENSIONS + 4,
    background: '#1253f7',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 18,
  },
  switchHandle: {
    width: SWITCH_HANDLE_DIMENSIONS,
    height: SWITCH_HANDLE_DIMENSIONS,
    position: 'absolute',
    background: '#fff',
    borderRadius: '50%',
    transition: '0.3s left',
    top: 2,
    left: ({ value, left }) => (value === left ? 2 : SWITCH_CONTAINER_WIDTH - SWITCH_HANDLE_DIMENSIONS - 2),
  },
});

export default SwitchBetweenTwoChoices;
