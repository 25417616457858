import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import DynamicList, { createCache } from 'react-window-dynamic-list';

import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import CommentItem from '../../../components/Comments/CommentItem';
import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import { FRONTLINER_URL } from '../../../constants/constants';
import { formatDate } from '../../../utils/dates';
import { selectActiveUserOrganization, tokenSelector } from '../../auth/auth.selectors';
import { selectBite } from '../bite.slice';

function Discussion() {
  const { t } = useTranslation();
  const [listRef, setListRef] = useState(null);
  const { isFetchingComments, comments, discussionEnabled, commentsError, bitePreview } = useSelector(selectBite);
  const userAuth = useSelector(selectActiveUserOrganization);
  const token = useSelector(tokenSelector);
  const cache = createCache();
  const classes = useStyles();

  // hack around DynamicList bug not rendering items' height properly on mount
  useEffect(() => {
    if (listRef) {
      listRef.resetAfterIndex(0);
    }
  }, [listRef]);

  if (!discussionEnabled) {
    return <p>{t('bites.commentsAreNotAllowed')}</p>;
  }

  if (commentsError) {
    return <ErrorMsg>{t('bites.errorLoadingComments')}</ErrorMsg>;
  }

  if (isFetchingComments) {
    return <Loader />;
  }

  if (comments.length === 0) {
    return <p>{t('bites.noComments')}</p>;
  }

  const handleReply = () => {
    window.open(`${FRONTLINER_URL}/bites/${bitePreview}/discussion?token=${token}&keepSection=true`, '_blank');
  };

  const handleExport = () => {
    const header = ['user, createdAt, comment'].join(',');

    const rows = comments.map((data) =>
      [
        data.user.fullName,
        formatDate(data.createdAt),
        `"${decodeURIComponent(data.comment).replace(/"/g, '""')}"`,
      ].join(','),
    );
    const contents = '\uFEFF' + [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const date = format(new Date(), 'dd-MM-yyyy');
    const fileName = `Bites-comments-${date}.csv`;
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div className={classes.container} style={{ width }}>
          <DynamicList
            // @ts-ignore
            ref={(ref) => setListRef(ref)}
            data={comments}
            width={'100%'}
            height={height}
            cache={cache}
            recalculateItemsOnResize={{ width: true, height: true }}
            className={classes.commentList}
          >
            {({ index, style }) => (
              <div style={{ ...style }}>
                <CommentItem
                  comment={comments[index]}
                  inDiscussion={true}
                  isAdmin={userAuth?.hasOrganizationAdminAccess}
                />
              </div>
            )}
          </DynamicList>
          <div className={classes.buttonContainer}>
            <PrimaryButton onClick={handleReply} className={classes.replyButton}>
              {t('bites.reply')}
            </PrimaryButton>
            <PrimaryButton onClick={handleExport} className={classes.replyButton}>
              {t('table.exportToExcel')}
            </PrimaryButton>
          </div>
        </div>
      )}
    </AutoSizer>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  commentList: {
    marginRight: 20,
    maxWidth: 480,
  },
  replyButton: {
    zIndex: 99,
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      flexDirection: 'column',
    },
    commentList: {
      marginRight: 0,
      maxWidth: 'none',
      '&> div': {
        marginBottom: 50,
      },
    },
    replyButton: {
      marginTop: -40,
    },
  },
}));

export default Discussion;
