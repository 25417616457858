import { createAction } from '@reduxjs/toolkit';

export interface IFetchAskAiPromptHistoryAction {
  page: number;
  pageSize: number;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}

export const fetchAskAiPromptHistory = createAction<IFetchAskAiPromptHistoryAction>(
  'askAiReports/fetchAskAiPromptHistory',
);
