import AuthErrorTypes from './auth.errors';

export enum EAuthFormType {
  AUTH = 'AUTH',
  FORGOT_PASSWORD_EMAIL = 'FORGOT_PASSWORD_EMAIL',
  FORGOT_PASSWORD_INFO = 'FORGOT_PASSWORD_INFO',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ENTER_CODE_EMAIL = 'ENTER_CODE_EMAIL',
  ENTER_CODE_PHONE = 'ENTER_CODE_PHONE',
  USER_INFO = 'USER_INFO',
}

export interface IFinalizeProfileAction {
  processId: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export interface IAuthDataMissingPayload {
  isAuthDataMissing: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
}

export interface ILoginWithEmail {
  username: string;
  password: string;
  onOtp?: (processId?: string) => void;
  processId?: string;
  otp?: string;
}

export interface ILoginWithPhone {
  username: string;
  onOtp?: (processId: string) => void;
  processId?: string;
  otp?: string;
}

export interface ISetNewRecoverPassword {
  token: string;
  password: string;
  processId?: string;
  onSuccess?: () => void;
}

export interface IPasswordRecoveryBody {
  email: string;
  processId: string;
  onSuccess?: (props: { processId: string }) => void;
}

export type TSignupErrorCodes = '1' | '2' | '3' | AuthErrorTypes;
