import { takeLatest, put, all } from 'redux-saga/effects';

import remoteConfig from '../../services/remoteConfig';

import { initFeatureFlags, setIsInitialized, setFlags } from './featureFlag.slice';

function* initFeatureFlagsSaga() {
  yield remoteConfig.initRemoteConfig();
  const flags = yield remoteConfig.getConfigValues();
  yield put(setFlags(flags));
  yield put(setIsInitialized(true));
}
export default function* appActivitySaga() {
  yield all([takeLatest(initFeatureFlags, initFeatureFlagsSaga)]);
}
