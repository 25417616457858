import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IOrgData } from '../../../../types/org';
import { selectUserManagement, updateUserField } from '../../userManagement.slice';

interface IProps {
  orgData: IOrgData;
  userId: number;
}

function UserSelectData({ orgData, userId }: IProps) {
  const user = useSelector(selectUserManagement).users.find((u) => u.id === userId);
  const dispatch = useDispatch();
  const handleChange = (evt, option) => {
    dispatch(
      // @ts-ignore there's no argument in the reducer action but there's on the saga
      updateUserField({
        id: userId,
        field: orgData.dataFieldName,
        value: option.id,
      }),
    );
  };

  return (
    orgData.options?.length && (
      <Autocomplete
        options={orgData.options}
        style={{ width: 230, height: 40 }}
        size='small'
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} variant='outlined' data-cy='userWindowUserDataAc' />}
        value={user[orgData.dataFieldName]}
        getOptionLabel={(optionOrOptionName) =>
          typeof optionOrOptionName === 'string' ? optionOrOptionName : optionOrOptionName.name
        }
        getOptionSelected={(optionOrOptionName, value) => {
          const toCompare = typeof optionOrOptionName === 'string' ? optionOrOptionName : optionOrOptionName.name;
          return toCompare === value;
        }}
        disableClearable
      />
    )
  );
}

export default UserSelectData;
