import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { EOrgUsersPrimaryKey } from '../../../../../../types/org';
import { selectOrg } from '../../../../../org/org.slice';
import { modalColors, S } from '../../ui';

const modelButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.validationYesBG,
  color: modalColors.validationYesColor,
  marginBottom: '10px',
  width: '160px',
};

const contentAddedStyle = { color: modalColors.successAdded, margin: '0px', padding: '0px' };
const contentModifyStyle = { color: modalColors.successModify, margin: '0px', padding: '0px' };
const contentDeleteStyle = { color: modalColors.successDelete, margin: '0px', padding: '0px' };
const textValidationContentStyle = { marginBottom: '70px' };

interface IUsersHelper {
  usersHelperProps: IUsers;
}

interface IUserDetail {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  employeeId: string;
}

interface IUsers {
  added: number;
  modified: number;
  deleted: number;
  admins: IUserDetail[];
}

interface ITextValidationHelper {
  hasChanges: boolean;
  users: IUsers;
  extraData: any;
}

interface IButtonValidHelper {
  hasChanges: boolean;
  handleValidationYesClick: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    maxHeight: 300,
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  listHeader: {
    padding: theme.spacing(2),
    backgroundColor: '#ff832b',
    color: theme.palette.primary.contrastText,
  },
  userGrid: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  userItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    textAlign: 'center',
    margin: theme.spacing(1),
  },
}));

export const UsersHelper = (props: IUsersHelper) => {
  const { t } = useTranslation();
  const { usersPrimaryKey } = useSelector(selectOrg);
  const { added, modified, deleted, admins } = props.usersHelperProps;

  const userPK = usersPrimaryKey ?? usersPrimaryKey ?? EOrgUsersPrimaryKey.Phone;

  const AddedUsers = () => {
    return (
      <>
        {added > 0 ? (
          <S.Content style={contentAddedStyle}>
            {t('userManagement.pages.previewValidation.addNewUsers', { added })}
          </S.Content>
        ) : null}
      </>
    );
  };

  const ModifiedUsers = () => {
    return (
      <>
        {modified > 0 ? (
          <S.Content style={contentModifyStyle}>
            {t('userManagement.pages.previewValidation.modifyUsers', { modified })}
          </S.Content>
        ) : null}
      </>
    );
  };

  const DeactivateUsers = () => {
    return (
      <>
        {deleted > 0 ? (
          <S.Content style={contentDeleteStyle}>
            {t('userManagement.pages.previewValidation.deactivateUsers', { deleted })}
          </S.Content>
        ) : null}
      </>
    );
  };

  const AdminUsers = () => {
    const classes = useStyles();

    return (
      admins.length > 0 && (
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.listHeader}>
            <Typography variant='h6'>{t('userManagement.pages.previewValidation.adminWarnning')}</Typography>
          </div>
          <Grid container className={classes.userGrid} spacing={2}>
            {admins.map((user, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <div className={classes.userItem}>
                  <Typography variant='body1'>
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {user[userPK]}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )
    );
  };

  return (
    <>
      <AddedUsers />
      <ModifiedUsers />
      <DeactivateUsers />
      <AdminUsers />
    </>
  );
};

export const TextValidationHelper = (props: ITextValidationHelper) => {
  const { t } = useTranslation();
  const { hasChanges, users, extraData } = props;
  if (!hasChanges) {
    return (
      <S.Content style={textValidationContentStyle}>
        {t('userManagement.pages.previewValidation.noChangesDetected')}
      </S.Content>
    );
  }
  return (
    <>
      <S.Content>You are about to:</S.Content>
      {users ? <UsersHelper usersHelperProps={extraData} /> : <></>}
      <S.Content>{t('userManagement.pages.previewValidation.areYouSure')}</S.Content>
    </>
  );
};

export const ButtonValidHelper = (props: IButtonValidHelper) => {
  const { t } = useTranslation();
  const { handleValidationYesClick, hasChanges } = props;

  if (!hasChanges) {
    return <></>;
  }
  return (
    <S.ModelButton style={modelButtonStyle} onClick={handleValidationYesClick}>
      {t('userManagement.pages.previewValidation.continue')}
    </S.ModelButton>
  );
};
