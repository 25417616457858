import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import '@inovua/reactdatagrid-enterprise/index.css';
import 'react-virtualized/styles.css';
import 'react-image-lightbox/style.css';

import './initImports';
import './styles/index.css';
import App from './App';
import history from './app/history';
import store, { persistor } from './app/store';
import MaterialThemeProvider from './app/ThemeProvider';
import Intercom from './components/Intercom';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from './styles/globalStyle';
import defaultTheme from './styles/themes';
import './services/datadog/datadog';

const query = new URLSearchParams(window.location.search);
const isPreview = query.get('isPreview');

const AppContainer = ({ children }) => (
  <GoogleOAuthProvider clientId={'885796386880-95l4tnci4dr9hnkqmpv1gvgolf7kknks.apps.googleusercontent.com'}>
    <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={defaultTheme}>
        <MaterialThemeProvider>
          <GlobalStyle />
          <Router history={history}>{children}</Router>
        </MaterialThemeProvider>
      </ThemeProvider>
    </PersistGate>
  </GoogleOAuthProvider>
);

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      {!isPreview ? (
        <IntercomProvider appId='y3w6xsal' autoBoot>
          <Intercom />
          <AppContainer>
            <Component />
          </AppContainer>
        </IntercomProvider>
      ) : (
        <AppContainer>
          <Component />
        </AppContainer>
      )}
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
