import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/named
import { Theme } from '@material-ui/core/styles';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { selectOrg } from '../../../features/org/org.slice';
import { IIntegrationType } from '../../../types/integration';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Modal from '../../Modal';
import SwitchBetweenTwoChoices from '../../SwitchBetweenTwoChoices';

import GroupAccordion from './GroupAccordion';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onChoose: (selectedGroupsIds: ISelectedGroupsIds, integration: IIntegrationType) => void;
  selectedGroupsIds: ISelectedGroupsIds;
  onSelectedGroupsIdsChange: (selectedGroupsIds: ISelectedGroupsIds) => void;
  everyoneVsCustom: IEveryoneVsCustom;
  onEveryoneVsCustomChange: (everyoneVcCustom: IEveryoneVsCustom) => void;
  usersCount: number;
  integration: IIntegrationType;
  isContinueButtonLoading?: boolean;
}

export type ISelectedGroupsIds = number[];
export type IEveryoneVsCustom = 'everyone' | 'custom';

function ChooseGroupsModal({
  isOpen,
  onClose,
  onChoose,
  selectedGroupsIds,
  onSelectedGroupsIdsChange,
  everyoneVsCustom,
  onEveryoneVsCustomChange,
  usersCount,
  integration,
  isContinueButtonLoading,
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles({ everyoneVsCustom });
  const { datas } = useSelector(selectOrg);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

  const handleGroupToggleAllOptions = (index: number) => {
    const optionsIds = datas[index].options?.map((o) => o.id);
    // if all optionsIds are in selectedGroupsIds, remove all of them
    if (optionsIds.every((oid) => selectedGroupsIds.includes(oid))) {
      onSelectedGroupsIdsChange(selectedGroupsIds.filter((oid) => !optionsIds.includes(oid)));
    } else {
      onSelectedGroupsIdsChange(uniq([...optionsIds, ...selectedGroupsIds]));
    }
  };

  const handleToggleOption = (id) =>
    selectedGroupsIds.includes(id)
      ? onSelectedGroupsIdsChange(selectedGroupsIds.filter((oid) => oid !== id))
      : onSelectedGroupsIdsChange([...selectedGroupsIds, id]);

  const handleAccordionToggleOpen = (index) => {
    const newIndex = index === openAccordionIndex ? -1 : index;
    setOpenAccordionIndex(newIndex);
  };

  const handleEveryoneCustomSwitch = () =>
    onEveryoneVsCustomChange(everyoneVsCustom === 'everyone' ? 'custom' : 'everyone'); // TODO: check this

  return (
    <Modal
      title={t('share.title')}
      HeaderIcon={ShareIcon}
      isOpen={isOpen}
      className={classes.modal}
      onClose={onClose}
      content={
        <div className={classes.content}>
          <div className={classes.everyoneCustom}>
            <SwitchBetweenTwoChoices
              value={everyoneVsCustom}
              left='everyone'
              right='custom'
              onSwitch={handleEveryoneCustomSwitch}
            />
          </div>
          {everyoneVsCustom === 'custom' &&
            datas.map((data, index) => (
              <GroupAccordion
                key={data.title}
                isOpen={index === openAccordionIndex}
                onToggleOpen={handleAccordionToggleOpen}
                index={index}
                onToggleAllOptions={handleGroupToggleAllOptions}
                selectedGroupsIds={selectedGroupsIds}
                onToggleOption={handleToggleOption}
                isLast={datas.length - 1 === index}
                {...data}
              />
            ))}
          <div className={classes.usersCount}>
            {everyoneVsCustom === 'everyone'
              ? t('share.withUsers', { usersCount: usersCount })
              : t('share.withGroups', { usersCount: selectedGroupsIds.length })}
          </div>
          <PrimaryButton
            className={classes.shareButton}
            onClick={() => onChoose(selectedGroupsIds, integration)}
            disabled={(everyoneVsCustom === 'custom' && selectedGroupsIds.length === 0) || isContinueButtonLoading}
            isLoading={isContinueButtonLoading}
          >
            Continue
          </PrimaryButton>
        </div>
      }
    />
  );
}

const useStyles = makeStyles<Theme, { everyoneVsCustom: 'everyone' | 'custom' }>({
  modal: {
    width: ({ everyoneVsCustom }) => (everyoneVsCustom === 'custom' ? 350 : 280),
  },
  content: {
    paddingBottom: 25,
  },
  everyoneCustom: {
    height: 62,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(77, 77, 77, 0.1)',
  },
  usersCount: {
    color: '#4d4d4d',
    textAlign: 'center',
    borderTop: '1px solid rgba(77, 77, 77, 0.1)',
    padding: '23px 0',
    fontSize: 12,
  },
  shareButton: {
    margin: '0 auto',
  },
});

export default ChooseGroupsModal;
