import filter from '@inovua/reactdatagrid-community/filter';
import { TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { IRdgFilters } from '../../types/reactDataGrid';
interface IArgs {
  searchText: string;
  users: any[];
  columns: TypeColumn[];
  filterValue?: IRdgFilters;
}

const useTableUsers = ({ searchText, users, columns, filterValue }: IArgs) => {
  const [filteredUsers, setFilteredUsers] = useState([]);

  const filterUserBySearch = useCallback(
    (user: any) => {
      const lowerSearchText = searchText.toLowerCase();
      return columns.reduce((acc, col) => {
        const v = String(user[col.name]).toLowerCase();
        return acc || v.indexOf(lowerSearchText) !== -1;
      }, false);
    },
    [columns, searchText],
  );

  const filterUsersBySearch = useCallback(
    (_users: any[]) => {
      if (!searchText) {
        return _users;
      }
      return _users.filter(filterUserBySearch);
    },
    [searchText, filterUserBySearch],
  );

  const debounced = useDebouncedCallback(() => {
    let result = users;
    if (searchText) {
      result = filterUsersBySearch(result);
    }
    if (filterValue) {
      result = filter(result, filterValue) as any[];
    }
    setFilteredUsers(result);
  }, 100);
  useEffect(() => {
    debounced();
  }, [searchText, users, columns, filterValue, debounced]);
  return filteredUsers;
};

export default useTableUsers;
