import { toast } from 'react-toastify';
import { put, takeLatest, select, call } from 'redux-saga/effects';

import { gtmSetUserProps } from '../../services/googleTagManager/track';
import { logError } from '../../services/log';
import { IOrg } from '../../types/org';
import { currentUserSelector } from '../auth/auth.selectors';
import { prefetchMainScreens } from '../auth/auth.slice';
import { fetchOverview } from '../overview/overview.slice';

import * as orgCalls from './org.api';
import {
  fetchOrg,
  updateDefaultOrg,
  fetchOrgError,
  fetchOrgSuccess,
  switchOrg,
  setUsersList,
  fetchTeams,
  selectOrg,
} from './org.slice';

export default function* orgSaga() {
  yield takeLatest(fetchOrg, fetchOrgSaga);
  yield takeLatest(switchOrg, switchOrgSaga);
  yield takeLatest(updateDefaultOrg, updateDefaultOrgSaga);
  yield takeLatest(fetchTeams, fetchTeamsSaga);
}

function* switchOrgSaga(action) {
  const orgId = action.payload;
  const currentUser = yield select(currentUserSelector);
  const orgName = currentUser?.organizations.find((org) => org.id === orgId).name;
  gtmSetUserProps(currentUser?.id, {
    last_org_id: `${orgId}`,
    last_org_name: orgName,
  });
  yield put(fetchOrg(action.payload));
  yield put(fetchOverview());
  yield put(prefetchMainScreens());
}

function* fetchOrgSaga(action) {
  try {
    const { data }: { data: IOrg } = yield orgCalls.getOrgInfo(action.payload);
    yield put(fetchOrgSuccess(data));
  } catch (error) {
    toast.error(error.message);
    yield put(fetchOrgError());
  }
}

function* updateDefaultOrgSaga(action: any) {
  const orgId = action.payload;
  const body = { is_default: true };
  try {
    yield orgCalls.updateUserDefaultOrganization(orgId, body);
  } catch (err) {
    logError(err);
  }
}

function* fetchTeamsSaga(action) {
  const { orgId } = action.payload;
  const { channelsList, usersList } = yield select(selectOrg);
  try {
    if (!usersList && !channelsList) {
      const { data: usersListData } = yield call(orgCalls.fetchOrganiztionTeamUsers, orgId);

      yield put(setUsersList(usersListData));
    }
  } catch (err) {
    logError(err);
  }
}
