import { makeStyles, Tabs } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';

import LinkTab from '../../../components/LinkTab';
import gtmTrack from '../../../services/googleTagManager/track';

interface IProps {
  selectedTabIndex: number;
  onTabChange: (evt: any, tabIndex: number) => void;
}

function QuizScreenTabs({ selectedTabIndex, onTabChange }: IProps) {
  const classes = useStyles();
  const { id: quizId } = useParams<{ id: string }>();

  const onOverviewClick = () => {
    gtmTrack('quizzes_quiz_overview', { quiz_id: quizId });
  };

  const onAnalysisClick = () => {
    gtmTrack('quizzes_quiz_quiz_analysis', { quiz_id: quizId });
  };

  const onUserViewClick = () => {
    gtmTrack('quizzes_quiz_user_view', { quiz_id: quizId });
  };

  return (
    <>
      <div>
        <Tabs
          value={selectedTabIndex}
          indicatorColor='primary'
          textColor='primary'
          onChange={onTabChange}
          classes={{
            root: classes.tabs,
          }}
        >
          <LinkTab
            className={classes.tab}
            label='Overview'
            to={`/quizzes/${quizId}/overview`}
            data-cy='tabOverview'
            onClick={onOverviewClick}
          />
          <LinkTab
            className={classes.tab}
            label='Quiz Analysis'
            to={`/quizzes/${quizId}/quiz-analysis`}
            data-cy='tabAnalysis'
            onClick={onAnalysisClick}
          />
          <LinkTab
            className={classes.tab}
            label='User View'
            to={`/quizzes/${quizId}/user-view`}
            data-cy='tabUserView'
            onClick={onUserViewClick}
          />
        </Tabs>
        <div className={classes.tabBottomBorder} />
      </div>
    </>
  );
}

const useStyles = makeStyles({
  tabs: {
    minHeight: 'auto',
    '& a': { minHeight: 'auto', paddingBottom: 0 },
  },
  tab: {
    textTransform: 'none',
  },
  tabBottomBorder: {
    background: '#EDEDF2',
    height: 2,
    marginTop: -2,
  },
});

export default QuizScreenTabs;
