import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { currentUserSelector } from '../features/auth/auth.selectors';
import { selectOrg } from '../features/org/org.slice';
import { sessionId } from '../features/tracking/tracking.constants';
import { getFullName } from '../utils/auth';

function Intercom() {
  const currentUser = useSelector(currentUserSelector);
  const fullName = getFullName(currentUser);
  const org = useSelector(selectOrg);
  const { update } = useIntercom();

  useEffect(() => {
    const user = {
      user_id: currentUser?.id,
      email: currentUser?.email,
      name: currentUser ? `${fullName} (Dashboard)` : 'Guest (Dashboard)',
      customAttributes: {
        org_id: org.id,
        org_name: org.name,
        // reset data from mobile web app due to intercom bug of carrying over data from last interaction
        title: '',
        url_trying_to_access: '',
        customized_auth: '',
        sessionId,
      },
    };
    update(user);
  }, [currentUser, update, org.id, org.name, fullName]);

  return null;
}

export default Intercom;
