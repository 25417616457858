import filter from '@inovua/reactdatagrid-community/filter';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportToExcel from '../../../../components/ReactDataGrid/ExportToExcel';
import { REACT_DATA_GRID_LICENSE_KEY } from '../../../../constants/constants';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getScrollProps } from '../../../../utils/reactDataGrid';
import { selectQuiz, setQuizAnalysisFilterValue } from '../../quiz.slice';

import useColumns from './useColumns';
import useFooterRows from './useFooterRows';

function Table({ onSelectQuestion }) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [gridRef, setGridRef] = useState(null);
  const { questions, quizAnalysisFilterValue, quizAnalysisSelectedQuestionIndex } = useSelector(selectQuiz);

  const columns = useColumns({ onSelectQuestion });
  const dataSource = useMemo(
    () => filter(questions, quizAnalysisFilterValue),
    [questions, quizAnalysisFilterValue],
  ) as any[];

  const footerRows = useFooterRows({ dataSource });

  return (
    <div>
      {!isMobile && (
        <div className={classes.tableIconContainer}>
          <ExportToExcel className={classes.tableIcon} gridRef={gridRef} dataSource={dataSource} origin='quiz' />
        </div>
      )}
      <ReactDataGrid
        onReady={(ref) => {
          setGridRef(ref);
        }}
        dataSource={dataSource}
        scrollProps={getScrollProps()}
        licenseKey={REACT_DATA_GRID_LICENSE_KEY}
        sortable={false}
        columns={columns}
        style={{ minHeight: window.innerHeight - 200 }}
        enableFiltering
        filterValue={quizAnalysisFilterValue}
        onFilterValueChange={(filterValue) => dispatch(setQuizAnalysisFilterValue(filterValue))}
        footerRows={footerRows}
        // @ts-ignore
        renderRow={({ style, rowIndex }) => {
          if (rowIndex === quizAnalysisSelectedQuestionIndex && !isMobile) {
            style.backgroundColor = 'rgba(77, 77, 77, 0.2)';
          }
        }}
      />
    </div>
  );
}

const useStyles = makeStyles({
  tableIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  tableIcon: {
    width: 40,
    height: 40,
    padding: '9px 8px',
    borderRadius: 11,
    boxShadow: '0 2px 3px 0 rgba(49, 89, 165, 0.46)',
    backgroundColor: 'rgba(239, 243, 249, 0.2)',
    fill: '#282828',
    cursor: 'pointer',
  },
});

export default Table;
