import { isProd } from '../utils/env';

export const RECAPTCHA_SITE_KEY = isProd()
  ? '6LeKBnokAAAAAO1iU5R7414d9BiAXl8i9IsL7MM1'
  : '6LfaMIkkAAAAAMggzPhB7F_NsyFDXwhVVAN7618K';

const tag = document.createElement('script');
tag.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

export default {};
