import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as AnswersIcon } from '../../../assets/icons/answers.svg';
import { ReactComponent as GlassesIcon } from '../../../assets/icons/glasses.svg';
import CreatedAndPreview from '../../../components/CreatedAndPreview';
import Kpi from '../../../components/Kpi';
import { FRONTLINER_URL } from '../../../constants/constants';
import { tokenSelector } from '../../auth/auth.selectors';
import { selectQuiz } from '../quiz.slice';

import Question, { QUESTION_WIDTH_BIG, QUESTION_WIDTH_SMALL } from './Question';

function Overview() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: quizId } = useParams<{ id: string }>();
  const { views, answeredCorrectlyPercentage, questions, createdBy } = useSelector(selectQuiz);
  const token = useSelector(tokenSelector);

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <CreatedAndPreview
          createdBy={createdBy}
          previewLabel='quiz'
          previewLink={`${FRONTLINER_URL}/quiz/${quizId}/?token=${token}`}
        />
      </div>
      <div className={classes.kpis}>
        <Kpi title={t('quiz.views')} Icon={GlassesIcon} iconClassName={classes.glassesIcon} number={views} />
        <Kpi
          title={t('quiz.correctAnswers')}
          Icon={AnswersIcon}
          iconClassName={classes.answersIcon}
          number={`${answeredCorrectlyPercentage}%`}
        />
      </div>
      <div className={classes.separator} />
      <div className={classes.questions}>
        {questions.map((question, index) => (
          <Question key={question.text} {...question} index={index} />
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'scroll',
  },
  details: {
    [theme.breakpoints.up('sm')]: {
      float: 'left',
      width: 200,
      marginInlineEnd: '20px',
    },
  },
  kpis: {
    [theme.breakpoints.up('sm')]: {
      float: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: 32,
    },

    '& > *': {
      [theme.breakpoints.up('md')]: {
        marginBottom: 20,
      },
    },
  },
  glassesIcon: { width: '70%' },
  answersIcon: { width: '50%' },
  separator: {
    width: 1,
    background: '#ccc',
    float: 'left',
    margin: '0 30px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      height: 76 * 2 + 20, // 2 KPIs + bottom margin
    },
    [theme.breakpoints.up('xl')]: {
      height: 165.5 * 2 + 20, // 2 KPIs + bottom margin
    },
  },
  questions: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      gridTemplateColumns: `${QUESTION_WIDTH_SMALL}px ${QUESTION_WIDTH_SMALL}px`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: `${QUESTION_WIDTH_BIG}px ${QUESTION_WIDTH_BIG}px`,
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: `${QUESTION_WIDTH_BIG}px ${QUESTION_WIDTH_BIG}px ${QUESTION_WIDTH_BIG}px`,
    },
    columnGap: 27,
    rowGap: '40px',
  },
}));

export default Overview;
