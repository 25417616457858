import { makeStyles } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import EyeIcon from '@material-ui/icons/Visibility';
import React from 'react';

import gtmTrack from '../services/googleTagManager/track';

import ExternalLink from './ExternalLink';
import MiniTitle from './MiniTitle';

interface IProps {
  createdBy: string;
  previewLink: string;
  previewLabel: string;
}

function CreatedAndPreview({ createdBy, previewLink, previewLabel }: IProps) {
  const classes = useStyles();

  const handlePreviewClick = () => {
    if (previewLabel === 'bite') {
      gtmTrack('bites_bite_preview_bite');
    } else if (previewLabel === 'playlist') {
      gtmTrack('playlists_overview_preview_playlist');
    } else if (previewLabel === 'quiz') {
      gtmTrack('quizzes_quiz_overview_preview_quiz');
    }
  };

  return (
    <div className={classes.createdAndPreview}>
      <div>
        <MiniTitle>Created by</MiniTitle>
        <div className={classes.authorWrap}>
          <div className={classes.author} data-cy='createdBy'>
            <FaceIcon className={classes.authorAvatar} />
            <span className={classes.authorName}>{createdBy}</span>
          </div>
        </div>
      </div>
      <div>
        <MiniTitle>Preview</MiniTitle>
        <ExternalLink className={classes.preview} href={previewLink}>
          <div className={classes.previewDiv} onClick={handlePreviewClick}>
            <EyeIcon className={classes.previewIcon} />
            <span className={classes.previewText}>Preview {previewLabel}</span>
          </div>
        </ExternalLink>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  createdAndPreview: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  authorWrap: {
    display: 'inline-block',
    marginBottom: 32,
  },
  author: {
    paddingRight: 16,
    paddingLeft: 4,
    height: 32,
    background: '#6691FF',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
  },
  authorAvatar: {
    height: 24,
    width: 24,
    marginRight: 8,
  },
  authorName: {
    color: '#fff',
    fontWeight: 500,
  },
  previewDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  preview: {
    height: 32,
    width: 'fit-content',
    borderRadius: 6,
    backgroundColor: '#EBF0FF',
    paddingLeft: 8,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
  },
  previewIcon: {
    width: 24,
    height: 24,
    marginRight: 4,
    color: '#1253FA',
  },
  previewText: {
    paddingTop: 4,
    color: '#1253FA',
  },
}));

export default CreatedAndPreview;
