import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import innerLocalStorage from '../utils/localStorage';

import rootSaga from './root.saga';
import reducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'bites-dashboard-1.0.2',
  whitelist: [],
  storage,
};

//clear old persisted stores
innerLocalStorage.removeItem('persist:bites-dashboard-1.0.1');

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: {
        ignoreActions: true,
      },
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV !== 'production') {
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const newRootReducer = require('./rootReducer').default;
      store.replaceReducer(persistReducer(persistConfig, newRootReducer));
    });
  }
}

sagaMiddleware.run(rootSaga);

export default store;

// @ts-ignore
// expose for cypress E2E tests
window.store = store;
