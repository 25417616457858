import { createAction, createSlice } from '@reduxjs/toolkit';

import { ILoginResponseUser } from '../../store/api/auth/auth.api';
import { ICurrentUser } from '../../types/user';

export const onUser = createAction<ICurrentUser | ILoginResponseUser>('auth/onUser');

type IAuthState = ReturnType<typeof getInitialState>;

function getInitialState() {
  return {
    currentUser: null as null | ICurrentUser,
    token: '',
  };
}

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    initClean: () => {
      // do nothing
    },
    loginSuccess: (state: IAuthState, action) => {
      const { token, user } = action.payload;
      state.currentUser = user;
      state.token = token;
    },
    logout: (state: IAuthState) => {
      state.currentUser = null;
      state.token = '';
    },
    refreshUserSuccess: (state: IAuthState, action) => {
      state.currentUser = action.payload.user;

      if (action.payload.token) {
        state.token = action.payload.token;
      }
    },
    loginFromQueryTokenSuccess: (state: IAuthState, action) => {
      const { user, token } = action.payload;
      state.currentUser = user;
      state.token = token;
    },
    prefetchMainScreens: () => {
      // do nothing
    },
  },
});

export const { initClean, loginSuccess, logout, refreshUserSuccess, loginFromQueryTokenSuccess, prefetchMainScreens } =
  authSlice.actions;

export default authSlice.reducer;
