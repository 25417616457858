import React, { memo, useCallback } from 'react';

import { ReactComponent as BiteStickHat } from '../../../../../../assets/icons/bite-stick-hat.svg';
import { EPagesSingleUser } from '../../SingleUserModel';
import { iconSize, modalColors, S } from '../../ui';

const blueButtomStyle = {
  margin: 'auto',
  marginBottom: '50px',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
  width: '35%',
};

const greenButtonStyle = {
  margin: 'auto',
  marginBottom: '50px',
  backgroundColor: modalColors.validationYesBG,
  color: modalColors.validationYesColor,
  width: '35%',
};

const headerStyle = { color: '#9be91c', textAlign: 'center', marginTop: '30px', fontSize: '24px' };
const buttonWrapperStyle = { marginTop: '100px', display: 'flex', flexDirection: 'column' };
const iconStyle = { width: iconSize + 25, height: iconSize + 25 };

export interface ISuccessSingleUser {
  onClose: (evt: any) => void;
  setPage: (evt: EPagesSingleUser) => void;
}

const SingleUpdateSuccessPage = (props: ISuccessSingleUser) => {
  const { onClose, setPage } = props;

  const handleDoneClick = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleNewUploadClick = useCallback(() => {
    setPage(EPagesSingleUser.SINGLE_UPLOAD);
  }, [setPage]);

  return (
    <S.Wrapper>
      <S.IconWrapper>
        <BiteStickHat style={iconStyle} />
      </S.IconWrapper>
      <S.TextHeader style={headerStyle}>Upload ended successfully</S.TextHeader>
      <S.Content>A new user has been added to your account</S.Content>
      <S.ButtonWrapper style={buttonWrapperStyle}>
        <S.ModelButton style={blueButtomStyle} onClick={handleDoneClick}>
          Done!
        </S.ModelButton>
        <S.ModelButton style={greenButtonStyle} onClick={handleNewUploadClick}>
          A new upload
        </S.ModelButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default memo(SingleUpdateSuccessPage);
