import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import gtmTrack from '../../../services/googleTagManager/track';
import { currentUserSelector } from '../../auth/auth.selectors';
import { selectOrg } from '../../org/org.slice';
import { fetchFilters, selectUserAnalysis, setFilterSelected } from '../userAnalysis.slice';

const getDataFormattedToIdForUsersOrg = (selected) => {
  return selected.map((s) => {
    return { userOrganizationId: s.value, label: s.label };
  });
};

const getDataFormattedToIdForDatas = (selected) => {
  return selected.map((s) => {
    return { id: s.value, label: s.label };
  });
};

interface IProps {
  onFilter: () => void;
}

function FilterBarComponent({ onFilter }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const org = useSelector(selectOrg);
  const { filterAvailabilities, filtersSelected } = useSelector(selectUserAnalysis);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const userOrgData = useMemo(() => {
    return filterAvailabilities?.userOrganizations?.map((c) => {
      return { value: c.userOrganizationId, label: c.userName };
    });
  }, [filterAvailabilities]);

  const handleSelectUser = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForUsersOrg(selected);
      dispatch(setFilterSelected({ userOrganizations: valueToId }));
    },
    [dispatch],
  );
  const handleSelectedData1 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data1: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData2 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data2: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData3 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data3: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData5 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data5: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData6 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data6: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData7 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data7: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData8 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data8: valueToId }));
    },
    [dispatch],
  );

  const handleSelectedData4 = useCallback(
    (selected) => {
      const valueToId = getDataFormattedToIdForDatas(selected);
      dispatch(setFilterSelected({ data4: valueToId }));
    },
    [dispatch],
  );

  const handleDateChange = useCallback(
    (range) => {
      const endDate = new Date(range.selection.endDate);
      endDate.setHours(endDate.getHours() + 23);
      endDate.setMinutes(endDate.getMinutes() + 59);
      endDate.setSeconds(endDate.getSeconds() + 59);

      dispatch(
        setFilterSelected({
          dateRange: {
            start: new Date(range.selection.startDate).setHours(0, 0, 0, 0),
            end: new Date(range.selection.endDate).setHours(23, 59, 59, 59),
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    gtmTrack('filter_changes', {
      bites_user_id: currentUser.id,
      org_id: org.id,
      start_date: filtersSelected.dateRange.start,
      end_date: filtersSelected.dateRange.end,
    });

    dispatch(
      fetchFilters({
        user_organization_ids: filtersSelected.userOrganizations.map((user) => user.userOrganizationId),
        data1_ids: filtersSelected.data1.map((data1) => data1.id),
        data2_ids: filtersSelected.data2.map((data2) => data2.id),
        data3_ids: filtersSelected.data3.map((data3) => data3.id),
        data4_ids: filtersSelected.data4.map((data4) => data4.id),
        data5_ids: filtersSelected.data5.map((data5) => data5.id),
        data6_ids: filtersSelected.data6.map((data6) => data6.id),
        data7_ids: filtersSelected.data7.map((data7) => data7.id),
        data8_ids: filtersSelected.data8.map((data8) => data8.id),
        start_date: filtersSelected.dateRange.start,
        end_date: filtersSelected.dateRange.end,
        organization: org.id,
      }),
    );
    onFilter();
  }, [onFilter, currentUser.id, dispatch, filtersSelected, org.id]);

  const dateRange = useMemo(() => {
    return [
      {
        startDate: new Date(filtersSelected.dateRange.start),
        endDate: new Date(filtersSelected.dateRange.end),
        key: 'selection',
      },
    ];
  }, [filtersSelected.dateRange]);

  const handleOpenCalendar = useCallback(() => {
    setCalendarIsOpen((isOpen) => !isOpen);
  }, []);

  const getDataFormattedToLabelData1 = useMemo(() => {
    return filterAvailabilities?.data1?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data1]);

  const getDataFormattedToLabelData2 = useMemo(() => {
    return filterAvailabilities?.data2?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data2]);

  const getDataFormattedToLabelData3 = useMemo(() => {
    return filterAvailabilities?.data3?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data3]);

  const getDataFormattedToLabelData4 = useMemo(() => {
    return filterAvailabilities?.data4?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data4]);

  const getDataFormattedToLabelData5 = useMemo(() => {
    return filterAvailabilities?.data5?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data5]);

  const getDataFormattedToLabelData6 = useMemo(() => {
    return filterAvailabilities?.data6?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data6]);

  const getDataFormattedToLabelData7 = useMemo(() => {
    return filterAvailabilities?.data7?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data7]);

  const getDataFormattedToLabelData8 = useMemo(() => {
    return filterAvailabilities?.data8?.map((c) => {
      return { value: c.id, label: c.name };
    });
  }, [filterAvailabilities?.data8]);

  return (
    <>
      <S.Container>
        <S.SelectWrapper>
          <S.FilterText>Users List:</S.FilterText>
          <Select options={userOrgData} onChange={handleSelectUser} isMulti />
        </S.SelectWrapper>
        {filterAvailabilities.data1?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[0]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData1} onChange={handleSelectedData1} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data2?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[1]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData2} onChange={handleSelectedData2} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data3?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[2]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData3} onChange={handleSelectedData3} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data4?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[3]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData4} onChange={handleSelectedData4} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data5?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[4]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData5} onChange={handleSelectedData5} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data6?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[5]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData6} onChange={handleSelectedData6} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data7?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[6]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData7} onChange={handleSelectedData7} isMulti />
          </S.SelectWrapper>
        )}
        {filterAvailabilities.data8?.length !== 0 && (
          <S.SelectWrapper>
            <S.FilterText>{org.datas[7]?.title || t('userAnalysis.chooseOption')}</S.FilterText>
            <Select options={getDataFormattedToLabelData8} onChange={handleSelectedData8} isMulti />
          </S.SelectWrapper>
        )}
        <S.SelectWrapper>
          <S.FilterText>Date Range</S.FilterText>
          <S.CalendarBtn onClick={handleOpenCalendar}>
            <S.DateRange>
              {new Date(filtersSelected.dateRange.start).toDateString() +
                ' - ' +
                new Date(filtersSelected.dateRange.end).toDateString()}
            </S.DateRange>
          </S.CalendarBtn>
          {calendarIsOpen && (
            <S.Calendar>
              <DateRangePicker
                ranges={dateRange}
                onChange={handleDateChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
              />
              <S.CloseButton onClick={handleOpenCalendar}>
                <CloseIcon width='10px' height='10px' />
              </S.CloseButton>
            </S.Calendar>
          )}
        </S.SelectWrapper>
      </S.Container>
    </>
  );
}

const S = {
  Container: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  `,
  CloseButton: styled.button`
    border-radius: 15px;
  `,
  PickDateText: styled.div`
    color: #1253fa;
  `,
  DateRange: styled.div`
    color: #828282;
    font-family: 'Gilroy';
  `,
  CalendarBtn: styled.button`
    border: 1px solid #cccccc;
    border-radius: 5px;
    width: 240px;
    height: 39px;
    color: #1253fa;
    background-color: white;
    flex: none;
  `,
  Calendar: styled.div`
    z-index: 99;
    position: fixed;
    border: 1px solid black;
    background-color: white;
    right: 250px;
  `,
  SelectWrapper: styled.div`
    width: 200px;
    margin-right: 10px;
  `,
  FilterText: styled.div``,
};

export default FilterBarComponent;
