import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EPagesSyncUpload } from '../../SyncUploadModal';
import { modalColors, S } from '../../ui';

import ErrorList from './errorListHelper';
import { IValidation } from './uploadCSV';

const headerStyle = { color: '#d14747', textAlign: 'center' };
const modelButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
};

const ValidationError = (props: IValidation) => {
  const { t } = useTranslation();
  const { validationErrorList, setPage } = props;
  const [error, setError] = useState(undefined);
  const [numOfErrors, setNumOfErrorsr] = useState('');

  useEffect(() => {
    setError(validationErrorList);
    setNumOfErrorsr(validationErrorList.length);
  }, [validationErrorList]);

  const handleErrorsClick = useCallback(() => {
    setPage(EPagesSyncUpload.UPLOAD_CSV);
  }, [setPage]);

  return (
    <S.Wrapper style={{ height: `${window.innerHeight - 500}px`, overflow: 'auto' }}>
      <S.TextHeader style={headerStyle}>{t('userManagement.pages.thereWereSomeErrors')}</S.TextHeader>
      <S.Content>{t('userManagement.pages.validationIssues', { numOfErrors })}</S.Content>
      <S.ErrorWrapper></S.ErrorWrapper>
      {error ? <ErrorList error={error} /> : <></>}
      <S.Controllers style={{ marginTop: '10px' }}>
        <S.ModelButton style={modelButtonStyle} onClick={handleErrorsClick}>
          {t('userManagement.pages.aNewUpload')}
        </S.ModelButton>
      </S.Controllers>
    </S.Wrapper>
  );
};

export default memo(ValidationError);
