import { formatDistance } from 'date-fns';

export const formatDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }
  const [yyyy, mm, dd] = dateString.split('T')[0].split('-');
  return [mm, dd, yyyy].join('/');
};

export const formatDateToRelativeTime = (dateString: string): string =>
  formatDistance(new Date(dateString), new Date(), {
    addSuffix: true,
  });
