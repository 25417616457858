import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import { ReactComponent as MultpileChoicesIcon } from '../../../../assets/icons/multiple-choices.svg';
import { ReactComponent as OpenEndedIcon } from '../../../../assets/icons/open-ended.svg';
import { ReactComponent as SurveyIcon } from '../../../../assets/icons/survey.svg';
import StyledPercentage from '../../../../components/StyledPercentage';
import useIsMobile from '../../../../hooks/useIsMobile';

interface IArgs {
  onSelectQuestion: (rowIndex: number) => void;
}

const useColumns = ({ onSelectQuestion }: IArgs): TypeColumns => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  return useMemo(() => {
    const QuestionColumn = ({ rowIndex, data }) => {
      return (
        <div
          data-cy='questionColumn'
          onClick={() => onSelectQuestion(rowIndex)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          {!isMobile && <div>{`Question ${rowIndex + 1}`}</div>}
          {isMobile && <div>{`${rowIndex + 1}`}</div>}
          {data.type === 'multiple choices' && <MultpileChoicesIcon className={classes.questionIcon} />}
          {data.type === 'survey' && <SurveyIcon className={classes.questionIcon} />}
          {data.type === 'open ended' && <OpenEndedIcon className={classes.questionIcon} />}
        </div>
      );
    };
    const StyledPercentageColumn = ({ value }) => <StyledPercentage percentage={value} />;
    const AnsweredCorrectlyPercentage = ({ data, value }) =>
      data.type === 'multiple choices' ? <StyledPercentage percentage={value} /> : null;

    return [
      {
        name: 'question',
        header: 'Question',
        width: isMobile ? 75 : 130,
        render: QuestionColumn,
      },
      { name: 'views', header: 'VIEWS', width: 80 },
      {
        name: 'completedPercentage',
        width: 90,
        header: 'Completed',
        render: StyledPercentageColumn,
      },
      {
        name: 'answeredCorrectlyPercentage',
        width: 82,
        header: 'Success',
        render: AnsweredCorrectlyPercentage,
      },
    ];
  }, [isMobile, onSelectQuestion, classes.questionIcon]);
};

const useStyles = makeStyles({
  questionIcon: {
    height: 20,
    width: 11,
    fill: '#4d4d4d',
  },
});

export default useColumns;
