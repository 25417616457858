import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { MAIN_SCREEN_HEADER_HEIGHT, MAIN_SCREEN_PADDING_BOTTOM, TOPBAR_HEIGHT } from '../constants/design.constant';

import Card from './Card';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

function MainScreenCard({ children, className = '', ...restOfProps }: IProps) {
  const classes = useStyles();
  return (
    <Card className={cx(className, classes.root)} {...restOfProps}>
      {children}
    </Card>
  );
}

const useStyles = makeStyles({
  root: {
    minHeight: `calc(100vh - ${TOPBAR_HEIGHT + MAIN_SCREEN_HEADER_HEIGHT + MAIN_SCREEN_PADDING_BOTTOM}px)`,
    padding: 24,
    flexGrow: 1,
    height: '100%',
  },
});

export default MainScreenCard;
