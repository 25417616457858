import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as BiteIcon } from '../../assets/icons/images-bite-main.svg';
import { setIsNoContentOverlayDismissed } from '../../features/appActivity/appActivity.slice';
import { ContentType } from '../../features/userAnalysis/components/Table/Table';
import Button from '../Buttons/Button';

interface IProps {
  withoutContent: boolean;
  contentType: ContentType;
}

function NoStatsModal({ withoutContent, contentType }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);

  const isPreview = query.get('isPreview');

  const biteIconStyle = useMemo(() => {
    return { position: 'absolute', top: '48px', left: '100px' };
  }, []);

  const createBiteButton = useCallback(() => {
    window.open('https://deep.mybites.io/deep/create-bite');
  }, []);

  const handleDismiss = useCallback(() => {
    dispatch(setIsNoContentOverlayDismissed(true));
  }, [dispatch]);

  return (
    <S.Overlay>
      <S.Content>
        <S.Header>
          <BiteIcon style={biteIconStyle as React.CSSProperties} />
          <S.HeaderText>
            {t('share.noStatsModal.headerText', { type: contentType === ContentType.BITE ? 'Bite' : 'Playlist' })}
          </S.HeaderText>
        </S.Header>
        <S.TitleText>
          {t('share.noStatsModal.start', { type: contentType === ContentType.BITE ? 'Bite' : 'Playlist' })}
        </S.TitleText>
        <S.Text>
          <em>{t('share.noStatsModal.total')}</em>
        </S.Text>
        <S.Buttons>
          <S.DismissButton onClick={handleDismiss}>{t('common.dismiss')}</S.DismissButton>
          {!isPreview && withoutContent && (
            <S.Button inline={true} onClick={createBiteButton}>
              <S.ButtonText>
                {t('share.noStatsModal.create', { type: contentType === ContentType.BITE ? 'Bite' : 'Playlist' })}
              </S.ButtonText>
            </S.Button>
          )}
        </S.Buttons>
      </S.Content>
    </S.Overlay>
  );
}

const S = {
  Header: styled.div`
    background: linear-gradient(#664ff9, #1253fa);
    width: ${window.innerWidth < 960 ? '100%' : '80%'}px;
    height: 250px;
  `,
  Content: styled.div`
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
  HeaderText: styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: ${window.innerWidth < 960 ? '20px' : '26px'};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    padding-top: ${window.innerWidth < 960 ? '150' : '125'}px;
    text-shadow: 1px 0 10px black;
    ${window.innerWidth < 960 ? 'line-height: 20px;' : ''}
    margin-left: 20px;
    margin-right: 20px;
  `,
  TitleText: styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${window.innerWidth < 960 ? '16px' : '22px'};
    padding-top: 45px;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
  `,
  Text: styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${window.innerWidth < 960 ? '14px' : '20px'};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    padding-top: 20px;
    ${window.innerWidth < 960 ? 'line-height: 20px;' : ''}
    padding-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  `,
  Overlay: styled.div<{ isSideBarOpen: boolean }>`
    z-index: 1;
    position: absolute;
    top: 0px;
    left: ${window.innerWidth < 960 ? '0' : '250px'};
    right: 0px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  `,
  Button: styled(Button)``,
  ButtonText: styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    font-size: 18px;
  `,
  Buttons: styled.div`
    justify-content: center;
    flex-direction: row;
    display: flex;
  `,
  DismissButton: styled.button`
    padding: 8px 0px;
    width: 159px;
    background-color: white;
    color: #1253fa;
    border-radius: 30px;
    background-color: #1253fa17;
    border-color: #1253fa;
    font-weight: bold;
    font-size: 18px;
    margin-right: 12px;
    cursor: pointer;
  `,
};

export default NoStatsModal;
