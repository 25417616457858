// eslint-disable-next-line import/named
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGeolocation, IInitialState } from './appActivity.types';

const APP_ACTIVITY = 'APP_ACTIVITY';

export const initialState: IInitialState = {
  translationsAreInitialized: false,
  geolocation: null,
  isNoContentOverlayDismissed: false,
};

export const runInit = createAction(`${APP_ACTIVITY}/runInit`);
export const requestGeolocation = createAction(`${APP_ACTIVITY}/requestGeolocation`);

const appActivitySlice = createSlice({
  name: APP_ACTIVITY,
  initialState,
  reducers: {
    setTranslationsAreInitialized: (state) => {
      state.translationsAreInitialized = true;
    },
    setGeolocation: (state, { payload }: PayloadAction<IGeolocation>) => {
      state.geolocation = payload;
    },
    setIsNoContentOverlayDismissed: (state, { payload }: PayloadAction<boolean>) => {
      state.isNoContentOverlayDismissed = payload;
    },
  },
});

export const { setTranslationsAreInitialized, setGeolocation, setIsNoContentOverlayDismissed } =
  appActivitySlice.actions;

export default appActivitySlice.reducer;
