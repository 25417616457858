import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import CorrectIcon from '../../../../components/Icons/CorrectIcon';
import WrongIcon from '../../../../components/Icons/WrongIcon';
import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import { IQuizQuestion } from '../../../../types/quiz';
import { formatDateToRelativeTime } from '../../../../utils/dates';
import { percentage } from '../../../../utils/numbers';
import { selectQuiz } from '../../quiz.slice';

const useRawColumns = (): TypeColumns => {
  const { questions } = useSelector(selectQuiz);
  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const Completed = ({ value, data }) => {
      const correctAnswer = 'Yes';

      if (data.__group) {
        const count = data.array.filter((item) => item.completed === correctAnswer).length;
        const total = data.array.length;
        return <PercentsAndNumbers count={count} total={total} />;
      }
      return value === correctAnswer ? <CorrectIcon /> : <WrongIcon />;
    };
    const AnsweredCorrectly = ({
      value,
      data,
    }: {
      value: number;
      data: { array: IQuizQuestion[]; __group: true; scorableQuestionsCount: number };
    }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.answeredCorrectly));
        const total = sumBy(data.array, 'scorableQuestionsCount');
        return <PercentsAndNumbers count={count} total={total} />;
      }
      return <PercentsAndNumbers count={value} total={data.scorableQuestionsCount} />;
    };
    const Answered = ({ value, data }: { value: number; data: { array: IQuizQuestion[]; __group: true } }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.answered));
        const total = data.array.length * questions.length;
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${questions.length}`;
    };

    return [
      {
        name: 'email',
        maxWidth: 100,
        header: 'Email',
        render: EllipsisColumn,
      },
      {
        name: 'phone',
        maxWidth: 100,
        header: 'Phone',
        render: EllipsisColumn,
      },
      {
        name: 'completed',
        header: 'Completed',
        maxWidth: 100,
        render: Completed,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: 'Yes', label: 'Yes' },
            { id: 'No', label: 'No' },
          ],
        },
      },
      {
        name: 'answeredCorrectly',
        header: 'Success',
        maxWidth: 130,
        render: AnsweredCorrectly,
      },
      {
        name: 'answered',
        header: 'Answers',
        maxWidth: 100,
        render: Answered,
      },
      {
        name: 'lastVisit',
        maxWidth: 100,
        header: 'Last View Date',
        render: ({ value }) => {
          return value ? formatDateToRelativeTime(value) : '';
        },
      },
      {
        name: 'employeeId',
        maxWidth: 100,
        header: 'Employee Id',
        render: EllipsisColumn,
      },
    ];
  }, [questions.length]);
};

export default useRawColumns;
