import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumn, TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import useIsMobile from '../../../../hooks/useIsMobile';
import { formatDate, formatDateToRelativeTime } from '../../../../utils/dates';
import { selectOrg } from '../../../org/org.slice';
import { selectUserManagement } from '../../userManagement.slice';

interface IArgs {
  onSelectUser: (id: number) => void;
}

const useColumns = ({ onSelectUser }: IArgs): TypeColumns => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { datas } = useSelector(selectOrg);
  const { columnsVisibility } = useSelector(selectUserManagement);

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={typeof value === 'string' ? value : ''} />;
    const Name = ({ data, value }) => (
      <EllipsisWithTooltip
        style={{ cursor: 'pointer' }}
        onClick={() => onSelectUser(data.id)}
        text={value || '-'}
        data-cy='nameColumn'
      />
    );
    // conditional is needed for backward compability
    const RolesColumn = ({ value }) => <EllipsisWithTooltip text={value?.join(', ')} />;

    let result: TypeColumns = [
      { name: 'fullName', header: t('userManagement.columns.name'), render: Name, defaultLocked: true },
    ];

    if (!isMobile) {
      datas.forEach((d) => {
        result.push({
          name: d.dataFieldName,
          // eslint-disable-next-line react/display-name
          render: ({ value }) => (
            <EllipsisWithTooltip data-cy={`${d.dataFieldName}Column`} text={typeof value === 'string' ? value : ''} />
          ),
          header: d.title.toUpperCase(),
          renderGroupTitle: (value, { data }) => `${value} (${data.array.length})`,
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            dataSource: d.options.map((option) => option.name).map((x) => ({ id: x, label: x })),
          },
        });
      });
    }

    const usernameColumn: TypeColumn = {
      name: 'username',
      header: t('userManagement.columns.username'),
      render: EllipsisColumn,
    };
    if (isMobile) {
      // @ts-ignore authors of the package wrongfully assigned this as read only
      usernameColumn.defaultFlex = 1;
    }
    result.push(usernameColumn);

    result = result.concat([
      { name: 'email', header: t('userManagement.columns.email'), render: EllipsisColumn },
      { name: 'phone', header: t('userManagement.columns.phone'), render: EllipsisColumn },
      { name: 'personalId', header: t('userManagement.columns.id'), render: EllipsisColumn },
      { name: 'employeeId', header: t('userManagement.columns.employeeId'), render: EllipsisColumn },
      {
        name: 'roles',
        header: t('userManagement.columns.roles'),
        render: RolesColumn,
      },
      {
        name: 'showStats',
        header: t('userManagement.columns.includeInStatistics'),
        render: ({ value, data }) => {
          if (data.__group) {
            return '';
          }
          return value ? t('common.yes') : t('common.no');
        },
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: true, label: t('common.yes') },
            { id: false, label: t('common.no') },
          ],
        },
      },
      {
        name: 'active',
        header: t('userManagement.columns.active'),
        render: ({ value, data }) => {
          if (data.__group) {
            return '';
          }
          return value ? t('common.yes') : t('common.no');
        },
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: true, label: t('common.yes') },
            { id: false, label: t('common.no') },
          ],
        },
      },
      {
        name: 'createdAt',
        header: t('userManagement.columns.created'),
        render: ({ value }) => formatDate(value),
      },
      {
        name: 'lastVisit',
        maxWidth: 100,
        header: t('userManagement.columns.lastVisit'),
        render: ({ value }) => {
          return value ? formatDateToRelativeTime(value) : '';
        },
      },
    ]);

    return result.map((c) => ({ ...c, visible: columnsVisibility[c.name] }));
  }, [isMobile, t, onSelectUser, datas, columnsVisibility]);
};

export default useColumns;
