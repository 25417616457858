import React from 'react';

import { percentage } from '../utils/numbers';

import StyledPercentage from './StyledPercentage';

interface IProps {
  count: number;
  total: number;
}

function PercentsAndNumbers({ count, total }: IProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {count}/{total} <StyledPercentage percentage={percentage(count, total)} />
    </div>
  );
}

export default PercentsAndNumbers;
