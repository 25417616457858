import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

interface IProps {
  value: string;
  className?: string;
  onChange: (text: string) => void;
  style?: Record<string, any>;
}

function TextInput({ className = '', style = {}, onChange, value, ...restOfProps }: IProps) {
  const classes = useStyles();

  return (
    <input
      style={style}
      value={value}
      className={cx(classes.inputRoot, className)}
      onChange={(evt) => onChange(evt.target.value)}
      {...restOfProps}
    />
  );
}

const useStyles = makeStyles({
  inputRoot: {
    display: 'block',
    width: '100%',
    height: 20,
    background: '#fff',
    border: 'solid 0.5px #999999',
    borderRadius: 5,
    padding: 5,
    outline: 'none',
  },
});

export default TextInput;
