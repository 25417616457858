import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from '../../../../components/Buttons/Button';
import { log } from '../../../tracking/tracking.slice';
import useAuthError from '../../hooks/useAuthError';
import PhoneInputWithIcon from '../components/PhoneInput';
import Header from '../Header';

interface IProps {
  onContinue: () => void;
  isLoading: boolean;
  onChange: (number: string) => void;
  phone: string;
  errorCodes: string[];
}

const PhoneNumberForm: FC<IProps> = ({ onContinue, isLoading, onChange, phone, errorCodes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneError, setPhoneError] = useState(null);

  const requestErrors = useAuthError();

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (!isPhoneValid) {
      setPhoneError(t('authForm.errorInvalidNumber'));
      return;
    }

    dispatch(
      log({
        event: 'PhoneNumberForm.handleContinue',
        data: {
          errorCodes,
          phone,
        },
      }),
    );

    onContinue();
  }, [isLoading, isPhoneValid, dispatch, errorCodes, phone, onContinue, t]);

  const handlePhoneNumberChange = useCallback(
    (number: string) => {
      setPhoneError(null);
      onChange(number);
    },
    [onChange],
  );

  return (
    <S.Container>
      <Header title={t('authForm.phoneNumberForm.title')} />
      <S.Form>
        <PhoneInputWithIcon
          value={phone}
          onChange={handlePhoneNumberChange}
          style={S.PhoneInputWithIconStyles}
          label={t('authForm.phoneNumberForm.phoneNumber')}
          error={phoneError || requestErrors}
          onValidationChange={setIsPhoneValid}
          onEnterPress={handleContinue}
        />
        <S.ContinueButton isLoading={isLoading} onClick={handleContinue}>
          {t('common.Continue')}
        </S.ContinueButton>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  Form: styled.div`
    margin-top: 32px;
    align-items: center;
    margin-bottom: 50px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
  `,
  PhoneInputWithIconStyles: {
    marginBottom: 18,
    width: 298,
  },
};

export default memo(PhoneNumberForm);
