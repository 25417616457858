import { all, put, select, takeLatest } from 'redux-saga/effects';

import { IBiteAnswersTableUser, IBiteViewsTableUser } from '../../types/biteTableUser';
import { IComment } from '../../types/comment';
import { selectOrg, switchOrg } from '../org/org.slice';

import * as biteCalls from './bite.api';
import {
  fetchAnswerAnalysis,
  fetchAnswerAnalysisError,
  fetchAnswerAnalysisSuccess,
  fetchBiteOverview,
  fetchBiteOverviewError,
  fetchBiteOverviewSuccess,
  fetchBiteViews,
  fetchBiteViewsError,
  fetchBiteViewsSuccess,
  fetchComments,
  fetchCommentsError,
  fetchCommentsSuccess,
  fetchDeleteComment,
  fetchDeleteCommentSuccess,
  fetchDeleteCommentError,
} from './bite.slice';

export default function* biteSaga() {
  yield all([
    takeLatest(fetchBiteOverview, fetchBiteOverviewSaga),
    takeLatest(fetchAnswerAnalysis, fetchAnswerAnalysisSaga),
    takeLatest(fetchComments, fetchCommentsSaga),
    takeLatest(fetchBiteViews, fetchBiteViewsSaga),
    takeLatest(fetchDeleteComment, fetchDeleteCommentSaga),
  ]);
}

function* fetchBiteOverviewSaga(action) {
  try {
    const biteId = action.payload;
    const { data } = yield biteCalls.getBite(biteId);
    const { id: orgId } = yield select(selectOrg);
    if (orgId !== data.organization) {
      yield put(switchOrg(data.organization));
    }
    yield put(fetchBiteOverviewSuccess(data));
  } catch (error) {
    yield put(fetchBiteOverviewError(error.message));
  }
}

function* fetchCommentsSaga(action) {
  const biteId = action.payload;
  try {
    const { data }: { data: IComment[] } = yield biteCalls.getComments(biteId);
    yield put(fetchCommentsSuccess(data));
  } catch (error) {
    yield put(fetchCommentsError(error.message));
  }
}

function* fetchAnswerAnalysisSaga(action) {
  try {
    const { biteId } = action.payload;
    const { data }: { data: IBiteAnswersTableUser[] } = yield biteCalls.getAnswerAnalysis(biteId);
    yield put(fetchAnswerAnalysisSuccess(data));
  } catch (error) {
    yield put(fetchAnswerAnalysisError(error.message));
  }
}

function* fetchBiteViewsSaga(action) {
  try {
    const { biteId } = action.payload;
    const { data }: { data: IBiteViewsTableUser[] } = yield biteCalls.getBiteViewsByUser(biteId);
    yield put(fetchBiteViewsSuccess(data));
  } catch (error) {
    yield put(fetchBiteViewsError(error.message));
  }
}

function* fetchDeleteCommentSaga(action) {
  const { commentId } = action.payload;
  try {
    yield biteCalls.deleteComment({ commentId });
    yield put(fetchDeleteCommentSuccess(commentId));
  } catch (err) {
    yield put(fetchDeleteCommentError(commentId));
  }
}
