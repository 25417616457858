import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

function Card({ children, className = '', ...restOfProps }: IProps) {
  const classes = useStyles();
  return (
    <div className={cx(className, classes.root)} {...restOfProps}>
      {children}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 12px 0 rgba(18,83,250,0.11), 0 1px 2px 0 rgba(175,196,250,0.08)',
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default Card;
