import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataGridSearchableTable from '../../../../components/ReactDataGrid/SearchableTable';
import { selectBite, setAnswerAnalysisByUserSortInfo } from '../../bite.slice';

import useColumns from './useColumns';
import useDataSource from './useDataSource';
import useFooterRows from './useFooterRows';

interface IProps {
  groupedByDataIndex: number;
}

function OverviewTable({ groupedByDataIndex }: IProps) {
  const dispatch = useDispatch();
  const { answerAnalysisByUserSortInfo } = useSelector(selectBite);
  const columns = useColumns({ groupedByDataIndex });
  const dataSource = useDataSource({ groupedByDataIndex });
  const footerRows = useFooterRows({ dataSource });

  const handleSort = useCallback(
    (value) => {
      const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
      dispatch(setAnswerAnalysisByUserSortInfo(newSortInfo));
    },
    [dispatch],
  );

  return (
    <ReactDataGridSearchableTable
      onSortInfoChange={handleSort}
      sortInfo={answerAnalysisByUserSortInfo}
      rawData={dataSource}
      columns={columns}
      style={{ minHeight: window.innerHeight - 200 }}
      footerRows={footerRows}
      origin='bite_answer_analysis'
    />
  );
}

export default OverviewTable;
