import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Features,
  SCHEDULE_DISTRIBUTION_DELAY_24_HOURS,
  SCHEDULE_DISTRIBUTION_DELAY_HOURS,
  SCHEDULE_DISTRIBUTION_DELAY_48_HOURS,
} from '../../../constants/constants';
import { selectOrg } from '../../../features/org/org.slice';
import { EIntegrations, IIntegrationType } from '../../../types/integration';

type TUseDistributionReminderDealyParams = {
  integration: IIntegrationType;
};

export const useDistributionReminderDealy = ({ integration }: TUseDistributionReminderDealyParams) => {
  const { t } = useTranslation();
  const org = useSelector(selectOrg);

  const delayValues = useMemo(() => {
    let delay: number =
      integration === EIntegrations.Email ? SCHEDULE_DISTRIBUTION_DELAY_24_HOURS : SCHEDULE_DISTRIBUTION_DELAY_HOURS;
    if (org?.features?.includes(Features['24H_REMINDER'])) {
      delay = SCHEDULE_DISTRIBUTION_DELAY_24_HOURS;
    } else if (org?.features?.includes(Features.REMAINDER_DELAY_48)) {
      delay = SCHEDULE_DISTRIBUTION_DELAY_48_HOURS;
    }

    let delayTextKey = '2 ½';
    switch (delay) {
      case SCHEDULE_DISTRIBUTION_DELAY_48_HOURS:
        delayTextKey = '48';
        break;
      case SCHEDULE_DISTRIBUTION_DELAY_24_HOURS:
        delayTextKey = '24';
        break;
    }

    return { delay, delayTextKey };
  }, [integration, org?.features]);

  const text = t('share.noStatsModal.sendReminder', { delayText: delayValues.delayTextKey });

  return { delay: delayValues.delay, text };
};
