import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorIcon } from '../../../../../../assets/icons/error.svg';
import { S, iconSize } from '../../ui';

import { IDetails, IFields } from './uploadCSV';

const iconErrorStyle = {
  width: iconSize,
  height: iconSize,
  backgroundColor: 'white',
  borderRadius: '20px',
};
const iconWrapperStyle = { padding: '15px' };
const errorTextStyle = { fontWeight: 'bold', fontSize: '15px' };
const extraErrorsTextStyle = { lineHeight: '50px', marginTop: 'auto' };

interface IErrorItem {
  item: IFields;
  index: number;
}

const ErrorItem = (props: IErrorItem) => {
  const { t } = useTranslation();
  const { index, item } = props;
  return (
    <S.ErrorItemWrapper>
      <S.ErrorUserText>{t('common.errorItem', { index: index + 1, field: item.field })}</S.ErrorUserText>
      <S.ErrorUserText>{item.message}</S.ErrorUserText>
    </S.ErrorItemWrapper>
  );
};

interface IErrorItemList {
  items: IFields[];
}

const ErrorItemList = (props: IErrorItemList) => {
  const { items } = props;
  const itemsList = items.map((item, index) => {
    return (
      <S.ItemsWrapper key={index}>
        <ErrorItem item={item} index={index} />
      </S.ItemsWrapper>
    );
  });
  return <>{itemsList}</>;
};

const ErrorList = (props: { error: IDetails[] }) => {
  const { error } = props;

  // Limit to 1000 errors in the list
  const maxErrorsToShow = 1000;
  const limitedErrors = error.slice(0, maxErrorsToShow);
  const extraErrorsCount = error.length - maxErrorsToShow;

  const errorList = limitedErrors.map((user, index) => {
    const { dataValidationErrors } = user;

    return (
      <S.ErrorWrapper key={index}>
        <S.IconWrapper style={iconWrapperStyle}>
          <ErrorIcon style={iconErrorStyle} />
        </S.IconWrapper>
        <S.ErrorMsgWrapper>
          <S.ErrorUserText style={errorTextStyle}>Error! (row {dataValidationErrors[0].rowIndex})</S.ErrorUserText>
          <ErrorItemList items={dataValidationErrors} />
        </S.ErrorMsgWrapper>
      </S.ErrorWrapper>
    );
  });

  return (
    <S.BoxErrorWrapper>
      {errorList}
      {extraErrorsCount > 0 && (
        <S.ErrorWrapper>
          <S.ErrorUserText style={{ ...errorTextStyle, ...extraErrorsTextStyle }}>
            And {extraErrorsCount} more validation errors...
          </S.ErrorUserText>
        </S.ErrorWrapper>
      )}
    </S.BoxErrorWrapper>
  );
};

export default memo(ErrorList);
