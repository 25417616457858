import { Checkbox, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/named
import { Theme } from '@material-ui/core/styles';
import React, { useCallback, memo, useMemo } from 'react';

import EllipsisWithTooltip from '../../EllipsisWithTooltip';

const OPTION_HEIGHT = 29;

function ChannelOrUserItem({ selectedIds, isOpen, name, index, onToggleIsOpen, onToggleOption, isLast, id }) {
  const classes = useStyles({ isOpen, isLast });

  const toggleIsOpen = useCallback(() => {
    onToggleIsOpen(index);
  }, [index, onToggleIsOpen]);

  const toggleOption = useCallback(() => {
    onToggleOption(id);
  }, [id, onToggleOption]);

  const isChecked = useMemo(() => {
    return selectedIds?.includes(id);
  }, [selectedIds, id]);

  return (
    <div className={classes.header}>
      <div className={classes.titleAndArrow} onClick={toggleIsOpen}>
        <div className={classes.title}>
          <EllipsisWithTooltip text={name} />
        </div>
      </div>
      <Checkbox color='primary' onChange={toggleOption} checked={isChecked} />
    </div>
  );
}

const useStyles = makeStyles<Theme, { isLast: boolean; isOpen: boolean }>({
  header: {
    borderBottom: ({ isLast }) => (isLast ? 'none' : 'solid 3px #e8eef4'),
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 46,
    paddingRight: 16,
  },
  titleAndArrow: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 150px',
    marginInlineEnd: '10px',
  },
  title: {
    fontSize: 16,
    color: '#333',
    marginRight: 10,
    maxWidth: 210,
  },
  arrow: {
    borderBottom: '1px solid #000',
    borderRight: '1px solid #000',
    width: 6,
    height: 6,
    transform: ({ isOpen }) => (isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'),
  },
  options: {
    background: '#fff',
    display: ({ isOpen }) => (isOpen ? 'block' : 'none'),
    padding: '0 26px',
    maxHeight: 280,
  },
  option: {
    fontSize: 14,
    color: '#656464',
    height: OPTION_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
  },
});

export default memo(ChannelOrUserItem);
