import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/Card';
import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import MainScreen from '../../components/MainScreen';
import NoStatsModal from '../../components/Share/NoStatsModal';
import { isNoContentOverlayDismissedSelector } from '../appActivity/appActivity.selectors';
import { ContentType } from '../userAnalysis/components/Table/Table';

import { fetchOverview, selectOverview } from './overview.slice';

function OverviewScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isNoContentOverlayDismissed = useSelector(isNoContentOverlayDismissedSelector);

  const { isFetching, totalBites, totalPlaylists, totalUsers, totalBitesViews, error } = useSelector(selectOverview);

  useEffect(() => {
    dispatch(fetchOverview());
  }, [dispatch]);

  return (
    <MainScreen headline={t('mainScreen.headline')} className={classes.root}>
      {totalBitesViews === 0 && !isNoContentOverlayDismissed && (
        <NoStatsModal withoutContent={true} contentType={ContentType.BITE} />
      )}
      {isFetching ? (
        <div className={classes.loaderWrap}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <Card className={classes.card}>
              <div className={classes.title}>{t('mainScreen.bites')}</div>
              <div className={classes.data} data-cy='bites'>
                {totalBites.toLocaleString()}
              </div>
            </Card>
            <Card className={classes.card}>
              <div className={classes.title}>{t('mainScreen.users')}</div>
              <div className={classes.data} data-cy='users'>
                {totalUsers.toLocaleString()}
              </div>
            </Card>
            <Card className={classes.card}>
              <div className={classes.title}>{t('mainScreen.playlists')}</div>
              <div className={classes.data} data-cy='playlists'>
                {totalPlaylists.toLocaleString()}
              </div>
            </Card>
            <Card className={classes.card}>
              <div className={classes.title}>{t('mainScreen.views')}</div>
              <div className={classes.data} data-cy='biteViews'>
                {totalBitesViews.toLocaleString()}
              </div>
            </Card>
          </div>
        </>
      )}
      {error && (
        <ErrorMsg data-cy='errorMsg' className={classes.error} style={{ marginTop: 0 }}>
          {t('mainScreen.error', { error })}
        </ErrorMsg>
      )}
    </MainScreen>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      boxShadow: 'none',
    },
  },
  loaderWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    backgroundColor: '#EDEDF2',
  },
  content: {
    backgroundColor: '#EDEDF2',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 20,
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      height: 180,
    },
    [theme.breakpoints.up('md')]: {
      flex: 1,
      height: 304,
      marginRight: 24,
    },
  },
  title: {
    marginTop: 16,
    marginLeft: 16,
    fontSize: 14,
  },
  data: {
    height: '100%',
    fontSize: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1253FA',
    fontFamily: 'Uni Sans SemiBold',
  },
  error: {
    background: '#EDEDF2',
  },
}));

export default OverviewScreen;
