import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { percentage } from '../../../../utils/numbers';
import { sort } from '../../../../utils/reactDataGrid';
import { selectQuiz } from '../../quiz.slice';

interface IArgs {
  groupedByDataIndex: number;
}

const useDataSource = ({ groupedByDataIndex }: IArgs) => {
  const { quizViewsByUser, questions, quizViewsByUserSortInfo } = useSelector(selectQuiz);

  return useMemo(() => {
    const result = quizViewsByUser
      .map((user) => ({
        ...user,
        completed: user.answered >= questions.length ? 'Yes' : 'No',
        scorableQuestionsCount: questions.slice(0, user.answered).filter((q) => q.type === 'multiple choices').length,
      }))
      .reduce((acc, user) => {
        const dataGroupName = user.datas[groupedByDataIndex] || 'Empty';
        const existingGroup = acc.find((item) => item.name === dataGroupName);
        if (!existingGroup) {
          acc.push({
            name: dataGroupName,
            completed: user.completed,
            answeredCorrectly: Number(user.answeredCorrectly),
            scorableQuestionsCount: user.scorableQuestionsCount,
            answered: Number(user.answered),
            recordsCount: 1,
          });
        } else {
          existingGroup.completed += Number(user.completed);
          existingGroup.answeredCorrectly += Number(user.answeredCorrectly);
          existingGroup.scorableQuestionsCount += user.scorableQuestionsCount;
          existingGroup.answered += Number(user.answered);
          existingGroup.recordsCount += 1;
        }
        return acc;
      }, [])
      .map((item) => ({
        ...item,
        completedPercentage: percentage(item.completed, item.recordsCount),
        answeredCorrectlyPercentage: percentage(Number(item.answeredCorrectly), item.scorableQuestionsCount),
        answeredPercentage: percentage(Number(item.answered), item.recordsCount * questions.length),
      }));
    return sort(result, quizViewsByUserSortInfo);
  }, [quizViewsByUser, groupedByDataIndex, quizViewsByUserSortInfo, questions]);
};

export default useDataSource;
