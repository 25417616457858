import { useEffect } from 'react';
import { useLocation } from 'react-router';

interface IArgs {
  selectedTabIndex: number;
  setSelectedTabIndex: (tabIndex: number) => void;
}

const useSetTabByUrl = ({ selectedTabIndex, setSelectedTabIndex }: IArgs): void => {
  const location = useLocation();

  useEffect(() => {
    const tab = location.pathname.split('/')[3];
    const tabIndex = ['overview', 'views', 'answer-analysis', 'discussion'].indexOf(tab);
    if (tabIndex !== selectedTabIndex) {
      setSelectedTabIndex(tabIndex >= 0 ? tabIndex : 0);
    }
  }, [location.pathname, selectedTabIndex, setSelectedTabIndex]);
};

export default useSetTabByUrl;
