import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as AppleIcon } from '../../../assets/icons/apple-icon.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google-colorfull.svg';
import { ReactComponent as MicrosoftIcon } from '../../../assets/icons/microsoft.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { setCurrentForm } from '../../authForm/authForm.slice';
import { log } from '../../tracking/tracking.slice';
import { EAuthFormType } from '../auth.types';
import useGoogleLogin from '../hooks/useGoogleLogin';
import useMicrosoftLogin from '../hooks/useMicrosoftLogin';

import AppleSocialButton from './components/AppleSocialButton';
import SocialButton from './SocialButton';

const SocialButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loginWithMicrosoft = useMicrosoftLogin();
  const loginWithGoogle = useGoogleLogin();

  const handleSingInWithPhoneNumber = useCallback(() => {
    dispatch(
      log({
        event: 'SocialButtons.handlePhoneClick',
        data: {
          destination: EAuthFormType.PHONE_NUMBER,
        },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.PHONE_NUMBER));
  }, [dispatch]);

  return (
    <S.SocialButtons>
      <S.SocialButton
        onClick={handleSingInWithPhoneNumber}
        icon={<PhoneIcon width={24} height={24} />}
        title={t('authForm.signInForm.withPhone')}
      />
      <S.SocialButton
        onClick={loginWithMicrosoft}
        icon={<MicrosoftIcon width={24} height={24} />}
        title={t('authForm.signInForm.withMicrosoft')}
      />
      <S.SocialButton
        onClick={loginWithGoogle}
        icon={<GoogleIcon width={24} height={24} />}
        title={t('authForm.signInForm.withGoogle')}
      />
      <S.AppleSocialButton
        icon={<AppleIcon width={24} height={24} fill={'black'} />}
        title={t('authForm.signInForm.withApple')}
      />
    </S.SocialButtons>
  );
};

const S = {
  SocialButtons: styled.div`
    min-width: 298px;
    margin-top: 24px;
  `,
  SocialButton: styled(SocialButton)`
    min-width: 295px;
  `,
  AppleSocialButton: styled(AppleSocialButton)`
    min-width: 295px;
  `,

  SocialButtonContainerStyles: { marginBottom: 12 },
};

export default memo(SocialButtons);
