import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import Button from '../../../components/Buttons/Button';
import { getIsEmailValid } from '../../../utils/auth';
import { authInProgressSelector, emailFormSelector, passwordFormSelector } from '../../authForm/authForm.selectors';
import { loginWithEmail, setCurrentForm, setEmail, setPassword, resetErrors } from '../../authForm/authForm.slice';
import { log } from '../../tracking/tracking.slice';
import { EAuthFormType } from '../auth.types';

import FormTextInput from './FormTextInput';

const EmailForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const email = useSelector(emailFormSelector);
  const password = useSelector(passwordFormSelector);
  const authInProgress = useSelector(authInProgressSelector);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const handleChangeEmail = useCallback(
    (text) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetErrors());
    },
    [dispatch],
  );

  const handleChangePassword = useCallback(
    (text) => {
      dispatch(setPassword(text));
      setPasswordError(null);
      dispatch(resetErrors());
    },
    [dispatch],
  );

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleOtp',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_EMAIL },
        }),
      );
      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_EMAIL));
    },
    [dispatch],
  );

  const handleEmailSignInSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleEmailSignInSubmit',
          processId,
          data: {
            username: email.trim(),
            password,
          },
        }),
      );

      dispatch(resetErrors());

      dispatch(
        loginWithEmail({
          username: email.trim(),
          password,
          processId,
          onOtp: handleOtp,
        }),
      );
    },
    [dispatch, email, password, handleOtp],
  );

  const handleContinuePress = useCallback(() => {
    if (authInProgress) {
      return;
    }

    const isEmailValid = getIsEmailValid(email);

    const processId = uuid();
    dispatch(
      log({
        event: 'EmailForm.handleContinuePress',
        processId,
        data: {
          email,
          isEmailValid,
        },
      }),
    );

    if (!isEmailValid) {
      setEmailError(t('authForm.emailIsNotValid'));
    }

    if (!password || !isEmailValid) {
      return;
    }

    handleEmailSignInSubmit(processId);
  }, [authInProgress, dispatch, email, handleEmailSignInSubmit, password, t]);

  const handleForgotPasswordClick = useCallback(() => {
    dispatch(
      log({
        event: 'EmailForm.handleForgotPasswordClick',
        data: { destination: EAuthFormType.FORGOT_PASSWORD_EMAIL },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_EMAIL));
  }, [dispatch]);

  return (
    <S.Container>
      <S.TextInput
        style={S.TextInputContainerStyles}
        value={email}
        onChange={handleChangeEmail}
        label={t('authForm.email')}
        onEnterPress={handleContinuePress}
        error={emailError}
      />
      <S.TextInput
        style={S.TextInputContainerStyles}
        value={password}
        onChange={handleChangePassword}
        label={t('authForm.password')}
        onEnterPress={handleContinuePress}
        error={passwordError}
        password
      />
      <S.ContinueButton isLoading={authInProgress} onClick={handleContinuePress}>
        {t('common.Continue')}
      </S.ContinueButton>
      <S.ForgotPassword onClick={handleForgotPasswordClick}>
        <S.ForgotPasswordText>{t('authForm.forgotPassword')}</S.ForgotPasswordText>
      </S.ForgotPassword>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    @keyframes slideInFromLeft {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
  `,
  TextInput: styled(FormTextInput)`
    display: flex;
    margin-bottom: 18px;
  `,
  ContinueButton: styled(Button)`
    min-height: 50px;
    min-width: 298px;
  `,
  ForgotPassword: styled.div`
    text-align: center;
    margin-top: 24px;
  `,
  ForgotPasswordText: styled.span`
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  TextInputContainerStyles: { marginBottom: 18 },
};

export default memo(EmailForm);
