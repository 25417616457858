import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

interface IProps {
  isOpen: boolean;
  message: string;
  onUndo: () => void;
  onClose: () => void;
}

function UndoSnackbar({ isOpen, message, onUndo, onClose }: IProps) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={
        <>
          <Button color='secondary' size='small' onClick={onUndo}>
            UNDO
          </Button>
          {/* @ts-ignore */}
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      }
    />
  );
}

export default UndoSnackbar;
