import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import CreatedAndPreview from '../../../components/CreatedAndPreview';
import Loader from '../../../components/Loader';
import { FRONTLINER_URL } from '../../../constants/constants';
import { tokenSelector } from '../../auth/auth.selectors';
import { selectBite } from '../bite.slice';

import CommentsColumn from './CommentsColumn';
import Kpis from './Kpis';
import QuestionColumn from './QuestionColumn';

function Overview() {
  const classes = useStyles();
  const { subject, createdBy, isFetchingOverview, bitePreview, isFetchingBiteViews } = useSelector(selectBite);
  const token = useSelector(tokenSelector);

  if (isFetchingOverview || isFetchingBiteViews) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <div className={classes.title}>{subject}</div>
        <CreatedAndPreview
          createdBy={createdBy}
          previewLabel='bite'
          previewLink={`${FRONTLINER_URL}/bites/${bitePreview}/?token=${token}`}
        />
      </div>
      <div className={classes.kpis}>
        <Kpis />
      </div>
      <div className={classes.column}>
        <QuestionColumn columnHeaderClassName={classes.columnHeader} columnPaddingClassName={classes.columnPadding} />
      </div>
      <div className={classes.column}>
        <CommentsColumn columnHeaderClassName={classes.columnHeader} columnPaddingClassName={classes.columnPadding} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  details: {
    [theme.breakpoints.up('sm')]: {
      width: 170,
      marginInlineEnd: '20px',
      float: 'left',
    },
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 21,
      fontSize: 16,
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: 44,
      fontSize: 22,
    },
  },
  column: {
    border: '1px solid #b3b3b3',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0',
      borderRadius: 6,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      float: 'left',
      height: 354,
      borderRadius: 6,
      width: 202,
      marginInlineEnd: '18px',
    },
    [theme.breakpoints.up('xl')]: {
      float: 'left',
      height: 683.5,
      borderRadius: 11,
      width: 316,
      marginInlineEnd: '34px',
    },
  },
  kpis: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'start',
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
    [theme.breakpoints.up('sm')]: {
      float: 'left',
      flexDirection: 'column',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      height: 354,
      marginInlineEnd: '18px',
    },
    [theme.breakpoints.up('xl')]: {
      height: 683.5,
      marginInlineEnd: '34px',
    },
  },
  columnPadding: {
    padding: '0 16px',
  },
  columnHeader: {
    background: '#e9edf5',
    lineHeight: '30px',
    [theme.breakpoints.down('lg')]: {
      fontSize: 11,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 17,
    },
  },
}));

export default Overview;
