import ReactDataGrid from '@inovua/reactdatagrid-enterprise';

import { IOrgData } from '../types/org';
import { IRdgSortInfo } from '../types/reactDataGrid';

export const initFilterValue =
  (reducerKey: string) =>
  (state: any, datas: IOrgData[]): void => {
    datas.forEach((d) => {
      // if filter exists, reset it (in case of logout and back in)
      const filterIndex = state[reducerKey].findIndex((f) => f.name === d.dataFieldName);
      if (filterIndex !== -1) {
        state[reducerKey][filterIndex].value = null;
      } else {
        // if filter doesn't exist, initialize it
        state[reducerKey].push({
          name: d.dataFieldName,
          operator: 'inlist',
          type: 'select',
          value: null,
        });
      }
    });
  };

export const initColumnVisibility =
  (reducerKey: string, datasIndexToShow?: number) =>
  (state: any, datas: IOrgData[]): void => {
    datas.forEach((d, index) => {
      state[reducerKey][d.dataFieldName] = index <= datasIndexToShow;
    });
  };

export const initGrouping =
  (reducerKey: string) =>
  (state: any, datas: IOrgData[]): void => {
    const result = [];
    if (datas.some((d) => d.dataFieldName === 'data1')) {
      result.push('data1');
    }
    if (datas.some((d) => d.dataFieldName === 'data2')) {
      result.push('data2');
    }
    state[reducerKey] = result;
  };

interface IFilter {
  name: string;
  operator: string;
  type: string;
  value: string;
}

export const mapStringFilter = (key: string): IFilter => ({
  name: key,
  operator: 'contains',
  type: 'string',
  value: '',
});

export const mapNumberFilter = (key: string): IFilter => ({
  name: key,
  operator: 'gte',
  type: 'number',
  value: '',
});

export const mapSelectFilter = (key: string): IFilter => ({
  name: key,
  operator: 'eq',
  type: 'select',
  value: null,
});

export const sort = (arr: any[], sortInfo: IRdgSortInfo): any[] => {
  arr = [].concat(arr);
  if (!sortInfo) {
    return arr;
  }
  return arr.sort((o1, o2) => {
    const v1 = o1[sortInfo.name];
    const v2 = o2[sortInfo.name];

    const result = sortInfo.type === 'number' ? v1 - v2 : v1.localeCompare(v2);

    return result * sortInfo.dir;
  });
};

export const getScrollProps = () => {
  return Object.assign({}, ReactDataGrid.defaultProps.scrollProps, {
    autoHide: false,
  });
};
