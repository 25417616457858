import { makeStyles, Hidden } from '@material-ui/core';
import cx from 'classnames';
import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { ReactComponent as NextArrow } from '../assets/icons/lightbox-next.svg';
import { ReactComponent as PrevArrow } from '../assets/icons/lightbox-prev.svg';

interface IProps {
  imgSrc?: string;
  title?: React.ReactNode;
  caption?: string;
  isOpen: boolean;
  onPrev: () => void;
  onNext: () => void;
  onClose: () => void;
}

function Lightbox({ imgSrc, title, caption, isOpen, onPrev, onNext, onClose }: IProps) {
  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = ({ keyCode }) => {
      if (keyCode === 27) {
        onClose();
      }
      if (keyCode === 39) {
        onNext();
      }
      if (keyCode === 37) {
        onPrev();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onNext, onPrev]);

  const customStyles = {
    overlay: {
      zIndex: 10000,
      inset: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    content: {
      width: '100%',
      height: '100%',
      border: 'none',
      inset: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: 0,
      background: 'rgba(0, 0, 0, .8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className={classes.outerContainer} onClick={onClose}>
        <Hidden smUp>
          <div className={classes.closeContainer}>
            <CloseIcon className={classes.closeIcon} />
          </div>
        </Hidden>
        <div className={classes.container} onClick={(evt) => evt.stopPropagation()}>
          <Hidden only='xs'>
            <PrevArrow
              className={cx(classes.arrow, classes.leftArrow)}
              onClick={(evt) => {
                evt.stopPropagation();
                onPrev();
              }}
            />
          </Hidden>
          {title && <div className={classes.title}>{title}</div>}
          {imgSrc && (
            <div className={classes.imgContainer}>
              <Hidden smUp>
                <PrevArrow
                  className={cx(classes.arrow, classes.leftArrow)}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onPrev();
                  }}
                />
              </Hidden>
              <img className={classes.img} src={imgSrc} alt='foo' />
              <Hidden smUp>
                <NextArrow
                  className={cx(classes.arrow, classes.rightArrow)}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onNext();
                  }}
                />
              </Hidden>
            </div>
          )}
          {caption && <div className={classes.caption}>{caption}</div>}
        </div>
        <Hidden only='xs'>
          <NextArrow
            className={cx(classes.arrow, classes.rightArrow)}
            onClick={(evt) => {
              evt.stopPropagation();
              onNext();
            }}
          />
        </Hidden>
      </div>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    position: 'absolute',
    right: 7,
    top: 7,
    width: 25,
    height: 25,
    border: '1px solid #fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  closeIcon: {
    width: 15,
    height: 15,
    fill: '#fff',
  },
  arrow: {
    width: 28,
    height: 74,
    position: 'absolute',
    top: '50%',
    marginTop: -74 / 2,
    cursor: 'pointer',
  },
  leftArrow: {
    [theme.breakpoints.up('sm')]: {
      left: '9%',
    },
    [theme.breakpoints.down('xs')]: {
      left: 10,
    },
  },
  rightArrow: {
    [theme.breakpoints.up('sm')]: {
      right: '9%',
    },
    [theme.breakpoints.down('xs')]: {
      right: 10,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#ededed',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'calc(100% - 80px)',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
      width: '60%',
      height: '83%',
    },
  },
  title: {
    padding: '12px 25px',
  },
  imgContainer: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'contain',
    width: 'auto',
    maxWidth: '100%',
  },
  caption: {
    padding: 25,
  },
}));

export default Lightbox;
