import { useCallback } from 'react';

import { IBiteViewsTableUser } from '../types/biteTableUser';
import { IPlaylistViewsTableUser } from '../types/playlistTableUser';
import { IQuizViewsTableUser } from '../types/quizTableUser';

const filterOutGuestsFromViewsTableUser = <
  T extends IPlaylistViewsTableUser | IBiteViewsTableUser | IQuizViewsTableUser,
>(
  viewsTableUser: T[],
): T[] => {
  return viewsTableUser.filter((user) => user.id !== 1);
};

export const useFilterOutGuestsFromViewsTableUser = () => {
  return useCallback(filterOutGuestsFromViewsTableUser, []);
};
