import { IHasId } from '../types/types';

export const mergeLeanAndFullCollections = <T extends IHasId, S extends IHasId>(
  leanCollection: T[],
  fullCollection: S[],
): ((T & S) | T)[] => {
  return leanCollection.map((leanItem) => {
    const matchingFullItem = fullCollection.find((fullItem) => fullItem.id === leanItem.id) || {};
    return { ...matchingFullItem, ...leanItem };
  });
};

export const mergeMapWithArrayById = (map, array) => {
  const newMap = array.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  return { ...map, ...newMap };
};
