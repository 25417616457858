import sumBy from 'lodash/sumBy';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import { IFullPlaylist } from '../../../../types/playlist';
import { selectPlaylist } from '../../playlist.slice';

const useMakeFooterRows = () => {
  const { totalQuestions } = useSelector(selectPlaylist);

  return useCallback(
    (data) => {
      const FooterName = (users) => `${users.length} users`;

      const FooterBites = (bites: IFullPlaylist[]) => {
        const count = sumBy(bites, (bite) => Number(bite.watched));
        const total = sumBy(bites, 'total');

        return <PercentsAndNumbers count={count} total={total} />;
      };

      const FooterAnswered = (bites: IFullPlaylist[]) => {
        const count = sumBy(bites, (bite) => Number(bite.answered));
        const total = bites.length * totalQuestions;

        return <PercentsAndNumbers count={count} total={total} />;
      };

      const FooterAnsweredCorrectly = (bites: IFullPlaylist[]) => {
        const count = sumBy(bites, (bite) => Number(bite.answeredCorrectly));
        const total = sumBy(bites, (bite) => Number(bite.answered));

        return <PercentsAndNumbers count={count} total={total} />;
      };

      return [
        {
          render: {
            name: () => FooterName(data),
            watched: () => FooterBites(data),
            answered: () => FooterAnswered(data),
            answeredCorrectly: () => FooterAnsweredCorrectly(data),
          },
        },
      ];
    },
    [totalQuestions],
  );
};

export default useMakeFooterRows;
