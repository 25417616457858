// eslint-disable-next-line import/named
import { DefaultTheme } from 'styled-components';

export const colors = {
  white: '#fff',
  black: 'rgb(0,0,0)',
  black2: '0a0a0a0a',
  transparentBlack: 'rgba(0, 0, 0, 0.3)',
  transparentWhite: 'rgba(255, 255, 255, 0.7)',
  primaryBlue: '#1253fa',
  darkerPrimaryBlue: '#1154FA',
  darkerPrimaryBlue2: '#001CF5',
  blue2: 'lightblue',
  blue3: '#004FFF',
  darkPrimary: '#344356',
  darkShadow: 'rgba(60,128,209,0.09)',
  lightBackground: 'rgba(243,245,249,0.5)',
  darkBlueBg: '#000925',
  defaultGrayBg: '#F4F0F5',
  darkGray1: '#304358',
  darkGray2: '#5C6977',
  darkGray3: '#AEAEB2',
  darkGray4: '#5B6977',
  darkGray5: '#666666',
  darkGray6: '#808080',
  darkGray7: '#909194',
  darkGray8: '#919191',
  lightGray1: '#E6E6E6',
  lightGray2: '#AEB4BC',
  lightGray3: '#F3F5F9',
  lightGray4: '#3D4B5D',
  lightGray5: '#C3C3C3',
  lightGray6: '#94979A',
  lightGray7: '#E8EEF4',
  lightGray8: '#858E99',
  lightGray9: '#CACACA',
  lightGray10: '#596775',
  lightGray11: '#C8C7CC',
  lightGray12: '#D8D8D8',
  lightGray13: '#6d6d6d',
  lightGray14: '#f2f4f8',
  lightGray15: '#f1f2f6',
  lightGray16: '#f2f0f5',
  lightGray17: '#e4e4e4',
  lightGray18: '#6c6c6c',
  buttonBlue: '#325aa4',
  warning: '#CC3300',
  lightText: '#999999',
  lightRed: '#F34357',
  lightblue1: '#E7EEF5',
  lightPurple: '#6A4DFF',
  green1: '#00BC7B',
  successGreen: '#32b777',
  moderateOrange: '#FFA73E',
  failRed: '#B53333',
  red1: '#FF284E',
  lightGreen1: 'rgba(0,188,123,0.04)',
  lightRed1: 'rgba(255,34,73,0.05)',

  text: '#313235',
  gray19: '#707175',
  pinkError: '#FE00C1',
  lightGray34: '#d9d9d9',
  lightGray19: '#E8E8E8',
};
export const msColors = {
  primaryTextcolor: '#252423',
  lightTextColor: '#484644',
  lightTextColor2: '#605e5C',
  lightTextColor3: '#fcfcfc',
  lightBgColor: '#FAF9F8',
  lightBorderColor: '#EDEBE9',
  lightBorderColor2: '#E1DFDD',
  darkGray: '#333',
  purple: '#6264A7',
  transparentBlack: 'rgba(0, 0, 0, 0.2)',
  hoverColor: 'rgb(16, 110, 190)',
};

export const fontFamilies = {
  GilroyBold: 'Gilroy Bold',
  GilroyMedium: 'Gilroy',
  UniSans: 'UniSans',
  Arimo: 'Arimo-Regular',
  LucidaGrande: 'LucidaGrande',
  SFProRegular: 'SFProText-Regular',
  SFProSemibold: 'SFProText-Semibold',
  TTNormsMedium: 'TTNorms-Medium',
  SegoeUISemilight: 'Segoe-UI-Semilight',
};

export const deviceSizes = {
  tablet: 768,
  mobileL: 550,
  mobileM: 375,
  mobileS: 320,
};

export const breakPoints = {
  tablet: `(max-width: ${deviceSizes.tablet}px)`,
  mobileL: `(max-width: ${deviceSizes.mobileL}px)`,
  mobileM: `(max-width: ${deviceSizes.mobileM}px)`,
  mobileS: `(max-width: ${deviceSizes.mobileS}px)`,
};

const defaultTheme: DefaultTheme = {
  colors,
  msColors,
  fontFamilies,
  breakPoints,
};

export default defaultTheme;
