import { createSlice, createAction } from '@reduxjs/toolkit';

import { IConfig } from '../../services/remoteConfig/remoteConfig.types';
import { IAction } from '../../types/types';

import { IInitialState } from './featureFlag.types';

export const initialState: IInitialState = {
  isInitialized: false,
  flags: {},
};

const FEATURE_FLAG = 'FEATURE_FLAG';

const featureFlagSlice = createSlice({
  name: FEATURE_FLAG,
  initialState,
  reducers: {
    setIsInitialized: (state, { payload }: IAction<boolean>) => {
      state.isInitialized = payload;
    },
    setFlags: (state, { payload }: IAction<IConfig>) => {
      state.flags = payload;
    },
  },
});

export const { setIsInitialized, setFlags } = featureFlagSlice.actions;
export const initFeatureFlags = createAction(`${FEATURE_FLAG}/initFeatureFlags`);

export default featureFlagSlice.reducer;
