import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AnswerDistribution from '../../../components/AnswerDistribution/AnswerDistribution';
import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';
import Lightbox from '../../../components/Lightbox';
import MediaViewer from '../../../components/MediaViewer/MediaViewer';
import { formatDate } from '../../../utils/dates';
import { selectQuiz } from '../quiz.slice';

function QuestionAndAnswers() {
  const [answersLightboxIndex, setAnswersLightboxIndex] = useState(0);
  const [isAnswersLightboxOpen, setIsAnswersLightboxOpen] = useState(false);
  const classes = useStyles();
  const { questions, quizAnalysisSelectedQuestionIndex } = useSelector(selectQuiz);
  const { text, answers, type, media } = questions[quizAnalysisSelectedQuestionIndex];
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.counter}>
          {quizAnalysisSelectedQuestionIndex + 1}/{questions.length}
        </div>
        <div className={classes.text} data-cy='questionText'>
          {text}
        </div>
        {media && (
          <div className={classes.mediaViewerContainer}>
            <MediaViewer {...media} />
          </div>
        )}
      </div>
      {type !== 'open ended' && (
        <>
          <div className={classes.separator} />
          <div className={classes.container}>
            <div className={classes.answerDistributionContainer}>
              <AnswerDistribution answers={answers} />
            </div>
          </div>
        </>
      )}
      {type === 'open ended' && (
        <div className={classes.openEndedTable}>
          {answers.map((answer, index) => (
            <div key={index} className={classes.openEndedTableRow}>
              <div className={classes.openEndedTableNameCell}>
                <EllipsisWithTooltip text={answer.name} />
              </div>
              <div className={classes.openEndedTableTextCell}>
                <EllipsisWithTooltip text={answer.text} />
              </div>
              <div className={classes.openEndedTableImageCell}>
                {answer.media && (
                  <img
                    src={answer.media?.imageUrl}
                    alt={answer.text}
                    className={classes.openEndedTableImage}
                    onClick={() => {
                      setAnswersLightboxIndex(index);
                      setIsAnswersLightboxOpen(true);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {answers[answersLightboxIndex] && (
        <Lightbox
          isOpen={isAnswersLightboxOpen}
          imgSrc={answers[answersLightboxIndex].media?.imageUrl}
          onNext={() => {
            const nextIndex = answersLightboxIndex === answers.length - 1 ? 0 : answersLightboxIndex + 1;
            setAnswersLightboxIndex(nextIndex);
          }}
          onPrev={() => {
            const nextIndex = answersLightboxIndex === 0 ? answers.length - 1 : answersLightboxIndex - 1;
            setAnswersLightboxIndex(nextIndex);
          }}
          onClose={() => setIsAnswersLightboxOpen(false)}
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>{answers[answersLightboxIndex].name}</div>
              <div>{formatDate(answers[answersLightboxIndex].createdAt)}</div>
            </div>
          }
          caption={answers[answersLightboxIndex].text}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    borderRadius: 11,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.47)',
    border: 'solid 1px #cccccc',
    background: '#fff',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      width: 418,
    },
    [theme.breakpoints.down('lg')]: {
      width: 340,
    },
  },
  container: {
    width: 268,
    margin: '0 auto',
  },
  counter: {
    marginTop: 11,
    fontSize: 18,
    color: '#1253fa',
    marginBottom: 37,
    textAlign: 'center',
  },
  text: {
    lineHeight: 1.2,
    color: '#4f5c6d',
    marginBottom: 33,
    [theme.breakpoints.up('xl')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
  },
  mediaViewerContainer: {
    height: 155,
    marginBottom: 32,
  },
  separator: {
    height: 1,
    background: '#b3b3b3',
    marginBottom: 40,
  },
  answerDistributionContainer: {
    marginBottom: 40,
  },
  openEndedTable: {
    maxHeight: 284,
    color: '#6a7784',
    overflowY: 'scroll',
    gap: '1px',
    borderTop: '1px solid #999',
    [theme.breakpoints.up('xl')]: {
      fontSize: 17,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
  },
  openEndedTableRow: {
    display: 'flex',
    borderBottom: '1px solid #999',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      height: 46,
    },
    [theme.breakpoints.down('lg')]: {
      height: 33,
    },
  },
  openEndedTableNameCell: {
    fontWeight: 'bold',
    padding: '0 8px',
    width: '33.33%',
    borderRight: '1px solid #999',
  },
  openEndedTableTextCell: {
    padding: '0 8px',
    width: '33.33%',
    minWidth: 0,
    borderRight: '1px solid #999',
  },
  openEndedTableImageCell: {
    width: '33.33%',
  },
  openEndedTableImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer',
  },
}));

export default QuestionAndAnswers;
