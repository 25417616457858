import { Button, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';
import ShareButtonAndManager from '../../../components/Share/ShareButtonAndManager';
import { useShareLink } from '../../../components/Share/useShareLink';
import { useFilterOutGuestsFromViewsTableUser } from '../../../utils/useUsers';
import { ContentType } from '../../userAnalysis/components/Table/Table';
import { selectQuiz } from '../quiz.slice';

import QuizSelectionDialog from './QuizSelectionDialog';

function QuizScreenTop() {
  const classes = useStyles();
  const [isQuizSelectOpen, setIsQuizSelectOpen] = useState(false);
  const { name, id, quizViewsByUser, coverUrl } = useSelector(selectQuiz);
  const shareablePath = `quiz/${id}`;

  // need to be in useCallback to keep memoization in QuizSelectionTable
  const handleCloseQuizSelectionDialog = useCallback(() => setIsQuizSelectOpen(false), []);

  const { handleCopyLink, onShareSuccessfulyToast } = useShareLink({
    contentType: ContentType.QUIZ,
    shareablePath,
    subject: name,
  });

  const filterOutGuestsFromViewsTableUser = useFilterOutGuestsFromViewsTableUser();

  return (
    <div className={classes.top}>
      <div className={classes.textContainer}>
        <label>QUIZ</label>
        <Button
          variant='outlined'
          onClick={() => setIsQuizSelectOpen(true)}
          title='Click to select another quiz'
          className={classes.button}
        >
          <EllipsisWithTooltip text={name} />
          <ArrowDropDownIcon className={classes.buttonIcon} />
        </Button>
        <QuizSelectionDialog isOpen={isQuizSelectOpen} onClose={handleCloseQuizSelectionDialog} />
      </div>
      <div className={classes.shareButton}>
        <ShareButtonAndManager
          contentId={id}
          subject={name}
          type='quiz'
          shareablePath={shareablePath}
          handleCopyLink={handleCopyLink}
          onShareSuccessfuly={onShareSuccessfulyToast}
          usersCount={filterOutGuestsFromViewsTableUser(quizViewsByUser).length}
          shareableCoverUrl={coverUrl}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  top: {
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    '& label': {
      color: '#666666',
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 4,
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    height: 40,
    fontFamily: 'Gilroy',
    textAlign: 'left',
    display: 'block',
    textTransform: 'none',
    position: 'relative',
  },
  buttonIcon: {
    color: '#0000008a',
    position: 'absolute',
    right: 8,
    top: 7,
  },
  shareButton: {
    paddingTop: 18.5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default QuizScreenTop;
