import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';

import QuizScreenContent from './components/QuizScreenContent';
import QuizScreenTabs from './components/QuizScreenTabs';
import QuizScreenTop from './components/QuizScreenTop';
import useFetchData from './hooks/useFetchData';
import useSetTabByUrl from './hooks/useSetTabByUrl';
import { selectQuiz } from './quiz.slice';

function Quiz() {
  const { isFetchingOverview, overviewError } = useSelector(selectQuiz);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (event: any, value: number): void => {
    setSelectedTabIndex(value);
  };

  useFetchData();
  useSetTabByUrl({ selectedTabIndex, setSelectedTabIndex });

  if (isFetchingOverview) {
    return <Loader />;
  }

  if (overviewError) {
    return <ErrorMsg>Error loading quiz: {overviewError}</ErrorMsg>;
  }

  return (
    <div>
      <QuizScreenTop />
      <QuizScreenTabs selectedTabIndex={selectedTabIndex} onTabChange={handleTabChange} />
      <QuizScreenContent />
    </div>
  );
}

export default Quiz;
