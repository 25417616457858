export default [
  {
    name: 'Afghanistan',
    dialCode: '93',
    isoCode: 'AF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/af.svg',
    flagRect: 'https://www.countryflags.io/AF/flat/64.png',
    iso2: 'af',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Albania (Shqipëri)',
    dialCode: '355',
    isoCode: 'AL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/al.svg',
    flagRect: 'https://www.countryflags.io/AL/flat/64.png',
    iso2: 'al',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    dialCode: '213',
    isoCode: 'DZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/dz.svg',
    flagRect: 'https://www.countryflags.io/DZ/flat/64.png',
    iso2: 'dz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'American Samoa',
    dialCode: '1684',
    isoCode: 'AS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/as.svg',
    flagRect: 'https://www.countryflags.io/AS/flat/64.png',
    iso2: 'as',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Andorra',
    dialCode: '376',
    isoCode: 'AD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ad.svg',
    flagRect: 'https://www.countryflags.io/AD/flat/64.png',
    iso2: 'ad',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Angola',
    dialCode: '244',
    isoCode: 'AO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ao.svg',
    flagRect: 'https://www.countryflags.io/AO/flat/64.png',
    iso2: 'ao',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Anguilla',
    dialCode: '1264',
    isoCode: 'AI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ai.svg',
    flagRect: 'https://www.countryflags.io/AI/flat/64.png',
    iso2: 'ai',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '1268',
    isoCode: 'AG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ag.svg',
    flagRect: 'https://www.countryflags.io/AG/flat/64.png',
    iso2: 'ag',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Argentina',
    dialCode: '54',
    isoCode: 'AR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ar.svg',
    flagRect: 'https://www.countryflags.io/AR/flat/64.png',
    iso2: 'ar',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Armenia (Հայաստան)',
    dialCode: '374',
    isoCode: 'AM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/am.svg',
    flagRect: 'https://www.countryflags.io/AM/flat/64.png',
    iso2: 'am',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Aruba',
    dialCode: '297',
    isoCode: 'AW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/aw.svg',
    flagRect: 'https://www.countryflags.io/AW/flat/64.png',
    iso2: 'aw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Australia',
    dialCode: '61',
    isoCode: 'AU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/au.svg',
    flagRect: 'https://www.countryflags.io/AU/flat/64.png',
    iso2: 'au',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Austria (Österreich)',
    dialCode: '43',
    isoCode: 'AT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/at.svg',
    flagRect: 'https://www.countryflags.io/AT/flat/64.png',
    iso2: 'at',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    dialCode: '994',
    isoCode: 'AZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/az.svg',
    flagRect: 'https://www.countryflags.io/AZ/flat/64.png',
    iso2: 'az',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bahamas',
    dialCode: '1242',
    isoCode: 'BS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bs.svg',
    flagRect: 'https://www.countryflags.io/BS/flat/64.png',
    iso2: 'bs',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    dialCode: '973',
    isoCode: 'BH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bh.svg',
    flagRect: 'https://www.countryflags.io/BH/flat/64.png',
    iso2: 'bh',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    dialCode: '880',
    isoCode: 'BD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bd.svg',
    flagRect: 'https://www.countryflags.io/BD/flat/64.png',
    iso2: 'bd',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Barbados',
    dialCode: '1246',
    isoCode: 'BB',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bb.svg',
    flagRect: 'https://www.countryflags.io/BB/flat/64.png',
    iso2: 'bb',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belarus (Беларусь)',
    dialCode: '375',
    isoCode: 'BY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/by.svg',
    flagRect: 'https://www.countryflags.io/BY/flat/64.png',
    iso2: 'by',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belgium (België)',
    dialCode: '32',
    isoCode: 'BE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/be.svg',
    flagRect: 'https://www.countryflags.io/BE/flat/64.png',
    iso2: 'be',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Belize',
    dialCode: '501',
    isoCode: 'BZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bz.svg',
    flagRect: 'https://www.countryflags.io/BZ/flat/64.png',
    iso2: 'bz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Benin (Bénin)',
    dialCode: '229',
    isoCode: 'BJ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bj.svg',
    flagRect: 'https://www.countryflags.io/BJ/flat/64.png',
    iso2: 'bj',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bermuda',
    dialCode: '1441',
    isoCode: 'BM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bm.svg',
    flagRect: 'https://www.countryflags.io/BM/flat/64.png',
    iso2: 'bm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bhutan (འབྲུག)',
    dialCode: '975',
    isoCode: 'BT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bt.svg',
    flagRect: 'https://www.countryflags.io/BT/flat/64.png',
    iso2: 'bt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bolivia',
    dialCode: '591',
    isoCode: 'BO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bo.svg',
    flagRect: 'https://www.countryflags.io/BO/flat/64.png',
    iso2: 'bo',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    dialCode: '387',
    isoCode: 'BA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ba.svg',
    flagRect: 'https://www.countryflags.io/BA/flat/64.png',
    iso2: 'ba',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Botswana',
    dialCode: '267',
    isoCode: 'BW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bw.svg',
    flagRect: 'https://www.countryflags.io/BW/flat/64.png',
    iso2: 'bw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Brazil (Brasil)',
    dialCode: '55',
    isoCode: 'BR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/br.svg',
    flagRect: 'https://www.countryflags.io/BR/flat/64.png',
    iso2: 'br',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'British Indian Ocean Territory',
    dialCode: '246',
    isoCode: 'IO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/io.svg',
    flagRect: 'https://www.countryflags.io/IO/flat/64.png',
    iso2: 'io',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'British Virgin Islands',
    dialCode: '1284',
    isoCode: 'VG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/vg.svg',
    flagRect: 'https://www.countryflags.io/VG/flat/64.png',
    iso2: 'vg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Brunei',
    dialCode: '673',
    isoCode: 'BN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bn.svg',
    flagRect: 'https://www.countryflags.io/BN/flat/64.png',
    iso2: 'bn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Bulgaria (България)',
    dialCode: '359',
    isoCode: 'BG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bg.svg',
    flagRect: 'https://www.countryflags.io/BG/flat/64.png',
    iso2: 'bg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Burkina Faso',
    dialCode: '226',
    isoCode: 'BF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bf.svg',
    flagRect: 'https://www.countryflags.io/BF/flat/64.png',
    iso2: 'bf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Burundi (Uburundi)',
    dialCode: '257',
    isoCode: 'BI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bi.svg',
    flagRect: 'https://www.countryflags.io/BI/flat/64.png',
    iso2: 'bi',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    dialCode: '855',
    isoCode: 'KH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kh.svg',
    flagRect: 'https://www.countryflags.io/KH/flat/64.png',
    iso2: 'kh',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cameroon (Cameroun)',
    dialCode: '237',
    isoCode: 'CM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cm.svg',
    flagRect: 'https://www.countryflags.io/CM/flat/64.png',
    iso2: 'cm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Canada',
    dialCode: '1',
    isoCode: 'CA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ca.svg',
    flagRect: 'https://www.countryflags.io/CA/flat/64.png',
    iso2: 'ca',
    priority: 1,
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    dialCode: '238',
    isoCode: 'CV',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cv.svg',
    flagRect: 'https://www.countryflags.io/CV/flat/64.png',
    iso2: 'cv',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cayman Islands',
    dialCode: '1345',
    isoCode: 'KY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ky.svg',
    flagRect: 'https://www.countryflags.io/KY/flat/64.png',
    iso2: 'ky',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    dialCode: '236',
    isoCode: 'CF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cf.svg',
    flagRect: 'https://www.countryflags.io/CF/flat/64.png',
    iso2: 'cf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Chad (Tchad)',
    dialCode: '235',
    isoCode: 'TD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/td.svg',
    flagRect: 'https://www.countryflags.io/TD/flat/64.png',
    iso2: 'td',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Chile',
    dialCode: '56',
    isoCode: 'CL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cl.svg',
    flagRect: 'https://www.countryflags.io/CL/flat/64.png',
    iso2: 'cl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'China (中国)',
    dialCode: '86',
    isoCode: 'CN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cn.svg',
    flagRect: 'https://www.countryflags.io/CN/flat/64.png',
    iso2: 'cn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Christmas Island',
    dialCode: '61',
    isoCode: 'CX',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cx.svg',
    flagRect: 'https://www.countryflags.io/CX/flat/64.png',
    iso2: 'cx',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Cocos (Keeling) Islands',
    dialCode: '61',
    isoCode: 'CC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cc.svg',
    flagRect: 'https://www.countryflags.io/CC/flat/64.png',
    iso2: 'cc',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Colombia',
    dialCode: '57',
    isoCode: 'CO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/co.svg',
    flagRect: 'https://www.countryflags.io/CO/flat/64.png',
    iso2: 'co',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    dialCode: '269',
    isoCode: 'KM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/km.svg',
    flagRect: 'https://www.countryflags.io/KM/flat/64.png',
    iso2: 'km',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    dialCode: '243',
    isoCode: 'CD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cd.svg',
    flagRect: 'https://www.countryflags.io/CD/flat/64.png',
    iso2: 'cd',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    dialCode: '242',
    isoCode: 'CG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cg.svg',
    flagRect: 'https://www.countryflags.io/CG/flat/64.png',
    iso2: 'cg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cook Islands',
    dialCode: '682',
    isoCode: 'CK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ck.svg',
    flagRect: 'https://www.countryflags.io/CK/flat/64.png',
    iso2: 'ck',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Costa Rica',
    dialCode: '506',
    isoCode: 'CR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cr.svg',
    flagRect: 'https://www.countryflags.io/CR/flat/64.png',
    iso2: 'cr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Côte d’Ivoire',
    dialCode: '225',
    isoCode: 'CI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ci.svg',
    flagRect: 'https://www.countryflags.io/CI/flat/64.png',
    iso2: 'ci',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Croatia (Hrvatska)',
    dialCode: '385',
    isoCode: 'HR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/hr.svg',
    flagRect: 'https://www.countryflags.io/HR/flat/64.png',
    iso2: 'hr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cuba',
    dialCode: '53',
    isoCode: 'CU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cu.svg',
    flagRect: 'https://www.countryflags.io/CU/flat/64.png',
    iso2: 'cu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Cyprus (Κύπρος)',
    dialCode: '357',
    isoCode: 'CY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cy.svg',
    flagRect: 'https://www.countryflags.io/CY/flat/64.png',
    iso2: 'cy',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Czech Republic (Česká republika)',
    dialCode: '420',
    isoCode: 'CZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/cz.svg',
    flagRect: 'https://www.countryflags.io/CZ/flat/64.png',
    iso2: 'cz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Denmark (Danmark)',
    dialCode: '45',
    isoCode: 'DK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/dk.svg',
    flagRect: 'https://www.countryflags.io/DK/flat/64.png',
    iso2: 'dk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Djibouti',
    dialCode: '253',
    isoCode: 'DJ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/dj.svg',
    flagRect: 'https://www.countryflags.io/DJ/flat/64.png',
    iso2: 'dj',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominica',
    dialCode: '1767',
    isoCode: 'DM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/dm.svg',
    flagRect: 'https://www.countryflags.io/DM/flat/64.png',
    iso2: 'dm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    dialCode: '1',
    isoCode: 'DO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/do.svg',
    flagRect: 'https://www.countryflags.io/DO/flat/64.png',
    iso2: 'do',
    priority: 2,
    areaCodes: ['809', '829', '849'],
  },
  {
    name: 'Ecuador',
    dialCode: '593',
    isoCode: 'EC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ec.svg',
    flagRect: 'https://www.countryflags.io/EC/flat/64.png',
    iso2: 'ec',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    dialCode: '20',
    isoCode: 'EG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/eg.svg',
    flagRect: 'https://www.countryflags.io/EG/flat/64.png',
    iso2: 'eg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'El Salvador',
    dialCode: '503',
    isoCode: 'SV',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sv.svg',
    flagRect: 'https://www.countryflags.io/SV/flat/64.png',
    iso2: 'sv',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    dialCode: '240',
    isoCode: 'GQ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gq.svg',
    flagRect: 'https://www.countryflags.io/GQ/flat/64.png',
    iso2: 'gq',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Eritrea',
    dialCode: '291',
    isoCode: 'ER',
    flag: 'https://hatscripts.github.io/circle-flags/flags/er.svg',
    flagRect: 'https://www.countryflags.io/ER/flat/64.png',
    iso2: 'er',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Estonia (Eesti)',
    dialCode: '372',
    isoCode: 'EE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ee.svg',
    flagRect: 'https://www.countryflags.io/EE/flat/64.png',
    iso2: 'ee',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ethiopia',
    dialCode: '251',
    isoCode: 'ET',
    flag: 'https://hatscripts.github.io/circle-flags/flags/et.svg',
    flagRect: 'https://www.countryflags.io/ET/flat/64.png',
    iso2: 'et',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    dialCode: '500',
    isoCode: 'FK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fk.svg',
    flagRect: 'https://www.countryflags.io/FK/flat/64.png',
    iso2: 'fk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Faroe Islands (Føroyar)',
    dialCode: '298',
    isoCode: 'FO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fo.svg',
    flagRect: 'https://www.countryflags.io/FO/flat/64.png',
    iso2: 'fo',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Fiji',
    dialCode: '679',
    isoCode: 'FJ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fj.svg',
    flagRect: 'https://www.countryflags.io/FJ/flat/64.png',
    iso2: 'fj',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Finland (Suomi)',
    dialCode: '358',
    isoCode: 'FI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fi.svg',
    flagRect: 'https://www.countryflags.io/FI/flat/64.png',
    iso2: 'fi',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'France',
    dialCode: '33',
    isoCode: 'FR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fr.svg',
    flagRect: 'https://www.countryflags.io/FR/flat/64.png',
    iso2: 'fr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'French Guiana (Guyane française)',
    dialCode: '594',
    isoCode: 'GF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gf.svg',
    flagRect: 'https://www.countryflags.io/GF/flat/64.png',
    iso2: 'gf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'French Polynesia (Polynésie française)',
    dialCode: '689',
    isoCode: 'PF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pf.svg',
    flagRect: 'https://www.countryflags.io/PF/flat/64.png',
    iso2: 'pf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gabon',
    dialCode: '241',
    isoCode: 'GA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ga.svg',
    flagRect: 'https://www.countryflags.io/GA/flat/64.png',
    iso2: 'ga',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gambia',
    dialCode: '220',
    isoCode: 'GM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gm.svg',
    flagRect: 'https://www.countryflags.io/GM/flat/64.png',
    iso2: 'gm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Georgia (საქართველო)',
    dialCode: '995',
    isoCode: 'GE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ge.svg',
    flagRect: 'https://www.countryflags.io/GE/flat/64.png',
    iso2: 'ge',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Germany (Deutschland)',
    dialCode: '49',
    isoCode: 'DE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/de.svg',
    flagRect: 'https://www.countryflags.io/DE/flat/64.png',
    iso2: 'de',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ghana (Gaana)',
    dialCode: '233',
    isoCode: 'GH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gh.svg',
    flagRect: 'https://www.countryflags.io/GH/flat/64.png',
    iso2: 'gh',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Gibraltar',
    dialCode: '350',
    isoCode: 'GI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gi.svg',
    flagRect: 'https://www.countryflags.io/GI/flat/64.png',
    iso2: 'gi',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Greece (Ελλάδα)',
    dialCode: '30',
    isoCode: 'GR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gr.svg',
    flagRect: 'https://www.countryflags.io/GR/flat/64.png',
    iso2: 'gr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    dialCode: '299',
    isoCode: 'GL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gl.svg',
    flagRect: 'https://www.countryflags.io/GL/flat/64.png',
    iso2: 'gl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Grenada',
    dialCode: '1473',
    isoCode: 'GD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gd.svg',
    flagRect: 'https://www.countryflags.io/GD/flat/64.png',
    iso2: 'gd',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guadeloupe',
    dialCode: '590',
    isoCode: 'GP',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gp.svg',
    flagRect: 'https://www.countryflags.io/GP/flat/64.png',
    iso2: 'gp',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guam',
    dialCode: '1671',
    isoCode: 'GU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gu.svg',
    flagRect: 'https://www.countryflags.io/GU/flat/64.png',
    iso2: 'gu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guatemala',
    dialCode: '502',
    isoCode: 'GT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gt.svg',
    flagRect: 'https://www.countryflags.io/GT/flat/64.png',
    iso2: 'gt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guernsey',
    dialCode: '44',
    isoCode: 'GG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gg.svg',
    flagRect: 'https://www.countryflags.io/GG/flat/64.png',
    iso2: 'gg',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Guinea (Guinée)',
    dialCode: '224',
    isoCode: 'GN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gn.svg',
    flagRect: 'https://www.countryflags.io/GN/flat/64.png',
    iso2: 'gn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    dialCode: '245',
    isoCode: 'GW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gw.svg',
    flagRect: 'https://www.countryflags.io/GW/flat/64.png',
    iso2: 'gw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Guyana',
    dialCode: '592',
    isoCode: 'GY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gy.svg',
    flagRect: 'https://www.countryflags.io/GY/flat/64.png',
    iso2: 'gy',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Haiti',
    dialCode: '509',
    isoCode: 'HT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ht.svg',
    flagRect: 'https://www.countryflags.io/HT/flat/64.png',
    iso2: 'ht',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Honduras',
    dialCode: '504',
    isoCode: 'HN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/hn.svg',
    flagRect: 'https://www.countryflags.io/HN/flat/64.png',
    iso2: 'hn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Hong Kong (香港)',
    dialCode: '852',
    isoCode: 'HK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/hk.svg',
    flagRect: 'https://www.countryflags.io/HK/flat/64.png',
    iso2: 'hk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Hungary (Magyarország)',
    dialCode: '36',
    isoCode: 'HU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/hu.svg',
    flagRect: 'https://www.countryflags.io/HU/flat/64.png',
    iso2: 'hu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iceland (Ísland)',
    dialCode: '354',
    isoCode: 'IS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/is.svg',
    flagRect: 'https://www.countryflags.io/IS/flat/64.png',
    iso2: 'is',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'India (भारत)',
    dialCode: '91',
    isoCode: 'IN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/in.svg',
    flagRect: 'https://www.countryflags.io/IN/flat/64.png',
    iso2: 'in',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Indonesia',
    dialCode: '62',
    isoCode: 'ID',
    flag: 'https://hatscripts.github.io/circle-flags/flags/id.svg',
    flagRect: 'https://www.countryflags.io/ID/flat/64.png',
    iso2: 'id',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iran (‫ایران‬‎)',
    dialCode: '98',
    isoCode: 'IR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ir.svg',
    flagRect: 'https://www.countryflags.io/IR/flat/64.png',
    iso2: 'ir',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    dialCode: '964',
    isoCode: 'IQ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/iq.svg',
    flagRect: 'https://www.countryflags.io/IQ/flat/64.png',
    iso2: 'iq',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ireland',
    dialCode: '353',
    isoCode: 'IE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ie.svg',
    flagRect: 'https://www.countryflags.io/IE/flat/64.png',
    iso2: 'ie',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Isle of Man',
    dialCode: '44',
    isoCode: 'IM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/im.svg',
    flagRect: 'https://www.countryflags.io/IM/flat/64.png',
    iso2: 'im',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    dialCode: '972',
    isoCode: 'IL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/il.svg',
    flagRect: 'https://www.countryflags.io/IL/flat/64.png',
    iso2: 'il',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Italy (Italia)',
    dialCode: '39',
    isoCode: 'IT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/it.svg',
    flagRect: 'https://www.countryflags.io/IT/flat/64.png',
    iso2: 'it',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Jamaica',
    dialCode: '1876',
    isoCode: 'JM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/jm.svg',
    flagRect: 'https://www.countryflags.io/JM/flat/64.png',
    iso2: 'jm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Japan (日本)',
    dialCode: '81',
    isoCode: 'JP',
    flag: 'https://hatscripts.github.io/circle-flags/flags/jp.svg',
    flagRect: 'https://www.countryflags.io/JP/flat/64.png',
    iso2: 'jp',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Jersey',
    dialCode: '44',
    isoCode: 'JE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/je.svg',
    flagRect: 'https://www.countryflags.io/JE/flat/64.png',
    iso2: 'je',
    priority: 3,
    areaCodes: null,
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    dialCode: '962',
    isoCode: 'JO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/jo.svg',
    flagRect: 'https://www.countryflags.io/JO/flat/64.png',
    iso2: 'jo',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kazakhstan (Казахстан)',
    dialCode: '77',
    isoCode: 'KZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kz.svg',
    flagRect: 'https://www.countryflags.io/KZ/flat/64.png',
    iso2: 'kz',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Kenya',
    dialCode: '254',
    isoCode: 'KE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ke.svg',
    flagRect: 'https://www.countryflags.io/KE/flat/64.png',
    iso2: 'ke',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kiribati',
    dialCode: '686',
    isoCode: 'KI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ki.svg',
    flagRect: 'https://www.countryflags.io/KI/flat/64.png',
    iso2: 'ki',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    dialCode: '965',
    isoCode: 'KW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kw.svg',
    flagRect: 'https://www.countryflags.io/KW/flat/64.png',
    iso2: 'kw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    dialCode: '996',
    isoCode: 'KG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kg.svg',
    flagRect: 'https://www.countryflags.io/KG/flat/64.png',
    iso2: 'kg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Laos (ລາວ)',
    dialCode: '856',
    isoCode: 'LA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/la.svg',
    flagRect: 'https://www.countryflags.io/LA/flat/64.png',
    iso2: 'la',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Latvia (Latvija)',
    dialCode: '371',
    isoCode: 'LV',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lv.svg',
    flagRect: 'https://www.countryflags.io/LV/flat/64.png',
    iso2: 'lv',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    dialCode: '961',
    isoCode: 'LB',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lb.svg',
    flagRect: 'https://www.countryflags.io/LB/flat/64.png',
    iso2: 'lb',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lesotho',
    dialCode: '266',
    isoCode: 'LS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ls.svg',
    flagRect: 'https://www.countryflags.io/LS/flat/64.png',
    iso2: 'ls',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Liberia',
    dialCode: '231',
    isoCode: 'LR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lr.svg',
    flagRect: 'https://www.countryflags.io/LR/flat/64.png',
    iso2: 'lr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    dialCode: '218',
    isoCode: 'LY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ly.svg',
    flagRect: 'https://www.countryflags.io/LY/flat/64.png',
    iso2: 'ly',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Liechtenstein',
    dialCode: '423',
    isoCode: 'LI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/li.svg',
    flagRect: 'https://www.countryflags.io/LI/flat/64.png',
    iso2: 'li',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Lithuania (Lietuva)',
    dialCode: '370',
    isoCode: 'LT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lt.svg',
    flagRect: 'https://www.countryflags.io/LT/flat/64.png',
    iso2: 'lt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Luxembourg',
    dialCode: '352',
    isoCode: 'LU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lu.svg',
    flagRect: 'https://www.countryflags.io/LU/flat/64.png',
    iso2: 'lu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Macau (澳門)',
    dialCode: '853',
    isoCode: 'MO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mo.svg',
    flagRect: 'https://www.countryflags.io/MO/flat/64.png',
    iso2: 'mo',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    dialCode: '389',
    isoCode: 'MK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mk.svg',
    flagRect: 'https://www.countryflags.io/MK/flat/64.png',
    iso2: 'mk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Madagascar (Madagasikara)',
    dialCode: '261',
    isoCode: 'MG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mg.svg',
    flagRect: 'https://www.countryflags.io/MG/flat/64.png',
    iso2: 'mg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malawi',
    dialCode: '265',
    isoCode: 'MW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mw.svg',
    flagRect: 'https://www.countryflags.io/MW/flat/64.png',
    iso2: 'mw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malaysia',
    dialCode: '60',
    isoCode: 'MY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/my.svg',
    flagRect: 'https://www.countryflags.io/MY/flat/64.png',
    iso2: 'my',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Maldives',
    dialCode: '960',
    isoCode: 'MV',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mv.svg',
    flagRect: 'https://www.countryflags.io/MV/flat/64.png',
    iso2: 'mv',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mali',
    dialCode: '223',
    isoCode: 'ML',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ml.svg',
    flagRect: 'https://www.countryflags.io/ML/flat/64.png',
    iso2: 'ml',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Malta',
    dialCode: '356',
    isoCode: 'MT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mt.svg',
    flagRect: 'https://www.countryflags.io/MT/flat/64.png',
    iso2: 'mt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Marshall Islands',
    dialCode: '692',
    isoCode: 'MH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mh.svg',
    flagRect: 'https://www.countryflags.io/MH/flat/64.png',
    iso2: 'mh',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Martinique',
    dialCode: '596',
    isoCode: 'MQ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mq.svg',
    flagRect: 'https://www.countryflags.io/MQ/flat/64.png',
    iso2: 'mq',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    dialCode: '222',
    isoCode: 'MR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mr.svg',
    flagRect: 'https://www.countryflags.io/MR/flat/64.png',
    iso2: 'mr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mauritius (Moris)',
    dialCode: '230',
    isoCode: 'MU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mu.svg',
    flagRect: 'https://www.countryflags.io/MU/flat/64.png',
    iso2: 'mu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mayotte',
    dialCode: '262',
    isoCode: 'YT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/yt.svg',
    flagRect: 'https://www.countryflags.io/YT/flat/64.png',
    iso2: 'yt',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Mexico (México)',
    dialCode: '52',
    isoCode: 'MX',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mx.svg',
    flagRect: 'https://www.countryflags.io/MX/flat/64.png',
    iso2: 'mx',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Micronesia',
    dialCode: '691',
    isoCode: 'FM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/fm.svg',
    flagRect: 'https://www.countryflags.io/FM/flat/64.png',
    iso2: 'fm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Moldova (Republica Moldova)',
    dialCode: '373',
    isoCode: 'MD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/md.svg',
    flagRect: 'https://www.countryflags.io/MD/flat/64.png',
    iso2: 'md',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Monaco',
    dialCode: '377',
    isoCode: 'MC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mc.svg',
    flagRect: 'https://www.countryflags.io/MC/flat/64.png',
    iso2: 'mc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mongolia (Монгол)',
    dialCode: '976',
    isoCode: 'MN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mn.svg',
    flagRect: 'https://www.countryflags.io/MN/flat/64.png',
    iso2: 'mn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Montenegro (Crna Gora)',
    dialCode: '382',
    isoCode: 'ME',
    flag: 'https://hatscripts.github.io/circle-flags/flags/me.svg',
    flagRect: 'https://www.countryflags.io/ME/flat/64.png',
    iso2: 'me',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Montserrat',
    dialCode: '1664',
    isoCode: 'MS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ms.svg',
    flagRect: 'https://www.countryflags.io/MS/flat/64.png',
    iso2: 'ms',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    dialCode: '212',
    isoCode: 'MA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ma.svg',
    flagRect: 'https://www.countryflags.io/MA/flat/64.png',
    iso2: 'ma',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Mozambique (Moçambique)',
    dialCode: '258',
    isoCode: 'MZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mz.svg',
    flagRect: 'https://www.countryflags.io/MZ/flat/64.png',
    iso2: 'mz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Myanmar (Burma)',
    dialCode: '95',
    isoCode: 'MM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mm.svg',
    flagRect: 'https://www.countryflags.io/MM/flat/64.png',
    iso2: 'mm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Namibia (Namibië)',
    dialCode: '264',
    isoCode: 'NA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/na.svg',
    flagRect: 'https://www.countryflags.io/NA/flat/64.png',
    iso2: 'na',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nauru',
    dialCode: '674',
    isoCode: 'NR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nr.svg',
    flagRect: 'https://www.countryflags.io/NR/flat/64.png',
    iso2: 'nr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nepal (नेपाल)',
    dialCode: '977',
    isoCode: 'NP',
    flag: 'https://hatscripts.github.io/circle-flags/flags/np.svg',
    flagRect: 'https://www.countryflags.io/NP/flat/64.png',
    iso2: 'np',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Netherlands (Nederland)',
    dialCode: '31',
    isoCode: 'NL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nl.svg',
    flagRect: 'https://www.countryflags.io/NL/flat/64.png',
    iso2: 'nl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    dialCode: '687',
    isoCode: 'NC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nc.svg',
    flagRect: 'https://www.countryflags.io/NC/flat/64.png',
    iso2: 'nc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'New Zealand',
    dialCode: '64',
    isoCode: 'NZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nz.svg',
    flagRect: 'https://www.countryflags.io/NZ/flat/64.png',
    iso2: 'nz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nicaragua',
    dialCode: '505',
    isoCode: 'NI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ni.svg',
    flagRect: 'https://www.countryflags.io/NI/flat/64.png',
    iso2: 'ni',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Niger (Nijar)',
    dialCode: '227',
    isoCode: 'NE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ne.svg',
    flagRect: 'https://www.countryflags.io/NE/flat/64.png',
    iso2: 'ne',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Nigeria',
    dialCode: '234',
    isoCode: 'NG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ng.svg',
    flagRect: 'https://www.countryflags.io/NG/flat/64.png',
    iso2: 'ng',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Niue',
    dialCode: '683',
    isoCode: 'NU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nu.svg',
    flagRect: 'https://www.countryflags.io/NU/flat/64.png',
    iso2: 'nu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norfolk Island',
    dialCode: '672',
    isoCode: 'NF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/nf.svg',
    flagRect: 'https://www.countryflags.io/NF/flat/64.png',
    iso2: 'nf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    dialCode: '850',
    isoCode: 'KP',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kp.svg',
    flagRect: 'https://www.countryflags.io/KP/flat/64.png',
    iso2: 'kp',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Northern Mariana Islands',
    dialCode: '1670',
    isoCode: 'MP',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mp.svg',
    flagRect: 'https://www.countryflags.io/MP/flat/64.png',
    iso2: 'mp',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Norway (Norge)',
    dialCode: '47',
    isoCode: 'NO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/no.svg',
    flagRect: 'https://www.countryflags.io/NO/flat/64.png',
    iso2: 'no',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    dialCode: '968',
    isoCode: 'OM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/om.svg',
    flagRect: 'https://www.countryflags.io/OM/flat/64.png',
    iso2: 'om',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    dialCode: '92',
    isoCode: 'PK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pk.svg',
    flagRect: 'https://www.countryflags.io/PK/flat/64.png',
    iso2: 'pk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Palau',
    dialCode: '680',
    isoCode: 'PW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pw.svg',
    flagRect: 'https://www.countryflags.io/PW/flat/64.png',
    iso2: 'pw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    dialCode: '970',
    isoCode: 'PS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ps.svg',
    flagRect: 'https://www.countryflags.io/PS/flat/64.png',
    iso2: 'ps',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Panama (Panamá)',
    dialCode: '507',
    isoCode: 'PA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pa.svg',
    flagRect: 'https://www.countryflags.io/PA/flat/64.png',
    iso2: 'pa',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Papua New Guinea',
    dialCode: '675',
    isoCode: 'PG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pg.svg',
    flagRect: 'https://www.countryflags.io/PG/flat/64.png',
    iso2: 'pg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Paraguay',
    dialCode: '595',
    isoCode: 'PY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/py.svg',
    flagRect: 'https://www.countryflags.io/PY/flat/64.png',
    iso2: 'py',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Peru (Perú)',
    dialCode: '51',
    isoCode: 'PE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pe.svg',
    flagRect: 'https://www.countryflags.io/PE/flat/64.png',
    iso2: 'pe',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Philippines',
    dialCode: '63',
    isoCode: 'PH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ph.svg',
    flagRect: 'https://www.countryflags.io/PH/flat/64.png',
    iso2: 'ph',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Poland (Polska)',
    dialCode: '48',
    isoCode: 'PL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pl.svg',
    flagRect: 'https://www.countryflags.io/PL/flat/64.png',
    iso2: 'pl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Portugal',
    dialCode: '351',
    isoCode: 'PT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pt.svg',
    flagRect: 'https://www.countryflags.io/PT/flat/64.png',
    iso2: 'pt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Puerto Rico',
    dialCode: '1',
    isoCode: 'PR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pr.svg',
    flagRect: 'https://www.countryflags.io/PR/flat/64.png',
    iso2: 'pr',
    priority: 3,
    areaCodes: ['787', '939'],
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    dialCode: '974',
    isoCode: 'QA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/qa.svg',
    flagRect: 'https://www.countryflags.io/QA/flat/64.png',
    iso2: 'qa',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Réunion (La Réunion)',
    dialCode: '262',
    isoCode: 'RE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/re.svg',
    flagRect: 'https://www.countryflags.io/RE/flat/64.png',
    iso2: 're',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Romania (România)',
    dialCode: '40',
    isoCode: 'RO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ro.svg',
    flagRect: 'https://www.countryflags.io/RO/flat/64.png',
    iso2: 'ro',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Russia (Россия)',
    dialCode: '7',
    isoCode: 'RU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ru.svg',
    flagRect: 'https://www.countryflags.io/RU/flat/64.png',
    iso2: 'ru',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Rwanda',
    dialCode: '250',
    isoCode: 'RW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/rw.svg',
    flagRect: 'https://www.countryflags.io/RW/flat/64.png',
    iso2: 'rw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    dialCode: '590',
    isoCode: 'BL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/bl.svg',
    flagRect: 'https://www.countryflags.io/BL/flat/64.png',
    iso2: 'bl',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Saint Helena',
    dialCode: '290',
    isoCode: 'SH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sh.svg',
    flagRect: 'https://www.countryflags.io/SH/flat/64.png',
    iso2: 'sh',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Kitts and Nevis',
    dialCode: '1869',
    isoCode: 'KN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kn.svg',
    flagRect: 'https://www.countryflags.io/KN/flat/64.png',
    iso2: 'kn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Lucia',
    dialCode: '1758',
    isoCode: 'LC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lc.svg',
    flagRect: 'https://www.countryflags.io/LC/flat/64.png',
    iso2: 'lc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    dialCode: '590',
    isoCode: 'MF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/mf.svg',
    flagRect: 'https://www.countryflags.io/MF/flat/64.png',
    iso2: 'mf',
    priority: 2,
    areaCodes: null,
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    dialCode: '508',
    isoCode: 'PM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/pm.svg',
    flagRect: 'https://www.countryflags.io/PM/flat/64.png',
    iso2: 'pm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dialCode: '1784',
    isoCode: 'VC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/vc.svg',
    flagRect: 'https://www.countryflags.io/VC/flat/64.png',
    iso2: 'vc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Samoa',
    dialCode: '685',
    isoCode: 'WS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ws.svg',
    flagRect: 'https://www.countryflags.io/WS/flat/64.png',
    iso2: 'ws',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'San Marino',
    dialCode: '378',
    isoCode: 'SM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sm.svg',
    flagRect: 'https://www.countryflags.io/SM/flat/64.png',
    iso2: 'sm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    dialCode: '239',
    isoCode: 'ST',
    flag: 'https://hatscripts.github.io/circle-flags/flags/st.svg',
    flagRect: 'https://www.countryflags.io/ST/flat/64.png',
    iso2: 'st',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    dialCode: '966',
    isoCode: 'SA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sa.svg',
    flagRect: 'https://www.countryflags.io/SA/flat/64.png',
    iso2: 'sa',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Senegal (Sénégal)',
    dialCode: '221',
    isoCode: 'SN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sn.svg',
    flagRect: 'https://www.countryflags.io/SN/flat/64.png',
    iso2: 'sn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Serbia (Србија)',
    dialCode: '381',
    isoCode: 'RS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/rs.svg',
    flagRect: 'https://www.countryflags.io/RS/flat/64.png',
    iso2: 'rs',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Seychelles',
    dialCode: '248',
    isoCode: 'SC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sc.svg',
    flagRect: 'https://www.countryflags.io/SC/flat/64.png',
    iso2: 'sc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sierra Leone',
    dialCode: '232',
    isoCode: 'SL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sl.svg',
    flagRect: 'https://www.countryflags.io/SL/flat/64.png',
    iso2: 'sl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Singapore',
    dialCode: '65',
    isoCode: 'SG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sg.svg',
    flagRect: 'https://www.countryflags.io/SG/flat/64.png',
    iso2: 'sg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sint Maarten',
    dialCode: '1721',
    isoCode: 'SX',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sx.svg',
    flagRect: 'https://www.countryflags.io/SX/flat/64.png',
    iso2: 'sx',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Slovakia (Slovensko)',
    dialCode: '421',
    isoCode: 'SK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sk.svg',
    flagRect: 'https://www.countryflags.io/SK/flat/64.png',
    iso2: 'sk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Slovenia (Slovenija)',
    dialCode: '386',
    isoCode: 'SI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/si.svg',
    flagRect: 'https://www.countryflags.io/SI/flat/64.png',
    iso2: 'si',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Solomon Islands',
    dialCode: '677',
    isoCode: 'SB',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sb.svg',
    flagRect: 'https://www.countryflags.io/SB/flat/64.png',
    iso2: 'sb',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Somalia (Soomaaliya)',
    dialCode: '252',
    isoCode: 'SO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/so.svg',
    flagRect: 'https://www.countryflags.io/SO/flat/64.png',
    iso2: 'so',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Africa',
    dialCode: '27',
    isoCode: 'ZA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/za.svg',
    flagRect: 'https://www.countryflags.io/ZA/flat/64.png',
    iso2: 'za',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Korea (대한민국)',
    dialCode: '82',
    isoCode: 'KR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/kr.svg',
    flagRect: 'https://www.countryflags.io/KR/flat/64.png',
    iso2: 'kr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    dialCode: '211',
    isoCode: 'SS',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ss.svg',
    flagRect: 'https://www.countryflags.io/SS/flat/64.png',
    iso2: 'ss',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Spain (España)',
    dialCode: '34',
    isoCode: 'ES',
    flag: 'https://hatscripts.github.io/circle-flags/flags/es.svg',
    flagRect: 'https://www.countryflags.io/ES/flat/64.png',
    iso2: 'es',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    dialCode: '94',
    isoCode: 'LK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/lk.svg',
    flagRect: 'https://www.countryflags.io/LK/flat/64.png',
    iso2: 'lk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    dialCode: '249',
    isoCode: 'SD',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sd.svg',
    flagRect: 'https://www.countryflags.io/SD/flat/64.png',
    iso2: 'sd',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Suriname',
    dialCode: '597',
    isoCode: 'SR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sr.svg',
    flagRect: 'https://www.countryflags.io/SR/flat/64.png',
    iso2: 'sr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Svalbard and Jan Mayen',
    dialCode: '47',
    isoCode: 'SJ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sj.svg',
    flagRect: 'https://www.countryflags.io/SJ/flat/64.png',
    iso2: 'sj',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Swaziland',
    dialCode: '268',
    isoCode: 'SZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sz.svg',
    flagRect: 'https://www.countryflags.io/SZ/flat/64.png',
    iso2: 'sz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Sweden (Sverige)',
    dialCode: '46',
    isoCode: 'SE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/se.svg',
    flagRect: 'https://www.countryflags.io/SE/flat/64.png',
    iso2: 'se',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Switzerland (Schweiz)',
    dialCode: '41',
    isoCode: 'CH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ch.svg',
    flagRect: 'https://www.countryflags.io/CH/flat/64.png',
    iso2: 'ch',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    dialCode: '963',
    isoCode: 'SY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/sy.svg',
    flagRect: 'https://www.countryflags.io/SY/flat/64.png',
    iso2: 'sy',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Taiwan (台灣)',
    dialCode: '886',
    isoCode: 'TW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tw.svg',
    flagRect: 'https://www.countryflags.io/TW/flat/64.png',
    iso2: 'tw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tajikistan',
    dialCode: '992',
    isoCode: 'TJ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tj.svg',
    flagRect: 'https://www.countryflags.io/TJ/flat/64.png',
    iso2: 'tj',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tanzania',
    dialCode: '255',
    isoCode: 'TZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tz.svg',
    flagRect: 'https://www.countryflags.io/TZ/flat/64.png',
    iso2: 'tz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Thailand (ไทย)',
    dialCode: '66',
    isoCode: 'TH',
    flag: 'https://hatscripts.github.io/circle-flags/flags/th.svg',
    flagRect: 'https://www.countryflags.io/TH/flat/64.png',
    iso2: 'th',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Timor-Leste',
    dialCode: '670',
    isoCode: 'TL',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tl.svg',
    flagRect: 'https://www.countryflags.io/TL/flat/64.png',
    iso2: 'tl',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Togo',
    dialCode: '228',
    isoCode: 'TG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tg.svg',
    flagRect: 'https://www.countryflags.io/TG/flat/64.png',
    iso2: 'tg',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tokelau',
    dialCode: '690',
    isoCode: 'TK',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tk.svg',
    flagRect: 'https://www.countryflags.io/TK/flat/64.png',
    iso2: 'tk',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tonga',
    dialCode: '676',
    isoCode: 'TO',
    flag: 'https://hatscripts.github.io/circle-flags/flags/to.svg',
    flagRect: 'https://www.countryflags.io/TO/flat/64.png',
    iso2: 'to',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Trinidad and Tobago',
    dialCode: '1868',
    isoCode: 'TT',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tt.svg',
    flagRect: 'https://www.countryflags.io/TT/flat/64.png',
    iso2: 'tt',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    dialCode: '216',
    isoCode: 'TN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tn.svg',
    flagRect: 'https://www.countryflags.io/TN/flat/64.png',
    iso2: 'tn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turkey (Türkiye)',
    dialCode: '90',
    isoCode: 'TR',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tr.svg',
    flagRect: 'https://www.countryflags.io/TR/flat/64.png',
    iso2: 'tr',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turkmenistan',
    dialCode: '993',
    isoCode: 'TM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tm.svg',
    flagRect: 'https://www.countryflags.io/TM/flat/64.png',
    iso2: 'tm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Turks and Caicos Islands',
    dialCode: '1649',
    isoCode: 'TC',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tc.svg',
    flagRect: 'https://www.countryflags.io/TC/flat/64.png',
    iso2: 'tc',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Tuvalu',
    dialCode: '688',
    isoCode: 'TV',
    flag: 'https://hatscripts.github.io/circle-flags/flags/tv.svg',
    flagRect: 'https://www.countryflags.io/TV/flat/64.png',
    iso2: 'tv',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'U.S. Virgin Islands',
    dialCode: '1340',
    isoCode: 'VI',
    flag: 'https://hatscripts.github.io/circle-flags/flags/vi.svg',
    flagRect: 'https://www.countryflags.io/VI/flat/64.png',
    iso2: 'vi',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uganda',
    dialCode: '256',
    isoCode: 'UG',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ug.svg',
    flagRect: 'https://www.countryflags.io/UG/flat/64.png',
    iso2: 'ug',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Ukraine (Україна)',
    dialCode: '380',
    isoCode: 'UA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ua.svg',
    flagRect: 'https://www.countryflags.io/UA/flat/64.png',
    iso2: 'ua',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    dialCode: '971',
    isoCode: 'AE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ae.svg',
    flagRect: 'https://www.countryflags.io/AE/flat/64.png',
    iso2: 'ae',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United Kingdom',
    dialCode: '44',
    isoCode: 'GB',
    flag: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
    flagRect: 'https://www.countryflags.io/GB/flat/64.png',
    iso2: 'gb',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'United States',
    dialCode: '1',
    isoCode: 'US',
    flag: 'https://hatscripts.github.io/circle-flags/flags/us.svg',
    flagRect: 'https://www.countryflags.io/US/flat/64.png',
    iso2: 'us',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uruguay',
    dialCode: '598',
    isoCode: 'UY',
    flag: 'https://hatscripts.github.io/circle-flags/flags/uy.svg',
    flagRect: 'https://www.countryflags.io/UY/flat/64.png',
    iso2: 'uy',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    dialCode: '998',
    isoCode: 'UZ',
    flag: 'https://hatscripts.github.io/circle-flags/flags/uz.svg',
    flagRect: 'https://www.countryflags.io/UZ/flat/64.png',
    iso2: 'uz',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vanuatu',
    dialCode: '678',
    isoCode: 'VU',
    flag: 'https://hatscripts.github.io/circle-flags/flags/vu.svg',
    flagRect: 'https://www.countryflags.io/VU/flat/64.png',
    iso2: 'vu',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    dialCode: '39',
    isoCode: 'VA',
    flag: 'https://hatscripts.github.io/circle-flags/flags/va.svg',
    flagRect: 'https://www.countryflags.io/VA/flat/64.png',
    iso2: 'va',
    priority: 1,
    areaCodes: null,
  },
  {
    name: 'Venezuela',
    dialCode: '58',
    isoCode: 'VE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ve.svg',
    flagRect: 'https://www.countryflags.io/VE/flat/64.png',
    iso2: 've',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Vietnam (Việt Nam)',
    dialCode: '84',
    isoCode: 'VN',
    flag: 'https://hatscripts.github.io/circle-flags/flags/vn.svg',
    flagRect: 'https://www.countryflags.io/VN/flat/64.png',
    iso2: 'vn',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Wallis and Futuna',
    dialCode: '681',
    isoCode: 'WF',
    flag: 'https://hatscripts.github.io/circle-flags/flags/wf.svg',
    flagRect: 'https://www.countryflags.io/WF/flat/64.png',
    iso2: 'wf',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    dialCode: '967',
    isoCode: 'YE',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ye.svg',
    flagRect: 'https://www.countryflags.io/YE/flat/64.png',
    iso2: 'ye',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Zambia',
    dialCode: '260',
    isoCode: 'ZM',
    flag: 'https://hatscripts.github.io/circle-flags/flags/zm.svg',
    flagRect: 'https://www.countryflags.io/ZM/flat/64.png',
    iso2: 'zm',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Zimbabwe',
    dialCode: '263',
    isoCode: 'ZW',
    flag: 'https://hatscripts.github.io/circle-flags/flags/zw.svg',
    flagRect: 'https://www.countryflags.io/ZW/flat/64.png',
    iso2: 'zw',
    priority: 0,
    areaCodes: null,
  },
  {
    name: 'Åland Islands',
    dialCode: '358',
    isoCode: 'AX',
    flag: 'https://hatscripts.github.io/circle-flags/flags/ax.svg',
    flagRect: 'https://www.countryflags.io/AX/flat/64.png',
    iso2: 'ax',
    priority: 1,
    areaCodes: null,
  },
];
