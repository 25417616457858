import { makeStyles } from '@material-ui/core';
import BarChart from '@material-ui/icons/BarChart';
import FeaturedPlayListOutlined from '@material-ui/icons/FeaturedPlayListOutlined';
import Link from '@material-ui/icons/Link';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Features } from '../../constants/constants';
import { selectActiveUserOrganization } from '../../features/auth/auth.selectors';
import { selectOrg } from '../../features/org/org.slice';
import gtmTrack from '../../services/googleTagManager/track';

import SidebarLink from './SidebarLink';

interface IProps {
  onCloseSidebar: () => void;
}

function Sidebar({ onCloseSidebar }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { logoUrl } = useSelector(selectOrg);
  const { showDashboardUserManagement } = useSelector(selectActiveUserOrganization);
  const currentOrg = useSelector(selectOrg);

  const handleGeneralStatsClick = useCallback(() => {
    gtmTrack('general_stats');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handleBitesClick = useCallback(() => {
    gtmTrack('bites');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handleAskAiReportsClick = useCallback(() => {
    gtmTrack('ask_ai_reports');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handlePlaylistsClick = useCallback(() => {
    gtmTrack('playlists');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handleQuizzesClick = useCallback(() => {
    gtmTrack('quizes');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handleUserAnalysisClick = useCallback(() => {
    gtmTrack('user_analysis');
    onCloseSidebar();
  }, [onCloseSidebar]);

  const handleUserManagementClick = useCallback(() => {
    gtmTrack('user_management');
    onCloseSidebar();
  }, [onCloseSidebar]);

  return (
    <aside className={classes.root}>
      <div className={classes.fixed}>
        <div className={classes.logoContainer}>
          {logoUrl && <img className={classes.logo} src={logoUrl} alt='Company Logo' />}
        </div>
        <div className={classes.logoDivider} />
        <div className={classes.linksList}>
          <SidebarLink
            text={t('sidebar.generalStats')}
            Icon={BarChart}
            path='/overview'
            onClick={handleGeneralStatsClick}
            intercomTarget='general_stats'
          />
          <SidebarLink text='Bites' Icon={Link} path='/bites' onClick={handleBitesClick} intercomTarget='bites_stats' />
          <SidebarLink
            text={t('sidebar.playlists')}
            Icon={FeaturedPlayListOutlined}
            path='/playlists'
            onClick={handlePlaylistsClick}
            intercomTarget='playlists_stats'
          />
          <SidebarLink
            text={t('sidebar.quizzes')}
            Icon={FeaturedPlayListOutlined}
            path='/quizzes'
            onClick={handleQuizzesClick}
            intercomTarget='quizzes_stats'
          />
          {currentOrg.features && currentOrg.features.includes(Features.ASK_AI_FEED) && (
            <SidebarLink
              text='Ask AI Reports'
              Icon={FeaturedPlayListOutlined}
              path='/ask-ai'
              onClick={handleAskAiReportsClick}
              intercomTarget='ask_ai_reports'
            />
          )}
          <SidebarLink
            text={t('sidebar.analysis')}
            Icon={FeaturedPlayListOutlined}
            path='/user-analysis'
            onClick={handleUserAnalysisClick}
            intercomTarget='user_analysis'
          />

          {showDashboardUserManagement && (
            <>
              <div className={classes.linksDivider} />
              <SidebarLink
                text={t('sidebar.management')}
                Icon={SettingsOutlined}
                path='/user-management'
                onClick={handleUserManagementClick}
                intercomTarget='user_management'
              />
            </>
          )}
        </div>
      </div>
    </aside>
  );
}

const ROOT_WIDTH = 248;

const useStyles = makeStyles({
  root: {
    width: ROOT_WIDTH,
  },
  fixed: {
    position: 'fixed',
    width: ROOT_WIDTH,
  },
  logoContainer: {
    height: 72,
    margin: '14px auto 0',
    padding: '0 15px 14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  logoDivider: {
    background: '#EDEDF2',
    height: 1,
  },
  linksList: {
    marginTop: 24,
  },
  linksDivider: {
    background: '#EDEDF2',
    height: 1,
    margin: '0 16px',
  },
});

export default Sidebar;
