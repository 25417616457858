import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import NoStatsModal from '../../components/Share/NoStatsModal';
import { isNoContentOverlayDismissedSelector } from '../appActivity/appActivity.selectors';
import { ContentType } from '../userAnalysis/components/Table/Table';

import PlaylistScreenContent from './components/PlaylistScreenContent';
import PlaylistScreenTabs from './components/PlaylistScreenTabs';
import PlaylistScreenTop from './components/PlaylistScreenTop';
import useFetchData from './hooks/useFetchData';
import useSetTabByUrl from './hooks/useSetTabByUrl';
import { selectPlaylist } from './playlist.slice';

function Playlist() {
  const isNoContentOverlayDismissed = useSelector(isNoContentOverlayDismissedSelector);
  const { isFetchingOverview, overviewError, watched } = useSelector(selectPlaylist);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (_event, value) => {
    setSelectedTabIndex(value);
  };

  useSetTabByUrl({ selectedTabIndex, setSelectedTabIndex });
  useFetchData();

  if (isFetchingOverview) {
    return <Loader />;
  }

  if (overviewError) {
    return <ErrorMsg>Error loading playlist: {overviewError}</ErrorMsg>;
  }

  return (
    <div>
      {!watched && !isNoContentOverlayDismissed && (
        <NoStatsModal withoutContent={false} contentType={ContentType.PLAYLIST} />
      )}
      <PlaylistScreenTop />
      <PlaylistScreenTabs selectedTabIndex={selectedTabIndex} onChangeTab={handleTabChange} />
      <PlaylistScreenContent />
    </div>
  );
}

export default Playlist;
