import { useGoogleLogin as useGoogleLoginHook } from '@react-oauth/google';
import { useDispatch } from 'react-redux';

import { APP } from '../../../constants/constants';
import log, { logError } from '../../../services/log';
import { setIsSocialLoginError, socialLogin, resetErrors } from '../../authForm/authForm.slice';

const useGoogleLogin = () => {
  const dispatch = useDispatch();

  const handleSuccess = (response) => {
    log({ event: 'useGoogleAuth: success' });
    const token = response.access_token;
    dispatch(resetErrors());
    dispatch(
      // @ts-ignore there's no argument in the reducer action but there's on the saga
      socialLogin({
        token,
        backend: 'google-oauth2',
        app: APP,
      }),
    );
  };

  const onError = (error) => {
    logError({ event: 'useGoogleAuth: error', data: { error } });
    dispatch(setIsSocialLoginError(true));
  };

  const signIn = useGoogleLoginHook({
    onSuccess: handleSuccess,
    onError,
    onNonOAuthError: onError,
  });

  return () => {
    log({ event: 'useGoogleAuth' });
    dispatch(resetErrors());
    signIn();
  };
};

export default useGoogleLogin;
