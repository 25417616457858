import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import QuizSelectionTable from '../../../components/QuizSelectionTable';
import { selectQuizzes } from '../../quizzes/quizzes.slice';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function QuizSelectionDialog({ isOpen, onClose }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isFetching, error } = useSelector(selectQuizzes);

  const renderTable = () => {
    if (error) {
      return <ErrorMsg>{t('quiz.error', { error })}</ErrorMsg>;
    }
    if (isFetching) {
      return <Loader />;
    }
    return <QuizSelectionTable onQuizSelect={onClose} />;
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='xl' aria-labelledby='max-width-dialog-title'>
      <DialogTitle id='max-width-dialog-title'>{t('quiz.title')}</DialogTitle>
      <DialogContent className={classes.content}>{renderTable()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t('quiz.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  content: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default QuizSelectionDialog;
