import sumBy from 'lodash/sumBy';
import { all, put, takeLatest } from 'redux-saga/effects';

import { IFullPlaylist } from '../../types/playlist';
import { IFullQuiz, IQuizQuestion } from '../../types/quiz';
import { IQuizViewsTableUser } from '../../types/quizTableUser';
import { percentage } from '../../utils/numbers';

import * as quizCalls from './quiz.api';
import {
  fetchQuizOverview,
  fetchQuizOverviewError,
  fetchQuizOverviewSuccess,
  fetchQuizSettingsSuccess,
  fetchQuizUserView,
  fetchQuizUserViewError,
  fetchQuizUserViewSuccess,
} from './quiz.slice';

export default function* quizSaga() {
  yield all([
    takeLatest(fetchQuizOverview, fetchQuizOverviewSaga),
    takeLatest(fetchQuizUserView, fetchQuizUserViewSaga),
  ]);
}

function* fetchQuizOverviewSaga(action) {
  try {
    const id = action.payload;
    const settingsCall = quizCalls.getQuizSettings(id);
    const { data }: { data: IFullPlaylist } = yield quizCalls.getQuiz(id);
    const formattedData = formatQuizOverviewResponse(data);
    yield put(fetchQuizOverviewSuccess(formattedData));
    const {
      data: [settings],
    } = yield settingsCall;
    yield put(
      fetchQuizSettingsSuccess({
        ...settings,
        questionbackgroundColor: settings.questionColor,
      }),
    );
  } catch (error) {
    yield put(fetchQuizOverviewError(error.message));
  }
}

function* fetchQuizUserViewSaga(action) {
  try {
    const id = action.payload;
    const { data }: { data: IQuizViewsTableUser[] } = yield quizCalls.getQuizViewsByUser(id);
    yield put(fetchQuizUserViewSuccess(data));
  } catch (error) {
    yield put(fetchQuizUserViewError(error.message));
  }
}

function formatQuizOverviewResponse(data: IFullPlaylist): IFullQuiz {
  const questions = data.biteShares
    .reverse()
    .filter((bs) => bs.playlistSection === 'body')
    .map((q) => ({
      ...q,
      type: q.questionType,
      text: q.question,
      views: q.watched,
      answeredCorrectlyPercentage: q.answers.find((a) => a.isCorrect)?.percentage || 0,
      completedPercentage: percentage(q.answered, q.watched),
    }));
  const scorableQuestions = data.biteShares.filter((bs) => bs.questionType === 'multiple choices');
  const scorableQuestionsAnswered = sumBy(scorableQuestions, (item) => Number(item.answered));
  const scorableQuestionsAnsweredCorrectly = sumBy(scorableQuestions, (item) => Number(item.answeredCorrectly));
  return {
    ...data,
    questions: questions as IQuizQuestion[],
    views: data.watched,
    answeredCorrectlyPercentage: percentage(scorableQuestionsAnsweredCorrectly, scorableQuestionsAnswered),
  };
}
