import React from 'react';

import { IComment } from '../../types/comment';

import CommentItem from './CommentItem';

interface IProps {
  comments: IComment[];
}

function Comments({ comments }: IProps) {
  return (
    <>
      {comments.map((comment, idx) => (
        <CommentItem key={idx} comment={comment} />
      ))}
    </>
  );
}

export default Comments;
