import TagManager from 'react-gtm-module';

import { APP } from '../constants/constants';
import { isProd, isStaging } from '../utils/env';

const GTM_ID = 'GTM-TKKVHDM';
const AUTH_STAGING = '5YOT0WeFdAsR0P8oPcC97A';
const AUTH_PROD = 'N97j8nF5pjL-ngaNfap_Mw';
const AUTH_DEV = 'NFECe9DHhzjFnIwuwZktHg';
const PREVIEW_STAGING = 'env-25';
const PREVIEW_PROD = 'env-1';
const PREVIEW_DEV = 'env-30';

TagManager.initialize({
  gtmId: GTM_ID,
  auth: getAuth(),
  preview: getPreview(),
  dataLayer: {
    // 'gtm.userId': state.authReducer.auth?.id,
    origin: APP,
  },
});

function getAuth() {
  if (isStaging()) {
    return AUTH_STAGING;
  }
  if (isProd()) {
    return AUTH_PROD;
  }
  return AUTH_DEV;
}

function getPreview() {
  if (isStaging()) {
    return PREVIEW_STAGING;
  }
  if (isProd()) {
    return PREVIEW_PROD;
  }
  return PREVIEW_DEV;
}
