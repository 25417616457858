import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AnswerDistributionChart from '../../../components/AnswerDistribution/AnswerDistributionChart';
import { selectBite } from '../bite.slice';

import { QUESTIONS_AND_COMMENTS_COLUMN_TOP_PADDING } from './styles';

interface IProps {
  columnHeaderClassName: string;
  columnPaddingClassName: string;
}

function QuestionColumn({ columnHeaderClassName, columnPaddingClassName }: IProps) {
  const { t } = useTranslation();
  const { question, questionType, answers } = useSelector(selectBite);
  const classes = useStyles();

  if (!questionType) {
    return (
      <>
        <div className={cx(columnHeaderClassName, columnPaddingClassName)}>Question</div>
        <div className={columnPaddingClassName}>
          <div className={classes.question}>{t('bites.noQuestion')}</div>
        </div>
      </>
    );
  }

  if (questionType === 'open ended') {
    return (
      <>
        <div className={cx(columnHeaderClassName, columnPaddingClassName)}>{t('bites.question')}</div>
        <div className={columnPaddingClassName}>
          <div className={classes.question}>{question}</div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={cx(columnHeaderClassName, columnPaddingClassName)}>{t('bites.answerDistribution')}</div>
      <div className={columnPaddingClassName}>
        <div className={classes.question}>{question}</div>
        {/* @ts-ignore we know votes field exists on non open ended Qs */}
        <AnswerDistributionChart answers={answers} />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  question: {
    margin: `${QUESTIONS_AND_COMMENTS_COLUMN_TOP_PADDING}px 0`,
    color: '#3159a5',
    [theme.breakpoints.up('xl')]: {
      fontSize: 17,
    },
  },
}));

export default QuestionColumn;
