import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../assets/icons/delete-comment.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';

import DeleteCommentModal from './DeleteCommentModal';

interface IProps {
  commentId: number;
}

const DeleteComment: React.FC<IProps> = ({ commentId }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return (
    <>
      <S.DeleteComment onClick={handleDelete} id='commentButton'>
        <TrashIcon />
      </S.DeleteComment>
      {isDialogOpen && (
        <DeleteCommentModal
          isOpen={isDialogOpen}
          modalIcon={DeleteIcon}
          onCancel={handleCancel}
          messageText={t('bites.areYouSureComment')}
          confirmText={t('bites.delete')}
          cancelText={t('bites.discard')}
          commentId={commentId}
        />
      )}
    </>
  );
};
const S: any = {};

S.DeleteComment = styled.div`
  cursor: pointer;
`;

export default DeleteComment;
