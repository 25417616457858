import { createSlice } from '@reduxjs/toolkit';

import { EYesNo } from '../../constants/constants';
import { IBiteShare } from '../../types/biteShare';
import { IPlaylistViewsTableUser } from '../../types/playlistTableUser';
import {
  IRdgColumnVisibility,
  IRdgFilters,
  IRdgGrouping,
  IRdgSortInfo,
  ITableDisplay,
} from '../../types/reactDataGrid';
import { IRootState } from '../../types/store';
import {
  initFilterValue,
  mapStringFilter,
  mapNumberFilter,
  initColumnVisibility,
  initGrouping,
} from '../../utils/reactDataGrid';
import { formatDatas, isMobile } from '../../utils/utils';

const playlistSlice = createSlice({
  name: 'playlist',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchPlaylistOverview,
  fetchPlaylistOverviewSuccess,
  fetchPlaylistOverviewError,
  fetchPlaylistUserView,
  fetchPlaylistUserViewSuccess,
  fetchPlaylistUserViewError,
  togglePlaylistColumnVisibility,
  setPlaylistFilterValue,
  setPlaylistViewsByUserGrouping,
  setPlaylistViewsByUserTableDisplay,
  setPlaylistViewsByUserSortInfo,
} = playlistSlice.actions;

export default playlistSlice.reducer;

export const selectPlaylist = (state: IRootState) => state.playlist;

function getInitialState() {
  return {
    isFetchingOverview: false,
    isFetchingUserView: false,
    overviewError: '',
    userViewError: '',
    answered: 0,
    answeredCorrectly: 0,
    biteShares: [] as IBiteShare[],
    createdBy: '',
    description: '',
    name: '',
    id: -1,
    watched: 0,
    totalQuestions: 0,
    coverUrl: undefined,
    ...getPlaylistViewsByUserInitialState(),
  };
}

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
    'org/fetchOrgSuccess': (state, action) => {
      const datas = formatDatas(action.payload);
      if (datas.length === 0) {
        state.playlistViewsByUserTableDisplay = 'drilldown';
      }
      initColumnVisibility('playlistViewsByUserColumnsVisibility', isMobile() ? -1 : 2)(state, datas);
      initFilterValue('playlistViewsByUserFilterValue')(state, datas);
      initGrouping('playlistViewsByUserGrouping')(state, datas);
    },
  };
}

function getReducers() {
  return {
    fetchPlaylistOverview: (state) => {
      state.isFetchingOverview = true;
      state.overviewError = '';
    },
    fetchPlaylistOverviewSuccess: (state, action) => {
      const {
        answered,
        answeredCorrectly,
        biteShares,
        createdBy,
        description,
        name,
        id,
        totalQuestions,
        watched,
        coverUrl,
      } = action.payload;
      state.isFetchingOverview = false;
      state.answered = answered;
      state.answeredCorrectly = answeredCorrectly;
      state.biteShares = biteShares.reverse();
      state.createdBy = createdBy;
      state.description = description;
      state.name = name;
      state.id = id;
      state.watched = watched;
      state.totalQuestions = totalQuestions;
      state.coverUrl = coverUrl;
    },
    fetchPlaylistOverviewError: (state, action) => {
      state.isFetchingOverview = false;
      state.overviewError = action.payload;
    },
    fetchPlaylistUserView: (state) => {
      state.isFetchingUserView = true;
      state.userViewError = '';
    },
    fetchPlaylistUserViewSuccess: (state, action) => {
      state.isFetchingUserView = false;
      state.playlistViewsByUser = action.payload.map((user) => ({
        ...user,
        // convert empty datas from null to empty strings to
        // avoid syncfusion grid from crashing
        datas: user.datas.map((data) => data || ''),
        // set answeredCorrectly, answered, watched to string for table grouping to work
        answeredCorrectly: user.answeredCorrectly === 0 ? '0' : user.answeredCorrectly,
        answered: user.answered === 0 ? '0' : user.answered,
        watched: user.watched === 0 ? '0' : user.watched,
        completed: user.watched === user.total ? EYesNo.YES : EYesNo.NO,
        started: user.watched > 0 ? EYesNo.YES : EYesNo.NO,
      }));
    },
    fetchPlaylistUserViewError: (state, action) => {
      state.isFetchingUserView = false;
      state.userViewError = action.payload;
    },
    togglePlaylistColumnVisibility: (state, action) => {
      state.playlistViewsByUserColumnsVisibility[action.payload] =
        !state.playlistViewsByUserColumnsVisibility[action.payload];
    },
    setPlaylistFilterValue: (state, action) => {
      state.playlistViewsByUserFilterValue = action.payload;
    },
    setPlaylistViewsByUserGrouping: (state, action) => {
      state.playlistViewsByUserGrouping = action.payload;
    },
    setPlaylistViewsByUserTableDisplay: (state, action) => {
      state.playlistViewsByUserTableDisplay = action.payload;
    },
    setPlaylistViewsByUserSortInfo: (state, action) => {
      state.playlistViewsByUserSortInfo = action.payload;
    },
  };
}

function getPlaylistViewsByUserInitialState() {
  return {
    playlistViewsByUser: [] as IPlaylistViewsTableUser[],
    playlistViewsByUserColumnsVisibility: {
      name: true,
      watched: !isMobile(),
      answered: true,
      answeredCorrectly: true,
      phone: false,
      email: false,
      lastVisit: false,
      employeeId: false,
    } as IRdgColumnVisibility,
    playlistViewsByUserFilterValue: [
      ...['name', 'started', 'completed'].map(mapStringFilter),
      ...['watched', 'answered', 'answeredCorrectly'].map(mapNumberFilter),
    ] as IRdgFilters,
    playlistViewsByUserGrouping: [] as IRdgGrouping,
    playlistViewsByUserTableDisplay: 'data1' as ITableDisplay,
    playlistViewsByUserSortInfo: {
      name: 'answered',
      type: 'number',
      dir: -1,
    } as IRdgSortInfo,
  };
}
