import { useSelector } from 'react-redux';

import { selectOrg } from '../../../features/org/org.slice';
import { IEveryoneVsCustom, ISelectedGroupsIds } from '../ChooseGroupsModal';

interface IReturnedFnArgs {
  selectedGroupsIds: ISelectedGroupsIds;
  everyoneVsCustom: IEveryoneVsCustom;
}

interface IReturnedFnReturn {
  data1?: number[];
  data2?: number[];
  data3?: number[];
  data4?: number[];
  data5?: number[];
  data6?: number[];
  data7?: number[];
  data8?: number[];
}

type IReturnedFn = ({ selectedGroupsIds, everyoneVsCustom }: IReturnedFnArgs) => IReturnedFnReturn;

const useGetGroupsIdsByDatas = (): IReturnedFn => {
  const { datas } = useSelector(selectOrg);

  const getGroupsIdsByDatasForEveryone = () => getEmptyDatasObject();

  const getGroupsIdsByDatasForCustom = (selectedGroupsIds: ISelectedGroupsIds) =>
    selectedGroupsIds.reduce((acc, gid) => {
      const data = datas.find((d) => d.options?.map((o) => o.id).includes(gid));
      acc[data.dataFieldName].push(gid);
      return acc;
    }, getEmptyDatasObject());

  const getCgroupsIdsByDatas = ({ selectedGroupsIds, everyoneVsCustom }: IReturnedFnArgs) =>
    everyoneVsCustom === 'everyone'
      ? getGroupsIdsByDatasForEveryone()
      : getGroupsIdsByDatasForCustom(selectedGroupsIds);

  return getCgroupsIdsByDatas;
};

export default useGetGroupsIdsByDatas;

const getEmptyDatasObject = () => ({
  data1: [],
  data2: [],
  data3: [],
  data4: [],
  data5: [],
  data6: [],
  data7: [],
  data8: [],
});
