import { makeStyles } from '@material-ui/core';
import React from 'react';
import ReactModal from 'react-modal';

import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

interface IProps {
  onClose: (evt: any) => void;
  title: string;
  content: React.ReactNode;
  isOpen: boolean;
  HeaderIcon?: any;
  className?: string;
}

function Modal({ onClose, title, HeaderIcon, content, isOpen, className, ...props }: IProps) {
  const classes = useStyles();

  return (
    <ReactModal
      overlayClassName={classes.overlay}
      onRequestClose={onClose}
      style={{ content: { maxWidth: '95%', outline: 'none' } }}
      ariaHideApp={false}
      isOpen={isOpen}
      className={className}
      {...props}
    >
      <div className={classes.root}>
        <div className={classes.closeContainer} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </div>
        {title && (
          <div className={classes.header}>
            {HeaderIcon && <HeaderIcon className={classes.headerIcon} />}
            <span className={classes.title}>{title}</span>
          </div>
        )}
        <div className={classes.content}>{content}</div>
      </div>
    </ReactModal>
  );
}

const useStyles = makeStyles({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  root: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 18,
    boxShadow: '0 27px 24px 0 rgba(0, 0, 0, 0.36)',
  },
  closeContainer: {
    position: 'absolute',
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 22,
    height: 22,
    top: 8,
    right: 8,
    border: 'solid 1.3px #cccbcb',
  },
  closeIcon: {
    fill: '#fff',
    width: 9,
    height: 9,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#1253f7',
    fill: '#fff',
    height: 50,
  },
  headerIcon: {
    fill: '#fff',
    width: 16,
    height: 14,
    marginInlineEnd: '7px',
  },
  title: {
    fontWeight: 'bold',
    color: '#fff',
    position: 'relative',
    top: 2,
    fontSize: 18,
  },
  content: {
    background: '#f3f5f9',
  },
});

export default Modal;
