import React, { memo } from 'react';
import styled from 'styled-components';

import { ReactComponent as BitesLogoIcon } from '../../../../assets/icons/bites-logo.svg';

const OrgLogo: React.FC = () => {
  return (
    <S.LogoContainer>
      <BitesLogoIcon />
    </S.LogoContainer>
  );
};

const S = {
  LogoContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 49px;
  `,
  Logo: styled.img`
    width: 100%;
    height: 100%;
  `,
};

export default memo(OrgLogo);
