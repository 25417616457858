import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StyledPercentage from '../../../../components/StyledPercentage';
import { IBiteShare } from '../../../../types/biteShare';
import { percentage } from '../../../../utils/numbers';

interface IArgs {
  biteShares: IBiteShare[];
}

const useFooterRows = ({ biteShares }: IArgs) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const FooterAnswered = () => {
      const count = sumBy(biteShares, (item) => Number(item.answered));
      const total = sumBy(
        biteShares.filter((bs) => bs.questionType),
        (item) => Number(item.watched),
      );
      return <StyledPercentage percentage={percentage(count, total)} />;
    };

    const FooterAnsweredCorrectly = () => {
      const count = sumBy(
        biteShares.filter((bs) => bs.questionType === 'multiple choices'),
        (item) => Number(item.answeredCorrectly),
      );
      const total = sumBy(
        biteShares.filter((bs) => bs.questionType === 'multiple choices'),
        (item) => Number(item.answered),
      );
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    return [
      {
        render: {
          name: () => t('playlist.bitesLength', { biteLength: biteShares.length }),
          watched: () => sumBy(biteShares, (item) => Number(item.watched)),
          answeredPercentage: FooterAnswered,
          correctAnswerPercentage: FooterAnsweredCorrectly,
        },
      },
    ];
  }, [biteShares, t]);
};

export default useFooterRows;
