import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
// eslint-disable-next-line import/named

import { IMediaFileType } from '../../types/media';

import VideoPlayer from './VideoPlayer';

interface IProps {
  imageUrl: string;
  mediaUrl: string;
  fileType: IMediaFileType;
}

function MediaViewer({ imageUrl, mediaUrl, fileType }: IProps) {
  const classes = useStyles({
    coverImage: imageUrl,
  });
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  if (!fileType) {
    return null;
  }

  return (
    <div style={{ height: '100%' }}>
      {imageUrl && <div className={classes.imageContainer} onClick={() => setIsLightboxOpen(true)}></div>}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={imageUrl}
          onCloseRequest={() => {
            setIsLightboxOpen(false);
          }}
        />
      )}
      {fileType !== 'image' && <VideoPlayer mediaUrl={mediaUrl} fileType={fileType} />}
    </div>
  );
}

const useStyles = makeStyles<Theme, { coverImage: string }>({
  imageContainer: ({ coverImage }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: 12,
    padding: 15,
    cursor: 'pointer',
    background: `url(${coverImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  }),
});

export default MediaViewer;
