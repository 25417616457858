import { makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { JSX } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useComponentWillMount from 'use-component-will-mount';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';

import Table from './components/Table';
import UserWindow from './components/UserWindow';
import { fetchUsers, selectUserManagement } from './userManagement.slice';

function UserManagementScreen() {
  const { t } = useTranslation();
  const [userIdInWindow, setUserIdInWindow] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  useComponentWillMount(() => {
    dispatch(fetchUsers());
  });
  const { isFetching, fetchingError, users } = useSelector(selectUserManagement);

  const handleSelectUser = useCallback(
    (id: number): void => {
      if (id === userIdInWindow) {
        setUserIdInWindow(null);
        return;
      }
      setUserIdInWindow(id);
    },
    [userIdInWindow],
  );

  const renderContents = (): JSX.Element => {
    if (isFetching && users.length === 0) {
      return <Loader />;
    }
    if (fetchingError) {
      return <ErrorMsg>{t('userManagement.error', { error: fetchingError })}</ErrorMsg>;
    }
    return (
      <div className={classes.root}>
        <Table onSelectUser={handleSelectUser} />
        <UserWindow userId={userIdInWindow} onClose={() => setUserIdInWindow(null)} />
      </div>
    );
  };

  return (
    <MainScreen headline='User Management'>
      <MainScreenCard>{renderContents()}</MainScreenCard>
    </MainScreen>
  );
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
});

export default UserManagementScreen;
