import { toast } from 'react-toastify';
import { all, put, takeLatest } from 'redux-saga/effects';

import * as userAnalysisCalls from './userAnalysis.api';
import { fetchFilters, fetchFiltersSuccess, fetchFiltersError, setFetchFiltersIsLoading } from './userAnalysis.slice';

export default function* userAnalysisSaga() {
  yield all([takeLatest(fetchFilters, fetchFiltersSaga)]);
}

function* fetchFiltersSaga(action) {
  try {
    yield put(setFetchFiltersIsLoading());
    const { data } = yield userAnalysisCalls.getFilters({
      ...action.payload,
      start_date: new Date(action.payload.start_date),
      end_date: new Date(action.payload.end_date),
    });
    yield put(fetchFiltersSuccess(data));
  } catch (error) {
    yield put(fetchFiltersError());
    toast.error(error.message);
  }
}
