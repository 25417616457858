import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { fetchAnswerAnalysis, fetchBiteOverview, fetchBiteViews, fetchComments } from '../bite.slice';

const useFetchData = (): void => {
  const dispatch = useDispatch();
  const { id: biteId } = useParams<{ id: string }>();

  useEffect(() => {
    // fetch the bite overview for getCying biteShares, subject and questionType
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchBiteOverview(biteId));
  }, [dispatch, biteId]);

  useEffect(() => {
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchComments(biteId));
    // prefetch to avoid/shorten loading time when navigating to views tab
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchBiteViews({ biteId }));
    // prefetch to avoid/shorten loading time when navigating to answer analysis tab
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchAnswerAnalysis({ biteId }));
  }, [dispatch, biteId]);
};

export default useFetchData;
