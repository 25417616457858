import { Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { JSX } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import BitesSelectionTable from '../../components/BitesSelectionTable';
import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';
import ShareButtonAndManager from '../../components/Share/ShareButtonAndManager';
import { useShareLink } from '../../components/Share/useShareLink';
import { useFilterOutGuestsFromViewsTableUser } from '../../utils/useUsers';
import BiteScreen from '../bite/Bite.screen';
import { selectBite } from '../bite/bite.slice';
import { ContentType } from '../userAnalysis/components/Table/Table';

import { fetchBites, selectBites } from './bites.slice';

function BitesScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isFetching, error, bites } = useSelector(selectBites);
  const { bitePreview: biteShareId, subject, biteViewsByUser, coverUrl } = useSelector(selectBite);
  useEffect(() => {
    dispatch(fetchBites());
  }, [dispatch]);
  const shareablePath = `bites/${biteShareId}`;

  const renderTable = (): JSX.Element => {
    if (error) {
      return <ErrorMsg>{t('bites.error', { error })}</ErrorMsg>;
    }
    if (isFetching && bites.length === 0) {
      return <Loader />;
    }
    if (bites.length !== 0 && !Object.prototype.hasOwnProperty.call(bites[0], 'watched')) {
      return <Loader />;
    }
    return <BitesSelectionTable />;
  };

  const { handleCopyLink, onShareSuccessfulyToast } = useShareLink({
    contentType: ContentType.BITE,
    shareablePath,
    subject,
  });

  const filterOutGuestsFromViewsTableUser = useFilterOutGuestsFromViewsTableUser();

  return (
    <MainScreen
      headline='Bites'
      headerEnd={
        <Hidden mdUp>
          <ShareButtonAndManager
            contentId={biteShareId}
            subject={subject}
            type='bite'
            shareablePath={shareablePath}
            handleCopyLink={handleCopyLink}
            onShareSuccessfuly={onShareSuccessfulyToast}
            usersCount={filterOutGuestsFromViewsTableUser(biteViewsByUser).length}
            shareableCoverUrl={coverUrl}
          />
        </Hidden>
      }
    >
      <MainScreenCard>
        <Switch>
          <Route path='/bites/:id'>
            <BiteScreen />
          </Route>
          <Route path='/bites'>
            <h1>{t('bites.select')}</h1>
            {renderTable()}
          </Route>
        </Switch>
      </MainScreenCard>
    </MainScreen>
  );
}

export default BitesScreen;
