import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import { IButtonProps } from '../../types/button';

import BaseButton from './BaseButton';

function PrimaryButton({ className, ...restOfProps }: IButtonProps) {
  const classes = useStyles();
  return <BaseButton className={cx(classes.primaryButton, className)} {...restOfProps} />;
}

const useStyles = makeStyles({
  primaryButton: {
    color: '#fff',
    background: '#1253fa',
    borderRadius: 38,
    minWidth: 180,
    fontWeight: 'bold',
  },
});
export default PrimaryButton;
