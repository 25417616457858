import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { fetchPlaylistOverview, fetchPlaylistUserView } from '../playlist.slice';

const useFetchData = (): void => {
  const dispatch = useDispatch();
  const { id: playlistId } = useParams<{ id: string }>();

  useEffect(() => {
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchPlaylistOverview(playlistId));
  }, [dispatch, playlistId]);

  useEffect(() => {
    // prefetch to avoid/shorten loading time when navigating to user view tab
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchPlaylistUserView(playlistId));
  }, [dispatch, playlistId]);
};

export default useFetchData;
