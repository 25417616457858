import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/named
import { Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import cx from 'classnames';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

interface IProps {
  text: string;
  percentage: string;
  isCorrect: boolean;
}

function AnswerDistributionItem({ text, percentage, isCorrect }: IProps) {
  const classes = useStyles({ percentage });

  return (
    <div className={cx(classes.root, { [classes.isCorrect]: isCorrect })}>
      <div className={classes.text}>
        <Tooltip title={text}>
          <LinesEllipsis maxLine='3' text={text} />
        </Tooltip>
      </div>
      <div className={classes.percentage}>{percentage}%</div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { percentage: string }>((theme) => ({
  root: {
    minHeight: 42,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    height: 32,
    alignItems: 'center',
    border: '1px solid #E8EEF4',
    borderRadius: 6,
    background: ({ percentage }) =>
      `linear-gradient(to right, #DCE5FE 0, #DCE5FE ${percentage}%, #FFFFFF ${percentage}%, #FFFFFF 100%)`,
  },
  isCorrect: {
    borderColor: '#00BC7B',
  },
  text: {
    maxWidth: '100%',
    paddingLeft: 8,
    marginRight: 16,
    lineHeight: 1.2,
    color: '#6a7784',
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 15,
    },
  },
  percentage: {
    fontSize: 16,
    color: '#1253FA',
    paddingRight: 8,
  },
}));

export default AnswerDistributionItem;
