import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import StyledPercentage from '../../../../components/StyledPercentage';
import { percentage } from '../../../../utils/numbers';
import { selectPlaylist } from '../../playlist.slice';

const useFooterRows = (): any[] => {
  const { playlistViewsByUser, totalQuestions } = useSelector(selectPlaylist);

  return useMemo(() => {
    const BitesFooter = () => {
      const count = sumBy(playlistViewsByUser, (playlist) => Number(playlist.watched));
      const total = sumBy(playlistViewsByUser, 'total');
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    const AnsweredFooter = () => {
      const count = sumBy(playlistViewsByUser, (playlist) => Number(playlist.answered));
      const total = playlistViewsByUser.length * totalQuestions;
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    const AnsweredCorrectlyFooter = () => {
      const count = sumBy(playlistViewsByUser, (playlist) => Number(playlist.answeredCorrectly));
      const total = sumBy(playlistViewsByUser, (playlist) => Number(playlist.answered));
      return <StyledPercentage percentage={percentage(count, total)} />;
    };
    return [
      {
        render: {
          name: () => `${playlistViewsByUser.length} Users`,
          bites: BitesFooter,
          answered: AnsweredFooter,
          answeredCorrectly: AnsweredCorrectlyFooter,
        },
      },
    ];
  }, [playlistViewsByUser, totalQuestions]);
};

export default useFooterRows;
