import { Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { JSX } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';
import PlaylistSelectionTable from '../../components/PlaylistSelectionTable';
import ShareButtonAndManager from '../../components/Share/ShareButtonAndManager';
import { useShareLink } from '../../components/Share/useShareLink';
import { useFilterOutGuestsFromViewsTableUser } from '../../utils/useUsers';
import PlaylistScreen from '../playlist/Playlist.screen';
import { selectPlaylist } from '../playlist/playlist.slice';
import { ContentType } from '../userAnalysis/components/Table/Table';

import { fetchPlaylists, selectPlaylists } from './playlists.slice';

function PlaylistsScreen() {
  const dispatch = useDispatch();
  const { isFetching, error, playlists, fetched } = useSelector(selectPlaylists);
  const { id, name, playlistViewsByUser, coverUrl } = useSelector(selectPlaylist);
  const shareablePath = `playlists/${id}`;

  useEffect(() => {
    if (!isFetching && !fetched) {
      dispatch(fetchPlaylists());
    }
  }, [dispatch, fetched, isFetching, playlists]);

  const renderTable = (): JSX.Element => {
    if (error) {
      return <ErrorMsg>Error loading playlists: {error}</ErrorMsg>;
    }
    if (isFetching && playlists.length === 0) {
      return <Loader />;
    }
    return <PlaylistSelectionTable />;
  };

  const { handleCopyLink, onShareSuccessfulyToast } = useShareLink({
    contentType: ContentType.PLAYLIST,
    shareablePath,
    subject: name,
  });

  const filterOutGuestsFromViewsTableUser = useFilterOutGuestsFromViewsTableUser();

  return (
    <MainScreen
      headline='Playlists'
      headerEnd={
        <Hidden mdUp>
          <ShareButtonAndManager
            contentId={id}
            subject={name}
            type='playlist'
            shareablePath={shareablePath}
            handleCopyLink={handleCopyLink}
            onShareSuccessfuly={onShareSuccessfulyToast}
            usersCount={filterOutGuestsFromViewsTableUser(playlistViewsByUser).length}
            shareableCoverUrl={coverUrl}
          />
        </Hidden>
      }
    >
      <MainScreenCard>
        <Switch>
          <Route path='/playlists/:id'>
            <PlaylistScreen />
          </Route>
          <Route path='/playlists'>
            <h1>Select a playlist</h1>
            {renderTable()}
          </Route>
        </Switch>
      </MainScreenCard>
    </MainScreen>
  );
}

export default PlaylistsScreen;
