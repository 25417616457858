import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import BitesLogo from '../../../../assets/images/bite-logo.png';
import Button from '../../../Buttons/Button';
import { basicMarkdownHandler, replaceDistributionTextPlaceholders } from '../utils';

type TMessageEmailPreviewProps = {
  shareableTitle: string;
  shareableCoverUrl: string;
  organizationName: string;
  subject: string;
  content1: string;
  content2?: string;
};

const MessageEmailPreview: React.FC<TMessageEmailPreviewProps> = ({
  shareableCoverUrl,
  shareableTitle,
  organizationName,
  subject,
  content1,
  content2,
}) => {
  const classes = useStyles();

  const content1Rendered = useMemo(
    () =>
      replaceDistributionTextPlaceholders({
        title: basicMarkdownHandler(content1),
        fullName: 'John Doe',
        organizationName,
        biteName: shareableTitle,
      }),
    [content1, organizationName, shareableTitle],
  );

  const content2Rendered = useMemo(
    () =>
      content2
        ? replaceDistributionTextPlaceholders({
            title: basicMarkdownHandler(content2),
            fullName: 'John Doe',
            organizationName,
            biteName: shareableTitle,
          })
        : '',
    [content2, organizationName, shareableTitle],
  );

  return (
    <>
      <b>Subject:</b>
      <div className={classes.wrapper}>
        <div>
          {replaceDistributionTextPlaceholders({
            title: subject,
            organizationName,
            biteName: shareableTitle,
          })}
        </div>
      </div>
      <div className={classes.spacer} />
      <b>Content:</b>
      <div className={classes.wrapper}>
        <div>
          <div className={classes.spaceBottom} dangerouslySetInnerHTML={{ __html: content1Rendered }} />
          <div>
            <Button width='fit-content' className={classes.emailPreviewButton} isBigText={false} height={22} disabled>
              Watch now!
            </Button>
          </div>
          <div
            className={classNames(classes.emailPreviewCallToAction)}
            dangerouslySetInnerHTML={{ __html: content2Rendered }}
          />
          <div className={classNames(classes.emailPreviewCallToAction, classes.contentBottom)}>
            <img className={classes.emailCoverImage} src={shareableCoverUrl ?? BitesLogo} alt='logo' />
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageEmailPreview;

const useStyles = makeStyles({
  emailPreviewContent: {
    paddingBottom: '2px',
  },
  emailPreviewCallToAction: {
    margin: '16px 0',
  },
  emailCoverImage: {
    maxHeight: '125px',
    maxWidth: '75%',
  },
  emailPreviewButton: {
    padding: '2px 6px',
    margin: 'auto',
  },
  contentBottom: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    backgroundColor: '#f9f9f9',
    borderRadius: 3,
    padding: 8,
  },
  spacer: {
    height: 1,
    width: '100%',
    backgroundColor: 'black',
    margin: '16px 0',
  },
  spaceBottom: {
    marginBottom: 16,
  },
});
