import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';

import AnswerAnalysis from '../answerAnalysis/AnswerAnalysis';
import Discussion from '../discussion/Discussion';
import Overview from '../overview/Overview';
import Views from '../views/Views';

function BiteScreenContent() {
  const classes = useStyles();

  const { id: biteId } = useParams<{ id: string }>();

  return (
    <div className={classes.content}>
      <Switch>
        <Route path='/bites/:id/overview'>
          <Overview />
        </Route>
        <Route path='/bites/:id/views'>
          <Views />
        </Route>
        <Route path='/bites/:id/answer-analysis'>
          <AnswerAnalysis />
        </Route>
        <Route path='/bites/:id/discussion'>
          <Discussion />
        </Route>
        <Route path='/bites/:id/'>
          <Redirect to={`/bites/${biteId}/overview`} />
        </Route>
      </Switch>
    </div>
  );
}

const useStyles = makeStyles({
  content: {
    marginTop: 24,
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default BiteScreenContent;
