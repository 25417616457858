import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../components/EllipsisWithTooltip';
import ExternalLink from '../../../components/ExternalLink';
import { IBiteShare } from '../../../types/biteShare';
import { formatDateToRelativeTime } from '../../../utils/dates';
import { tokenSelector } from '../../auth/auth.selectors';

interface IProps {
  biteShares: IBiteShare[];
}

function BiteSharesList({ biteShares }: IProps) {
  const token = useSelector(tokenSelector);
  const classes = useStyles();

  return (
    <div className={classes.root} data-cy='biteSharesList'>
      {biteShares.map((bs) => (
        <ExternalLink
          href={`https://mybiteshares.com/bites/${bs.id}/?token=${token}`}
          className={classes.biteShare}
          key={bs.id}
        >
          <div className={classes.img} style={{ backgroundImage: `url(${bs.coverUrl})` }} />
          <EllipsisWithTooltip className={classes.time} text={formatDateToRelativeTime(bs.createdAt)} />
          <EllipsisWithTooltip className={classes.name} text={bs.name} />
        </ExternalLink>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 20,
    },
  },
  biteShare: {
    width: 120,
    float: 'left',
    marginRight: 10,
  },
  img: {
    height: 100,
    borderRadius: 20,
    marginBottom: 10,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  time: {
    marginBottom: 7,
    opacity: 0.4,
    color: '#344356',
  },
  name: {
    fontSize: 15,
    color: '#304358',
  },
}));

export default BiteSharesList;
