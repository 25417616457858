import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

import Overview from '../overview/Overview';
import QuizAnalysis from '../quizAnalysis/QuizAnalysis';
import UserView from '../userView/UserView';

function QuizScreenContent() {
  const classes = useStyles();
  const { id: quizId } = useParams<{ id: string }>();

  return (
    <div className={classes.content}>
      <Switch>
        <Route path='/quizzes/:id/overview'>
          <Overview />
        </Route>
        <Route path='/quizzes/:id/quiz-analysis'>
          <QuizAnalysis />
        </Route>
        <Route path='/quizzes/:id/user-view'>
          <UserView />
        </Route>

        <Route path='/quizzes/:id/'>
          <Redirect to={`/quizzes/${quizId}/overview`} />
        </Route>
      </Switch>
    </div>
  );
}

const useStyles = makeStyles({
  content: {
    marginTop: 24,
  },
});

export default QuizScreenContent;
