import { makeStyles } from '@material-ui/core';
import React, { memo, useState, useCallback, useEffect } from 'react';

import Modal from '../../../../components/Modal';

import DownloadCSV from './pages/bulkUpload/downloadCsv';
import Instruction from './pages/bulkUpload/instruction';
import PreviewValidation from './pages/bulkUpload/previewValidation';
import SuccessPage from './pages/bulkUpload/success';
import UploadCSV from './pages/bulkUpload/uploadCSV';
import ValidationError from './pages/bulkUpload/validationError';

interface IProps {
  isVisible: boolean;
  onClose: (evt: any) => void;
}

export interface IPage {
  setPage: (evt: EPagesSyncUpload) => void;
}

export interface ISuccessSingleUser {
  setOpenModal: (evt: any) => void;
  setPage: (evt: EPagesSyncUpload) => void;
}

export enum EPagesSyncUpload {
  DOWNLOAD_CSV = 'DOWNLOAD',
  INSTRUCTION = 'INSTRUCTION',
  UPLOAD_CSV = 'UPLOAD_CSV',
  PREVIEW = 'PREVIEW',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const UploadUsersModal = (props: IProps) => {
  const { isVisible, onClose } = props;
  const [pageModal, setPageModal] = useState(EPagesSyncUpload.DOWNLOAD_CSV);

  const [uploadDetails, setUploadDetails] = useState(null);

  const [validationErrorList, setValidationErrorList] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setPageModal(EPagesSyncUpload.DOWNLOAD_CSV);
    }
  }, [isVisible]);

  const PageModal = useCallback(() => {
    switch (pageModal) {
      case EPagesSyncUpload.DOWNLOAD_CSV:
        return <DownloadCSV setPage={setPageModal} />;
      case EPagesSyncUpload.INSTRUCTION:
        return <Instruction setPage={setPageModal} />;
      case EPagesSyncUpload.UPLOAD_CSV:
        return (
          <UploadCSV
            setPage={setPageModal}
            setUploadDetails={setUploadDetails}
            setValidationErrorList={setValidationErrorList}
          />
        );
      case EPagesSyncUpload.PREVIEW:
        return <PreviewValidation setPage={setPageModal} uploadDetails={uploadDetails} />;
      case EPagesSyncUpload.SUCCESS:
        return <SuccessPage onClose={onClose} />;
      case EPagesSyncUpload.ERROR:
        return <ValidationError setPage={setPageModal} validationErrorList={validationErrorList} />;
    }
  }, [pageModal, onClose, uploadDetails, validationErrorList]);

  const classes = useStyles();

  return (
    <Modal
      title=''
      HeaderIcon=''
      className={classes.modal}
      isOpen={isVisible}
      onClose={onClose}
      content={<PageModal />}
    />
  );
};

export default memo(UploadUsersModal);

const useStyles = makeStyles({
  modal: {
    width: 650,
    position: 'absolute',
  },
});
