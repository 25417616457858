import { makeStyles, Tabs } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LinkTab from '../../../components/LinkTab';
import gtmTrack from '../../../services/googleTagManager/track';

interface IProps {
  selectedTabIndex: number;
  onChangeTab: (evt: any, tabIndex: number) => void;
}

function PlaylistScreenTabs({ selectedTabIndex, onChangeTab }: IProps) {
  const { t } = useTranslation();
  const { id: playlistId } = useParams<{ id: string }>();
  const classes = useStyles();

  const onOverviewClick = () => {
    gtmTrack('playlists_overview', { playlist_id: playlistId });
  };

  const onAnalysisClick = () => {
    gtmTrack('playlists_playlist_analysis', { playlist_id: playlistId });
  };

  const onUserViewClick = () => {
    gtmTrack('playlists_playlist_user_view', { playlist_id: playlistId });
  };

  return (
    <div>
      <Tabs
        value={selectedTabIndex}
        indicatorColor='primary'
        textColor='primary'
        onChange={onChangeTab}
        classes={{
          root: classes.tabs,
        }}
      >
        <LinkTab
          className={classes.tab}
          label={t('playlist.overview')}
          to={`/playlists/${playlistId}/overview`}
          data-cy='tabOverview'
          onClick={onOverviewClick}
        />
        <LinkTab
          className={classes.tab}
          label={t('playlist.analysis')}
          to={`/playlists/${playlistId}/playlist-analysis`}
          data-cy='tabAnalysis'
          onClick={onAnalysisClick}
        />
        <LinkTab
          className={classes.tab}
          label={t('playlist.userView')}
          to={`/playlists/${playlistId}/user-view`}
          data-cy='tabUserView'
          onClick={onUserViewClick}
        />
      </Tabs>
      <div className={classes.tabBottomBorder} />
    </div>
  );
}

const useStyles = makeStyles({
  tabs: {
    minHeight: 'auto',
    '& a': { minHeight: 'auto', paddingBottom: 0 },
  },
  tab: {
    textTransform: 'none',
  },
  tabBottomBorder: {
    background: '#EDEDF2',
    height: 2,
    marginTop: -2,
  },
});

export default PlaylistScreenTabs;
