import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import React, { memo, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import EllipsisWithTooltip from '../../EllipsisWithTooltip';
import SearchInput from '../../Inputs/SearchInput';

import chooseGroupsModalStyles from './chooseGroupsModalStyles';
import ChooseGroupsRowItem from './ChooseGroupsRowItem';
import { scrollContentMaxHeight } from './consts';

type TGroupAccordionProps = {
  selectedGroupsIds: number[];
  isOpen: boolean;
  title: string;
  options: Array<{ id: number; name: string }>;
  index: number;
  onToggleOpen: (index: number) => void;
  onToggleAllOptions: (index: number) => void;
  onToggleOption: (id: number) => void;
  isLast: boolean;
};

const GroupAccordion: React.FC<TGroupAccordionProps> = ({
  selectedGroupsIds,
  isOpen,
  title,
  options,
  index,
  onToggleOpen,
  onToggleAllOptions,
  onToggleOption,
  isLast,
}) => {
  const optionsIds = useMemo(() => options?.map(({ id }) => id), [options]);
  const classes = useStyles({ isOpen, isLast });
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(() => {
    return options?.filter((o) => o.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [options, searchValue]);

  const listData = useMemo(
    () => ({
      items: filteredOptions,
      selectedGroupsIds,
      onToggleOption,
    }),
    [filteredOptions, selectedGroupsIds, onToggleOption],
  );

  const areAllOptionsSelected = useMemo(
    () => optionsIds.every((oid) => selectedGroupsIds.includes(oid)),
    [selectedGroupsIds, optionsIds],
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleAndArrow} onClick={() => onToggleOpen(index)}>
          <div className={classes.title}>
            <EllipsisWithTooltip text={title} />
          </div>
          <div className={classes.arrow} />
        </div>
        {isOpen && (
          <div>
            <SearchInput value={searchValue} onChange={setSearchValue} />
          </div>
        )}
      </div>
      <div className={classes.options}>
        <div className={classes.option}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={() => onToggleAllOptions(index)}
                checked={areAllOptionsSelected}
                id='option-all'
              />
            }
            label='All'
          />
        </div>

        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              height={scrollContentMaxHeight}
              itemCount={filteredOptions?.length || 0}
              itemData={listData}
              itemSize={35}
              width={width}
            >
              {ChooseGroupsRowItem}
            </List>
          )}
        </AutoSizer>
      </div>
    </>
  );
};

const useStyles = makeStyles<Theme, { isLast: boolean; isOpen: boolean }>({
  header: {
    borderBottom: ({ isLast }) => (isLast ? 'none' : 'solid 3px #e8eef4'),
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 46,
    paddingRight: 16,
  },
  titleAndArrow: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 150px',
    marginInlineEnd: '10px',
  },
  title: {
    fontSize: 16,
    color: '#333',
    marginRight: 10,
    maxWidth: 134,
  },
  arrow: {
    borderBottom: '1px solid #000',
    borderRight: '1px solid #000',
    width: 6,
    height: 6,
    transform: ({ isOpen }) => (isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'),
  },
  options: {
    background: '#fff',
    display: ({ isOpen }) => (isOpen ? 'block' : 'none'),
    paddingLeft: 26,
    maxHeight: 280,
    '&::-webkit-scrollbar': {
      visibility: 'initial',
      display: 'initial',
    },
  },
  option: chooseGroupsModalStyles.option,
});

export default memo(GroupAccordion);
