import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AnswerDistributionChart from '../../../components/AnswerDistribution/AnswerDistributionChart';
import { percentage } from '../../../utils/numbers';
import { selectPlaylist } from '../playlist.slice';

import Table from './Table';

function PlaylistAnalysis() {
  const { t } = useTranslation();
  const { biteShares } = useSelector(selectPlaylist);
  const classes = useStyles();

  const { answered, answeredCorrectly } = React.useMemo(
    () =>
      biteShares.reduce(
        (acc, biteShare) => {
          if (!biteShare || !biteShare.answered || !biteShare.answeredCorrectly) {
            return acc;
          }

          acc.answered += Number(biteShare.answered);
          acc.answeredCorrectly += Number(biteShare.answeredCorrectly);
          return acc;
        },
        { answered: 0, answeredCorrectly: 0 },
      ),
    [biteShares],
  );

  return (
    <div className={classes.root}>
      <div className={classes.charts}>
        <div>
          <strong>{t('playlist.correctAnswerRatio')}</strong>
        </div>
        <div className={classes.answerChartWrap}>
          <AnswerDistributionChart hideAnswersCount answers={formatAnswers(answered, answeredCorrectly)} />
        </div>
        {/* Uncomment and connect to real data once we can track the
        number of users each bite has been sent to */}
        {/* <ViewedChart viewed={150} didntView={200} /> */}
      </div>
      <div className={classes.tableWrap}>
        <Table biteShares={biteShares} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },
  },
  tableWrap: {
    flex: 1,
  },
  charts: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 24,
      width: '28%',
    },
  },
  answerChartWrap: {
    marginBottom: 40,
  },
}));

export default PlaylistAnalysis;

function formatAnswers(answered: number, answeredCorrectly: number) {
  const answeredWrongly = answered - answeredCorrectly;
  return [
    {
      text: 'Correct',
      votes: answeredCorrectly,
      isCorrect: true,
      percentage: percentage(answeredCorrectly, answered),
    },
    {
      text: 'Incorrect',
      votes: answeredWrongly,
      isCorrect: false,
      percentage: percentage(answeredWrongly, answered),
    },
  ];
}
