import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { JSX } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorMsg from '../../../components/ErrorMsg';
import Loader from '../../../components/Loader';
import PlaylistSelectionTable from '../../../components/PlaylistSelectionTable';
import { selectPlaylists } from '../../playlists/playlists.slice';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function PlaylistSelectionDialog({ isOpen, onClose }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isFetching, error } = useSelector(selectPlaylists);

  const renderTable = (): JSX.Element => {
    if (error) {
      return <ErrorMsg>{t('playlist.error', { error })}</ErrorMsg>;
    }
    if (isFetching) {
      return <Loader />;
    }
    return <PlaylistSelectionTable onPlaylistSelect={onClose} />;
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='xl' aria-labelledby='max-width-dialog-title'>
      <DialogTitle id='max-width-dialog-title'>{t('playlist.selectPlaylist')}</DialogTitle>
      <DialogContent className={classes.content}>{renderTable()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  content: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default PlaylistSelectionDialog;
