import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

interface IProps {
  value: string;
  onChange: (evt: any) => void;
  className?: string;
  placeholder?: string;
}

function Textarea({ className, placeholder, onChange, value }: IProps) {
  const classes = useStyles();
  return (
    <textarea
      onChange={(evt) => onChange(evt.target.value)}
      className={cx(classes.textarea, className)}
      placeholder={placeholder}
      value={value}
    />
  );
}

const useStyles = makeStyles({
  textarea: {
    background: '#fff',
    color: '#000',
    fontSize: 12,
    border: 'none',
    resize: 'none',
    width: '100%',
    outline: 'none',
    minHeight: 82,
    padding: 8,
    borderRadius: 10,
  },
});

export default Textarea;
