import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { phoneFormSelector } from '../../../authForm/authForm.selectors';
import { loginWithPhone } from '../../../authForm/authForm.slice';
import { log } from '../../../tracking/tracking.slice';
import { EAuthFormType } from '../../auth.types';

import EnterCodeForm from './EnterCodeForm';

const EnterPhoneCode = () => {
  const dispatch = useDispatch();

  const phone = useSelector(phoneFormSelector);
  const currentForm = EAuthFormType.ENTER_CODE_PHONE;

  const handleContinue = useCallback(
    (otp: string) => {
      const processId = uuid();

      dispatch(
        log({
          event: 'EnterPhoneCode.handleContinue',
          processId,
          data: { username: phone.trim(), otp, currentForm },
        }),
      );

      dispatch(
        loginWithPhone({
          username: phone.trim(),
          otp,
          processId,
        }),
      );
    },
    [dispatch, currentForm, phone],
  );

  const handleResend = useCallback(() => {
    const processId = uuid();

    dispatch(
      log({
        event: 'EnterPhoneCode.handleResend',
        processId,
        data: {
          currentForm,
          username: phone.trim(),
        },
      }),
    );

    dispatch(
      loginWithPhone({
        username: phone.trim(),
      }),
    );
  }, [dispatch, currentForm, phone]);

  return <EnterCodeForm onContinue={handleContinue} onResend={handleResend} destination={phone} />;
};

export default memo(EnterPhoneCode);
