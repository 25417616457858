import { createSlice } from '@reduxjs/toolkit';

import { IFullPlaylist, ILeanPlaylist } from '../../types/playlist';
import { IRootState } from '../../types/store';
import { mergeLeanAndFullCollections } from '../../utils/collections';

const playlistsSlice = createSlice({
  name: 'playlists',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const { fetchPlaylists, fetchLeanPlaylistsSuccess, fetchFullPlaylistsSuccess, fetchPlaylistsError } =
  playlistsSlice.actions;

export default playlistsSlice.reducer;

export const selectPlaylists = (state: IRootState) => state.playlists;

function getInitialState() {
  return {
    isFetching: false,
    error: '',
    playlists: [] as (ILeanPlaylist | IFullPlaylist)[],
    fetched: false,
  };
}

type IPlaylistsState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}

function getReducers() {
  return {
    fetchPlaylists: (state: IPlaylistsState) => {
      state.isFetching = true;
      state.error = '';
      state.fetched = true;
    },
    fetchLeanPlaylistsSuccess: (state: IPlaylistsState, action) => {
      const leanPlaylists: ILeanPlaylist[] = action.payload;
      state.isFetching = false;
      state.playlists = mergeLeanAndFullCollections<ILeanPlaylist, ILeanPlaylist | IFullPlaylist>(
        leanPlaylists,
        state.playlists,
      );
    },
    fetchFullPlaylistsSuccess: (state: IPlaylistsState, action) => {
      state.isFetching = false;
      state.playlists = action.payload;
    },
    fetchPlaylistsError: (state: IPlaylistsState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
  };
}
