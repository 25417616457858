import { List, ListItem } from '@material-ui/core';
import fileDownload from 'js-file-download';
import React, { memo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaDownload, FaFileUpload, FaUserEdit, FaUserCheck, FaUserTimes } from 'react-icons/fa';
import { TbHierarchy3 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as TwoPeople } from '../../../../../../assets/icons/icons-invite-default.svg';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import Loader from '../../../../../../components/Loader';
import { useIsMounted } from '../../../../../../hooks/useIsMounted';
import { Feature } from '../../../../../../types/feature';
import { EOrgUsersPrimaryKey } from '../../../../../../types/org';
import { selectOrg } from '../../../../../org/org.slice';
import { generateCsvTemplate } from '../../../../userManagement.api';
import { IPage, EPagesSyncUpload } from '../../SyncUploadModal';
import { ErrorMessageComponent, HelpHelper, iconSize, modalColors, StatusBarHelper } from '../../ui';

const UserPkToDisplay = {
  [EOrgUsersPrimaryKey.Email]: 'Email',
  [EOrgUsersPrimaryKey.Phone]: 'Phone Number',
  [EOrgUsersPrimaryKey.EmployeeId]: 'Employee ID',
};

const ModelButtonStyle = {
  flex: 1,
  marginRight: 5,
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
};
const contentStyle = { marginBottom: '10px', fontWeight: 'bold' };
const headerStyle = { marginBottom: '35px' };
const iconStyle = { width: iconSize, height: iconSize };

const generateFileName = (id: number) => {
  const today = new Date();
  const dateStr = `${today.getDate()}_${
    today.getMonth() + 1
  }_${today.getFullYear()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
  return `${id}_${dateStr}`;
};

const DownloadCSV = (props: IPage) => {
  const { t } = useTranslation();
  const org = useSelector(selectOrg);
  const { setPage } = props;
  const { id, usersPrimaryKey } = useSelector(selectOrg);
  const isMountedRef = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownloadFileClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data }: any = await generateCsvTemplate(id);
      if (!isMountedRef.current) {
        return;
      }
      const blob = new Blob([new Uint8Array(data.data)]);
      fileDownload(blob, `${generateFileName(id)}.xlsx`);

      setPage(EPagesSyncUpload.INSTRUCTION);
      setIsLoading(false);
    } catch (e) {
      setError(t('common.error'));
      setIsLoading(false);
    }
  }, [id, isMountedRef, setPage, t]);

  const mandatory = usersPrimaryKey ?? usersPrimaryKey ?? EOrgUsersPrimaryKey.Phone;
  const [firstOptional, secondOptional] = Object.values(EOrgUsersPrimaryKey).filter((pk) => pk !== mandatory);

  return (
    <S.Wrapper>
      <S.TextHeader style={headerStyle}>{t('userManagement.pages.headerText')}</S.TextHeader>
      <S.IconWrapper>
        <TwoPeople style={iconStyle} />
      </S.IconWrapper>
      <S.Content style={contentStyle}>{t('userManagement.downloadCsv.letsGetStarted')}</S.Content>
      <S.Step>
        <S.StepIcon>
          <FaDownload />
        </S.StepIcon>
        <S.StepContent>
          <S.StepTitle>{t('userManagement.explanation.download.title')}</S.StepTitle>
          {t('userManagement.explanation.download.description')}
        </S.StepContent>
      </S.Step>
      <S.Step>
        <S.StepIcon>
          <FaUserEdit />
        </S.StepIcon>
        <S.StepContent>
          <S.StepTitle>{t('userManagement.explanation.details.title')}</S.StepTitle>
          <Trans>{t('userManagement.explanation.details.mandatory', { column: UserPkToDisplay[mandatory] })}</Trans>
          <Trans>
            {t('userManagement.explanation.details.optional', {
              firstOptional: UserPkToDisplay[firstOptional],
              secondOptional: UserPkToDisplay[secondOptional],
            })}
          </Trans>
        </S.StepContent>
      </S.Step>
      {org.features.length > 0 && org.features.includes(Feature.ORG_CHART) && (
        <S.Step>
          <S.StepIcon>
            <TbHierarchy3 />
          </S.StepIcon>
          <S.StepContent>
            <S.StepTitle>{t('userManagement.orgchart.title')}</S.StepTitle>
            <List dense={true} style={{ paddingInlineStart: 0, fontSize: 16 }}>
              <ListItem style={{ paddingRight: 0, paddingLeft: 0, display: 'block' }}>
                <Trans>{t('userManagement.orgchart.pkRule', { pk: UserPkToDisplay[mandatory] })}</Trans>
              </ListItem>
              <ListItem style={{ paddingRight: 0, paddingLeft: 0, display: 'block' }}>
                <Trans>{t('userManagement.orgchart.existRule', { pk: UserPkToDisplay[mandatory] })}</Trans>
              </ListItem>
              <ListItem style={{ paddingRight: 0, paddingLeft: 0, display: 'block' }}>
                <Trans>{t('userManagement.orgchart.emptyRule')}</Trans>
              </ListItem>
            </List>
          </S.StepContent>
        </S.Step>
      )}
      <S.Step>
        <S.StepIcon>
          <FaFileUpload />
        </S.StepIcon>
        <S.StepContent>
          <S.StepTitle>{t('userManagement.explanation.upload.title')}</S.StepTitle>
          {t('userManagement.explanation.upload.description')}
          <S.List>
            <S.Item>
              <S.StepItemIcon>
                <FaUserCheck />
              </S.StepItemIcon>
              <Trans>{t('userManagement.explanation.upload.newItem')}</Trans>
            </S.Item>
            <S.Item>
              <S.StepItemIcon>
                <FaUserEdit />
              </S.StepItemIcon>
              <Trans>{t('userManagement.explanation.upload.updateItem', { matchKey: mandatory })}</Trans>
            </S.Item>
            <S.Item>
              <S.StepItemIcon>
                <FaUserTimes />
              </S.StepItemIcon>
              <Trans>{t('userManagement.explanation.upload.deleteItem')}</Trans>
            </S.Item>
          </S.List>
        </S.StepContent>
      </S.Step>
      <S.WornNote>{t('userManagement.explanation.warning')}</S.WornNote>
      {error ? <ErrorMessageComponent message={error} /> : null}

      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Controllers>
          <S.ModelButton style={ModelButtonStyle} onClick={handleDownloadFileClick}>
            {t('userManagement.downloadCsv.downloadFile')}
          </S.ModelButton>
        </S.Controllers>
      )}
      <HelpHelper />
      <StatusBarHelper numOfActiveItems={1} />
    </S.Wrapper>
  );
};

const S = {
  Wrapper: styled.div`
    overflow: scroll;
    height: ${window.innerHeight - 200}px;
    padding: 40px;
    background-color: white;
  `,
  HelpWrapper: styled.div`
    position: absolute;
    bottom: 50px;
    width: 80%;
    right: 60px;
    margin-bottom: 40px;
  `,
  TextHeader: styled.div`
    font-weight: bold;
    font-size: 25px;
    font-family: 'Uni Sans SemiBold';
    margin-bottom: 20px;
    text-align: center;
    color: ${modalColors.headerColor};
    font-family: 'Gilroy';
  `,
  Controllers: styled.div`
    align-items: center;
  `,
  IconWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Content: styled.p`
    white-space: pre;
    font-size: 24px;
    color: gray;
    text-align: center;
    font-family: 'Gilroy';
  `,
  LoaderWrapper: styled.div`
    position: relative;
  `,
  ModelButton: styled(PrimaryButton)`
    border-radius: 5px;
    font-size: 14px;
    min-width: 0px;
    padding-left: 30px;
    padding-right: 30px;
  `,

  Step: styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
  `,
  StepIcon: styled.div`
    font-size: 28px;
    margin-right: 20px;
    color: #4a90e2;
  `,
  StepContent: styled.div`
    flex: 1;
    font-size: 18px;
    color: #555;
  `,
  StepTitle: styled.h3`
    font-size: 20px;
    font-weight: 700;
  `,
  List: styled.ul`
    margin-top: 10px;
    list-style-type: none;
    margin-left: 20px;
  `,
  Item: styled.li`
    display: flex;
  `,
  StepItemIcon: styled.div`
    margin-right: 10px;
  `,
  WornNote: styled.p`
    font-weight: 600;
    color: #d32f2f;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
  `,
};

export default memo(DownloadCSV);
