import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { fetchQuizOverview, fetchQuizUserView } from '../quiz.slice';

const useFetchData = () => {
  const dispatch = useDispatch();
  const { id: quizId } = useParams<{ id: string }>();

  useEffect(() => {
    // @ts-ignore// @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchQuizOverview(quizId));
  }, [dispatch, quizId]);

  useEffect(() => {
    // prefetch to avoid/shorten loading time when navigating to user view tab
    // @ts-ignore there's no argument in the reducer action but there's on the saga
    dispatch(fetchQuizUserView(quizId));
  }, [dispatch, quizId]);
};

export default useFetchData;
