import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { percentage } from '../../../../utils/numbers';
import { sort } from '../../../../utils/reactDataGrid';
import { selectBite } from '../../bite.slice';

interface IArgs {
  groupedByDataIndex: number;
}

const useDataSource = ({ groupedByDataIndex }: IArgs) => {
  const { t } = useTranslation();
  const { answerAnalysisByUserSortInfo, answerAnalysisByUser } = useSelector(selectBite);

  return useMemo(() => {
    const result = answerAnalysisByUser
      .reduce((acc, user) => {
        const dataGroupName = user.datas[groupedByDataIndex] || t('bites.empty');
        const existingGroup = acc.find((item) => item.name === dataGroupName);
        if (!existingGroup) {
          acc.push({
            name: dataGroupName,
            answered: Number(!!user.answer),
            answeredCorrectly: Number(user.answeredCorrectly),
            recordsCount: 1,
          });
        } else {
          existingGroup.answered += Number(!!user.answer);
          existingGroup.answeredCorrectly += Number(user.answeredCorrectly);
          existingGroup.recordsCount += 1;
        }
        return acc;
      }, [])
      .map((item) => ({
        ...item,
        answeredPercentage: percentage(item.answered, item.recordsCount),
        answeredCorrectlyPercentage: percentage(Number(item.answeredCorrectly), item.answered),
      }));
    return sort(result, answerAnalysisByUserSortInfo);
  }, [t, answerAnalysisByUser, groupedByDataIndex, answerAnalysisByUserSortInfo]);
};

export default useDataSource;
