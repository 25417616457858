import { debounce } from 'lodash';
import sumBy from 'lodash/sumBy';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import ReactDataGridSearchableTable from '../../../../components/ReactDataGrid/SearchableTable';
import gtmTrack from '../../../../services/googleTagManager/track';
import { selectBite, setBiteViewsByUserSortInfo } from '../../bite.slice';

import useColumns from './useColumns';
import useDataSource from './useDataSource';

function OverviewTable({ groupedByDataIndex }) {
  const dispatch = useDispatch();
  const { biteViewsByUserSortInfo } = useSelector(selectBite);
  const columns = useColumns({ groupedByDataIndex });
  const dataSource = useDataSource({ groupedByDataIndex });

  const footerRows = useMemo(() => {
    const ViewedPercentageFooter = () => {
      const count = sumBy(dataSource, 'viewed');
      return <PercentsAndNumbers count={count} total={sumBy(dataSource, 'recordsCount')} />;
    };

    return [
      {
        render: {
          name: () => `${sumBy(dataSource, 'recordsCount')} Users`,
          viewedPercentage: ViewedPercentageFooter,
        },
      },
    ];
  }, [dataSource]);

  const handleSort = useCallback(
    (value) => {
      const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value;
      dispatch(setBiteViewsByUserSortInfo(newSortInfo));
    },
    [dispatch],
  );

  const handleSearch = (term) => {
    trackSearch(term);
  };

  return (
    <ReactDataGridSearchableTable
      onSortInfoChange={handleSort}
      sortInfo={biteViewsByUserSortInfo}
      rawData={dataSource}
      columns={columns}
      onSearch={handleSearch}
      style={{ minHeight: window.innerHeight - 200 }}
      footerRows={footerRows}
      origin='bite_views_table'
    />
  );
}
export default OverviewTable;

const trackSearch = debounce((term) => gtmTrack('bites_bite_overview_department_search', { term }), 1000);
