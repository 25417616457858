import { toast } from 'react-toastify';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { IUserOrganization } from '../../types/user';
import { selectOrg } from '../org/org.slice';

import * as userManagementCalls from './userManagement.api';
import {
  bulkBlock,
  bulkBlockSuccess,
  bulkResetPassword,
  bulkUnblock,
  bulkUnblockSuccess,
  fetchUsers,
  fetchUsersError,
  fetchUsersSuccess,
  resetPassword,
  undoUpdateUserField,
  undoUpdateUserFieldError,
  undoUpdateUserFieldSuccess,
  updateUserField,
  updateUserFieldError,
  updateUserFieldSuccess,
} from './userManagement.slice';

export default function* userManagementSaga() {
  yield all([
    takeLatest(fetchUsers, fetchUsersSaga),
    takeLatest(updateUserField, updateUserFieldSaga),
    takeLatest(undoUpdateUserField, undoUpdateUserFieldSaga),
    takeLatest(resetPassword, resetPasswordSaga),
    takeLatest(bulkResetPassword, bulkResetPasswordSaga),
    takeLatest(bulkBlock, bulkBlockSaga),
    takeLatest(bulkUnblock, bulkUnblockSaga),
  ]);
}

function* fetchUsersSaga() {
  try {
    const { id: orgId } = yield select(selectOrg);
    const { data }: { data: IUserOrganization[] } = yield userManagementCalls.getUsers({
      orgId,
    });
    yield put(fetchUsersSuccess(data));
  } catch (error) {
    yield put(fetchUsersError(error.message));
  }
}

function* updateUserFieldSaga(action) {
  try {
    const { id, field, value, fields } = action.payload;
    const reqData: any = { id };
    if (fields) {
      reqData.fields = fields;
    } else {
      reqData.fields = {
        [field]: value,
      };
    }

    const { data } = yield userManagementCalls.updateUser(reqData);
    const successValue = formatUpdateUserSuccessValue({ data, field, fields });
    const updatedData: any = { id };
    if (fields) {
      updatedData.fields = successValue;
    } else {
      updatedData.field = field;
      updatedData.value = successValue;
    }
    yield put(updateUserFieldSuccess(updatedData));
  } catch (error) {
    yield put(updateUserFieldError());
    toast.error(error.message);
  }
}

function* undoUpdateUserFieldSaga(action) {
  try {
    const { id, field, value, fields } = action.payload;
    const undo: any = { id };
    if (fields) {
      undo.fields = Object.keys(fields).reduce((undoObj: any, key: string) => {
        undoObj[key] = value;
        return undoObj;
      }, {});
    } else {
      undo.field = field;
      undo.value = value;
    }
    yield userManagementCalls.updateUser(undo);
    yield put(undoUpdateUserFieldSuccess(undo));
  } catch (error) {
    toast.error(error.message);
    yield put(undoUpdateUserFieldError());
  }
}

function* resetPasswordSaga(action) {
  try {
    const id = action.payload;
    yield userManagementCalls.resetPassword(id);
    toast('Password resetted successfully');
  } catch (error) {
    toast.error(error.message);
  }
}

function* bulkResetPasswordSaga(action) {
  try {
    const ids = action.payload;
    yield userManagementCalls.bulkResetPassword(ids);
    toast('Passwords resetted successfully');
  } catch (error) {
    toast.error(error.message);
  }
}

function* bulkBlockSaga(action) {
  try {
    const ids = action.payload;
    yield userManagementCalls.bulkBlock(ids);
    yield put(bulkBlockSuccess(ids));
    toast('Users blocked successfully');
  } catch (error) {
    toast.error(error.message);
  }
}

function* bulkUnblockSaga(action) {
  try {
    const ids = action.payload;
    yield userManagementCalls.bulkUnblock(ids);
    yield put(bulkUnblockSuccess(ids));
    toast('Users unblocked successfully');
  } catch (error) {
    toast.error(error.message);
  }
}

function formatUpdateUserSuccessValue({ data, field, fields }): any {
  if (fields) {
    return Object.keys(fields).reduce((updated: { [k: string]: any }, currentKey: string) => {
      updated[currentKey] = data[currentKey];
      return updated;
    }, {});
  }
  if (isDatasUpdate(field)) {
    return data[field].name;
  }
  return data[field];
}

function isDatasUpdate(field: string): boolean {
  return field.startsWith('data');
}
