import { createSlice } from '@reduxjs/toolkit';

import { IRootState } from '../../types/store';

const overviewSlice = createSlice({
  name: 'overview',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const { fetchOverview, fetchOverviewSuccess, fetchOverviewError } = overviewSlice.actions;

export default overviewSlice.reducer;

export const selectOverview = (state: IRootState) => state.overview;

function getInitialState() {
  return {
    isFetching: false,
    totalBites: '' as string | number,
    totalUsers: '' as string | number,
    totalPlaylists: '' as string | number,
    totalBitesViews: '' as string | number,
    error: '',
  };
}

type IOverviewState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}

function getReducers() {
  return {
    fetchOverview: (state: IOverviewState) => {
      state.isFetching = true;
      state.error = '';
    },
    fetchOverviewSuccess: (state: IOverviewState, action) => {
      state.isFetching = false;
      state.totalBites = action.payload.totalBites;
      state.totalUsers = action.payload.totalUsers;
      state.totalPlaylists = action.payload.totalPlaylists;
      state.totalBitesViews = action.payload.totalBiteViews;
    },
    fetchOverviewError: (state: IOverviewState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
  };
}
