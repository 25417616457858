import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import CorrectIcon from '../../../../components/Icons/CorrectIcon';
import WrongIcon from '../../../../components/Icons/WrongIcon';
import PercentsAndNumbers from '../../../../components/PercentsAndNumbers';
import StyledPercentage from '../../../../components/StyledPercentage';
import { EYesNo } from '../../../../constants/constants';
import { IFullPlaylist } from '../../../../types/playlist';
import { formatDateToRelativeTime } from '../../../../utils/dates';
import { percentage } from '../../../../utils/numbers';
import { selectPlaylist } from '../../playlist.slice';

const useRawColumns = (): TypeColumns => {
  const { totalQuestions } = useSelector(selectPlaylist);
  const { t } = useTranslation();

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const Bites = ({
      value,
      data,
    }: {
      value: number;
      data: { array: IFullPlaylist[]; __group: boolean; total: number };
    }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.watched));
        const total = sumBy(data.array, 'total');
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${data.total}`;
    };
    const Answered = ({ value, data }: { value: number; data: { array: IFullPlaylist[]; __group: boolean } }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.answered));
        const total = data.array.length * totalQuestions;
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${totalQuestions}`;
    };
    const AnsweredCorrectly = ({
      value,
      data,
    }: {
      value: any;
      data: { array: IFullPlaylist[]; __group: any; answered: number };
    }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => (item.answeredCorrectly ? Number(item.answeredCorrectly) : 0));
        const total = sumBy(data.array, (item) => Number(item.answered));
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${data.answered}`;
    };

    const Completed = ({ value, data }: { value: EYesNo; data: { array: IFullPlaylist[]; __group: boolean } }) => {
      if (data.__group) {
        const count = data.array.filter((item) => item.completed === EYesNo.YES).length;

        return <PercentsAndNumbers count={count} total={data.array.length} />;
      }

      return value === EYesNo.YES ? <CorrectIcon /> : <WrongIcon />;
    };

    const Started = ({ value, data }: { value: EYesNo; data: { array: IFullPlaylist[]; __group: boolean } }) => {
      if (data.__group) {
        const count = data.array.filter((item) => item.started === EYesNo.YES).length;

        return <PercentsAndNumbers count={count} total={data.array.length} />;
      }
      return value === EYesNo.YES ? <CorrectIcon /> : <WrongIcon />;
    };

    return [
      {
        name: 'email',
        maxWidth: 100,
        header: 'Email',
        render: EllipsisColumn,
      },
      {
        name: 'phone',
        maxWidth: 100,
        header: 'Phone',
        render: EllipsisColumn,
      },
      { name: 'watched', header: 'Bites Opened', maxWidth: 100, render: Bites },
      {
        name: 'answered',
        header: 'Questions Answered',
        maxWidth: 150,
        render: Answered,
      },
      {
        name: 'answeredCorrectly',
        header: 'Success',
        maxWidth: 100,
        render: AnsweredCorrectly,
      },
      {
        name: 'completed',
        header: 'Completed',
        render: Completed,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: EYesNo.YES, label: t('common.yes') },
            { id: EYesNo.NO, label: t('common.no') },
          ],
        },
        groupSummaryReducer: {
          initialValue: 0,
          reducer: (acc, completed) => acc + (completed === EYesNo.YES ? 1 : 0),
        },
      },
      {
        name: 'started',
        header: 'Started',
        render: Started,
        filterEditor: SelectFilter,
        filterEditorProps: {
          dataSource: [
            { id: EYesNo.YES, label: t('common.yes') },
            { id: EYesNo.NO, label: t('common.no') },
          ],
        },
        groupSummaryReducer: {
          initialValue: 'test',
          reducer: (acc, started) => acc + (started === EYesNo.YES ? 1 : 0),
        },
      },
      {
        name: 'lastVisit',
        maxWidth: 100,
        header: 'Last View Date',
        render: ({ value }) => {
          return value ? formatDateToRelativeTime(value) : '';
        },
      },
      {
        name: 'employeeId',
        maxWidth: 100,
        header: 'Employee Id',
        render: EllipsisColumn,
      },
    ];
  }, [t, totalQuestions]);
};

export default useRawColumns;
