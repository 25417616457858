import { Chip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectDatasStrings } from '../features/org/org.slice';
import gtmTrack from '../services/googleTagManager/track';
import { ITableDisplay } from '../types/reactDataGrid';

interface IProps {
  currentDisplay: ITableDisplay;
  setDisplay: (display: ITableDisplay) => void;
  origin?: string;
}

function SelectTableDisplay({ currentDisplay, setDisplay, origin }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const datasStrings = useSelector(selectDatasStrings);

  const onOverviewDepartmentClick = () => {
    dispatch(setDisplay('data1'));
    if (origin === 'bite') {
      gtmTrack('bite_overview_department');
    }
  };

  const onDetailedViewClick = () => {
    dispatch(setDisplay('drilldown'));
    if (origin === 'bite') {
      gtmTrack('bites_bite_detailed_view');
    } else if (origin === 'playlist') {
      gtmTrack('playlist_detailed_view');
    }
  };

  return (
    <div data-cy='selectTableDisplay'>
      {datasStrings[0] && (
        <Chip
          style={{ marginInlineEnd: '10px', marginBottom: 10 }}
          label={t('table.overviewLength', { data: datasStrings[0] })}
          onClick={onOverviewDepartmentClick}
          color={currentDisplay === 'data1' ? 'primary' : 'default'}
          data-cy='selectTableDisplayData1'
        />
      )}
      {datasStrings[1] && (
        <Chip
          style={{ marginInlineEnd: '10px', marginBottom: 10 }}
          label={t('table.overviewLength', { data: datasStrings[1] })}
          onClick={() => dispatch(setDisplay('data2'))}
          color={currentDisplay === 'data2' ? 'primary' : 'default'}
          data-cy='selectTableDisplayData2'
        />
      )}
      <Chip
        style={{ marginInlineEnd: '10px', marginBottom: 10 }}
        label={t('table.detailedView')}
        onClick={onDetailedViewClick}
        color={currentDisplay === 'drilldown' ? 'primary' : 'default'}
        data-cy='selectTableDisplayDrilldown'
      />
    </div>
  );
}

export default SelectTableDisplay;
