import { makeStyles } from '@material-ui/core';
import React from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';

import TextInput from './TextInput';

interface IProps {
  value: string;
  className?: string;
  onChange: (text: string) => void;
}

function SearchInput({ className, onChange, value }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.searchInputRoot}>
      <TextInput value={value} style={{ paddingRight: 27 }} onChange={onChange} className={className} />
      <SearchIcon className={classes.icon} />
    </div>
  );
}

const useStyles = makeStyles({
  searchInputRoot: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    width: 13,
    height: 14,
    top: 3,
    right: 3,
  },
});

export default SearchInput;
